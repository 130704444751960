import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { AppServiceService } from '../_services/app-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCalendar, NgbModal, NgbModalConfig, NgbDate, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'angular-toastify';
import { environment } from 'src/environments/environment';
import { DatePipe, PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { AlertService } from '@full-fledged/alerts';
import Swal from 'sweetalert2';
// import { google, calendar_v3 } from 'googleapis';

// import { google } from 'googleapis';
// const { google } = require('googleapis');

// const client_id = `${environment.client_id}`;
// const apiKey = `${environment.apiKey}`

// declare var google;

// declare var gapi;


const share = `${environment.share}`;


@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit {

  joinForm: FormGroup;
  imgFile: any;
  qqq: boolean;
  dropdownSettings: any = {};
  ShowFilter = true;
  allSegments: any = [];
  myEvents: any;
  selectedDay: string = 'Today'
  selectedSegment: string
  filteredEvents: any;
  eventId: any;
  perpages: any;
  pages = 1
  sizes: any;
  segment_id: any;
  start_date: any;
  end_date: any;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  coin_symbol: any = null;
  user_id: any = null;
  myZone: any;
  users: any;
  isAttendee: any;
  attendees: any;
  id: any;
  paidForm: any;
  event: any;
  content2: any;
  searchEvent: string
  timeOut: any;
  timeOutDuration = 3000;
  constructor(
    private service: AppServiceService,
    private router: Router,
    private modal: NgbModal,
    config: NgbModalConfig,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    calendar: NgbCalendar,
    private _toastService: ToastService,
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private clipboard: Clipboard,
    private alertService: AlertService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 7);


    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  ngOnInit(): void {
    this.listEvents()
    this.initForm()
    this.getZonebyId()

  }

  // join(event, content) {
  //   this.id = event.id
  //   this.attendees = event.attendee
  //   if (this.service.isLogedIn()) {
  //     this.attendEvent()
  //   } else {
  //     this.modal.open(content, {}).result
  //   }
  // }
  attendEvent() {
    this.service.attendEvent(this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        // this.getUser()
        if (res.data.make_payment) {
          this.modal.open(this.content2, {}).result
        }
        else {
          this.router.navigateByUrl('/events/' + this.id + '/rsvp')
        }
      },
      error: (err: any) => {
        console.log(err)
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        // this.listEvents()
        // this.getEventById()
        // this.router.navigateByUrl('/events/' + this.id + '/rsvp')
      }
    })
  }
  async getUser() {
    const a = await this.service.getUser()
    this.users = a
    console.log(this.users)
    if (this.users) {
      this.isAttendee = this.isRSVPed()
      console.log('Is attendee', this.isAttendee)
    }

  }

  isRSVPed() {
    for (const item of this.attendees) {
      if (item.user_id === this.users.id.toString()) {
        return true;
        // if (item.user.email === this.users.email && item.user.name === this.users.name) {
        //   return true;
        // }
      }
    }
    return false;
  }

  checkRSVP(data) {
    this.attendees = data.attendees
    this.getUser()
    this.isAttendee = this.isRSVPed()
  }



  // join(item, content) {
  //   this.eventId = item.id
  //   this.modal.open(content, {}).result
  // }

  initForm() {
    this.joinForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],

    });

    this.paidForm = this.fb.group({
      payment_method: ['', Validators.required]
    })
  }

  cancel() {
    this.modal.dismissAll()
    this.joinForm.reset();
  }


  submit() {
    const payload = {
      email: this.joinForm.value.email,
      password: this.joinForm.value.password,
      service: 'event'
    }

    this.service.login(payload).subscribe({
      next: async (res: any) => {
        console.log(res)
        const token = res.data.token
        this.getUserByToken(token);
        const result = this.service.setAuthFromLocalStorage(res);
      },
      error: (err: any) => {
        console.log(err)
        // this.alertService.danger('error')
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        this.cancel()
      }
    })

  }


  getUserByToken(token) {
    this.service.getUserByToken(token).subscribe({
      next: (res: any) => {
        // console.log('User reeeeeeee ', res)
        this.service.saveUserToStorage(res)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.getUser()
        this.attendEvent()
      }
    })
  }

  open(content3) {
    this.modal.open(content3, {}).result
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      // this.modal.dismissAll();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    // console.log(this.fromDate, this.toDate);
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }
  filter() {
    // console.log(this.fromDate, this.toDate)
    this.start_date = this.getDate(this.fromDate)
    this.end_date = this.getDate(this.toDate)
    console.log(this.start_date, this.end_date)
    if (this.start_date && this.end_date) {
      this.listEvents()
      this.modal.dismissAll()

    }

  }

  getDate(date) {
    const dateObject = date;

    const year = dateObject.year;
    const month = String(dateObject.month).padStart(2, '0');
    const day = String(dateObject.day).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;

  }
  clear() {
    this.fromDate = null;
    this.toDate = null;
    this.modal.dismissAll();
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  listEvents() {
    const token = '$GARY'
    this.service.getCreatorEvent(this.pages, token, this.start_date, this.end_date, this.searchEvent).subscribe({
      next: (event: any) => {
        console.log(event)
        this.myEvents = event.data.data
        const meta = event.data.meta
        this.perpages = meta.per_page
        this.sizes = meta.total
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  search(ev: any) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      if (ev) {
        this.searchEvent = ev;

        // this.getSearchedUsers(this.search)
      } else {
        this.searchEvent = '';
      }
      console.log(this.searchEvent)
      this.pages = 1
      this.listEvents()
    }, this.timeOutDuration);
    // this.searchEvent = '';
  }

  getPages(ev) {
    this.pages = ev
    this.listEvents()
  }

  filterBySegment(id) {
    // console.log(id)
    if (id !== 0) {
      this.segment_id = id
      this.listEvents()
      // if (this.myEvents.length > 0) {
      //   this.filteredEvents = this.myEvents.filter(event => event.segment_ids.includes(id));
      // }
    } else if (id === 0) {
      this.segment_id = null
      this.listEvents()
      // this.filteredEvents = this.myEvents
    }
  }

  onCLick(data) {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate);
    this.end_date = formattedDate;



    this.start_date = null
    this.selectedDay = data
    if (data === 'This week') {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 7);

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      const sevenDaysAgo = `${year}-${month}-${day}`;

      console.log(sevenDaysAgo);
      this.start_date = sevenDaysAgo
    }
    else if (data === 'Today') {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      const yesterday = `${year}-${month}-${day}`;

      this.start_date = yesterday
      console.log(yesterday);

    }

    this.listEvents()
  }

  clearFilter() {
    this.start_date = null
    this.end_date = null
    this.segment_id = null
    this.pages = 1
    // this.coin_symbol = null;
    this.user_id = null;
    this.searchEvent = null

    this.listEvents()
  }

  getSegmentById(id: any) {
    // console.log(id)
    // const item = this.allSegments.find((item) => item.id === id);

    return id;
  }

  view(item) {
    // console.log(item.id)
    this.router.navigateByUrl('/events/' + item.id)
  }

  getFormattedDate(date: any) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = this.datePipe.transform(date, 'yyyyMMdd\'T\'HHmmss', userTimezone);

    return formattedDate
  }
  addToCalendar(item) {
    this.checkRSVP(item)

    if (this.isAttendee) {
      const a = this.covertTZ(item.date, item.timezone)
      const b = this.covertTZ(item.end_date, item.timezone)
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const data = {
        name: item.name,
        startDate: this.getFormattedDate(a),
        endDate: this.getFormattedDate(b),
        ctz: userTimezone,
        details: item.description,
        location: item.location
      }
      // console.log(data)

      const params = new URLSearchParams();
      params.set('action', 'TEMPLATE');
      params.set('text', data.name);
      params.set('dates', data.startDate + '/' + data.endDate);
      params.set('ctz', data.ctz);
      params.set('details', data.details);
      params.set('location', data.location);

      const apiUrl = 'https://calendar.google.com/calendar/render';

      const link = apiUrl + '?' + params.toString();

      const anchor = this.elementRef.nativeElement.appendChild(document.createElement('a'));
      anchor.href = link;
      anchor.target = '_blank';

      anchor.click();

      // Cleanup: remove the anchor element
      anchor.remove();


    }



  }

  // copyLink(item) {
  //   const a = item.location
  //   console.log(a)
  //   this.copyToClipboard(a)
  // }

  copyToClipboard(item) {
    const url = `${share}/${item.id}`
    // const a = `https://gary.club/events/${item.id}`
    this.clipboard.copy(url)
    // this.alertService.success('Copied to clipboard');
    Swal.fire('', 'Copied to clipboard', 'success')

  }



  // copyToClipboarda(text: string) {
  //   const textArea = this.renderer.createElement('textarea');
  //   textArea.value = text;
  //   this.renderer.appendChild(document.body, textArea);
  //   textArea.select();
  //   document.execCommand('copy');
  //   this.renderer.removeChild(document.body, textArea);
  //   this._toastService.success('Copied to clipboard');
  // }

  // copyToClipboard(text: string) {
  //   this.clipboard.copy(text)
  //   this.alertService.success('Copied to clipboard');

  // }

  getZonebyId() {
    this.service.getZoneByIp().subscribe({
      next: (res: any) => {
        this.myZone = res
        console.log('timezone re naw', res)
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('usertimezone', userTimezone)
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  isValidTimezoneFormat(timezone: string): boolean {
    // Regular expression to match the format 'Continent/City' or 'Continent/Country/City'
    const timezoneRegex = /^[A-Za-z_\-/]+(\/[A-Za-z_\-/]+)*$/;

    // console.log(timezoneRegex.test(timezone))
    // Check if the input timezone matches the regular expression
    return timezoneRegex.test(timezone);
  }

  covertTZ(fromDate: string, fromTz: string): string {
    // console.log(fromDate, fromTz, toTz)
    if (!this.isValidTimezoneFormat(fromTz)) {
      // throw new Error("Invalid timezone format. Please use the format 'Continent/City'.");
      return fromDate
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (fromTz === userTimezone) {
      return fromDate
    } else if (fromTz === null) {
      return fromDate
    }
    else {
      const fromDatetime = parseISO(fromDate);


      // Convert the datetime to UTC
      const utcDatetime = zonedTimeToUtc(fromDatetime, fromTz);

      // Convert the UTC datetime to the target timezone
      const toDatetime = format(utcDatetime, 'yyyy-MM-dd HH:mm:ssXXX', {
        timeZone: userTimezone,
      });

      return toDatetime;
    }


  }

  rsvpEvent(content, event, content2) {
    this.id = event.id
    this.event = event
    this.content2 = content2
    if (this.service.isLogedIn()) {
      this.attendEvent()
      // if (event.ticket === 'paid') {
      //   this.modal.open(content2, {}).result
      // }
      // else {
      //   this.attendEvent()
      // }


    } else {
      this.modal.open(content, {}).result
    }
  }




  payNow() {
    const modalRef = this.modal.open(LoaderModalComponent);
    console.log(modalRef)
    const pay = {
      payment_method: this.paidForm.value.payment_method,
    }

    console.log(pay)

    this.service.makePayment(pay, this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.page_link) {
          window.location.href = res.page_link
        } else {
          modalRef.dismiss();
          this.router.navigateByUrl('/events/' + this.id + '/rsvp')
        }
      },
      error: (err: any) => {
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
        modalRef.dismiss();
      },
      complete: () => {
        this.modal.dismissAll()
        modalRef.dismiss();

        // this.router.navigateByUrl('/events/' + this.id + '/rsvp')
      }
    })
  }



  // signInWithGoogle() {
  //   // @ts-ignore
  //   if (typeof google === 'undefined') {
  //     console.error('Google Sign-In API library is not loaded.');
  //     return;
  //   }

  //   // @ts-ignore
  //   google.accounts.id.initialize({
  //     client_id: `${client_id}`,
  //     callback: this.handleGoogleSignIn.bind(this)
  //   });

  //   // @ts-ignore
  //   google.accounts.id.prompt();
  // }

  // handleGoogleSignIn(response: any) {
  //   // console.log(response);
  //   // const idToken = response.getAuthResponse().id_token;

  //   // Call a function to add the event using the obtained ID token (idToken)
  //   // this.addEventToGoogleCalendar(response);
  //   this.loadClient(response.credential)
  // }


  // addEventToGoogleCalendar(idToken: any) {
  //   console.log(google)
  //   // @ts-ignore
  //   const calendar = google.calendar('v3');
  //   const a = google.calendar('v3')

  //   const event = {
  //     summary: 'My Event',
  //     location: 'Event Location',
  //     start: {
  //       dateTime: '2023-06-15T10:00:00',
  //       timeZone: 'UTC+1'
  //     },
  //     end: {
  //       dateTime: '2023-06-15T12:00:00',
  //       timeZone: 'UTC+1'
  //     }
  //   };

  //   calendar.events.insert({
  //     calendarId: 'primary',
  //     events: event,
  //     auth: idToken
  //   }, (error, response) => {
  //     if (error) {
  //       console.error('Error adding event:', error);
  //     } else {
  //       console.log('Event added:', response.data);
  //     }
  //   });
  // }




  // getRelativeDate(daysOffset, hour) {
  //   const date = new Date();
  //   date.setDate(date.getDate() + daysOffset);
  //   date.setHours(hour);
  //   date.setMinutes(0);
  //   date.setSeconds(0);
  //   date.setMilliseconds(0);
  //   return date;
  // }

  // authenticate() {
  //   return gapi.auth2.getAuthInstance()
  //     .signIn({ scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events" })
  //     .then(() => {
  //       console.log("Sign-in successful");
  //       this.loadClient()
  //     })
  //     .catchError((err) => {
  //       console.error("Error signing in", err);
  //     })

  //   // function (err) { console.error("Error signing in", err); });
  // }
  // loadClient(credential) {
  //   gapi.client.setApiKey(`${apiKey}`);
  //   return gapi.client.load("https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest")
  //     .then(function () { createEvent(credential) },
  //       function (err) { console.error("Error loading GAPI client for API", err); });
  // }
  // Make sure the client is loaded and sign-in is complete before calling this method.
  // execute() {
  //   return gapi.client.calendar.events.insert({
  //     "resource": {
  //       "end": {},
  //       "start": {}
  //     }
  //   })
  //     .then(function (response) {
  //       // Handle the results here (response.result has the parsed body).
  //       console.log("Response", response);
  //     },
  //       function (err) { console.error("Execute error", err); });
  // }

  // createEvent() {
  //   // const calendarId = 'primary';
  //   const start = this.getRelativeDate(1, 12);
  //   const end = this.getRelativeDate(1, 13);
  //   // event details for creating event.
  //   let event = {
  //     id: '1',
  //     summary: 'Lunch Meeting',
  //     location: 'The Deli',
  //     description: 'To discuss our plans for the presentation next week.',
  //     start: {
  //       dateTime: start.toISOString()
  //     },
  //     end: {
  //       dateTime: end.toISOString()
  //     },
  //     attendees: [
  //       { email: 'gduser1@workspacesample.dev' },
  //       { email: 'gduser2@workspacesample.dev' }
  //     ],
  //     "creator": {
  //       "displayName": "Gary",
  //       "email": "gary@gmail.com"
  //     },
  //     // Red background. Use Calendar.Colors.get() for the full list.
  //     colorId: 11
  //   };
  //   try {
  //     // call method to insert/create new event in provided calandar
  //     event = gapi.client.calendar.events.insert(event);
  //     console.log('Event ID: ' + event.id);
  //   } catch (err) {
  //     console.log('Failed with error %s', err.message);
  //   }
  // }


}
// function createEvent(credential) {
//   console.log('Creating event', credential)
//   // const start = this.getRelativeDate(1, 12);
//   // const end = this.getRelativeDate(1, 13);
//   // event details for creating event.
//   const calendarId = 'primary';
//   let event = {
//     id: '1',
//     summary: 'Lunch Meeting',
//     location: 'The Deli',
//     description: 'To discuss our plans for the presentation next week.',
//     start: {
//       dateTime: "2023-06-25T11:00"
//     },
//     end: {
//       dateTime: "2023-06-26T11:00"
//     },
//     attendees: [
//       { email: 'gduser1@workspacesample.dev' },
//       { email: 'gduser2@workspacesample.dev' }
//     ],
//     "creator": {
//       "displayName": "Gary",
//       "email": "gary@gmail.com"
//     },
//     // Red background. Use Calendar.Colors.get() for the full list.
//     colorId: 11
//   };

//   // gapi.client.calendar.events.insert({
//   //   calendarId: 'primary',
//   //   resource: event,
//   //   auth: credential,
//   // }).then((response) => {
//   //   console.log('Event added:', response.result);
//   // }, (error) => {
//   //   console.error('Error adding event:', error);
//   // });
//   try {
//     // call method to insert/create new event in provided calandar
//     gapi.client.calendar.events.insert({
//       calendarId: "primary",
//       resource: event,
//       // auth: credential,
//     }).then((res) => {
//       console.log('Event added:', res);
//       return res
//     })
//     // console.log(event);
//     // return event
//   } catch (err) {
//     console.log('Failed with error %s', err.message);
//     return null
//   }
// }

// function execute(credential) {
//   return gapi.client.calendar.events.insert({
//     "calendarId": "primary",
//     auth: credential,
//     "resource": {
//       "end": {
//         "date": "2023-06-25",
//         "timeZone": "UTC+1"
//       },
//       "start": {
//         "dateTime": "2023-06-25T11:00"
//       },
//       "attendees": [
//         {
//           "email": "test@gmail.com"
//         }
//       ],
//       "location": "discord",
//       "description": "lorem",
//       "creator": {
//         "displayName": "Gary",
//         "email": "gary@gmail.com"
//       },
//       "colorId": "11"
//     }
//   })
//     .then(function (response) {
//       // Handle the results here (response.result has the parsed body).
//       console.log("Response", response);
//     },
//       function (err) { console.error("Execute error", err); });
// }

// function listCalendars() {
//   let calendars;
//   let pageToken;
//   do {
//     calendars = gapi.client.calendar.CalendarList.list({
//       maxResults: 100,
//       pageToken: pageToken

//     });
//     if (!calendars.items || calendars.items.length === 0) {
//       console.log('No calendars found.');
//       return;
//     }
//     // Print the calendar id and calendar summary
//     for (const calendar of calendars.items) {
//       console.log('%s (ID: %s)', calendar.summary, calendar.id);
//     }
//     pageToken = calendars.nextPageToken;
//   } while (pageToken);
// }



@Component({
  selector: 'app-loader-modal',
  template: `
  <div class="modal-body modal-center p-5 rounded-5">
    <div class="d-flex align-items-center">
      <div class="d-block">
        <h3>Please Wait...</h3>
  <p class="texs-sm">This step may take up to 1 minute</p>
      </div>
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl ms-auto"></i>
    </div>

  <!-- <br> -->
  <!-- <div class="text-center mt-3">
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl"></i>
  </div> -->
</div>
  `,
  styles: [
    `
     .text.active {
   background-color: var(--bs-primary) !important;
   color: var(--bs-text-secondary) !important;

 }

 .text {
   border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
   border-radius: 50%;
 }
    `
  ]
})
export class LoaderModalComponent implements OnInit {
  constructor(private modalService: NgbModal, config: NgbModalConfig,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

  // openModal(): NgbModalRef {
  //   return this.modalService.open(content);
  // }

  closeModal(modalRef: NgbModalRef): void {
    modalRef.dismiss();
  }
}

