<div class=" relative">
  <div class="overlay mt-3" style="height: 200px;" *ngIf="loading">
    <p class="text-center">
      This is a feed of the creators you are supporting.
    </p>
    <p class="text-center">As you support new creators we will start to fill
      this feed for you</p>
  </div>
  <div *ngIf="!loading">
    <div class="text-center my-3 " *ngIf="errorMessage">
      <p> {{ errorMessage }} </p>
    </div>
    <div class="text-center my-3 " *ngIf="app.activityLogs?.length <=0">
      <p class="text-center">
        This is a feed of the creators you are supporting.
      </p>
      <p class="text-center">As you support new creators we will start to fill
        this feed for you</p>
    </div>

    <!-- <div class="row align-items-center mb-3" *ngFor="let item of supportingactivityLogs">
      <div class="col-5 col-md-4">
        <div class="d-flex align-items-center ">
          <span>
            <a [href]="item?.image_link" *ngIf="item?.image_link  !== null">
              <img [src]="item?.image" onerror="this.onerror=null;this.src='assets/imgs/placeholder.png'"
                class="rounded-circle actimg">
            </a>
            <img *ngIf="item?.image_link  === null" [src]="item?.image"
              onerror="this.onerror=null;this.src='assets/imgs/placeholder.png'" class="rounded-circle  actimg">
          </span>
          <span class="ms-auto"><i class="fa-solid fa-arrow-right fa-lg"></i></span>
          <span class=" ms-auto ">
            <a *ngIf="item?.owner_link  !== null" [href]="item?.owner_link">
              <img [src]="item?.owner" onerror="this.onerror=null;this.src='assets/imgs/placeholder.png'"
                class="rounded-circle actimg">
            </a>
            <img *ngIf="item?.owner_link  === null" [src]="item?.owner"
              onerror="this.onerror=null;this.src='assets/imgs/placeholder.png'" class="rounded-circle  actimg">
          </span>


        </div>

      </div>
      <div class="col-7 col-md-8">
        <div class="ms-md-5 d-block test">
          <span class="smalltext" [innerHTML]="item?.text"></span>
          <p class="mb-0"> {{ item?.date }} </p>
        </div>
      </div>
    </div> -->

    <div class="row " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="loadMore()">
      <div class="col-md-12">
        <ul class="list-unstyled border">


          <li class="border-bottom" *ngFor="let item of app.activityLogs">
            <!-- <app-post-item [item]="item" [user]="user" [app]="app"></app-post-item>
            <app-sale-item [item]="item" [user]="user" [app]="app"></app-sale-item>
            <app-reward-item [item]="item" [user]="user" [app]="app"></app-reward-item>
            <app-general-item [item]="item" [user]="user" [app]="app"></app-general-item>
            <app-offers-item [item]="item" [user]="user" [app]="app"></app-offers-item>
            <app-events-item [item]="item" [user]="user" [app]="app"></app-events-item>
            <app-pow-item [item]="item" [user]="user" [app]="app"></app-pow-item>
            <app-subscription-item [item]="item" [user]="user" [app]="app"></app-subscription-item>
            <app-referral-item [item]="item" [user]="user" [app]="app"></app-referral-item> -->
            <app-custom [item]="item" [user]="user" [app]="app"></app-custom>

          </li>
        </ul>
        <!-- <br> -->
        <!-- <div class="text-end">
                      <button class="btn btn-clear " (click)="getMoreActivities()">
                        Load more
                      </button>
                    </div> -->
      </div>
    </div>

    <div class="mt-3 text-center" *ngIf="newloading">
      <i class="fa-solid fa-spinner fa-spin fa-2x"></i>
    </div>


    <!-- <div class="text-end mt-3" *ngIf="!newloading">
      <button *ngIf="!hideLoad" class="btn btn-dark" (click)="loadMore()">Load more</button>
    </div> -->
  </div>
</div>




<!--
  activity: "sold_product"
​​​​
date: "15 hours ago"
​​​​
id: 3635
​​​​
image: "https://pub-3a9bbf364b7e4fafb2ccf4e85d9eb22a.r2.dev/profile-pictures/pm@digitalmarketing.orgprs-65063fd400fad.jpg"
​​​​
text: "Sold product for 1 $DEV"
 -->