import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CompleteregService } from '../../../_services/completereg.service';
// import { UserType } from 'src/app/modules/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { WalletService } from '../../../_services/wallet.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BN } from '@project-serum/anchor';
import { PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
// import { ModalPopupComponent } from 'src/app/_metronic/layout/components/modal-popup/modal-popup.component';
import { BuySOLComponent } from '../buySOL/buySOL.component';
import { AppServiceService } from 'src/app/_services/app-service.service';

export type UserType = any | undefined;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() showModal: boolean = false;
  @Input() showSOLModal: boolean = false;
  @Input() editTowerModal: boolean = false;
  @Input() editTowerVal: any;
  @Input() iframeSrc: string = '';
  @Input() reloadTower: any;
  @Input() totalBalance: any;
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  evenForm: FormGroup;
  user$: Observable<UserType>;
  SOLBalance: any;
  custodial_wallet: any;
  ShowFilter = true;
  towerTitle: string = '';
  towerDescription: string = '';
  floorPrice: number;
  floorValue: number;
  selectedSegment: string = '';
  selectedSegmentsArray: string[] = [];
  towerLink: string = '';
  towerId: string = '';
  dropdownSettings: any = {};
  towerData: {
    link: string,
    name: string,
    floorPrice: number,
    floorValue: number,
    description: string,
  };
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;

  @ViewChild(BuySOLComponent) modalPopup: BuySOLComponent;

  ngOnChanges(changes: SimpleChanges): void {

  }

  constructor(
    private service: CompleteregService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private settings: AppServiceService,
    private clipboard: Clipboard,
    private walletservice: WalletService,
  ) {
  }

  ngOnInit(): void {
    this.getSOLBalance()
    this.user$ = this.settings.getUserDetails();
    this.user$.subscribe((_user) => {
      if (_user?.custodial_wallet !== '') {
        this.custodial_wallet = _user?.custodial_wallet
      }
    })
    this.walletservice.initialize();
  }


  // save wallet
  async loadWallet() {
    let wllta = this.walletservice.wallet.publicKey.toString()

    console.log(this.custodial_wallet);
    await this.sendFee();
  }

  ngOnDestroy() {
    // if (this.refresh) {
    //   clearInterval(this.refresh);
    // }
  }

  onDisconnect() {
    this.walletservice.onDisconnect();
  }

  onSelectWallet(walletName: any) {
    this.walletservice.onSelectWallet(walletName);
  }

  handleModalToggle() {
    this.showSOLModal = !this.showSOLModal;
    this.iframeSrc = `https://onramp.mirrorworld.fun/?api_key=mw_AD3fNZ8vygPjG5NGsWfdYlYYgXqOogqNnx2&network=solana&crypto_currency=sol&wallet_address=${this.custodial_wallet}`

  }

  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // //console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  fixUnsafe(url: any) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async getSolanaBalance() {
    try {
      return (
        (await this.walletservice.connection.getBalance(
          this.walletservice.wallet.publicKey
        )) / 1000000000
      );
    } catch (error) {
      return 0;
    }
  }

  async sendFee() {
    let feeBN = new BN(0.05 * Math.pow(10, 9));
    let solBalance = await this.getSolanaBalance();
    let solBalanceBN = new BN(solBalance * Math.pow(10, 9));
    if (feeBN.gt(solBalanceBN)) {
      Swal.fire(
        'Insuficient Balance',
        `You do not have enough SOL to complete the transaction`,
        'error'
      );
      this.isLoading$$.next(false);
      return 'err';
    }
    else return null;


    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: this.walletservice.wallet.publicKey,
        toPubkey: new PublicKey(this.custodial_wallet),
        lamports: 0.05 * Math.pow(10, 9),
      })
    );

    const blockHash = await this.walletservice.connection.getLatestBlockhash();
    transaction.feePayer = this.walletservice.wallet.publicKey;
    transaction.recentBlockhash = await blockHash.blockhash;
    // //console.log(transaction);
    try {
      const signed = await this.walletservice.wallet.signTransaction(
        transaction
      );
      // //console.log(signed);
      const signature = await this.walletservice.connection.sendRawTransaction(
        signed.serialize()
      );
      let res = await this.walletservice.connection.confirmTransaction(
        signature,
        'processed'
      );
      this.isLoading$$.next(false);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        html:
          'Wallet funded successfully<br/><br/> ' +
          '<a href="https://solscan.io/tx/' +
          signature +
          '" target="_blank">View Transaction</a>',
      });
      return 'paid';
    } catch (error: any) {
      this.isLoading$$.next(false);
      Swal.fire('Failed', error.message, 'error');
    }
  }

  closeModal() {
    this.showModal = !this.showModal;
  }


  copy(code: string) {
    this.clipboard.copy(code);

    Swal.fire('Copied', '', 'success')
  }

  getSOLBalance() {
    this.settings.getSolBalance().subscribe({
      next: (res: any) => {
        this.SOLBalance = res.data.balance
        if (this.SOLBalance >= 0.05) {
          this.closeModal();
        }
        localStorage.setItem("sol_balance", JSON.stringify(res.data.balance));
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

}
