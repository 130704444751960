<div class="row">
  <div class="col-12 text-center relative" *ngIf="startloading">
    <div class="overlay" style="height: 100px;"></div>
  </div>
</div>
<div class="row" *ngIf="!startloading">
  <div class="col-md-8 offset-md-2 mb-4">
    <div class="text-end">
      <a [routerLink]="['/pow']" class="btn btn-clear me-3 border border-danger" placement="top"
        ngbTooltip="Submit POW">
        <i class="fa-regular fa-plus text-danger"></i>
      </a>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-primary" [ngClass]="powDisplay === 'list' ? 'selected' : 'active2'"
          (click)="switchView('list')">
          <i class="fa-regular fa-list-ul"></i>
        </button>
        <button type="button" class="btn btn-primary" [ngClass]="powDisplay === 'grid' ? 'selected' : 'active2'"
          (click)="switchView('grid')">
          <i class="fa-solid fa-grid"></i>
        </button>
      </div>
    </div>
  </div>
</div>



<div class="row" *ngIf="powDisplay==='list'">
  <div class="col-md-8 offset-md-2">
    <ul class="list-unstyled border">
      <span *ngFor="let item of data2">
        <li class="border-bottom" *ngIf="item?.work_sumarry">
          <div class="py-2 px-3">
            <div class="d-flex mb-2 align-items-center">
              <p class="text-w-light text-muted mb-0">
                <span class="icon_padding me-2">
                  <i class="fa-regular fa-sm fa-briefcase"></i>
                </span>
                POW
              </p>
              <div class="ms-auto ">
                <span class="text-muted">{{ item?.date | date: 'MMM dd, YYYY' }}</span>
              </div>
            </div>
            <div class="d-flex align-items-start">
              <div class="activity_content mb-1">
                <ul *ngIf="item?.work_sumarry" class="summary">
                  <li *ngFor="let work of item?.work_sumarry; let i =  index">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M0.5 0V8C0.5 9.65685 1.84315 11 3.5 11H11.5" stroke="white" />
                      </svg>
                    </i>
                    <span>
                      {{ work }}
                    </span>

                  </li>
                </ul>
                <div *ngIf="!item?.work_sumarry ">
                  <p class="mb-0">No data for today</p>
                </div>
              </div>

            </div>
            <div class="mt-1 d-flex ">
              <div class="  d-block ms-3 ">
                <p class="mb-0 text-muted">Price</p>
                <h6> {{app.getPriceInGary(item?.price_usd_with_fee) || '0' | number : '0.0-2'}} {{ app.coin_symbol }}
                </h6>

              </div>
              <div class="  d-block ms-5 ">
                <p class="mb-0 text-muted">Volume</p>
                <h6>{{app.getPriceInGary(item?.volume_stake) || '0' | number : '0.0-2'}} {{ app.coin_symbol }}</h6>

              </div>
              <div class=" ms-5  ">
                <p class="mb-0 text-muted">Points</p>
                <h6>{{item?.score || '0'}}</h6>
              </div>

            </div>
          </div>
        </li>
      </span>

    </ul>
  </div>
</div>


<div *ngIf="powDisplay==='grid'">
  <div class="card pcard align-items-center">
    <div class=" relative" *ngIf="startloading" style="height: 390px;width: 100%;">
      <div class="overlay"></div>
    </div>
    <div class="card-body test" *ngIf="!startloading">

      <div class="">

        <div class="flex flex-row ps-3">
          <div class="flex flex-col">
            <div class="graph">
              <ul class="months  w-fit">
                <li>Jan</li>
                <li>Feb</li>
                <li>Mar</li>
                <li>Apr</li>
                <li>May</li>
                <li>Jun</li>
                <li>Jul</li>
                <li>Aug</li>
                <li>Sep</li>
                <li>Oct</li>
                <li>Nov</li>
                <li>Dec</li>
              </ul>

              <ul class="squares">
                <li class=" false
                  rounded-2px sm:rounded group relative flex flex-col items-center pointer"
                  *ngFor="let item of updateYear" (click)="onDayClicked(item.date)" placement="top"
                  ngbTooltip="{{ item.date | date  : 'MMMM dd'  }}">
                  <span class="bg-list" [class]="'intensity'+item.intensity"></span>
                  <!-- {{ item | date: 'dd' }} -->
                </li>
              </ul>
            </div>

          </div>

          <div class="hidden lg:block">
            <div class="flex flex-row lg-flex-col mt-9 ms-2 px-3">
              <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm active" *ngFor="let item of years"
                [ngClass]="{'active': item.year === yearToSend}"
                (click)="item.year !== yearToSend ? changeYear(item.year) : ''">
                {{item.year}} </div>
              <!-- <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm">2021</div> -->
            </div>
          </div>
        </div>
        <div class=" lg:hidden">
          <div class="flex flex-row lg-flex-col mt-9 ms-2 px-3">
            <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm active" *ngFor="let item of years"
              [ngClass]="{'active': item.year === yearToSend}"
              (click)="item.year !== yearToSend ? changeYear(item.year) : ''">
              {{item.year}} </div>

            <!-- <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm">2021</div> -->
          </div>
        </div>
      </div>
      <div class="max-width-100 ms-auto me-auto">
        <!-- <h4 class="text-[12px] text-darkButtonBg mr-2">Low Impact</h4> -->
        <div class="flex flex-row mt-5 justify-content-center items-center mb-12 align-items-center intensitycard">
          <div class="intensity intensity0"></div>
          <div class="intensity intensity1"></div>
          <div class="intensity intensity2"></div>
          <div class="intensity intensity3"></div>
          <div class="intensity intensity4"></div>
          <div class="intensity intensity5"></div>
          <h4 class="text12px text-darkButtonBg ms-2">Intensity</h4>
        </div>
      </div>
    </div>
  </div>


  <div class="row mt-5 align-items-center" *ngIf="!startloading">
    <div class="col-md-1 offset-md-1 text-end">
      <button (click)="prevDay()" class="btn btn-icon btn-clear hiddenonsmall" *ngIf="total > 1">
        <i class="fa-solid fa-chevron-left text-primary"></i>
      </button>
      <i class="fa"></i>
    </div>
    <div class="col-md-8 ">
      <div class="card card-border">
        <div class="card-header py-3">
          <div class="row align-items-center justify-content-evenly ps-0 ps-md-3 ps-lg-5">
            <div class="col-sm-4 mb-3 mb-md-0 mb-lg-0 mb-xl-0 hmm">
              <h4>{{ selectedDate | date : "longDate" }}</h4>
            </div>
            <!-- <div class="col border border-start-0 border-bottom-0 border-top-0 d-block ps-3 ">
              <p>Reward Coin</p>
              <h6>{{POWStats?.reward_coin }}</h6>

            </div> -->
            <div class="col border border-start-0 border-bottom-0 border-top-0 d-block ps-3 ">
              <p>Price</p>
              <h6>{{app.getPriceInGary(dateData?.price_usd_with_fee) || '0' | number : '0.0-2'}} {{ app.coin_symbol }}
              </h6>
              <!-- <h6>$ {{dateData?.price_usd_with_fee | number : '0.0-4' || '-'}}</h6> -->

            </div>
            <div class="col border border-start-0 border-bottom-0 border-top-0 d-block ps-3 ">
              <p>Volume</p>
              <h6>{{app.getPriceInGary(dateData?.volume_stake) || '0' | number : '0.0-2'}} {{ app.coin_symbol }}</h6>

            </div>
            <div class="col ps-3 ms-auto me-auto ">
              <p>Points</p>
              <h6>{{dateData?.score || '-'}}</h6>
            </div>
          </div>
        </div>
        <div class="card-body ps-5">
          <ul *ngIf="dateData" class="summarry">

            <li *ngFor="let item of dateData?.work_sumarry; let i =  index">
              <span>
                {{ item }}
              </span>

            </li>
          </ul>

          <div *ngIf="!dateData?.work_sumarry ">
            <p>No data for today</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1">
      <button (click)="nextDay()" class="btn btn-icon btn-clear hiddenonsmall" *ngIf="total > 1">
        <i class="fa-solid fa-chevron-right text-primary"></i>
      </button>
    </div>
  </div>
</div>