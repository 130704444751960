import { Component, OnInit, OnDestroy, Input } from '@angular/core';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
// export class AppComponent implements OnInit, OnDestroy {
//   year = environment.year
//   theme: string
//   public isMenuCollapsed = true;
//   readonly wallets$ = this.walletservice.wallets$;
//   readonly wallet$ = this.walletservice.wallet$;
//   readonly walletName$ = this.walletservice.walletName$;
//   readonly walletIcon$ = this.walletservice.walletIcon$;
//   readonly ready$ = this.walletservice.ready$;

//   readonly connected$ = this.walletservice.connected$;
//   public publicKey$ = this.walletservice.publicKey$;
//   garyPrice: number = null;
//   coin_symbol: any;
//   joinForm: FormGroup;
//   noteForm: FormGroup;
//   content: any;
//   url: any;
//   refresh: NodeJS.Timeout;
//   private authLocalStorageToken = `user`;
//   private userLocalStorageToken = `userdetails`;
//   isAuthenticated: boolean;
//   showsearch: boolean;

//   constructor(
//     private app: AppServiceService,
//     private alertService: AlertService,
//     private loadingBar: LoadingBarService,
//     private walletservice: WalletService,
//     private sanitizer: DomSanitizer,
//     private clipboard: Clipboard,
//     private router: Router,
//     private modal: NgbModal,
//     config: NgbModalConfig,
//     private service: AppServiceService,
//     private fb: FormBuilder,
//   ) { }
//   ngOnInit(): void {
//     this.url = window.location.pathname;
//     this.getTheme()
//     this.walletservice.initialize();
//     this.getpricewid();
//     this.initForm()
//     this.getAuth()
//     this.scrollPageToTop()
//   }
//   scrollPageToTop() {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth"
//     });
//   }
//   loginModal(content) {
//     this.modal.open(content, { backdrop: 'static' }).result
//   }
//   cancel() {
//     this.modal.dismissAll()
//     this.noteForm.reset();
//     this.joinForm.reset()
//     // this.content = null
//   }
//   initForm() {
//     // this.noteForm = this.fb.group({
//     //   amount: ['', Validators.required],
//     //   message: ['', Validators.required],
//     //   to: this.social_tag,
//     // })

//     this.joinForm = this.fb.group({
//       email: ['', Validators.required],
//       password: ['', Validators.required],

//     });
//   }
//   login() {
//     const payload = {
//       email: this.joinForm.value.email,
//       password: this.joinForm.value.password,
//       service: 'event'
//     }

//     this.service.login(payload).subscribe({
//       next: async (res: any) => {
//         console.log(res)
//         const token = res.data.token
//         this.getUserByToken(token);
//         const result = this.service.setAuthFromLocalStorage(res);
//         // location.reload();
//       },
//       error: (err: any) => {
//         console.log(err)
//         // this.alertService.danger('error')
//         // this.alertService.danger(err.error.message)
//         Swal.fire('', err.error.message, 'error')
//       },
//       complete: () => {
//         this.cancel()
//       }
//     })

//   }
//   getUserByToken(token) {
//     this.service.getUserByToken(token).subscribe({
//       next: (res: any) => {
//         // console.log('User reeeeeeee ', res)
//         this.service.saveUserToStorage(res)
//       },
//       error: (err: any) => {
//         console.log(err)
//       },
//       complete: () => {
//         location.reload();
//         //  this.submit()
//         // this.modal.open(this.content, {}).result
//       }
//     })
//   }
//   getAuth() {
//     const auth = this.app.getAuth()
//     console.log(auth)
//     if (auth) {
//       this.isAuthenticated = true
//     } else {

//       this.isAuthenticated = false
//       localStorage.removeItem(this.authLocalStorageToken);
//       localStorage.removeItem(this.userLocalStorageToken);
//     }
//   }
//   ngOnDestroy() {
//     if (this.refresh) {
//       clearInterval(this.refresh);
//     }
//   }

//   getTheme() {
//     if (localStorage.getItem('theme') == null) {
//       this.theme = 'dark-theme';
//       localStorage.setItem('theme', 'dark-theme');
//       document.body.classList.add('dark-theme');
//     } else {
//       this.theme = localStorage.getItem('theme') || 'light';
//     }

//   }


//   toggleDarkTheme(): void {
//     let theme = localStorage.getItem('theme');

//     if (theme === 'dark-theme') {
//       document.body.classList.remove('dark-theme');
//       localStorage.setItem('theme', 'light');
//     } else {
//       localStorage.setItem('theme', 'dark-theme');
//       document.body.classList.add('dark-theme');
//     }
//     window.location.reload();
//   }


//   onDisconnect() {
//     this.walletservice.onDisconnect();
//   }

//   onSelectWallet(walletName) {
//     this.walletservice.onSelectWallet(walletName);
//   }

//   truncate(name: any) {
//     const name2: string = JSON.stringify(name);
//     const a = JSON.parse(name2);

//     var truncateRegex =
//       /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
//     var match = a.match(truncateRegex);
//     // console.log(match);
//     if (!match) return name;
//     return match[1] + '\u2026' + match[2];
//   }

//   fixUnsafe(url) {
//     //  let newurl =url;
//     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
//   }

//   copyText() {
//     // console.log(this.walletservice.wallet.publicKey);
//     this.clipboard.copy(this.walletservice.wallet.publicKey);
//     this.alertService.success('Copied');
//   }

//   getpricewid() {
//     this.app.getGaryPrice().subscribe({
//       next: (res: any) => {
//         console.log(res);
//         this.coin_symbol = res.data.coin.coin_symbol;
//         this.garyPrice = res.data.price_usd.toFixed(4);
//       },
//     });
//   }

//   onClick() {
//     // this.alertService.info('This service is coming soon');
//     // this.router.navigateByUrl('https://app.socialconnector.io/auth/registration/g104akl3')
//     const link = 'https://app.socialconnector.io/auth/registration/g104akl3'
//     let a = document.createElement('a');
//     document.body.appendChild(a);
//     a.href = link;
//     a.click();
//     document.body.removeChild(a);
//   }

//   logOut() {
//     // this.currentUserSubject.next(null);
//     const returnUrl = location.pathname;
//     localStorage.removeItem(this.authLocalStorageToken);
//     localStorage.removeItem(this.userLocalStorageToken);
//     window.location.reload()
//   }

//   search(content2) {
//     this.modal.open(content2, { centered: false })
//   }

//   testSearch() {
//     this.showsearch = true
//   }
// }

export class AppComponent { }
