import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { AppServiceService } from 'src/app/_services/app-service.service';
// import { TestService } from '../test.service';

interface Task {
  grow_audience: string;
  make_money: string;
  intensity: number;
  work_sumarry: string[];
  date: string;
  price: number;
  score: string;
  volume: number;
  volume_stake: number;
  price_usd_with_fee: number;
}
@Component({
  selector: 'app-team-pow',
  templateUrl: './team-pow.component.html',
  styleUrls: ['./team-pow.component.scss']
})
export class TeamPOWComponent implements OnInit {
  @Input() social_tag: string;
  yearCalendar: any[];
  selectedDate: any;
  data: Task[];
  dateData: Task;
  updateYear: (Task | {
    date: any; intensity: number; grow_audience: string; make_money: string; work_sumarry: any[];
    price: number; score: string; volume: number;
  })[];
  yearCurrent: number;
  total: number;
  yesterday: Date;
  tomorrow: Date;
  allYears: any;
  currentYear: number;
  years: { year: number; isActive: boolean; }[];
  yearToSend: any;
  setSctiveYear: any;
  POWStats: any;
  startloading = true;
  powDisplay: string = 'list'
  data2: any;
  currentPage: any = 1;
  nextCursor: null;
  constructor(
    private test: AppServiceService,
    public app: AppServiceService,
    private datePipe: DatePipe,
    private orderPipe: OrderPipe,
    private cd: ChangeDetectorRef
  ) {
    const today = new Date();
    console.log('today', today)
    console.log(new Date().getUTCFullYear())
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    this.yesterday = yesterday
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    this.tomorrow = tomorrow
  }
  ngOnInit(): void {
    this.startloading = true
    const today = new Date();
    this.selectedDate = today;
    this.currentYear = new Date().getUTCFullYear();
    this.yearToSend = new Date().getUTCFullYear();
    console.log(this.yearToSend)
    this.setSctiveYear = this.yearToSend
    this.years = this.getYearsArrayDescending(this.currentYear);
    this.yearCalendar = this.getDaysInCurrentYear(this.yearToSend)

    // this.yearToSend = new Date().getUTCFullYear();
    this.getData(this.yearToSend)
    this.getData2()
  }

  getYearsArray(): number[] {
    const currentYear = this.yearToSend
    const startYear = 2022;
    const numYears = currentYear - startYear + 1;
    const yearsArray = Array.from({ length: numYears }, (_, index) => currentYear - index);
    return yearsArray;
  }

  getYearsArrayDescending(activeYear: number): { year: number; isActive: boolean }[] {
    const currentYear = new Date().getUTCFullYear();
    // const currentYear = 2024g67
    const startYear = 2022;
    const numYears = currentYear - startYear + 1;
    const yearsArray = Array.from(
      { length: numYears },
      (_, index) => ({
        year: currentYear - index,
        isActive: currentYear - index === activeYear
      })
    );
    return yearsArray;
  }

  changeYear(year) {
    this.startloading = true
    // console.log('change year', year);
    this.yearToSend = year
    this.setSctiveYear = this.yearToSend
    // console.log(this.currentYear, ' and', this.yearToSend);

    this.yearCalendar = this.getDaysInCurrentYear(this.yearToSend)
    // console.log('new Yearcalendar', this.yearCalendar)

    this.getData(year)
    // this.startloading = false
  }

  getDaysInCurrentYear(currentYear) {
    this.yearCurrent = currentYear;
    const firstDayOfYear = new Date(Date.UTC(currentYear, 0, 1)); // Set the first day of the year in UTC timezone
    const lastDayOfYear = new Date(Date.UTC(currentYear, 11, 31)); // Set the last day of the year in UTC timezone
    const daysInYear = []; // Create an empty array to hold the days of the year

    // Loop through each day of the year and add it to the array
    for (let date = firstDayOfYear; date <= lastDayOfYear; date.setUTCDate(date.getUTCDate() + 1)) {
      daysInYear.push(new Date(date.getTime())); // Push a new Date object with UTC timezone to the array
    }

    // console.log('completing year to send')

    return daysInYear;
  }

  onDayClicked(date: any) {
    // console.log('this date', date)
    this.dateData = null
    this.selectedDate = new Date(date);
    // console.log('first selected date', this.selectedDate)

    for (let i = 0; i < this.data.length; i++) {
      const element = this.data[i];
      const date = new Date(this.selectedDate);
      const year = date.getUTCFullYear();
      const month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Add leading zero if needed
      const day = ('0' + date.getUTCDate()).slice(-2); // Add leading zero if needed
      const dateString = `${year}-${month}-${day}`;
      this.selectedDate = dateString
      // console.log('second selected date', this.selectedDate)
      // console.log(dateString, element.date); // Outputs: 2023-03-18

      if (dateString === element.date) {
        this.dateData = element;
        // console.log(this.dateData)
      }

    }
    this.cd.detectChanges();

  }


  prevDay() {
    // console.log('previous', date)
    if (typeof this.selectedDate === "string") {
      this.selectedDate = new Date((this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd')));
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() - 1);
      this.onDayClicked(dateA);
      // console.log(dateA);
    } else if (this.selectedDate instanceof Date) {
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() - 1);
      this.onDayClicked(dateA);
      // console.log(dateA);
    }
    this.cd.detectChanges();

  }

  nextDay() {
    // console.log(this.selectedDate)
    if (typeof this.selectedDate === "string") {
      this.selectedDate = new Date(this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd'));
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() + 1);
      this.onDayClicked(dateA);
    } else if (this.selectedDate instanceof Date) {
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() + 1);
      this.onDayClicked(dateA);
    }


    this.cd.detectChanges();

  }

  getData(year) {
    this.test.getCreatorPOW(this.social_tag, year).subscribe({
      next: (res: any) => {
        console.log('ddata', res.data)
        this.data = res.data
        this.total = this.data.length
      },
      complete: () => {
        this.getPOWStat()
        this.checkSomething()
        for (let i = 0; i < this.data.length; i++) {
          const element = this.data[i];
          const date = new Date(this.selectedDate);
          const year = date.getUTCFullYear();
          const month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Add leading zero if needed
          const day = ('0' + date.getUTCDate()).slice(-2); // Add leading zero if needed
          const dateString = `${year}-${month}-${day}`;
          // console.log(dateString, element.date); // Outputs: 2023-03-18

          if (dateString === element.date) {
            this.dateData = element;
          }

        }

        this.startloading = false
      }
    })
  }


  checkSomething() {
    let c = []
    for (let i = 0; i < this.yearCalendar.length; i++) {
      const element = this.yearCalendar[i];
      const date = new Date(element);
      const year = date.getUTCFullYear();
      const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
      const day = ('0' + date.getUTCDate()).slice(-2);
      const dateString = `${year}-${month}-${day}`;
      c.push(dateString);
    }
    const a = c
    const b = this.data

    const updatedA = a.map(value => {
      const matchIndex = b.findIndex(item => item.date === value);
      return matchIndex !== -1 ? b[matchIndex] : { date: value, intensity: 0, grow_audience: 'no', make_money: 'no', work_sumarry: [], price: null, score: '0', volume: null };
    });

    this.updateYear = updatedA

  }

  getPOWStat() {
    this.test.getCreatorPOWStats(this.social_tag).subscribe({
      next: (res: any) => {
        console.log('POW stats', res)
        this.POWStats = res.data
      }
    })
  }


  getData2() {
    this.test.getPOWDATA(this.social_tag, this.yearToSend).subscribe({
      next: (res: any) => {
        console.log('POWdata', res)
        this.data2 = res.data
        this.data2 = this.orderPipe.transform(this.data2, 'date', true)
        // this.nextCursor = res.data.meta.next_cursor
      },
      complete: () => {
        this.startloading = false
        // this.getPOWStat()
        // this.checkSomething()

      }
    })
  }

  getMoreActivities() {
    console.log(this.yearToSend)
    console.log(this.yearToSend - 1);
    // if (this.nextCursor != null) {
    //   this.test.getPOWDATA(this.social_tag, this.nextCursor).subscribe({
    //     next: (res: any) => {
    //       console.log('new data', res)
    //       this.nextCursor = res.data.meta.next_cursor
    //       this.data2.push(...res.data.data);
    //     },
    //     error: (err: any) => {
    //       console.log(err)
    //     }
    //   })
    // }
  }

  switchView(data) {
    this.powDisplay = data
    console.log(this.powDisplay)
  }
}

