import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-swap',
  templateUrl: './new-swap.component.html',
  styleUrls: ['./new-swap.component.scss']
})
export class NewSwapComponent implements OnInit {
  swapDisplay = 'sc'

  constructor() { }

  ngOnInit(): void {
  }

  switchView(data) {
    this.swapDisplay = data
    console.log(this.swapDisplay)
  }
}
