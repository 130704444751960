import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppServiceService, UserType } from '../_services/app-service.service';
// import { BN } from '@project-serum/anchor';
import BN from 'bn.js';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { WalletService } from '../_services/wallet.service';
import { PublicKey, Transaction, VersionedTransaction, ComputeBudgetProgram } from '@solana/web3.js';
import { getMintInfo } from 'rly-js';
import { getAssociatedTokenAddress } from 'src/utils';
// import connection from 'pusher-js/types/src/core/connection/connection';
import { WalletName } from '@solana/wallet-adapter-base';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID, u64,
} from '@solana/spl-token';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-gary-locked',
  templateUrl: './gary-locked.component.html',
  styleUrls: ['./gary-locked.component.scss']
})
export class GaryLockedComponent implements OnInit {
  pool_stats: any;
  pools: any;
  stakePoolJSON: any;
  inputMonth: number;
  resultSeconds: number | null;
  options = [
    // { label: '1 Day', value: 0.0333333333333334 },
    { label: '1 Month', value: 1 },
    { label: '3 Months', value: 3 },
    { label: '6 Months', value: 6 }
  ];
  reward_multiplier = 1
  amount: number
  total_weight = 0
  custodial_wallet: any;
  user$: Observable<UserType>;
  activeStake: any;
  transaction: any;
  signed: any;
  alert: string;
  tnxlink: any;
  private signature: string;
  showLoad: boolean;
  item: any;
  showLoadwit: boolean;
  showLoadclaim: boolean;
  priceRsc: any;
  tokenDecimal: any;
  tokenBalance: any;
  connection = this.walletservice.connection;
  sBalance: any;
  walletvalue: any = 'sc';

  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;
  coin_symbol: any;
  garyPrice: any;
  rlyPrice: any;
  exchangemessage: any = null
  coinprice: any;
  coinprices: any;

  constructor(
    private modal: NgbModal,
    config: NgbModalConfig,
    private app: AppServiceService,
    private wallet: WalletService,
    public walletservice: WalletService,
    private sanitizer: DomSanitizer,
  ) {
    // config.backdrop = 'static';
    config.keyboard = true;
    config.centered = true;
  }
  async ngOnInit(): Promise<void> {
    const a = await this.app.getUser()
    this.custodial_wallet = a?.custodial_wallet
    this.getPool()
    this.getPoolStats()
    // this.checkConnection();
    console.log('input month', this.inputMonth)

    this.getCoreBalance()
    // this.GetActiveStake()
    this.getpricewid();
    this.getcoinprice()
    this.walletservice.resetBalance();
    if (this.walletservice.exchange_message !== undefined) {
      this.exchangemessage = this.walletservice.exchange_message
    }

  }
  getcoinprice() {
    this.coinprice = 0;
    this.coinprices = 0;
    this.app.getcoinprice2('$GARY').subscribe({
      next: (res: any) => {
        console.log(res);
        this.coinprice = parseFloat(res.data.price_usd).toFixed(4);
        this.coinprices = parseFloat(res.data.price_usd);
      },
    });
  }

  onDisconnect() {
    this.walletservice.onDisconnect();
  }
  getpricewid() {
    this.app.getGaryPrice().subscribe({
      next: (res: any) => {
        console.log(res);
        this.coin_symbol = res.data.coin.coin_symbol;
        this.garyPrice = res.data.price_usd.toFixed(4);
        this.rlyPrice = res.data.price.toFixed(4);
      },
    });
  }
  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  onSelectWallet(walletName: WalletName<string>) {
    this.walletservice.onSelectWallet(walletName);
  }
  getCoreBalance() {
    this.app.getCoreBalance().subscribe({
      next: (res: any) => {
        console.log('price ', res)
        this.priceRsc = res.data
      }
    })
  }

  isDateGreaterThanToday(givenDate): boolean {

    let curentDate = new Date()
    // console.log('current date', curentDate)
    return givenDate.getTime() > curentDate.getTime();
  }
  fixUnsafe(url: string) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  checkConnection() {
    const intervalId = setInterval(async () => {
      if (this.walletservice.connection !== undefined) {
        let wallet = null;
        if (this.walletvalue === 'sc') {
          wallet = this.custodial_wallet
        }
        else if (this.walletvalue === 'external') {
          wallet = this.publicKey$
        }
        clearInterval(intervalId); // Clear the interval once the connection is established
        // console.log('Connection is established:', this.walletservice.connection);
        // You can do whatever you need to do after the connection is established here
        const callerTokenAAccount = await getAssociatedTokenAddress(
          new PublicKey(this.stakePoolJSON.mint),
          new PublicKey(wallet)
        );

        this.tokenBalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenAAccount
          )
        ).value.uiAmount;
        console.log('tokenbalance', this.tokenBalance);
      }
    }, 800); // Check every 1 second
  }

  convertMonthToSeconds() {
    // console.log(this.inputMonth)
    if (this.inputMonth !== null && this.inputMonth !== undefined && !isNaN(this.inputMonth)) {
      const daysInMonth = this.inputMonth * 30
      const secondsInDay = 24 * 60 * 60;
      this.resultSeconds = daysInMonth * secondsInDay;
      console.log(this.resultSeconds)
    } else {
      this.resultSeconds = 0;
    }
    this.getMultiplier()
  }
  timestampToNormalTime(timestamp: number): string {
    const normalTime = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
    return normalTime.toISOString(); // Returns the date in ISO format (e.g., "2024-03-18T12:34:56.789Z")
  }

  secondsToMonths(seconds: number, timestamp: number): Date {
    const daysInMonth = 30;
    const secondsInDay = 24 * 60 * 60;
    const months = seconds / (daysInMonth * secondsInDay);
    const a = this.timestampToNormalTime(timestamp)
    const lockedDate = new Date(a);
    const unlockDate = this.calculateUnlockDate(lockedDate, months);
    // console.log(unlockDate)
    return unlockDate;
    // return months;
  }

  calculateUnlockDate(lockedDate: Date, lockDurationInMonths: number): Date {
    const unlockDate = new Date(lockedDate);
    unlockDate.setMonth(lockedDate.getMonth() + lockDurationInMonths);
    return unlockDate;
  }


  // GetActiveStake() {
  //   this.app.getActiveStake(this.custodial_wallet).subscribe({
  //     next: (res: any) => {
  //       console.log('active stake ', res)
  //       this.activeStake = res
  //     },
  //     error: (err: any) => {
  //       console.log('active stake error', err);
  //     }
  //   })
  // }

  getMultiplier() {
    this.reward_multiplier = this.calculateStakeWeight(new BN(this.stakePoolJSON.minDuration), new BN(this.stakePoolJSON.maxDuration), new BN(this.stakePoolJSON.baseWeight), new BN(this.stakePoolJSON.maxWeight), new BN(this.resultSeconds));
    // console.log('multiplier', this.reward_multiplier)

    this.total_weight = this.reward_multiplier * this.amount || 0
  }

  getPool() {
    this.app.getPool().subscribe({
      next: async (res: any) => {
        console.log('pool', res)
        this.stakePoolJSON = res
      },
      error: (err: any) => {
        console.log('pool', err)
      },
      // complete: async () => {
      //   const connection = await this.connection;
      //   const { decimals: tokenBDecimals } = await getMintInfo({
      //     tokenMint: new PublicKey(this.stakePoolJSON.mint),
      //     connection,
      //   })
      //   this.tokenDecimal = tokenBDecimals
      //   // console.log('decimals', this.tokenDecimal)
      // }
    })
  }

  getPoolStats() {
    this.app.getPoolStats().subscribe({
      next: (res: any) => {
        console.log('pool stats', res)
        this.pool_stats = res
      },
      error: (err: any) => {
        console.log('pool stats', err)
      }
    })
  }

  calculateStakeWeight(minimumDuration, maxDuration, baseWeight, maxWeight, daysInSeconds) {
    // console.log('min Duration', minimumDuration.toString())
    // console.log('max Duration', maxDuration.toString())
    // console.log('baseWeight', baseWeight.toString())
    // console.log('maxWeight', maxWeight.toString())
    // console.log('daysInSeconds', daysInSeconds.toString())
    let SCALE_FACTOR_BASE = 1000000000
    let SCALE_FACTOR_BASE_BN = new BN(1000000000);
    // Calculate the duration difference
    const lt = maxDuration.sub(minimumDuration);
    // If the duration difference is zero, return the base weight
    if (lt.eq(new BN(0))) return baseWeight;
    // Calculate ft using the provided formula
    const ft = daysInSeconds.sub(minimumDuration).mul(SCALE_FACTOR_BASE_BN).div(lt);
    // Calculate ht as the difference between maxWeight and baseWeight
    const ht = maxWeight.sub(baseWeight);
    // Calculate the stake weight using the formula and return the maximum value
    const max = BN.max(baseWeight.add(ft.mul(ht).div(SCALE_FACTOR_BASE_BN)), baseWeight);
    // return max.div(SCALE_FACTOR_BASE_BN).toString();
    return (parseInt(max.toString()) / 1e9).toString();
  }

  open(content) {
    this.modal.open(content, { size: 'lg' }).result
  }

  lockGary() {
    this.showLoad = true
    let data = {
      stakeKey: environment.stake_key,
      lockedDuration: this.resultSeconds,
      amount: this.amount,
      walletAddress: this.custodial_wallet,
      program: environment.program
    }
    console.log(data)
    this.app.depositStake(data).subscribe({
      next: (res: any) => {
        this.transaction = res.transaction
      },
      error: (err: any) => {
        console.log('deposit error:', err)
        this.showLoad = false
        Swal.fire('Failed to stake', err.error.message, 'error')
      },
      complete: async () => {
        this.gettnx()
      }
    })
  }

  async gettnx(message = '$GARY Lock Successful') {
    let signature;
    let signedTxn = [];
    let tx = new Transaction();
    let data = { transactions: [this.transaction] }
    await this.wallet.getSignedTnx(data).subscribe({
      next: (res: any) => {
        console.log('signed re naw', res)
        this.signed = res.data.signedTxns
      },
      error: (err: any) => {
        Swal.fire('Failed to generate transaction', err.error.message, 'error');
        console.log(err);
        this.showLoad = false
        this.showLoadwit = false
        this.showLoadclaim = false
        // this.isLoading$$.next(false);
      },
      complete: async () => {
        try {
          const signed = this.signed

          for (let singleTxn of signed) {
            signature = await this.wallet.connection.sendRawTransaction(
              Transaction.from(Buffer.from(singleTxn, 'base64')).serialize(),
              { skipPreflight: false, preflightCommitment: 'confirmed' }
            );
            console.log(signature);
            let response =
              await this.wallet.connection.confirmTransaction(
                signature,
                'confirmed'
              );
            // console.log(signed);
            this.signature = signature;
          }
          this.modal.dismissAll()
          Swal.fire('Success', message, 'success').then((_) => {
            window.location.reload()

          })
        } catch (error: any) {
          this.modal.dismissAll()
          // this.isLoading$$.next(false);
          // state.stop();
          if (error.message == 'failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1') {
            Swal.fire('Failed', "You do not have sufficient funds to complete the transaction!", 'error')
          } else if (error.message == 'failed to send transaction: Transaction simulation failed: Error processing Instruction 1: custom program error: 0x1') {
            Swal.fire('Failed', "You do not have sufficient funds to complete the transaction!", 'error')
          } else {
            Swal.fire('Failed', error.message, 'error')
          }

          this.showLoad = false
          this.showLoadclaim = false
          this.showLoadwit = false
          throw new Error(error);
          // Swal.fire('Failed', error, 'error')
        }
        console.log(this.signature);
        // state.stop();
        this.showLoad = false
        this.showLoadclaim = false
        this.showLoadwit = false
        // Swal.fire('', 'Please wait while we verify your transaction', 'info')
        // this.payInfo = 'Verifying transaction'
      }
    })
  }


  openclaim(content2, item) {
    this.item = item
    this.modal.open(content2, { backdrop: 'static' }).result
  }

  claim() {
    this.showLoadclaim = true
    const data = {
      stakeKey: environment.stake_key,
      stakeDepositReceipt: this.item.stakeDepositReceipt,
      walletAddress: this.custodial_wallet,
      program: environment.program
    }
    console.log(data)
    this.app.claimStake(data).subscribe({
      next: (res: any) => {
        this.transaction = res.transaction
      },
      error: (err: any) => {
        this.showLoadclaim = false
        console.log('deposit error:', err)
        Swal.fire('Failed to claim', err.error.message, 'error')
      },
      complete: async () => {
        // this.modal.dismissAll()
        this.gettnx("$GARY Lock Rewards Claimed")
      }
    })
  }

  withdraw(item) {
    this.showLoadwit = true
    const data = {
      stakeKey: environment.stake_key,
      stakeDepositReceipt: item.stakeDepositReceipt,
      walletAddress: this.custodial_wallet,
      program: environment.program
    }
    console.log(data)
    this.app.withdrawStake(data).subscribe({
      next: (res: any) => {
        this.transaction = res.transaction
      },
      error: (err: any) => {
        this.showLoadwit = false
        console.log('deposit error:', err)
        Swal.fire('Failed to claim', err.error.message, 'error')
      },
      complete: async () => {
        this.gettnx()
      }
    })
  }

  setMax() {
    this.amount = this.tokenBalance;
    this.getMultiplier()
  }

  setMaxexternal() {
    this.amount = this.coinprice;
    this.getMultiplier()
  }

  getAmount(amount) {
    return (amount / 10 ** this.stakePoolJSON?.mint_decimal)
  }


  shrotstring(str: string) {
    if (str) {
      return str.substring(0, 5);
    } else {
      return str
    }

  }

  setWallet(data) {
    console.log(data)
    this.walletvalue = data
  }
}





