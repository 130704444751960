<div class="container-fluid">
  <div class="row align-items-center mb-5 p-5">
    <div class="col-md-5">
      <div>
        <img src="assets/imgs/gary_involved.png" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="col-md-7">
      <h2>Get involved in Gary Club</h2>
      <p>
        Welcome to Gary Club, where we're all about taking action and getting
        results in the creator economy. This is the place where creators come to
        level up their game and make some real money, no excuses allowed.
      </p>
      <p>
        We don't sugarcoat things here. We know that building a brand and making
        an impact in the creator economy takes hard work, dedication, and a
        willingness to go above and beyond. But we're here to guide you on your
        journey and support you every step of the way.
      </p>
      <p>
        At Gary Club, we're all about empowering you to take control of your
        destiny and achieve your goals as a creator. We'll give you the tools,
        the community, and the support you need to make it happen. So if you're
        ready to step up, stack up that $GARY, and become a true hero in the
        creator economy, then join us at Gary Club today.
      </p>
    </div>
    <div class="text-center">
      <a href="https://discord.gg/GaryClub" target="_blank" class="btn btn-custom">Join Gary Club</a>
    </div>
  </div>

  <div class="card mb-5">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-md-5">
          <div>
            <img src="assets/imgs/giraffe.png" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-md-7">
          <h2>Giraffe Tower NFT</h2>
          <p>
            As a creator, you're on a journey of growth and self-discovery. But
            sometimes, you need a little support to make it to the top. That's
            where the Giraffe Tower NFT comes in.
          </p>
          <p>
            With this NFT in your wallet, you'll be part of a community of
            like-minded creators who will have your back every step of the way.
          </p>
          <p>
            You'll have access to cutting-edge technology and tools that will
            help you build your brand and monetize your content.
          </p>
          <p>
            And, most importantly, you'll have a symbol of your commitment to
            your own success.
          </p>
          <p>
            So if you're ready to take your place among the top creators in the
            game, get yourself a Giraffe Tower NFT and join the ranks of the
            most successful creators in the creator economy.
          </p>

          <a href="https://www.tensor.trade/trade/giraffetower" target="_blank" class="btn btn-custom">Buy a Giraffe</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center mb-5">
    <div class="col-md-5">
      <div>
        <img src="assets/imgs/vibin_involved.png" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="col-md-7">
      <h2>Vibin'</h2>
      <p>
        Get your groove on with the Vibin' NFT - the ultimate ticket to
        exclusive parties and activities within the Gary Club community. As a
        Vibin' holder, you'll gain access to private events, parties, and
        activities, including our legendary "Vibin' Day" events. With only 50
        Vibin' available, owning one puts you in an elite group of Gary Club
        supporters who know how to have a good time. Join the party and become a
        part of our thriving ecosystem powered by $GARY.
      </p>

      <a href="https://magiceden.io/marketplace/GBadxEmDQU1GMXB9GGZf4swEaKHeSQyaRMzhBhjMPdCB" target="_blank"
        class="btn btn-custom">Buy a Vibin'</a>
    </div>
  </div>

  <div class="card mb-5">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-md-5">
          <div>
            <img src="assets/imgs/golden_ticket_involved.png" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-md-7">
          <h2>Gary Club Golden Ticket</h2>
          <p>
            The most exclusive collection in Gary Club, 25 lucky members get
            access to unannounced perks and rewards. Be part of the ultimate
            creator's club with a golden ticket.
          </p>
          <a href="https://magiceden.io/marketplace/gary_club_golden_ticket_" target="_blank" class="btn btn-custom">Buy
            a Golden Ticket</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center mb-5">
    <div class="col-md-5">
      <div>
        <img src="assets/imgs/stacker_involved.png" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="col-md-7">
      <h2>Gary Club Stacker:</h2>

      <p>
        Listen up, people! Are you ready to be a real-life hero? To make a
        difference in the lives of creators who are struggling to get their
        social tokens off the ground? Then it's time to become a Gary Club
        Stacker!
      </p>
      <p>
        As a Stacker, you'll get access to an exclusive group of like-minded
        individuals who share your passion for helping others succeed. And, as
        if that wasn't awesome enough, you'll also receive a 1:1 Super Hero NFT
        of your very own Giraffe Tower NFT.
      </p>
      <p>
        But here's the real kicker: you'll be the driving force behind making
        other creators' dreams a reality. You'll be the one empowering them to
        launch their social tokens and achieve financial freedom. And as they
        soar to new heights, you'll be right there with them, earning daily
        $GARY rewards and enjoying the satisfaction of knowing you helped make
        it all possible.
      </p>
      <p>
        So, are you ready to unleash your inner hero and join the ranks of the
        Gary Club Stackers?
      </p>
      <p>Lets Do this!</p>

      <a href="https://magiceden.io/marketplace/gary_club_stacker" target="_blank" class="btn btn-custom">Buy a
        Stacker</a>
    </div>
  </div>

  <div class="card mb-5">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-md-5">
          <div>
            <img src="assets/imgs/bags.png" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-md-7">
          <h2>$BAGS Member</h2>
          <p>
            Are you ready to stack up that $GARY and become a $BAGS member? With
            500 $BAGS, you'll gain access to early social token drops and a
            private exchange to connect with other $BAGS holders. Plus, you'll
            earn daily $GARY rewards and have the opportunity to support your
            favorite creators. Don't settle for less, stack up that $GARY and
            become a $BAGS member today.
          </p>

          <a [routerLink]="['/tokens/$BAGS']" class="btn btn-custom">Buy $BAGS</a>
        </div>
      </div>
    </div>
  </div>
</div>