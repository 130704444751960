import { Component, OnInit } from '@angular/core';
import { AppServiceService } from './../_services/app-service.service';
import Swal from 'sweetalert2';
import { NotificationService } from '../_services/notification-service.service';
import { PusherService } from '../_services/pusher.service';
import { BehaviorSubject, Observable, scan, switchMap, tap } from 'rxjs';

import { Howl } from 'howler'
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  public loading: boolean = true
  notifications: any[]
  activityLogs: any = [];
  p: any;
  user: any;
  feeds: any
  perPage: number;
  totalLength: any;
  currentPage: any = 1;
  nextCursor: any = null;
  refresh: NodeJS.Timer;
  timeout: NodeJS.Timeout;
  hideLoad: boolean = false;
  newloading: boolean = false;
  errorMessage: string = null;
  public posts$: Observable<any>;
  public loading$ = new BehaviorSubject(true);
  private page$ = new BehaviorSubject(1);
  itemsPerPage: number = 10;

  constructor(private app: AppServiceService, private notificationService: NotificationService, private pusherService: PusherService,) { }

  ngOnInit(): void {
    // var sound = new Howl({
    //   src: ["./assets/notification.mp3"]
    // });

    // sound.play();
    const a = JSON.parse(localStorage.getItem('userdetails'));
    this.user = a
    this.getNotifications()
    this.pusherService.channel.bind(`notification-for-${this.user.id}`, data => {
      if (data) {
        var sound = new Howl({
          src: ["https://bucket.socialconnector.io/notification.mp3"],
          html5: true,
          format: ['mp3', 'aac']
        });

        sound.play();
        this.notificationService.updateNotificationIndicator(true);
        this.getNotifications()
      }
    });
    this.posts$ = this.getNotificationsNew$();
  }

  private getNotificationsNew$(): Observable<any> {
    return this.page$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap((page) => this.app.getNotificationsNew$(page)),
      scan(this.updateNotifications, { items: [], page: 0, hasMorePages: true }),
      tap(() => this.loading$.next(false)),
    );
  }
  clearNotification() {
    this.app.clearNotifications().subscribe({
      next: (res: any) => {

        this.getNotifications()

      },
      error: (err: any) => {
        Swal.fire('Oops! Something went wrong', err.message, 'error');
      }
    });
  }
  private updateNotifications = (accumulator, value) => {

    if (value.page === 1) {
      accumulator.items = value.items
      this.feeds = value.items;
      return value;
    }

    accumulator.items.push(...value.items);
    // console.log(value.items);
    // console.log(this.feeds);
    // this.feeds.push(...value.items)
    // console.log(this.feeds);
    accumulator.page = value.page;
    accumulator.hasMorePages = value.hasMorePages;

    return accumulator;
  }

  public loadMoreNotifications(posts) {
    console.log(posts)
    this.page$.next(posts.page + 1);
  }

  onNewNotificationArrival() {
    const allReadAtNonNull = this.notifications.every(el => el.read_at !== null);

    if (!allReadAtNonNull) {
      this.notificationService.updateNotificationIndicator(true);
    } else {
      this.notificationService.updateNotificationIndicator(false);
    }
  }

  getNotifications() {
    this.loading = true;
    this.app.getNotifications().subscribe({
      next: (res: any) => {

        // Filter out items with category null
        const filteredNotifications = res.data.data
          .filter((item: any) => item.category !== null)
          .sort((a: any, b: any) => {
            if (a.read_at === null && b.read_at !== null) return -1;
            if (a.read_at !== null && b.read_at === null) return 1;

            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          });

        this.notifications = filteredNotifications;

        this.nextCursor = res.data.meta.current_page + 1

        this.loading = false;

        this.onNewNotificationArrival();
      },
      error: (err: any) => {
        Swal.fire('Oops! Something went wrong', err.message, 'error');
        this.loading = false;
      }
    });
  }

  checkCategoryNotNull(): boolean {
    return !this.loading && this.notifications.length > 10 && this.notifications.some(item => item.category !== null);
  }

  makeFirstWordBold(text: string): string {
    if (text) {
      // const words = text.split(' ');
      // if (words.length > 1) {
      //   words[0] = `<span style="font-weight: bold;">${words[0]}</span>`;
      //   return words.join(' ');
      // }
    }
    return text;
  }

  openLinkInNewTab(link: any, id: any) {
    if (link) {
      window.location.href = link
      // window.open(link);

      setTimeout(() => {
        this.app.markAsRead(id).subscribe({
          next: (res: any) => {
            this.getNotifications();
            this.loading = false;
          },
          error: (err: any) => {
            console.log(err);
            Swal.fire('error', err.message, 'error');
            this.loading = false;
          }
        });
      }, 100);
    }
  }

  markAsRead(id: any, link: any) {
    this.loading = true;

    this.openLinkInNewTab(link, id);
  }

  shouldTruncate(description: string): boolean {
    return description.length > 60;
  }

  truncateDescription(description: string): string {
    if (this.shouldTruncate(description)) {
      return description.slice(0, 60) + '...';
    } else {
      return description;
    }
  }

  loadMore() {
    if (this.notifications.length > this.itemsPerPage) {
      this.itemsPerPage += 5
    }

  }


}
