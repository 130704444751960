import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupDataService } from 'src/app/_services/popup-data.service';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})

export class ImagePopupComponent {
  imageUrl: string;
  @ViewChild('content') content: TemplateRef<any>;

  constructor(private modalService: NgbModal, private popupDataService: PopupDataService) {
    this.popupDataService.imageUrl$.subscribe((url) => {
      this.imageUrl = url;
      // alert(url);
      this.open();
    });
  }

  open() {
    const modalRef = this.modalService.open(this.content, {
      centered: true, // Center the modal vertically
      size: 'lg', // Adjust the size as needed
      windowClass: 'image-popup-modal', // Custom CSS class for styling
    });

    // Disable modal backdrop click to close
    modalRef.result.then(
      (result) => { },
      (reason) => { }
    );
  }
}
