<div class="container mt-lg-3 mt-2">
  <div class="row mt-lg-3 mt-2">
    <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
      <div class="row">
        <div class="col-10">
          <div class="nav">
            <a class="nav-link" [routerLink]="['/home/activity']" routerLinkActive="active">Feed</a>
            <a class="nav-link" [routerLink]="['/home/fam']" routerLinkActive="active">Fam</a>
            <a class="nav-link" [routerLink]="['/home/supporter']" routerLinkActive="active">Supporters</a>
            <a class="nav-link" [routerLink]="['/home/supporting']" routerLinkActive="active">Supporting</a>
          </div>
        </div>
        <div class="col-2">
          <div class="text-end">
            <div ngbDropdown>
              <button type=" button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
                <i class="fa-regular fa-sliders ico"></i>
              </button>
              <div ngbDropdownMenu class="dropdown-menu-end">
                <button ngbDropdownItem (click)="filter('')">ALL</button>
                <button ngbDropdownItem (click)="filter('event_rsvp,schedule_event')">
                  EVENTS
                </button>
                <button ngbDropdownItem
                  (click)=" filter('update_profile_picture,activate_creator_coin,send_note,username_update')">
                  GENERAL
                </button>
                <button ngbDropdownItem (click)="filter('giraffe_buy')">
                  GIRAFFE SALE
                </button>
                <button ngbDropdownItem (click)="filter('mint_collectibles')">
                  OFFERS
                </button>
                <button ngbDropdownItem (click)="filter('create_post,post')">
                  POST
                </button>
                <button ngbDropdownItem (click)="filter('pow_submit')">
                  POW
                </button>
                <button ngbDropdownItem (click)="filter('affiliate_stake,refer_user')">
                  REFERRAL
                </button>
                <button ngbDropdownItem
                  (click)="filter('royalty_stake,holder_reward,bags_reward,reward_stacker,claim_segment_drop')">
                  REWARD
                </button>
                <button ngbDropdownItem (click)="filter('buy_stake,sell_stake,bought_product')">
                  SALE
                </button>
                <button ngbDropdownItem (click)="filter('subscribe_recurrent_stripe,renew_recurrent_stripe')">
                  SUBSCRIPTION
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-lg-3 mt-2">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<button *ngIf="showpost" id="postButton" (click)="createModal(content)">
  Post
</button>

<!-- <ng-template #content let-modal>
  <div class="container d-none d-md-block">
    <div class="modal-body">
      <h3 class="text-center mb-3" style="color: white; padding-top: 10px;">
        Create Post
      </h3>
      <div class="row mb-4">
        <div class="col-md-2 col-3">
          <img [src]="user?.sc_profile_picture" alt="" class="img-fluid prof-img"
            style="height: 65px !important; width: 65px !important;">
        </div>
        <div class="col-md-10 col-9">
          <h4 class="mb-2" style="color: white !important;">
            {{ user?.name }}
          </h4>
          <div class="mb-3">
            <select name="" id="" [(ngModel)]="post.type">
              <option value="public">Public</option>
              <option value="supporters">Supporters</option>
            </select>
          </div>
          <div class="post-details">
            <textarea name="" id="" cols="35" rows="5" placeholder="What's going on?" (click)="showEmojiPicker = false"
              (blur)="showEmojiPicker = false" [(ngModel)]="post.body"></textarea>
          </div>
          <div class="post-img" style="text-align:center;">
            <img *ngIf="imgFile" [src]="imgFile" style="
                        width: 50%;
                        height: 50%;
                        background-position: center;
                        background-size: cover" alt="" />
            <input class="test" #imageInput style="display: none;" type="file" accept="image/*" id="file"
              (change)="processFile(imageInput)" />
          </div>
        </div>
      </div>
      <div class="row" style="padding-bottom: 10px;">
        <div class="col-md-6">
          <div class="row">

            <div class="col-md-3">
              <span class="like-icon">
                <i class="fa-regular fa-image fa-xl image-icon"
                  (click)="showEmojiPicker = false; openFileExplorer()"></i>
              </span>
            </div>
            <div class="col-md-3">
              <span class="like-icon">
                <i class="fa-solid fa-video fa-xl gif-icon" (click)="showEmojiPicker = false;"></i>
              </span>
            </div>
            <div class="col-md-3">
              <span class="like-icon"><i class="fa-regular fa-face-smile fa-xl face-icon"
                  (click)="toggleEmojiPicker()"></i>
              </span>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
            [disabled]="post.body == ''">
            Post</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="emojis">
            <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
              title="Pick your emoji…"></emoji-mart>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="container ">
    <div class="modal-body pt-4 pb-4 d-block d-md-none">
      <div class="row mb-4">
        <div class="col-md-2 col-3">
          <img [src]="user?.sc_profile_picture" alt="" class="img-fluid prof-img"
            style="height: 65px !important; width: 65px !important;">
        </div>
        <div class="col-md-10 col-9">

          <div class="mb-3">
            <select name="" id="" [(ngModel)]="post.type">
              <option value="public">Public</option>
              <option value="supporters">Supporters</option>
            </select>
          </div>
          <div class="post-details">
            <textarea name="" id="post-field" cols="35" rows="5" placeholder="What's going on?" [(ngModel)]="post.body"
              (input)="countCharacters()" (click)="showEmojiPicker = false" (blur)="showEmojiPicker = false"></textarea>
          </div>
          <div class="post-img" style="text-align:center;">
            <img *ngIf="imgFile" [src]="imgFile" style="
                        width: 50%;
                        height: 50%;
                        background-position: center;
                        background-size: cover" alt="" />
            <input class="test" #imageInput style="display: none;" type="file" id="file"
              (change)="processFile(imageInput)" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <div class="row">

            <div class="col-md-3 col-3">
              <span class="like-icon">
                <i class="fa-regular fa-image fa-xl image-icon"
                  (click)="showEmojiPicker = false; openFileExplorer()"></i>
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <i class="fa-solid fa-video fa-xl gif-icon" (click)="showEmojiPicker = false;"></i>
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon"><i class="fa-regular fa-face-smile fa-xl face-icon"
                  (click)="toggleEmojiPicker()"></i>
              </span>
            </div>
          </div>

        </div>
        <div class="col-md-6 col-6">
          <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
            [disabled]="post.body == ''">
            Post</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="emojis">
            <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
              title="Pick your emoji…"></emoji-mart>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-template> -->

<ng-template #content let-modal>
  <div class="modal-body">
    <h3 class="text-center mb-3" style="padding-top: 10px">Create Post</h3>
    <div class="row mb-4">
      <div class="col-md-2 col-3">
        <img [src]="user?.sc_profile_picture || 'assets/imgs/placeholder.png'" alt="" class="img-fluid prof-img" />
      </div>
      <div class="col-md-10 col-9">
        <h4 class="mb-2">
          {{ user?.name }}
        </h4>
        <!-- <div class="mb-3">
          <div class="row">
            <div class="col-6 col-md-5">
              <select name="" class="form-select" id="" [(ngModel)]="post.type" (ngModelChange)="setPrice($event)">
                <option value="public">Public</option>
                <option value="supporters">Supporters</option>
                <option value="paid">Paid</option>
              </select>
            </div>
            <div class="col-6 col-md-6" *ngIf="showPrice">
              <div class="input-group mb-3">
                <input type="text" class="form-control" [(ngModel)]="post.amount">
                <span class="input-group-text">$GARY</span>
              </div>
            </div>
          </div>

        </div> -->
        <div class="post-details">
          <textarea name="" id="" class="form-control" rows="3" placeholder="What's going on?"
            (click)="showEmojiPicker = false" (blur)="showEmojiPicker = false" [(ngModel)]="post.body"></textarea>
        </div>
        <div class="post-img">

          <input class="test" #imageInput style="display: none" type="file" accept="image/*" id="file"
            (change)="processFiles($event)" multiple />

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="postImages.length > 0">
          <div class="row">
            <div class="col-md-6 col-6 mb-3" *ngFor="let item of postImages">
              <img [src]="item.src" class="img-upload" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="padding-bottom: 10px">
      <div class="col-md-5 col-5">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <span class="like-icon">
              <i class="fa-regular fa-image fa-xl image-icon" (click)="showEmojiPicker = false; openFileExplorer()"></i>
            </span>
          </div>
          <div class="col-md-3 col-3">
            <span class="like-icon"><i class="fa-regular fa-face-smile fa-xl face-icon"
                (click)="toggleEmojiPicker()"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-7 col-7">
        <div class="d-flex justify-content-end">
          <div class="me-2">
            <div ngbDropdown>
              <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                <span *ngIf="post.type != 'Paid' || post.amount == null">
                  {{ post.type }}
                </span>
                <span *ngIf="post.type == 'Paid' && post.amount != null">
                  {{ post.amount }} $GARY
                </span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div class="d-item" (click)="setPostType('Public')">Public</div>
                <div class="d-item" (click)="setPostType('Paid')">Paid</div>
                <div class="d-item" *ngIf="post.type == 'Paid'">
                  <div class="input-group">
                    <input type="number" class="form-control" [(ngModel)]="post.amount" />
                  </div>
                </div>
                <div class="d-item" (click)="setPostType('Supporters')">
                  Supporters
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
              [disabled]="post.body == '' ||  diasable">
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="emojis">
          <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
            title="Pick your emoji…"></emoji-mart>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="container ">
    <div class="modal-body pt-4 pb-4 d-block d-md-none">
      <div class="row mb-4">
        <div class="col-md-2 col-3">
          <img [src]="user?.sc_profile_picture" alt="" class="img-fluid prof-img"
            style="height: 65px !important; width: 65px !important;">
        </div>
        <div class="col-md-10 col-9">

          <div class="mb-3">
            <select name="" id="" [(ngModel)]="post.type">
              <option value="public">Public</option>
              <option value="supporters">Supporters</option>
            </select>
          </div>
          <div class="post-details">
            <textarea name="" id="post-field" cols="35" rows="5" placeholder="What's going on?" [(ngModel)]="post.body"
              (input)="countCharacters()" (click)="showEmojiPicker = false" (blur)="showEmojiPicker = false"></textarea>
          </div>
          <div class="post-img" style="text-align:center;">
            <img *ngIf="imgFile" [src]="imgFile" style="
                        width: 50%;
                        height: 50%;
                        background-position: center;
                        background-size: cover" alt="" />
            <input class="test" #imageInput style="display: none;" type="file" id="file"
              (change)="processFile(imageInput)" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <div class="row">
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/image.svg" alt="" (click)="showEmojiPicker = false; openFileExplorer()">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/videoo.svg" alt="" (click)="showEmojiPicker = false; openFileExplorer()">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/gif.svg" alt="" (click)="showEmojiPicker = false;">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/smileee.svg" alt="" (click)="toggleEmojiPicker()">
              </span>
            </div>
          </div>

        </div>
        <div class="col-md-6 col-6">
          <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
            [disabled]="post.body == ''">
            Post</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="emojis">
            <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
              title="Pick your emoji…"></emoji-mart>
          </div>
        </div>
      </div>
    </div>

  </div> -->
</ng-template>