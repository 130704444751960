<div class="overlay" style="height: 400px;" *ngIf="loading"></div>
<div class="container" *ngIf="!loading">
  <div class="row">
    <div class="row mt-4">
      <div class="col-md-4 mb-3 mb-md-0 ">
        <div class="border p-3">
          <p>Total $GARY Available</p>
          <h3> {{ (rewards[0].config.total_amount / 1e9) |number : '1.0-2' }} </h3>
        </div>

      </div>

      <div class="col-md-4 mb-3 mb-md-0">
        <div class="border p-3">
          <p>Total $GARY Claimed</p>
          <h3> {{ ((rewards[0].config.total_amount / 1e9) - rewards[0].config.to_burn) |number: '1.0-2'}} </h3>
        </div>

      </div>

      <div class="col-md-4 mb-3 mb-md-0">
        <div class="border p-3">
          <p>Total $GARY To Be Burned</p>
          <h3> {{ rewards[0].config.to_burn |number: '1.0-2'}} </h3>
        </div>

      </div>

    </div>
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="text-center mt-5">
        <h1>CLAIM $GARY</h1>
      </div>
      <div *ngIf="!dater">
        <div class="reward-container mt-4 pt-3 pb-3" *ngFor="let reward of rewards">
          <div *ngIf="reward.amount && !end_dater" class="row">
            <div class="col-md-4">
              <div class="reward-amount text-center">
                <img src="https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg" alt="GARY Icon">
                <span>{{(reward.amount / 1e9) | number : "1.0-2"}} $GARY</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="reward-title text-center">
                {{reward.title}}
              </div>
            </div>
            <div class="col-md-4 text-center">
              <button class="claim-button" *ngIf="reward.unclaimed !== '0'" (click)="claimReward(reward)">Claim</button>
              <button class="claim-button" *ngIf="reward.unclaimed == '0'" disabled>Claimed</button>
            </div>
          </div>
          <div *ngIf="!reward.amount && !end_dater" class="reward-amount text-center">
            You did not qualify for this airdrop
          </div>
          <div *ngIf="end_dater" class="reward-amount text-center">
            Airdrop has ended
          </div>
        </div>
      </div>
      <div *ngIf="dater">
        <div class="reward-container mt-3 pt-3 pb-3">
          <!-- Countdown Timer -->
          <div class="countdown-timer text-center">
            <div id="countdown">
              <span id="days"></span> days
              <span id="hours"></span> hours
              <span id="minutes"></span> minutes
              <span id="seconds"></span> seconds
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <h1>Claim Time Remaining</h1>
        <div id="countdown">
          <ul>
            <li><span id="days"></span>days</li>
            <li><span id="hours"></span>hours</li>
            <li><span id="minutes"></span>mins</li>
            <li><span id="seconds"></span>sec</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>

</div>
