<nav class="navbar navbar-expand-sm navbar-light mt-4">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
      <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" />
    </a>


  </div>
</nav>


<div class="container mt-5" [ngClass]="loginFormClass === true ? 'showForm' : 'hideForm'">
  <div class="row text-center">
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <h3>Welcome to Gary Club</h3>
      <p>A Crypto Creator Community</p>
      <p>Login with your Social Connector account or create one to get started</p>

      <form [formGroup]="joinForm ">
        <div class="form-group mt-3">
          <input type="email" name="email" formControlName="email"
            class="form-control form-control-lg form-control-solid" placeholder="Email">
        </div>
        <div class="form-group">
          <input type="password" name="password" formControlName="password"
            class="form-control form-control-lg form-control-solid" placeholder="Password">
        </div>
        <button class="btn btn-block btn-custom my-3" (click)="login()" [disabled]="joinForm.invalid">Login</button>
      </form>

      <a [href]="'/forgot_password'">Forgot Password?</a>

      <a class="btn btn-outline-primary btn-block mt-4 " [href]="'/signup/'+referral">
        Create A Free Account
      </a>

      <div class="mt-2 mt-md-4">
        <h4 *ngIf="weekly_rewards > 0"> {{ weekly_rewards | number : '1.0-2' }} $GARY paid to creators</h4>
      </div>
    </div>
  </div>
</div>


<div class="container mt-5" [ngClass]="loginFormClass === false ? 'showForm' : 'hideForm'">
  <div class="row text-center">
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <h3>Welcome to Gary Club</h3>
      <p>A Crypto Creator Community</p>
      <!-- <p>Login with your Social Connector account or create one to get started</p> -->
      <!-- <p>A Crypto Creator Community</p> -->
      <p>Please enter 2FA code</p>
      <div class="text-start mt-5">

        <div class="fv-row mb-10">
          <label class="form-label fw-bolder text-gray-900 fs-6">Enter otp</label>
          <code-input [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>

        <div class="text-center">
          <a class="btn btn-clear me-3" (click)="cancel()">Cancel</a>
          <button type="submit" class="btn btn-custom my-3" (click)="verifyOtp()">

            <ng-container>
              <span class="indicator-label">Continue</span>
            </ng-container>
          </button>
          <!-- <button class="btn btn-custom my-3" [disabled]="resetPasswordForm.invalid">Continue</button> -->
        </div>
      </div>

      <!-- <a [href]="'/forgot_password'">Forgot Password?</a> -->




    </div>
  </div>
