import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss']
})
export class CustomComponent implements OnInit {
  @Input() item: any; // Define an Input property to receive data from the parent component
  @Input() app: any;
  @Input() user: any;
  constructor() { }

  ngOnInit(): void {
  }

}
