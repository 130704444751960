import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppServiceService } from 'src/app/_services/app-service.service';

@Component({
  selector: 'app-user-tansactions',
  templateUrl: './user-tansactions.component.html',
  styleUrls: ['./user-tansactions.component.scss']
})
export class UserTansactionsComponent implements OnInit, OnDestroy {
  transactions: any;
  currPage: any = null;
  nextPage: any;
  lastPage: any;
  interval: NodeJS.Timer;
  constructor(
    private service: AppServiceService

  ) {
  }

  ngOnInit(): void {
    this.getAllTransactions(this.currPage);
    this.interval = setInterval(() => {
      this.getAllTransactions(1);
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
  getAllTransactions(currentpage) {
    this.service.getuserTransactions(currentpage).subscribe({
      next: (res: any) => {
        this.transactions = res.data.data
        this.currPage = res.data.meta.current_page;
        this.lastPage = res.data.meta.last_page;
        this.nextPage = this.currPage + 1
        console.log(this.transactions);
      }
    })
  }
  getMoreTransactions() {
    clearInterval(this.interval)
    if (this.currPage < this.lastPage) {
      this.service.getuserTransactions(this.nextPage).subscribe({
        next: (res: any) => {
          this.currPage = res.data.meta.current_page;
          this.lastPage = res.data.meta.last_page;
          this.nextPage = this.currPage + 1
          this.transactions.push(...res.data.data);
          console.log(this.transactions);

        }
      })
    }
  }
  shareToX(amt, type, twitter_username) {
    let url;
    if (twitter_username != "") {
      if (type == 'buy') {
        url = `https://twitter.com/intent/tweet/?text=I just bought a coin and supported @${twitter_username} at &hashtags=GaryClub`;
      } else {
        url = `https://twitter.com/intent/tweet/?text=I just sold some @${twitter_username} coi at &hashtags=GaryClub`;
      }
    } else {
      if (type == 'buy') {
        url = `https://twitter.com/intent/tweet/?text=I just bought a coin at &hashtags=GaryClub`;
      } else {
        url = `https://twitter.com/intent/tweet/?text=I just sold some coin at &hashtags=GaryClub`;

      }
    }
    window.open(url, 'blank');
  }
}
