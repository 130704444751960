<div class="container relative">
  <div class="overlay mt-5" style="height: 200px;" *ngIf="loading">
  </div>
  <div *ngIf="!loading">
    <div class="text-center my-3 " *ngIf="errorMessage">
      <p> {{ errorMessage }} </p>
    </div>


    <div class="row" *ngIf="errorMessage == null">
      <div class="col-md-8 offset-md-2">
        <ul class="list-unstyled border">

          <li class="border-bottom" style="max-width: 100% !important;">
            <div class="py-2 px-3 pt-4">

              <div class="d-flex align-items-start">
                <a [href]="'/'+app.post?.user?.social_tag">
                  <img
                    [src]="app.post?.user.sc_profile_picture?app.post?.user.sc_profile_picture:'assets/imgs/placeholder.png'"
                    class="activityimg me-2">
                </a>
                <div>
                  <h3 class="activity_title">
                    <a [href]="'/'+app.post?.user?.social_tag">{{ app.post?.user?.social_tag }}</a>
                  </h3>
                  <!-- <a [href]="feeds?.post_link"> -->
                  <!-- <a> -->
                  <div class="activity_content act-cont mb-1" [innerHTML]="sanitizeHTML(app.post?.comment)"></div>
                  <!-- <img [src]="feeds?.postImages[0].path" *ngIf="feeds?.postImages.length > 0" alt=""
                    class="img-fluid mb-1 d-none d-md-block" style="border-radius: 10px; max-width:400px;">
                  <img [src]="feeds?.postImages[0].path" *ngIf="feeds?.postImages.length > 0" alt=""
                    class="img-fluid mb-1 d-block d-md-none" style="border-radius: 10px;"> -->
                  <!-- </a> -->
                  <div class="mt-1 d-flex align-items-center opacity8">
                    <button class="btn btn-clear me-4" (click)="app.commentPost(contentComment, app.post)">
                      <i class="fa-regular fa-comment"></i> {{ app.post?.comments_count }}
                    </button>
                    <button class="btn btn-clear me-4" (click)="app.likePostUI(app.post?.id,'reply')"
                      *ngIf="app.post?.user_likes_count < 1">
                      <!-- <i class="fa-regular fa-thumbs-up  "></i> -->
                      <i class="fa-sharp fa-regular fa-heart"></i>
                      <!-- <i class="fa-solid fa-thumbs-up fa-xl "></i> -->
                      {{ app.post?.likes_count }} </button>
                    <button class="btn btn-clear me-4" (click)="app.likePostUI(app.post?.id,'reply')"
                      *ngIf="app.post?.user_likes_count > 0">
                      <!-- <i class="fa-solid fa-thumbs-up  "></i> -->
                      <i class="fa-sharp fa-solid fa-heart"></i>
                      <!-- <i class="fa-solid fa-thumbs-up fa-xl "></i> -->
                      {{ app.post?.likes_count }} </button>
                    <!-- <button class="btn btn-clear" (click)="app.dislikePostUI(app.post?.id,'reply')"
                      *ngIf="app.post?.user_dis_likes_count < 1">
                      <i class="fa-regular fa-thumbs-down  "></i>
                      {{ app.post?.dis_likes_count }}
                    </button>
                    <button class="btn btn-clear" (click)="app.dislikePostUI(app.post?.id,'reply')"
                      *ngIf="app.post?.user_dis_likes_count > 0">
                      <i class="fa-solid fa-thumbs-down  "></i>
                      {{ app.post?.dis_likes_count }}
                    </button> -->
                  </div>
                </div>
                <div class="ms-auto ">
                  <span class="text-muted">{{ app.post?.created_at | dateAsAgo }}</span>
                  <div ngbDropdown class="d-inline-block" *ngIf="app.post?.user?.social_tag == user?.social_tag">
                    <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
                      <i class="fa-solid fa-ellipsis fa-1x ico"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button class="ico2" ngbDropdownItem (click)="app.deleteCommentUI(app.post)">Delete
                        Post</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-bottom">
            <div class="py-2 px-3">

              <div class="d-flex align-items-start" style="display: flex !important;
              align-items: center !important;
              justify-content: center !important;">
                <a [href]="'/'+user?.social_tag">
                  <img [src]="user?.sc_profile_picture?user?.sc_profile_picture:'assets/imgs/placeholder.png'"
                    class="activityimg2 me-2">
                </a>
                <textarea name="" class="autoresize" id="" (click)="app.showEmojiPicker = false"
                  [(ngModel)]="app.comment.comment" [placeholder]="'Reply to ' + app.post?.user?.social_tag"
                  style="overflow: hidden; overflow-wrap: break-word; height: 49px;"></textarea>
                <div class="post-publication">
                  <button class="btn btn-publish" [disabled]="app.commenting"
                    (click)="app.showEmojiPicker = false;app.commentOnCommentUI(app.post?.id,'reply')">Post</button>
                </div>
              </div>
            </div>
          </li>

          <app-comment [comments]="app.comments" [user]="user" [app]="app"
            [postDetails]="app.postDetails"></app-comment>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #contentComment let-modal>
  <div class="container">
    <div class="modal-body">
      <div class="postDetails">
        <div class="user-details palo" style="margin-bottom: 15px;">
          <img (click)="goToProfile(app.postDetails?.user.social_tag)" [src]="app.postDetails.user.sc_profile_picture"
            onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
            style="width: 50px; height:50px;" />
          <h6 class="nameea" (click)="goToProfile(app.postDetails?.user.social_tag)">
            {{ app.postDetails?.user.social_tag }}
          </h6>
        </div>
        <div class="line">

          <div class="user-details palo">
            <img (click)="goToProfile(app.postDetails?.user.social_tag)"
              [src]="app.postDetails?.user?.sc_profile_picture"
              onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
              style="visibility: hidden;" />
            <div class=" mb-1 act-cont" [innerHTML]="sanitizeHTML(app.postDetails?.comment)"></div>

          </div>
        </div>
      </div>
      <div class="user-details palo" style="margin-bottom: 15px;">
        <img (click)="goToProfile(user?.social_tag)" [src]="user?.sc_profile_picture"
          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
          style="width: 50px; height:50px;" />
        <textarea name="" class="autoresize" id="" (click)="app.showEmojiPicker = false"
          [(ngModel)]="app.comment.comment" [placeholder]="'Reply to ' +app.postDetails?.user.social_tag"
          style="overflow: hidden; overflow-wrap: break-word; height: 49px;"></textarea>
      </div>
      <div class="post-img">
        <img *ngIf="app.imgFile" [src]="app.imgFile" style="
                          width: 50%;
                          height: 50% !important;
                         " alt="" />
        <input class="test" #imageInput style="display: none;" type="file" accept="image/*" id="file"
          (change)="app.processFile(imageInput)" />
      </div>
      <div class="emoji">
        <emoji-mart class="emoji-mart" [perLine]="12" [emojiTooltip]="false" [showPreview]="false" set="twitter"
          [hideRecent]="true" *ngIf="app.showEmojiPicker" (emojiSelect)="app.addEmoji($event)"
          title="Pick your emoji…"></emoji-mart>
      </div>
      <div class="timeline-box">
        <button class="ctrl-btn" type="button">
          <i class="fa-regular fa-face-smile fa-xl face-icon" (click)="app.toggleEmojiPicker()"></i>
        </button>
        <button class="ctrl-btn" type="button">
          <i class="fa-regular fa-image fa-xl face-icon"
            (click)="app.showEmojiPicker = false; app.openFileExplorer()"></i>
        </button>

      </div>
      <div class="post-publication">
        <button class="btn btn-publish" [disabled]="app.commenting"
          (click)="app.showEmojiPicker = false;app.commentOnCommentUI(app.postDetails?.id,'reply')">Post</button>
      </div>
    </div>
  </div>
</ng-template>