<div class="container">
  <div class="overlay" style="height: 400px;" *ngIf="loading"></div>
  <div class="row mt-5" *ngIf="!loading">
    <div class="col-12 ">
      <div class="d-flex align-items-center mb-2">
        <h2 class="mb-0">{{ towerMembers?.name }}</h2>
        <!-- <a [href]="buildJoinTowerString(towerMembers?.trait_type, towerMembers?.trait_value)" target="_blank" -->
        <a [href]="towerMembers.link" target="_blank" rel="noopener noreferrer"
          class="btn btn-custom rounded-pill ms-3">
          Join Tower
        </a>
      </div>
      <p class="description mb-3">
        {{ towerMembers?.description }}

      </p>
      <!-- <p class="mb-0">Floor Price</p>
      <span *ngIf="towerMembers.floor_price > 0; else noListings">${{ towerMembers.floor_price | number: '0.0-2' }}</span>
      <ng-template #noListings>No Listings</ng-template> -->


      <div class="mt-5">
        <h3>Members</h3>
      </div>
    </div>

  </div>

  <div class="row mt-3">
    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mb-3"
      *ngFor="let items of towerMembers?.segment_users | paginate: { itemsPerPage: 30, currentPage: p }; let i = index">
      <a [href]="'/'+items?.name">
        <div class="text-center">
          <img [src]="items?.image || 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg'" alt=""
            class="img-fluid towerImg">
          <p class="tower">{{ items?.name }}</p>
        </div>
      </a>

    </div>
  </div>

  <div class="text-end mt-3">
    <pagination-controls *ngIf="towerMembers?.segment_users.length && towerMembers?.segment_users.length > 30"
      class="d-flex justify-content-end my-pagination" [directionLinks]="true" [responsive]="true"
      (pageChange)="p = $event"></pagination-controls>
  </div>
</div>