<div class="py-2 px-3" *ngIf="item?.activity === 'mint_collectibles'  ">
  <div class="d-flex mb-2 align-items-center">
    <p class="text-w-light text-muted mb-0">
      <span class="icon_padding me-2">
        <i class="fa-regular fa-sm fa-tag"></i>
      </span>
      OFFERS
    </p>
    <div class="ms-auto ">
      <span class="text-muted">{{ item?.date }}</span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div class="imgs me-1" *ngIf="item?.image_link">
      <a [href]="item?.image_link">
        <img [src]="item?.image?item?.image:'assets/imgs/placeholder.png'" class="activityimg ">
      </a>
    </div>
    <div class="imgs me-2" *ngIf="item?.owner_link">
      <a [href]="item?.owner_link">
        <img [src]="item?.owner?item?.owner:'assets/imgs/placeholder.png'" class="activityimg ">
      </a>
    </div>
    <div class="activity_content" [innerHTML]="item?.text?.text"></div>
    <div class="price">
      <span *ngIf="item?.text?.price"> {{ item?.text?.price }} </span>
    </div>
  </div>
</div>