<div class="py-2 px-3" *ngIf="item?.activity === 'post' ||  item?.activity==='create_post' ">
  <div class="d-flex mb-2 align-items-center">
    <p *ngIf="item?.text?.supporter_only" class="text-w-light text-muted mb-0">
      <span class="icon_padding me-2">
        <i class="fa-regular fa-sm fa-lock  fa-sm"></i>
      </span>

      SUPPORTER POST
    </p>
    <p *ngIf="item?.text?.paid_post" class="text-w-light text-muted mb-0">
      <span class="icon_padding me-2">
        <i class="fa-regular fa-sm fa-lock  fa-sm"></i>
      </span>

      PAID POST
    </p>

    <p *ngIf="!item?.text?.paid_post && !item?.text?.supporter_only" class="text-w-light text-muted mb-0">
      <span class="icon_padding me-2">
        <i class="fa-regular fa-sm fa-newspaper  fa-sm"></i>
      </span>

      POST
    </p>
    <div class="ms-auto ">
      <span class="text-muted">{{ item?.date }}</span>
      <div ngbDropdown class="d-inline-block" *ngIf="item?.text?.user?.social_tag == user?.social_tag">
        <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
          <i class="fa-solid fa-ellipsis fa-1x ico"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="ico2" ngbDropdownItem (click)="app.deletePostUI(item?.text)">Delete
            Post</button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-start">
    <a [href]="item?.owner_link">
      <img [src]="item?.owner?item?.owner:'assets/imgs/placeholder.png'" class="activityimg me-2">
    </a>
    <div style="width: 100%;">
      <h3 class="activity_title">
        <a [href]="item?.owner_link">{{ item?.owner_name }}</a>
      </h3>
      <span *ngIf="!item?.text?.should_pay && !item?.text?.should_subscribe">
        <span style="cursor: pointer;" [routerLink]="'/post/'+item?.text?.id">
          <!-- <a> -->

          <div class="activity_content mb-3" [innerHTML]="app.sanitizeHTML(item?.text?.body)"></div>
          <div *ngIf="item?.text?.post_images?.length > 0">
            <div class="image-div" *ngIf="item?.text?.post_images.length >= 4">
              <div class="image-container">
                <img *ngFor="let image of item?.text?.post_images.slice(0,4)" [src]="image.path" alt=""
                  class="img-fluid uploaded-image" style="width: 50%; height:230px;object-fit: cover !important;">
              </div>
            </div>
            <div class="image-div" *ngIf="item?.text?.post_images.length == 2">
              <div class="image-container">
                <img *ngFor="let image of item?.text?.post_images" [src]="image.path" alt=""
                  class="img-fluid uploaded-image" style="width: 50%; height:288px;object-fit: cover !important;">
              </div>
            </div>
            <div class="image-div" *ngIf="item?.text?.post_images.length == 1">
              <div class="image-container">
                <img *ngFor="let image of item?.text?.post_images" [src]="image.path" alt="" class="img-fluid  one_img"
                  style="object-fit: cover !important;">
              </div>
            </div>
          </div>

        </span>
        <div class="mt-1 d-flex align-items-center opacity8">
          <button class="btn btn-clear me-4" (click)="app.commentPost(content, item)">
            <i class="fa-regular fa-comment"></i> {{ item?.text?.comments_count }}
          </button>
          <button class="btn btn-clear me-4" (click)="app.likePostUI(item?.text?.id)"
            *ngIf="item?.text?.user_likes_count < 1">
            <!-- <i class="fa-regular fa-thumbs-up  "></i> -->
            <i class="fa-sharp fa-regular fa-heart"></i>
            <!-- <i class="fa-solid fa-thumbs-up fa-xl "></i> -->
            {{ item?.text?.likes_count }} </button>
          <button class="btn btn-clear me-4" (click)="app.likePostUI(item?.text?.id)"
            *ngIf="item?.text?.user_likes_count > 0">
            <!-- <i class="fa-solid fa-thumbs-up  "></i> -->
            <i class="fa-sharp fa-solid fa-heart"></i>
            <!-- <i class="fa-solid fa-thumbs-up fa-xl "></i> -->
            {{ item?.text?.likes_count }} </button>
          <!-- <button class="btn btn-clear" (click)="app.dislikePostUI(item?.text?.id)"
            *ngIf="item?.text?.user_dis_likes_count < 1">
            <i class="fa-regular fa-thumbs-down  "></i>
            {{ item?.text?.dis_likes_count }}
          </button> -->
          <!-- <button class="btn btn-clear" (click)="app.dislikePostUI(item?.text?.id)"
            *ngIf="item?.text?.user_dis_likes_count > 0">
            <i class="fa-solid fa-thumbs-down  "></i>
            {{ item?.text?.dis_likes_count }}
          </button> -->
        </div>
      </span>
      <span *ngIf="item?.text?.should_subscribe">
        <div class="activity_content mb-1">
          <div class="activity_content mb-3" [innerHTML]="app.sanitizeHTML(item?.text?.body)"></div>
        </div>
        <div class="blur">


          <button class="btn btn-outline-light btn-block rounded-pill py-3 me-2 pay-btn"
            (click)="toLink(item?.owner_name)">
            Support to Unlock
          </button>
        </div>
      </span>
      <span *ngIf="item?.text?.should_pay">
        <!-- <a [href]="'/post/'+item?.text?.id"> -->
        <!-- <a> -->

        <div class="activity_content mb-1 ">
          <div class="activity_content mb-3" [innerHTML]="app.sanitizeHTML(item?.text?.body)"></div>
        </div>
        <div class="blur">

          <button class="btn btn-outline-light btn-block rounded-pill py-3 me-2 pay-btn"
            (click)="confirmPay(item?.text?.id, content2)">
            Pay {{ item?.text?.amount }} $GARY to unlock
          </button>


        </div>

        <!-- </a> -->
        <!-- <div class="cover">

        </div> -->
      </span>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="container">
    <div class="modal-body">
      <div class="postDetails">
        <div class="user-details palo" style="margin-bottom: 15px;">
          <img (click)="app.goToProfile(app.postDetails?.owner_name)" [src]="app.postDetails?.owner"
            onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
            style="width: 50px; height:50px;" />
          <h6 class="nameea" (click)="app.goToProfile(app.postDetails?.owner_name)">
            {{ app.postDetails?.owner_name }}
          </h6>
        </div>
        <div class="line">

          <div class="user-details palo">
            <img (click)="app.goToProfile(app.comment?.user?.social_tag)" [src]="app.comment?.user?.sc_profile_picture"
              onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
              style="visibility: hidden;" />
            <div class=" mb-1 act-cont">
              <div [innerHTML]="app.sanitizeHTML(app.postDetails?.text?.body)"></div>
            </div>
            <!-- <img [src]="postDetails?.text?.post_images[0].path" *ngIf="postDetails?.text?.post_images.length > 0" alt=""
              class="img-fluid mb-1 d-none d-md-block" style="border-radius: 10px; max-width:400px;">
            <img [src]="postDetails?.text?.post_images[0].path" *ngIf="postDetails?.text?.post_images.length > 0" alt=""
              class="img-fluid mb-1 d-block d-md-none" style="border-radius: 10px;"> -->
          </div>
        </div>
      </div>
      <div class="user-details palo" style="margin-bottom: 15px;">
        <img (click)="app.goToProfile(user?.social_tag)" [src]="user?.sc_profile_picture"
          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
          style="width: 50px; height:50px;" />
        <textarea name="" class="autoresize" id="" (click)="app.showEmojiPicker = false"
          [(ngModel)]="app.comment.comment" [placeholder]="'Reply to ' + app.postDetails?.owner_name"
          style="overflow: hidden; overflow-wrap: break-word; height: 49px;"></textarea>

      </div>
      <div class="post-img">
        <img *ngIf="app.imgFile" [src]="app.imgFile" style="
                          width: 50%;
                          height: 50% !important;
                         " alt="" />
        <input class="test" #imageInput style="display: none;" type="file" accept="image/*" id="file"
          (change)="app.processFile(imageInput)" />
      </div>
      <div class="emoji">
        <emoji-mart class="emoji-mart" [perLine]="12" [emojiTooltip]="false" [showPreview]="false" set="twitter"
          [hideRecent]="true" *ngIf="app.showEmojiPicker" (emojiSelect)="app.addEmoji($event)"
          title="Pick your emoji…"></emoji-mart>
      </div>
      <div class="timeline-box">
        <button class="ctrl-btn" type="button">
          <i class="fa-regular fa-face-smile fa-xl face-icon" (click)="app.toggleEmojiPicker()"></i>
        </button>
        <button class="ctrl-btn" type="button" (click)="app.showEmojiPicker = false; app.openFileExplorer()">
          <i class="fa-regular fa-image fa-xl face-icon"></i>
        </button>
        <!-- <button class="ctrl-btn" type="button">
          <i class="fa-regular fa-gif fa-xl face-icon"></i>
        </button> -->
      </div>
      <!-- <hr> -->
      <div class="post-publication">
        <button class="btn btn-publish" [disabled]="app.commenting"
          (click)="app.showEmojiPicker = false;app.commentOnPost(app.postDetails?.text?.id)">Post</button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-body">
    <div class="row pt-2 px-2">
      <div class="col-12">
        <h5>Are you sure you want to unlock this post?</h5>
        <div class="bg-danger p-2 rounded-3 mt-3">
          <p class="mb-0 fs-9" style="color: #fff !important;"><b>Disclaimer:</b> This is a DeFi payment that can't
            be reversed. Funds go directly to the creator</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-clear" (click)="modal.dismiss('Close click')">Cancel</button>
    <button class="btn btn-primary" (click)="pay()">Unlock</button>
  </div>
</ng-template>