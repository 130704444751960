import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppServiceService } from 'src/app/_services/app-service.service';
// import { TestService } from '../test.service';

interface Task {
  grow_audience: string;
  make_money: string;
  intensity: number;
  work_sumarry: string[];
  date: string;
  price: number;
  score: string;
  volume: number;
}

@Component({
  selector: 'app-pow',
  templateUrl: './pow.component.html',
  styleUrls: ['./pow.component.scss'],
  providers: [DatePipe],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PowComponent implements OnInit {
  @Input() tokens: string;
  yearCalendar: any[];
  selectedDate: any;
  data: Task[];
  dateData: Task;
  updateYear: (Task | {
    date: any; intensity: number; grow_audience: string; make_money: string; work_sumarry: any[];
    price: number; score: string; volume: number;
  })[];
  yearCurrent: number;
  total: number;
  yesterday: Date;
  tomorrow: Date;
  allYears: any;
  currentYear: number;
  years: { year: number; isActive: boolean; }[];
  yearToSend: any;
  setSctiveYear: any;
  startloading: boolean;

  constructor(private test: AppServiceService, private datePipe: DatePipe, private cd: ChangeDetectorRef, public app: AppServiceService) {
    const today = new Date();
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    this.yesterday = yesterday
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    this.tomorrow = tomorrow
  }

  ngOnInit(): void {
    this.startloading = true
    const today = new Date();
    this.selectedDate = today;
    this.currentYear = new Date().getUTCFullYear();
    this.yearToSend = new Date().getUTCFullYear();
    this.setSctiveYear = this.yearToSend
    this.years = this.getYearsArrayDescending(this.currentYear);
    this.yearCalendar = this.getDaysInCurrentYear(this.yearToSend)
    // console.log('Year calendar', this.yearCalendar)

    this.getData(this.yearToSend)

  }

  getYearsArray(): number[] {
    const currentYear = this.yearToSend
    const startYear = 2022;
    const numYears = currentYear - startYear + 1;
    const yearsArray = Array.from({ length: numYears }, (_, index) => currentYear - index);
    return yearsArray;
  }

  getYearsArrayDescending(activeYear: number): { year: number; isActive: boolean }[] {
    const currentYear = new Date().getUTCFullYear();
    const startYear = 2022;
    const numYears = currentYear - startYear + 1;
    const yearsArray = Array.from(
      { length: numYears },
      (_, index) => ({
        year: currentYear - index,
        isActive: currentYear - index === activeYear
      })
    );
    return yearsArray;
  }

  changeYear(year) {
    this.startloading = true
    // console.log('change year', year);
    this.yearToSend = year
    this.setSctiveYear = this.yearToSend
    // console.log(this.currentYear, ' and', this.yearToSend);

    this.yearCalendar = this.getDaysInCurrentYear(this.yearToSend)
    // console.log('new Yearcalendar', this.yearCalendar)

    this.getData(year)
    // this.startloading = false
  }



  // getDaysInCurrentYear(currentYear) {
  //   this.yearCurrent = currentYear;
  //   const firstDayOfYear = new Date(currentYear, 0, 1); // Set the first day of the year
  //   const lastDayOfYear = new Date(currentYear, 11, 31); // Set the last day of the year
  //   const daysInYear = []; // Create an empty array to hold the days of the year

  //   // Loop through each day of the year and add it to the array
  //   for (let date = firstDayOfYear; date <= lastDayOfYear; date.setUTCDate(date.getUTCDate() + 1)) {
  //     daysInYear.push(new Date(date));
  //   }

  //   // console.log('completing year to send')

  //   return daysInYear;
  // }

  getDaysInCurrentYear(currentYear) {
    this.yearCurrent = currentYear;
    const firstDayOfYear = new Date(Date.UTC(currentYear, 0, 1)); // Set the first day of the year in UTC timezone
    const lastDayOfYear = new Date(Date.UTC(currentYear, 11, 31)); // Set the last day of the year in UTC timezone
    const daysInYear = []; // Create an empty array to hold the days of the year

    // Loop through each day of the year and add it to the array
    for (let date = firstDayOfYear; date <= lastDayOfYear; date.setUTCDate(date.getUTCDate() + 1)) {
      daysInYear.push(new Date(date.getTime())); // Push a new Date object with UTC timezone to the array
    }

    // console.log('completing year to send')

    return daysInYear;
  }


  // getDaysInYear(): Date[] {
  //   const daysInYear: Date[] = [];
  //   const currentYear = new Date().getFullYear();
  //   const startDate = new Date(currentYear, 0, 1); // January 1st
  //   const endDate = new Date(currentYear, 11, 31); // December 31st
  //   const millisecondsInDay = 86400000; // Number of milliseconds in a day

  //   for (let date = startDate; date <= endDate; date.setTime(date.getTime() + millisecondsInDay)) {
  //     daysInYear.push(new Date(date.getTime()));
  //   }

  //   return daysInYear;
  // }

  onDayClicked(date: any) {
    // console.log('this date', date)
    this.dateData = null
    this.selectedDate = new Date(date);
    // console.log('first selected date', this.selectedDate) /

    for (let i = 0; i < this.data.length; i++) {
      const element = this.data[i];
      const date = new Date(this.selectedDate);
      const year = date.getUTCFullYear();
      const month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Add leading zero if needed
      const day = ('0' + date.getUTCDate()).slice(-2); // Add leading zero if needed
      const dateString = `${year}-${month}-${day}`;
      this.selectedDate = dateString
      // console.log('second selected date', this.selectedDate)
      // console.log(dateString, element.date); // Outputs: 2023-03-18

      if (dateString === element.date) {
        this.dateData = element;
        // console.log(this.dateData)
      }

    }
    this.cd.detectChanges();

  }

  prevDay() {
    // console.log('previous', date)
    if (typeof this.selectedDate === "string") {
      this.selectedDate = new Date((this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd')));
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() - 1);
      this.onDayClicked(dateA);
      // console.log(dateA);
    } else if (this.selectedDate instanceof Date) {
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() - 1);
      this.onDayClicked(dateA);
      // console.log(dateA);
    }
    this.cd.detectChanges();

  }

  nextDay() {
    // console.log(this.selectedDate)
    if (typeof this.selectedDate === "string") {
      this.selectedDate = new Date(this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd'));
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() + 1);
      this.onDayClicked(dateA);
    } else if (this.selectedDate instanceof Date) {
      const dateA = this.selectedDate
      dateA.setUTCDate(dateA.getUTCDate() + 1);
      this.onDayClicked(dateA);
    }


    this.cd.detectChanges();

  }

  getData(year) {
    this.test.getPOW(this.tokens, year).subscribe({
      next: (res: any) => {
        // console.log('data', res.data)
        this.data = res.data
        this.total = this.data.length
      },
      complete: () => {
        this.checkSomething()
        for (let i = 0; i < this.data.length; i++) {
          const element = this.data[i];
          const date = new Date(this.selectedDate);
          const year = date.getUTCFullYear();
          const month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Add leading zero if needed
          const day = ('0' + date.getUTCDate()).slice(-2); // Add leading zero if needed
          const dateString = `${year}-${month}-${day}`;
          // console.log(dateString, element.date); // Outputs: 2023-03-18

          if (dateString === element.date) {
            this.dateData = element;
          }

        }

        this.startloading = false
      }
    })
  }


  checkSomething() {
    let c = []
    // const a = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    for (let i = 0; i < this.yearCalendar.length; i++) {
      const element = this.yearCalendar[i];
      const date = new Date(element);
      const year = date.getUTCFullYear();
      const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
      const day = ('0' + date.getUTCDate()).slice(-2);
      const dateString = `${year}-${month}-${day}`;
      c.push(dateString);
    }
    // console.log(c)
    const a = c
    // const b = [{ date: 12, intensity: 1 }, { date: 14, intensity: 2 }];
    const b = this.data

    const updatedA = a.map(value => {
      const matchIndex = b.findIndex(item => item.date === value);
      return matchIndex !== -1 ? b[matchIndex] : { date: value, intensity: 0, grow_audience: 'no', make_money: 'no', work_sumarry: [], price: null, score: '0', volume: null };
    });

    // console.log('updated Year', updatedA);
    this.updateYear = updatedA

    // console.log('updated', this.updateYear)



    // grow_audience: string;
    // make_money: string;
    // intensity: number;
    // work_sumarry: string[];
    // date: string;
  }


}
