<div class="container-fluid pt-5">
  <div>
    <div class="mb-4">
      <h2>
        Your Transaction Status
      </h2>
    </div>
    <div class="row my-5">
      <div class="col-12">
        <div class="card py-md-3">
          <div class="card-body">
            <div class="table-responsive overflow rounded">
              <table class="table table-striped align-middle">
                <thead>
                  <tr>
                    <th>CREATOR</th>
                    <th>TRANSACTION</th>
                    <th>QUANTITY</th>
                    <th>COST</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of transactions">
                    <td>
                      <a [routerLink]="['/'+item.owner_social_tag]" routerLinkActive="router-link-active">
                        <img
                          [src]="item.owner_profile_img ?? 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg' "
                          alt="" class="rounded-circle me-3 img-fluid imgsize" />
                        <span>
                          {{item.owner_social_tag}}
                        </span></a>
                    </td>
                    <td>
                      {{ item.type }}
                    </td>
                    <td>
                      {{ item.amount }}
                    </td>
                    <td>
                      <span *ngIf="item.status == 'processing' || item.status == 'pending'" class="text-warning"> {{
                        item?.gary_quote | number:'1.2-2' }} $GARY </span>
                      <span *ngIf="item.status == 'approved'">
                        {{ item.gary_amount | number:'1.2-2' }} $GARY </span>

                    </td>
                    <td>
                      <p class="badge badge-primary" *ngIf="item.status == 'approved'">
                        COMPLETED
                      </p>
                      <p class="badge badge-warning" *ngIf="item.status == 'processing' || item.status == 'pending'">
                        PROCESSING
                      </p>
                      <p class="badge badge-danger" *ngIf="item.status == 'failed'">
                        FAILED
                      </p>
                    </td>
                    <td>
                      <button class="shareToXEnabled" *ngIf="item.status == 'approved'"
                        (click)="shareToX(item.amount, item.type, item.owner_twitter_username)">Share
                        to X</button>
                      <button class="shareToXDisabled" disabled *ngIf="item.status != 'approved'">Share
                        to X</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="text-end">
            <button class="btn btn-clear text-primary" (click)="getMoreTransactions()">
              Load more
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>