<div class="py-2 px-3" *ngIf="item?.activity === 'pow_submit'  ">
  <div class="d-flex mb-2 align-items-center">
    <p class="text-w-light text-muted mb-0">
      <span class="icon_padding me-2">
        <i class="fa-regular fa-sm fa-briefcase"></i>
      </span>
      POW
    </p>
    <div class="ms-auto ">
      <span class="text-muted">{{ item?.date }}</span>
    </div>
  </div>
  <div class="d-flex d-flex align-items-start">
    <div class="imgs me-1" *ngIf="item?.image_link">
      <a [href]="item?.image_link">
        <img [src]="item?.image?item?.image:'assets/imgs/placeholder.png'" class="activityimg ">
      </a>
    </div>
    <div class="imgs me-2" *ngIf="item?.owner_link">
      <a [href]="item?.owner_link">
        <img [src]="item?.owner?item?.owner:'assets/imgs/placeholder.png'" class="activityimg ">
      </a>
    </div>
    <div class="activity_content">
      <span [innerHTML]="item?.text?.text"></span>
      <ul *ngIf="item?.text?.data" class="summary mt-1">
        <li *ngFor="let work of item?.text?.data?.work_summary">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M0.5 0V8C0.5 9.65685 1.84315 11 3.5 11H11.5" stroke="white" />
            </svg>
          </i>
          <span>
            {{ work }}
          </span>

        </li>
      </ul>
    </div>
  </div>
  <div class="mt-1 d-flex ms-5">
    <div class="  d-block ms-3 ">
      <p class="mb-0 text-muted">Price</p>
      <h6>{{app.getPriceInGary(item?.text?.data?.price_usd_with_fee) || '0' | number : '0.0-2'}} {{ app.coin_symbol }}
      </h6>

    </div>
    <div class="  d-block ms-5 ">
      <p class="mb-0 text-muted">Volume</p>
      <h6>{{item?.text?.data?.volume_stake || '0'}} {{ app.coin_symbol }}</h6>
    </div>
    <div class=" ms-5  ">
      <p class="mb-0 text-muted">Points</p>
      <h6>{{item?.text?.data?.score || '0'}}</h6>
    </div>

  </div>
</div>

