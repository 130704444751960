import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, finalize, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
// const api = `${environment.social_pay_baseurl}`;
const apiUrl = `${environment.apiUrl}`;
const base_api = `${environment.base_api}`
// @ts-ignore
const gateway = `${environment.social_pay_gateway}`;
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
    providedIn: 'root'
})
export class POWService {

    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    private authLocalStorageToken = `user`;
    isLoading$: Observable<boolean>;
    isLoadingSubject: BehaviorSubject<boolean>;
    userData: any;
    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(true);
        this.isLoading$ = this.isLoadingSubject.asObservable();
        this.userData = this.getAuth()
        // console.log(a)
    }

    saveToPOW(data) {
        this.isLoadingSubject.next(true);
        return this.http.post(`${apiUrl}/exchange/proof-of-work/store`, data, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false))
        )
    }
    public getAuth() {
        try {
            const lsValue = localStorage.getItem(this.authLocalStorageToken);
            if (!lsValue) {
                return undefined;
            }

            const authData = JSON.parse(lsValue);
            const a = authData.data.token;
            return a;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }
    saveToPOWConfig(data) {
        this.isLoadingSubject.next(true);
        return this.http.post(`${apiUrl}/exchange/proof-of-work/store-regular`, data, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false))
        )
    }

    getPOW() {
        this.isLoadingSubject.next(true);
        return this.http.get(`${apiUrl}/exchange/proof-of-work/${this.userData.permission_coin}`, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false)))
    }

    updatePOWConfigStatus(payload: any, hash: any) {
        this.isLoadingSubject.next(true);
        return this.http.patch(`${apiUrl}/exchange/proof-of-work/update-config-status/${hash}`, payload, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false)))
    }

    updatePOWConfig(payload: any) {
        this.isLoadingSubject.next(true);
        return this.http.patch(`${apiUrl}/exchange/pow/update-config/${payload?.hash}`, payload, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false)))
    }

    getConfigPOW(hash: any) {
        this.isLoadingSubject.next(true);
        return this.http.get(`${apiUrl}/exchange/user-submitted-pow/${hash}`, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false)))
    }
    getPowConfig() {
        this.isLoadingSubject.next(true);
        return this.http.get(`${apiUrl}/exchange/proof-of-work/list-config`, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false)))
    }
    getUserPow() {
        this.isLoadingSubject.next(true);
        return this.http.get(`${apiUrl}/exchange/proof-of-work/user-pow`, httpOptions).pipe(
            finalize(() => this.isLoadingSubject.next(false)))
    }
}
