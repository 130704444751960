import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'angular-toastify';
import { AppServiceService } from 'src/app/_services/app-service.service';
import { PopupDataService } from 'src/app/_services/popup-data.service';
import { PusherService } from 'src/app/_services/pusher.service';
import Swal from 'sweetalert2';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit, OnDestroy {
  loading = true
  newloading: boolean = false;
  // page = 1
  nextCursor: any = null;
  totalLength: any;
  perPage: number;
  hideLoad: boolean = false;
  errorMessage: string = null;
  timeout: NodeJS.Timeout;
  refresh: NodeJS.Timer;
  user: any;
  imageErrValue: string;
  imageError: boolean;
  qqq: boolean;
  maxFileSize = 3 * 1024 * 1024;
  selectedFile: ImageSnippet;
  imgFile: any;
  param: void;
  constructor(
    public app: AppServiceService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private pusherService: PusherService,
    private modal: NgbModal,
    private popupDataService: PopupDataService,
    private elementRef: ElementRef,
    private service: AppServiceService,
  ) { }
  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: Event): void {
  //   // Check if the click occurred outside of the modal
  //   // if (!this.elementRef.nativeElement.contains(event.target)) {
  //   //   this.comment.comment = "";
  //   //   this.comment.post_id = "";
  //   //   this.showEmojiPicker = false;
  //   // }
  // }
  onModalClick(event: Event): void {
    // Stop the click event from propagating to the document
    event.stopPropagation();
  }
  ngOnInit(): void {
    this.app.activityLogs = [];
    this.app.imgFile = "";
    this.app.selectedFile = null;

    this.getUser();
    this.allActivtyLogs(this.nextCursor);
    this.service.buttonClick$.subscribe((param) => {
      this.param = param
      this.allActivtyLogs(null, param);
    });
    this.refresh = setInterval(() => {
      this.allActivtyLogs(null)
    }, 1200000);
  }

  openImagePopup(url) {
    this.popupDataService.openImagePopup(url);
  }

  async getUser() {
    const a = await this.app.getUser()
    this.user = a
  }
  allActivtyLogs(nextCursor, filterParam?: any) {
    this.app.allActivtyLogs(nextCursor, filterParam).subscribe({
      next: (res: any) => {
        this.errorMessage = null
        this.nextCursor = res.data.meta.next_cursor
        // this.totalTokenList = this.tokenLists

        this.app.activityLogs = res.data.data

        this.totalLength = this.app.activityLogs.length
        if (this.totalLength <= 0) {
          this.hideLoad = true
        }
        else {
          this.hideLoad = false
        }

      },
      error: (err: any) => {
        this.errorMessage = 'There seems to be an error, please wait while we try again '
        this.loading = false
        this.newloading = true
        this.timeout = setTimeout(() => {
          this.allActivtyLogs(this.nextCursor)
        }, 5000)
        // this.allActivtyLogs(this.nextCursor)
      },
      complete: () => {
        this.errorMessage = null
        this.loading = false
        this.newloading = false
      }
    })
  }




  ngOnDestroy() {
    clearTimeout(this.timeout)
    clearInterval(this.refresh)
    this.app.activityLogs = [];
  }

  loadMore() {


    if (this.nextCursor != null) {
      // this.loading = true
      this.newloading = true
      this.app.allActivtyLogs(this.nextCursor, this.param).subscribe({
        next: (res: any) => {
          this.nextCursor = res.data.meta.next_cursor
          // this.tokenLists = this.orderPipe.transform(this.totalTokenList, this.order)
          this.app.activityLogs.push(...res.data.data);
          this.totalLength = this.app.activityLogs.length
          this.perPage += 10

          // this.filteredTokenLists = this.orderPipe.transform(this.filteredTokenLists, this.order)

        },
        error: (err: any) => {
          this.loading = false
          this.newloading = false
          Swal.fire('Failed to load', err.error.message, 'error')
          // this.allActivtyLogs(this.nextCursor
        },
        complete: () => {
          this.errorMessage = null
          // this.loading = false
          this.newloading = false
          if (this.nextCursor === null) {
            this.hideLoad = true
          } else {
            this.hideLoad = false
          }
        }
      })
    } else {
      this.hideLoad = true
    }
    // this.allActivtyLogs(this.page + 1)
  }

  sanitizeHTML(text: string) {
    return this.app.sanitizeHTML(text)
  }
}
