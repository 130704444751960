import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppServiceService } from '../_services/app-service.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  leaderBoard: any[];
  showpage: boolean;
  loading: boolean;
  size: number;
  page: number = 1
  perpage = 10
  hideLoad: boolean;
  marqueeLeaderboard: any[];
  hideMore: boolean;
  test: string;
  badges: any;
  myForm: FormGroup
  selectedItems: any = [];
  dropdownSettings: any = {};
  ShowFilter = true;
  filterdLeaderboard = []
  testData: any;
  returnedData: any;
  showAllBadges: boolean = false;

  constructor(private app: AppServiceService, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  ngOnInit(): void {
    this.getLeaderBoard('$GARY');
    this.getBadges('$GARY');
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'badge_name',
      selectAllText: 'Select All',
      enableCheckAll: false,
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter,
      placeholder: 'Select a badge to see who has it',
      closeDropDownOnSelection: true
    };
    this.myForm = this.fb.group({
      badge: [this.selectedItems]
    });

  }
  // onItemSelect(item: any) {

  //   const array1 = this.leaderBoard
  //   const array2 = this.myForm.value.badge
  //   let array3 = []
  //   this.filterdLeaderboard = []

  //   if (array2.length > 0) {

  //     array1.forEach(obj1 => {
  //       obj1.badges.forEach(badge1 => {
  //         const foundBadge = array2.find(badge2 => badge2.id === badge1.id);
  //         if (foundBadge && !array3.includes(obj1)) {
  //           array3.push(obj1);
  //         }
  //       });
  //     });
  //   } else {
  //     array3 = array1
  //   }

  //   this.filterdLeaderboard = array3


  // }

  onItemSelect(item: any) {
    const array1 = this.leaderBoard;
    const array2 = this.myForm.value.badge;
    let array3 = [];
    this.filterdLeaderboard = [];

    if (array2.length > 0) {
      array1.forEach(obj1 => {
        let matchFound = true;
        array2.forEach(badge2 => {
          const foundBadge = obj1.badges.find(badge1 => badge1.id === badge2.id);
          if (!foundBadge) {
            matchFound = false;
          }
        });
        if (matchFound && !array3.includes(obj1)) {
          array3.push(obj1);
        }
      });
    } else {
      array3 = array1;
    }

    this.filterdLeaderboard = array3;
    console.log(this.filterdLeaderboard);
    this.cdr.detectChanges();
  }
  // onSelectAll(items: any) {
  //   // console.log('onSelectAll', items);
  //   console.log('all selected', this.myForm.value)
  // }
  // toogleShowFilter() {
  //   this.ShowFilter = !this.ShowFilter;
  //   this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  // }

  // Function to get leaderboard for a specific token
  getLeaderBoard(token: any) {
    this.app.getLeaderBoardToken(token).subscribe({
      next: (res: any) => {
        this.leaderBoard = res.data;
        this.filterdLeaderboard = this.leaderBoard;
        this.marqueeLeaderboard = this.leaderBoard.slice(0, 50)
        this.size = this.leaderBoard.length
        console.log('leader board re naw', this.leaderBoard);
        this.loading = false;
        this.showpage = false;
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.showpage = true
      }
    })
  }

  getBadges(token) {
    this.app.getBadges(token).subscribe({
      next: (res: any) => {
        this.badges = res.data.badges;
      },
      error: (err: any) => {
        console.log(err)
      },
    })
  }


  loadMore() {
    if (this.size > this.perpage) {

      this.perpage += 10
      if (this.perpage >= this.size) {
        this.hideMore = true
      }
    }
    if (this.perpage > 10) {
      this.hideLoad = false
    }
  }

  loadLess() {
    // console.log(this.perpage)
    if (this.perpage > 10) {
      // console.log(this.perpageL)
      // console.log('loading less')
      this.perpage -= 10
      if (this.perpage <= 10) {
        this.hideLoad = true
      }
      if (this.perpage < this.size) {
        this.hideMore = false
      }
    }
    else {
      this.hideLoad = true
    }

    // this.hideLoad = false

  }

  makePositive(num: number): number {
    return Math.abs(num);
  }

  // limitBadge(data) {
  //   this.testData = data
  //   this.returnedData = this.testData.slice(0, 5)
  //   return this.returnedData
  // }

  onMouseEnter() {
    console.log('onMouseEnter')
    this.returnedData = this.testData
  }

  onMouseLeave() {
    console.log('onMouseLeave')
    this.returnedData = this.testData.slice(0, 5)
  }


  showFullBadges() {
    this.showAllBadges = true;
  }

  showSlicedBadges() {
    this.showAllBadges = false;
  }

  // limitBadge(badges: any[]): any[] {
  //   if (this.showAllBadges) {
  //     return badges;
  //   } else {
  //     return badges.slice(0, 5);
  //   }
  // }

}


