import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-giraffe-tower',
  templateUrl: './giraffe-tower.component.html',
  styleUrls: ['./giraffe-tower.component.scss']
})
export class GiraffeTowerComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
    document.body.classList.add('dark-theme');
  }

  ngOnDestroy() {
    let theme = localStorage.getItem('theme');
    if (theme != 'dark-theme') {
      document.body.classList.remove('dark-theme');
      localStorage.setItem('theme', 'light');
    } else {
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    }
    // window.location.reload();
  }

}
