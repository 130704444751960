<div class="card pcard align-items-center">
  <div class=" relative" *ngIf="startloading" style="height: 390px;width: 100%;">
    <div class="overlay"></div>
  </div>
  <div class="card-body" *ngIf="!startloading">

    <div class="">

      <div class="flex flex-row ps-3">
        <div class="flex flex-col">
          <div class="graph">
            <ul class="months  w-fit">
              <li>Jan</li>
              <li>Feb</li>
              <li>Mar</li>
              <li>Apr</li>
              <li>May</li>
              <li>Jun</li>
              <li>Jul</li>
              <li>Aug</li>
              <li>Sep</li>
              <li>Oct</li>
              <li>Nov</li>
              <li>Dec</li>
            </ul>

            <ul class="squares">
              <li class=" false
                rounded-2px sm:rounded group relative flex flex-col items-center pointer"
                *ngFor="let item of updateYear" (click)="onDayClicked(item.date)" placement="top"
                ngbTooltip="{{ item.date | date  : 'MMMM dd'  }}">
                <span class="bg-list" [class]="'intensity'+item.intensity"></span>
                <!-- {{ item | date: 'dd' }} -->
              </li>
            </ul>
          </div>

        </div>

        <div class="hidden lg:block">
          <div class="flex flex-row lg-flex-col mt-9 ms-2 px-3">
            <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm active" *ngFor="let item of years"
              [ngClass]="{'active': item.year === yearToSend}" (click)="changeYear(item.year)">
              {{item.year}} </div>
            <!-- <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm">2021</div> -->
          </div>
        </div>
      </div>
      <div class=" lg:hidden">
        <div class="flex flex-row lg-flex-col mt-9 ms-2 px-3">
          <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm active" *ngFor="let item of years"
            [ngClass]="{'active': item.year === yearToSend}">
            {{item.year}} </div>

          <!-- <div class="px-3 px-md-5 px-lg-5 py-1 mb-lg-3 cursor-pointer text-sm">2021</div> -->
        </div>
      </div>
    </div>
    <div class="max-width-100 ms-auto me-auto">
      <!-- <h4 class="text-[12px] text-darkButtonBg mr-2">Low Impact</h4> -->
      <div class="flex flex-row mt-5 justify-content-center items-center mb-12 align-items-center intensitycard">
        <div class="intensity intensity0"></div>
        <div class="intensity intensity1"></div>
        <div class="intensity intensity2"></div>
        <div class="intensity intensity3"></div>
        <div class="intensity intensity4"></div>
        <div class="intensity intensity5"></div>
        <h4 class="text12px text-darkButtonBg ms-2">Intensity</h4>
      </div>
    </div>
  </div>
</div>


<div class="row mt-5 align-items-center" *ngIf="!startloading">
  <div class="col-md-1 offset-md-1 text-end">
    <button (click)="prevDay()" class="btn btn-icon btn-clear hiddenonsmall" *ngIf="total > 1">
      <i class="fa-solid fa-chevron-left text-primary"></i>
    </button>
    <i class="fa"></i>
  </div>
  <div class="col-md-8 ">
    <div class="card card-border">
      <div class="card-header py-3">
        <div class="row align-items-center justify-content-evenly ps-0 ps-md-3 ps-lg-5">
          <div class="col-sm-4 mb-3 mb-md-0 mb-lg-0 mb-xl-0 hmm">
            <h4>{{ selectedDate | date : "longDate" }}</h4>
          </div>
          <!-- <div class="col border border-start-0 border-bottom-0 border-top-0 d-block ps-3 ">
            <p>Reward Coin</p>
            <h6>{{POWStats?.reward_coin }}</h6>

          </div> -->
          <div class="col border border-start-0 border-bottom-0 border-top-0 d-block ps-3 ">
            <p>Price</p>
            <h6>$ {{dateData?.price_usd_with_fee | number : '0.0-4' || '-'}}</h6>

          </div>
          <div class="col border border-start-0 border-bottom-0 border-top-0 d-block ps-3 ">
            <p>Volume</p>
            <h6>${{dateData?.volume_stake || '-' }}</h6>

          </div>
          <div class="col ps-3 ms-auto me-auto ">
            <p>Points</p>
            <h6>{{dateData?.score || '-'}}</h6>
          </div>
        </div>
      </div>
      <div class="card-body ps-5">
        <ul *ngIf="dateData" class="summarry">

          <li *ngFor="let item of dateData?.work_sumarry; let i =  index">
            <span>
              {{ item }}
            </span>

          </li>
        </ul>

        <div *ngIf="!dateData?.work_sumarry ">
          <p>No data for today</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1">
    <button (click)="nextDay()" class="btn btn-icon btn-clear hiddenonsmall" *ngIf="total > 1">
      <i class="fa-solid fa-chevron-right text-primary"></i>
    </button>
  </div>
</div>