import { environment } from 'src/environments/environment';
import { AlertService } from '@full-fledged/alerts';
import { Meta } from '@angular/platform-browser';
import { AppServiceService } from './../../_services/app-service.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, NgZone, AfterViewChecked } from '@angular/core';
import { NgbCalendar, NgbDate, NgbModal, NgbModalConfig, NgbModalRef, NgbNavChangeEvent, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import { data } from "./series-data";
import { BusinessDay, ChartOptions, ColorType, createChart, DeepPartial, IChartApi, isBusinessDay, UTCTimestamp, PriceLineOptions, isUTCTimestamp, BarPrices } from 'lightweight-charts';
import {
  ChartComponent,
  ApexChart,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke
} from "ng-apexcharts";
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { OrderPipe } from 'ngx-order-pipe';
import { single } from './contribution_data';
import { ColorHelper, ScaleType } from '@swimlane/ngx-charts';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import Swal from 'sweetalert2';

moment().format();
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  series2: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
  labels: any,
  theme: any
};

const share = `${environment.share}`;

@Component({
  selector: 'app-token-details',
  templateUrl: './token-details.component.html',
  styleUrls: ['./token-details.component.scss']
})
export class TokenDetailsComponent implements OnInit, AfterViewChecked {
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("container") container: HTMLElement;
  public chartOptions: Partial<ChartOptions2>;
  chartOptions3: DeepPartial<ChartOptions>
  public activeOptionButton = "all";
  // public updateOptionsData = {
  //   "1D": {
  //     xaxis: {
  //       min: new Date("28 Jan 2013").getTime(),
  //       max: new Date("27 Feb 2013").getTime()
  //     }
  //   },
  //   "7D": {
  //     xaxis: {
  //       min: new Date("27 Sep 2012").getTime(),
  //       max: new Date("27 Feb 2013").getTime()
  //     }
  //   },
  //   "1M": {
  //     xaxis: {
  //       min: new Date("27 Feb 2012").getTime(),
  //       max: new Date("27 Feb 2013").getTime()
  //     }
  //   },
  //   "3M": {
  //     xaxis: {
  //       min: new Date("01 Jan 2013").getTime(),
  //       max: new Date("27 Feb 2013").getTime()
  //     }
  //   },
  //   "1Y": {
  //     xaxis: {
  //       min: new Date("01 Jan 2013").getTime(),
  //       max: new Date("27 Feb 2013").getTime()
  //     }
  //   },
  //   all: {
  //     xaxis: {
  //       min: undefined,
  //       max: undefined
  //     }
  //   }
  // };
  activeId = 3
  token: string;
  coinDetails: any;
  links: any;
  offers: any;
  analytics: any;
  loading3: boolean = true;
  dateentered: any = [];
  chartprice: any[] = [];
  day0: any;
  day1: any;
  type: string = 'hour';
  tomorrow: string;
  refresh: NodeJS.Timeout;
  gary_locked: any[] = [];
  usd_price: any[] = [];
  volume_traded: any[] = [];
  usd_price_10: any[] = [];
  price_chart: any[] = [];
  active2 = 5;
  chartOptions2: Partial<ChartOptions2>;
  page = 1;
  l = 1
  key = 'created_at';
  direction = 'desc';
  data: any;
  size: any;
  perpage: any;
  loading: boolean = true;
  allSupporters: any;
  perPage = 10
  perpageL = 10
  p = 1
  total: any
  perpage2: any;
  pamentPages: any;
  coinRewards: any;
  socials_url: any;
  creatorInfo: any;
  event: any;
  activeclass: boolean = true;
  activeclass2: boolean;
  activeclass3: boolean;
  activeclass4 = true
  activeclass5: boolean
  newArr: any[] = [];
  volumeArr: any[] = [];
  price: any;
  theme: string;
  color: string = 'white';
  textcolor: string;
  activeclass6: boolean;
  activeclass7: boolean;
  starting_price: number;
  scheduled_launch: any;
  activeclass8: boolean;
  showpage: boolean;
  social_pay_url = environment.social_pay_url;
  order3 = 'amount';
  filteredoffers: any;
  leaderBoard: any[];
  sdata: any[];
  view: [number, number] = [600, 200];
  gradient: boolean = true;
  colorScale: string[];
  colorScheme: any = {
    domain: ['#F87774', '#F9B87B', '#F9DB7B', '#B2D67D', '#8DBF6F']
  };
  totaldata: any;
  today: string;
  isTodayInArray: any;
  hideLoad: boolean = true;
  leaderboardSize: number;
  hideMore: boolean;
  POWStats: any;
  submittedDate: any[];
  dropdownSettings: any = {};
  ShowFilter = true;
  myForm: FormGroup;
  selectedItems: any = [];
  filterdLeaderboard = [];
  badges: any;
  mybadges: any;
  myEvents: any;
  perpages: any;
  sizes: any;
  joinForm: any;
  paidForm: any;
  myZone: any;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  segment_id: any;
  start_date: any;
  end_date: any;
  pages: number;
  user_id: null;
  id: any;
  content2: any;
  users: any;
  isAttendee: any;
  attendees: any;
  searchEvent: string
  timeOut: any;
  timeOutDuration = 3000;
  circulating_supply: number;


  constructor(
    private modalService: NgbModal,
    public app: AppServiceService,
    private ngZone: NgZone,
    private metaService: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private orderPipe: OrderPipe,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    config: NgbNavConfig,
    private clipboard: Clipboard,
    private elementRef: ElementRef,
    mconfig: NgbModalConfig,
    private calendar: NgbCalendar,
  ) {

    mconfig.backdrop = 'static';
    mconfig.keyboard = false;
    mconfig.centered = true;

    config.destroyOnHide = false;
    config.roles = false;
    this.activeclass4 = true
    // this.getPriceCharts()
    this.token = this.route.snapshot.paramMap.get('token')
    this.token = this.token.toUpperCase();
    this.day1 = moment().format('YYYY-MM-DD HH:mm');
    // console.log(this.day1)
    this.type = 'hour';
    this.day0 = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm');
    // this.day1 = this.day0;
    this.tomorrow = moment().add(1, 'days').format('YYYY-MM-DD HH:mm');
    this.getTokenDetails(this.token)
    this.getPriceCharts()
    this.getTheme()
    // this.initChart2()
    this.getLeaderBoard(this.token);

    const now = new Date();
    this.today = this.datePipe.transform(now, 'yyyy-MM-dd')!;
    // this.getBadges(this.token)

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'badge_name',
      selectAllText: 'Select All',
      enableCheckAll: false,
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: this.ShowFilter,
      placeholder: 'Select a badge to see who has it',
      closeDropDownOnSelection: true
    };
    this.myForm = this.fb.group({
      badge: [this.selectedItems]
    });
    // console.log(this.today);
  }


  onItemSelect(item: any) {
    const array1 = this.leaderBoard;
    const array2 = this.myForm.value.badge;
    let array3 = [];
    this.filterdLeaderboard = [];

    if (array2.length > 0) {
      array1.forEach(obj1 => {
        let matchFound = true;
        array2.forEach(badge2 => {
          const foundBadge = obj1.badges.find(badge1 => badge1.id === badge2.id);
          if (!foundBadge) {
            matchFound = false;
          }
        });
        if (matchFound && !array3.includes(obj1)) {
          array3.push(obj1);
        }
      });
    } else {
      array3 = array1;
    }

    this.filterdLeaderboard = array3;
    console.log(this.filterdLeaderboard);
    this.cdr.detectChanges();
  }



  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    margin: 16,
    autoHeight: true,

    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 2
      },
      1000: {
        items: 2
      }
    },
    nav: true
  }

  ngOnInit(): void {
    this.getTokenDetails(this.route.snapshot.paramMap.get('token'));
    this.getOffers()
    this.getAnalytics()
    this.getAllTrades()
    this.getSupporters()
    // this.getCreatorInfo()
    this.getPaymentPages()
    this.getRewards()
    this.getPOWStat()
    this.getBadges(this.token)
    this.listEvents()
    this.initForm()
    this.getZonebyId()

    // this.getData()
  }

  ngAfterViewChecked() {
    // console.log(this.badges)
    // this.getBadges(this.token)

  }

  listEvents() {
    this.app.getCreatorEvent(this.pages, this.token, this.start_date, this.end_date, this.searchEvent).subscribe({
      next: (event: any) => {
        console.log(event)
        this.myEvents = event.data.data
        const meta = event.data.meta
        this.perpages = meta.per_page
        this.sizes = meta.total
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
  search(ev: any) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      if (ev) {
        this.searchEvent = ev;

        // this.getSearchedUsers(this.search)
      } else {
        this.searchEvent = '';
      }
      console.log(this.searchEvent)
      this.pages = 1
      this.listEvents()
    }, this.timeOutDuration);
    // this.searchEvent = '';
  }

  initForm() {
    this.joinForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.paidForm = this.fb.group({
      payment_method: ['', Validators.required]
    })
  }

  getZonebyId() {
    this.app.getZoneByIp().subscribe({
      next: (res: any) => {
        this.myZone = res
        console.log('timezone re naw', res)
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('usertimezone', userTimezone)
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  isValidTimezoneFormat(timezone: string): boolean {
    // Regular expression to match the format 'Continent/City' or 'Continent/Country/City'
    const timezoneRegex = /^[A-Za-z_\-/]+(\/[A-Za-z_\-/]+)*$/;

    // console.log(timezoneRegex.test(timezone))
    // Check if the input timezone matches the regular expression
    return timezoneRegex.test(timezone);
  }

  covertTZ(fromDate: string, fromTz: string): string {
    // console.log(fromDate, fromTz, toTz)
    if (!this.isValidTimezoneFormat(fromTz)) {
      // throw new Error("Invalid timezone format. Please use the format 'Continent/City'.");
      return fromDate
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (fromTz === userTimezone) {
      return fromDate
    } else if (fromTz === null) {
      return fromDate
    }
    else {
      const fromDatetime = parseISO(fromDate);


      // Convert the datetime to UTC
      const utcDatetime = zonedTimeToUtc(fromDatetime, fromTz);

      // Convert the UTC datetime to the target timezone
      const toDatetime = format(utcDatetime, 'yyyy-MM-dd HH:mm:ssXXX', {
        timeZone: userTimezone,
      });

      return toDatetime;
    }


  }

  copyToClipboard(event) {
    const url = `${share}/${event.id}`
    // const a = window.location.href
    this.clipboard.copy(url)
    // this.alertService.success('Copied to clipboard');
    Swal.fire('Copied to clipboard', '', 'success')
  }

  View(item) {
    // console.log(item.id)
    this.router.navigateByUrl('/events/' + item.id)
  }


  getFormattedDate(date: any) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = this.datePipe.transform(date, 'yyyyMMdd\'T\'HHmmss', userTimezone);

    return formattedDate
  }

  addToCalendar(item) {
    const a = this.covertTZ(item.date, item.timezone)
    const b = this.covertTZ(item.end_date, item.timezone)
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
      name: item.name,
      startDate: this.getFormattedDate(a),
      endDate: this.getFormattedDate(b),
      ctz: userTimezone,
      details: item.description,
      location: item.location
    }

    // console.log(data)

    const params = new URLSearchParams();
    params.set('action', 'TEMPLATE');
    params.set('text', data.name);
    params.set('dates', data.startDate + '/' + data.endDate);
    params.set('ctz', data.ctz);
    params.set('details', data.details);
    params.set('location', data.location);

    const apiUrl = 'https://calendar.google.com/calendar/render';

    const link = apiUrl + '?' + params.toString();

    const anchor = this.elementRef.nativeElement.appendChild(document.createElement('a'));
    anchor.href = link;
    anchor.target = '_blank';

    anchor.click();

    // Cleanup: remove the anchor element
    anchor.remove();



  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      // this.modal.dismissAll();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    // console.log(this.fromDate, this.toDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  clear() {
    this.fromDate = null;
    this.toDate = null;
    this.modalService.dismissAll();
  }



  filterBySegment(id) {
    // console.log(id)
    if (id !== 0) {
      this.segment_id = id
      this.listEvents()
      // if (this.myEvents.length > 0) {
      //   this.filteredEvents = this.myEvents.filter(event => event.segment_ids.includes(id));
      // }
    } else if (id === 0) {
      this.segment_id = null
      this.listEvents()
      // this.filteredEvents = this.myEvents
    }
  }

  filter() {
    // console.log(this.fromDate, this.toDate)
    this.start_date = this.getDate(this.fromDate)
    this.end_date = this.getDate(this.toDate)
    console.log(this.start_date, this.end_date)
    if (this.start_date && this.end_date) {
      this.listEvents()
      this.modalService.dismissAll()

    }

  }

  getDate(date) {
    const dateObject = date;

    const year = dateObject.year;
    const month = String(dateObject.month).padStart(2, '0');
    const day = String(dateObject.day).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;

  }


  clearFilter() {
    this.start_date = null
    this.end_date = null
    // this.segment_id = null
    this.pages = 1
    this.searchEvent = null
    // this.coin_symbol = null;
    // this.user_id = null;

    this.listEvents()
  }

  rsvpEvent(content4, event, content2) {
    this.id = event.id
    this.event = event
    this.content2 = content2
    if (this.app.isLogedIn()) {
      this.attendEvent()
      // if (event.ticket === 'paid') {
      //   this.modal.open(content2, {}).result
      // }
      // else {
      //   this.attendEvent()
      // }


    } else {
      this.modalService.open(content4, {}).result
    }
  }

  attendEvent() {
    this.app.attendEvent(this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        // this.getUser()
        if (res.data.make_payment) {
          this.modalService.open(this.content2, {}).result
        }
        else {
          this.router.navigateByUrl('/events/' + this.id + '/rsvp')
        }
      },
      error: (err: any) => {
        console.log(err)
        // this.alert.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        // this.listEvents()
        // this.getEventById()
        // this.router.navigateByUrl('/events/' + this.id + '/rsvp')
      }
    })
  }

  async getUser() {
    const a = await this.app.getUser()
    this.users = a
    console.log(this.users)
    if (this.users) {
      this.isAttendee = this.isRSVPed()
      console.log('Is attendee', this.isAttendee)
    }

  }

  isRSVPed() {
    for (const item of this.attendees) {
      if (item.user_id === this.users.id.toString()) {
        return true;
        // if (item.user.email === this.users.email && item.user.name === this.users.name) {
        //   return true;
        // }
      }
    }
    return false;
  }

  cancel() {
    this.modalService.dismissAll()
    this.joinForm.reset();
  }

  submit() {
    const payload = {
      email: this.joinForm.value.email,
      password: this.joinForm.value.password
    }

    this.app.login(payload).subscribe({
      next: async (res: any) => {
        console.log(res)
        const token = res.data.token
        this.getUserByToken(token);
        const result = this.app.setAuthFromLocalStorage(res);
      },
      error: (err: any) => {
        console.log(err)
        // this.alertService.danger('error')
        // this.alert.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        this.cancel()
      }
    })

  }

  getUserByToken(token) {
    this.app.getUserByToken(token).subscribe({
      next: (res: any) => {
        // console.log('User reeeeeeee ', res)
        this.app.saveUserToStorage(res)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.getUser()
        this.attendEvent()
      }
    })
  }

  test() {
    const modalRef = this.modalService.open(LoaderComponent, { size: 'sm' });
  }


  payNow() {
    const modalRef = this.modalService.open(LoaderComponent, { size: 'sm' });
    const pay = {
      payment_method: this.paidForm.value.payment_method,
    }

    console.log(pay)

    this.app.makePayment(pay, this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.page_link) {
          modalRef.dismiss();
          window.location.href = res.page_link
        } else {
          modalRef.dismiss();
          this.router.navigateByUrl('/events/' + this.id + '/rsvp')
        }
      },
      error: (err: any) => {
        modalRef.dismiss();
        Swal.fire('Failed', err.error.message, 'error')
        // this.alert.danger(err.error.message)

      },
      complete: () => {
        modalRef.dismiss();
        this.modalService.dismissAll()

        // this.router.navigateByUrl('/events/' + this.id + '/rsvp')
      }
    })
  }



  getPages(ev) {
    this.pages = ev
    this.listEvents()
  }

  checkRSVP(data) {
    this.attendees = data.attendees
    this.getUser()
    this.isAttendee = this.isRSVPed()
  }

  getPOWStat() {
    this.app.getPOWStats(this.token).subscribe({
      next: (res: any) => {
        this.POWStats = res.data
      }
    })
  }

  getBadges(token) {
    this.app.getBadges(token).subscribe({
      next: (res: any) => {
        this.mybadges = res.data.badges;
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {

      }
    })
  }


  getLeaderBoard(token: any) {
    this.app.getLeaderBoardToken(token).subscribe({
      next: (res: any) => {
        this.leaderBoard = res.data;
        this.filterdLeaderboard = this.leaderBoard;
        this.leaderboardSize = this.leaderBoard.length
        // this.marqueeLeaderboard = this.leaderBoard.slice(0, 50)
        // this.size = this.leaderBoard.length
        console.log('leader board re naw', this.leaderBoard);
        this.loading = false;
        this.showpage = false;
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.showpage = true
      }
    })
  }

  generateColorScale(): string[] {
    const colorHelper = new ColorHelper(this.colorScheme, ScaleType.Linear, [0, 5]);
    const colorScale = [];
    for (let i = 0; i <= 5; i++) {
      const color = colorHelper.getColor(i);
      colorScale.push(color);
    }
    return colorScale;
  }

  loadMore() {
    if (this.leaderboardSize > this.perpageL) {

      this.perpageL += 10
      if (this.perpageL >= this.leaderboardSize) {
        this.hideMore = true
      }
    }
    if (this.perpageL > 10) {
      this.hideLoad = false
    }
  }

  loadLess() {
    console.log(this.perpageL)
    if (this.perpageL > 10) {
      // console.log(this.perpageL)
      // console.log('loading less')
      this.perpageL -= 10
      if (this.perpageL <= 10) {
        this.hideLoad = true
      }
      if (this.perpageL < this.leaderboardSize) {
        this.hideMore = false
      }
    }
    else {
      this.hideLoad = true
    }

    // this.hideLoad = false

  }


  onSelect(event): void {
    // console.log(event);
  }
  generateData(): any[] {
    // Your data array comes in the format of an array of objects, where each object represents a day
    // Here's an example of how you can generate some sample data:
    const data = [];
    for (let i = 0; i < 365; i++) {
      const intensity = Math.floor(Math.random() * 5) + 1;
      const date = moment().subtract(365, 'days').add(i, 'days').toDate();
      const work_summary = [`Task ${i + 1}`];
      data.push({ value: intensity, date, work_summary });
    }
    return data;
  }


  getTokenDetails(token) {
    this.app.getSingleToken(token).subscribe({
      next: (res: any) => {
        console.log('token re naw', res)
        this.coinDetails = res.data
        if (this.coinDetails.show_token === "false") {
          this.router.navigateByUrl('/swap/sc_swap/' + token)
        }
        this.links = this.coinDetails.links
        this.socials_url = this.coinDetails.socials_url
        this.starting_price = this.coinDetails?.starting_price
        this.scheduled_launch = this.coinDetails.scheduled_launch
        const shareableUrl = `https://gary.club/tokens/${this.coinDetails.coin_symbol}`;
        if (this.coinDetails.bio !== '' || this.coinDetails.bio !== null) {

          const plainTextBioDet = this.stripHtmlTags(this.coinDetails.bio);
          console.log(plainTextBioDet)
          this.metaService.updateTag({ name: 'description', content: plainTextBioDet });
          this.metaService.updateTag({ name: 'twitter:image', content: this.coinDetails.coin_img });
          this.metaService.updateTag({ property: 'og:url', content: shareableUrl });
          this.ngZone.run(() => {
            this.metaService.updateTag({ property: 'og:description', content: plainTextBioDet });
            this.metaService.updateTag({ property: 'og:url', content: shareableUrl });
            this.metaService.updateTag({ property: 'og:title', content: 'Gary Club ' + this.coinDetails.coin_name });
            this.metaService.updateTag({ property: 'og:site_name', content: 'Gary Club ' + this.coinDetails.coin_name });
            this.metaService.updateTag({ property: 'og:image', content: this.coinDetails.coin_img });
            this.metaService.updateTag({ name: 'twitter:image', content: this.coinDetails.coin_img });
          });
        } else {
          this.metaService.updateTag({ name: 'description', content: "Join Gary Club and elevate your brand in the creator economy. With our community and cutting-edge technology, you'll earn $GARY rewards and make money while achieving your goals. It's time to unleash your inner hero and become a part of something bigger than yourself. Let's stack up that $GARY and take your brand to new heights." });
          this.metaService.updateTag({ name: 'og:description', content: "Join Gary Club and elevate your brand in the creator economy. With our community and cutting-edge technology, you'll earn $GARY rewards and make money while achieving your goals. It's time to unleash your inner hero and become a part of something bigger than yourself. Let's stack up that $GARY and take your brand to new heights." });
        }
        // // console.log('links re naw', this.links)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.showpage = true
      }
    })
  }
  private stripHtmlTags(htmlString: string): string {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  }
  getOffers() {
    this.app.getCoinOffers(this.token).subscribe({
      next: (res: any) => {
        // console.log('offer re naw', res)
        this.offers = res.data
        this.filteredoffers = this.orderPipe.transform(this.offers, this.order3)
        // console.log('filteredoffers re naw', this.filteredoffers)
      }
    })
  }

  getAnalytics() {
    this.app.getCoinActivity(this.token).subscribe({
      next: (res: any) => {
        console.log('analytic re naw', res)
        this.analytics = res.data;
        const num = parseFloat(this.analytics?.circulating_supply.replace(/,/g, ""));
        // console.log(num);
        this.circulating_supply = num
        console.log('circulating', this.circulating_supply)
      }
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
  }

  getPriceCharts() {
    this.loading3 = true;
    this.dateentered.length = 0;
    this.chartprice.length = 0;
    this.usd_price.length = 0;
    this.usd_price_10.length = 0;
    this.volume_traded.length = 0;
    this.price_chart.length = 0
    this.newArr.length = 0
    this.volumeArr.length = 0
    this.app.getPriceCharts(this.token, this.day0, this.day1).subscribe({
      next: (res: any) => {
        let alldata;
        let type;
        if (Array.isArray(res.data)) {
          alldata = res.data;
          type = 'hour';
        } else {
          alldata = Object.values(res.data);
          type = 'day';

        }

        alldata.forEach((element: string | any[]) => {

          let test, f;
          if (element[0] !== undefined) {
            // console.log(element.length);
            f = element[element.length - 1];
            test = f.created_at;
          } else {
            f = element;
            test = element['date_entered'];
          }
          const test2 = test.split(' ');
          let date;
          if (this.type == 'hour') {
            date = test2[1];
          } else {
            date = test2[0];
          }

          this.dateentered?.push(test);
          const price = parseFloat(f.price_gary).toFixed(5);
          this.chartprice.push(price);

          const priceusd = (parseFloat(f.price_usd)).toFixed(5);
          const aaaaa = priceusd + ' Price ($)'
          this.usd_price.push(aaaaa);


          const priceusd_10 = (parseFloat(f.price_usd)).toFixed(5);

          this.price_chart.push(priceusd_10)
          this.price = this.price_chart[0]

          const volume = parseFloat(f.volume_traded).toFixed(5);
          this.volume_traded.push(volume);

          const glock = parseFloat(f.gary_locked).toFixed(5);
          this.gary_locked.push(glock);
        });

        this.loading3 = false;


        this.newArr = this.price_chart.map((value, index) => {
          return { time: Date.parse(this.dateentered[index]) / 1000 as UTCTimestamp, value: value };
        });


        this.volumeArr = this.volume_traded.map((value, index) => {
          return { time: Date.parse(this.dateentered[index]) / 1000 as UTCTimestamp, value: value, color: 'rgba(0, 150, 136, 0.8)' };

        });
      },
      complete: () => {
        if (this.activeclass4 == true) {
          setTimeout(() => {
            this.initChart2()
          }, 1000);
        } else {
          setTimeout(() => {
            this.initChart3()
          }, 1000);
        }


      },
      error: (err: any) => {
        // console.log(err);
        // this.alert.danger(err.error.message);
        // Swal.fire('', err.error.message, 'error')
      },
    });
  }


  getAllTrades() {
    this.loading = true;
    this.app.getTrades(this.token, this.page, this.key, this.direction).subscribe({
      next: (res: any) => {
        console.log('transaction re naw', res);
        const allData = res.data;
        const meta = res.data.meta
        this.data = allData.data;
        console.log('data', this.data)
        this.size = meta.total;
        console.log(this.size)
        this.perpage2 = meta.per_page;

        // this.allrooms = data.rooms.data;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.loading = false;
      },
    });
  }
  getSupporters() {
    this.app.getCoinSupporters(this.token).subscribe({
      next: (res: any) => {
        // console.log('supporters re naw', res)
        this.allSupporters = res.data
        this.total = this.allSupporters.length
      }
    })
  }



  getPage(page: any) {
    this.page = page;
    this.getAllTrades();
    // this.getRoomHistory();
  }

  getPaymentPages() {
    this.app.getPaymentPages(this.token).subscribe({
      next: (res: any) => {
        // console.log('pages re naw', res)
        this.pamentPages = res.data
      }
    })
  }
  getRewards() {
    this.app.getCoinRewards(this.token).subscribe({
      next: (res: any) => {
        // console.log('rewards re naw', res)
        this.coinRewards = res.data
      }
    })
  }
  initChart(): void {
    this.chartOptions = {
      series: [
        {
          name: this.token + ' Price ($)',
          data: this.usd_price,
          type: "area",
        },
        // {
        //   name: "Price ($)",
        //   data: this.usd_price_10,
        //   type: "column",
        // }
      ],
      colors: ['#f64e60', '#b3292461'],
      theme: {
        mode: 'dark',
        palette: 'palette1',
        monochrome: {
          enabled: false,
          color: '#d63384',
          shadeTo: 'dark',
          shadeIntensity: 0.65
        },
      },
      chart: {
        height: 400,
        type: "area",
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
        // sparkline: {
        //   enabled: true,
        // },
        // dropShadow: {
        //   enabled: true,
        //   enabledOnSeries: undefined,
        //   top: 5,
        //   left: 0,
        //   blur: 3,
        //   // color: lightColor,
        //   opacity: 0.5,
        // },
      },
      // title: {
      //   text: "Price ($)"
      // },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 2,
        colors: ['rgba(246, 78, 96,.9)'],
      },
      markers: {
        size: 0,
        colors: ['rgb(246, 78, 96)'],
        strokeColors: ['rgb(246, 78, 96)'],
        strokeWidth: 3,
      },
      xaxis: {
        type: 'datetime',
        categories: this.dateentered
      },
      fill: {
        colors: ['#f64e60', '#B32824'],
        type: "gradient",
        gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 70],
          // gradientToColors: ['#f64e60', '#f64e5f9a'],
        }
      },
      annotations: {
        yaxis: [
          {
            y: 1.0,
            borderColor: "#999",
            label: {
              text: "Support",
              style: {
                color: "#fff",
                background: "#00E396"
              }
            }
          }
        ],
      },
      labels: {
        style: {
          colors: 'red',
          fontSize: '12px',
        },
      },
    };



    this.chartOptions2 = {
      series: [
        {
          name: this.token + ' Volume traded  ($)',
          data: this.volume_traded,
          type: "area",
        },
      ],
      colors: ['#f64e60', '#B32824'],
      chart: {
        height: 400,
        type: "area",
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },

      },

      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 2,
        colors: ['rgba(246, 78, 96,.9)'],
      },
      markers: {
        size: 0,
        colors: ['rgb(246, 78, 96)'],
        strokeColors: ['rgb(246, 78, 96)'],
        strokeWidth: 3,
      },
      xaxis: {
        type: 'datetime',
        categories: this.dateentered
      },
      fill: {
        colors: ['#f64e60', '#B32824'],
        type: "gradient",
        gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 70, 100],
          // gradientToColors: ['#f64e60', '#f64e5f9a'],
        }
      },
      annotations: {
        // yaxis: [
        //   {
        //     y: 1.0,
        //     borderColor: "#999",
        //     label: {
        //       text: "Support",
        //       style: {
        //         color: "#fff",
        //         background: "#00E396"
        //       }
        //     }
        //   }
        // ],
      },
      labels: {
        style: {
          colors: 'red',
          fontSize: '12px',
        },
      },
    };
  }
  formatTime(time) {
    // console.log(time)
    let [hours, minutes, seconds] = time.split(':').map(x => x.padStart(2, '0'));
    return `${hours}:${minutes}:${seconds}`;
  }

  getTheme() {
    this.theme = localStorage.getItem('theme') || 'light';
  }


  formatDateReturn(date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}`;
  }

  initChart2() {
    // Get the current users primary locale
    const currentLocale = window.navigator.languages[0];
    // Create a number format using Intl.NumberFormat
    const myPriceFormatter = Intl.NumberFormat(currentLocale, {
      style: 'currency',
      currency: 'USD', // Currency for data points
    }).format;
    if (this.theme == 'light') {
      this.color = 'white'
      this.textcolor = 'black'
    } else {
      this.color = '#151520'
      this.textcolor = 'white'
    }


    // var width = '100'
    // var height = 300;
    const container = document.getElementById('container')
    this.chartOptions3 = {
      // width: width,
      height: 400,
      layout: {
        textColor: this.textcolor,
        background: { type: ColorType.Solid, color: this.color },

      },
      rightPriceScale: {

        borderVisible: true,
      },
      timeScale: {
        tickMarkFormatter: (time) => {
          const date = new Date(time * 1000);
          // console.log(date)
          if (this.type == 'hour') {
            const a = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
            return this.formatTime(a)
            // return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
          }
          else {
            const a = date
            return this.formatDateReturn(a)
          }
          // console.log(date)
          // return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
          // return `${date.toString() + "<br>" + date.toLocaleString()}`
        },
      },
      localization: {
        priceFormatter: myPriceFormatter,
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
    };

    const chart = createChart(container, this.chartOptions3);
    const baselineSeries = chart.addBaselineSeries({
      baseValue: { type: 'price', price: this.price },
      topLineColor: 'rgba( 38, 166, 154, 1)',
      topFillColor1: 'rgba( 38, 166, 154, 0.28)',
      topFillColor2: 'rgba( 38, 166, 154, 0.05)',
      bottomLineColor: 'rgba( 239, 83, 80, 1)',
      bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
      bottomFillColor2: 'rgba( 239, 83, 80, 0.28)'
    });
    const volumeSeries = chart.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.9,
        bottom: 0,
      },
    });
    // const data = [
    //   { time: '2023-01-25 01:02:24', value: 27.58405298746434 },
    //   { time: "2023-01-25 02:02:21", value: 31.74088841431117 },
    //   { time: "2023-01-25 03:05:06", value: 35.892978753808926 },
    //   { time: "2023-01-25 04:05:40", value: 39.63642029045179 },
    //   { time: "2023-01-25 05:05:07", value: 40.79167357702531 },
    //   { time: "2023-01-25 06:10:05", value: 47.691740220947764 },
    //   { time: "2023-01-25 07:00:28", value: 49.377161099825415 },
    //   { time: "2023-01-25 08:05:04", value: 52.47379203136591 },
    //   { time: "2023-01-25 09:05:04", value: 50.40209743179448 },
    //   { time: "2023-01-25 10:05:06", value: 61.47316837848548 },
    //   { time: "2023-01-25 11:05:04", value: 61.47316837848548 },
    //   // { time: '2018-12-12', value: 61.47316837848548 },
    //   // { time: '2018-12-13', value: 10.47316837848548 },
    //   // { time: '2018-12-14', value: 11.47316837848548 },
    //   // { time: '2018-12-15', value: 1.47316837848548 },

    // ];



    const data = this.newArr
    // const vdata = this.volumeArr


    baselineSeries.setData(data);
    // volumeSeries.setData(vdata)
    let minimumPrice = data[0].value;
    let maximumPrice = minimumPrice;
    for (let i = 1; i < data.length; i++) {
      const price = data[i].value;
      if (price > maximumPrice) {
        maximumPrice = price;
      }
      if (price < minimumPrice) {
        minimumPrice = price;
      }
    }
    let avgPrice = (maximumPrice + minimumPrice) / 2;
    let startPrice = this.starting_price

    const lineWidth = 2;
    const minPriceLine: PriceLineOptions = {
      price: minimumPrice,
      color: '#ef5350',
      lineWidth: lineWidth,
      lineStyle: 2,
      axisLabelVisible: true,
      title: 'min price',
      lineVisible: true
    };
    const avgPriceLine: PriceLineOptions = {
      price: avgPrice,
      color: 'purple',
      lineWidth: lineWidth,
      lineStyle: 0,
      axisLabelVisible: true,
      title: 'avg price',
      lineVisible: true
    };
    const maxPriceLine: PriceLineOptions = {
      price: maximumPrice,
      color: '#26a69a',
      lineWidth: lineWidth,
      lineStyle: 2,
      axisLabelVisible: true,
      title: 'max price',
      lineVisible: true
    };
    const startingPrice: PriceLineOptions = {
      price: startPrice,
      color: '#f87774',
      lineWidth: lineWidth,
      lineStyle: 2,
      axisLabelVisible: true,
      title: 'start price',
      lineVisible: true
    };

    baselineSeries.createPriceLine(minPriceLine);
    // baselineSeries.createPriceLine(avgPriceLine);
    baselineSeries.createPriceLine(maxPriceLine);
    baselineSeries.createPriceLine(startingPrice);

    var toolTipWidth = 100;
    var toolTipHeight = 80;
    var toolTipMargin = 15;
    const type = this.type

    var toolTip = document.createElement('div');
    toolTip.className = 'floating-tooltip-2';
    container.appendChild(toolTip);

    chart.subscribeCrosshairMove(function (param) {
      function formatTime(time) {
        let [hours, minutes, seconds] = time.split(':').map(x => x.padStart(2, '0'));
        return `${hours}:${minutes}:${seconds}`;
      }
      function formatNumber(data: any): string {
        const num = parseFloat(data);
        return num.toLocaleString();
      }
      // console.log(this.type)
      if (!param.time || param.point.x < 0 || param.point.y < 0) {
        toolTip.style.display = 'none';
        return;
      }
      // var dateStr = isUTCTimestamp(param.time)
      //   ? param.time.toLocaleString()
      //   : businessDayToString(param.time);
      // var dateStr = isBusinessDay(param.time)
      //   ? businessDayToString(param.time)
      //   : new Date(param.time * 1000).toLocaleDateString();
      // console.log(param.time)
      let dateStr;
      let businessDay: any = param.time
      const date = new Date(businessDay * 1000);
      if (type == 'hour') {
        const a = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        dateStr = formatTime(a)
        // dateStr = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      }
      else {
        // let b = `${date.toDateString()}`
        dateStr = `${date.toDateString()}`
      }

      toolTip.style.display = 'block';
      toolTip.style.textAlign = 'center';
      var price: any = param.seriesPrices.get(baselineSeries);
      price = formatNumber(price)
      //  dateStr = param.time.toLocaleString()
      // const a = businessDayToString(param.time)
      toolTip.innerHTML = '<div style="color: #f87774">Price ($)</div>' +
        '<div style="font-size: 24px; margin: 4px 0px"> $' + price + '</div>' +
        '<div>' + dateStr + '</div>';

      var y = param.point.y;

      var left = param.point.x + toolTipMargin;
      // if (left > width - toolTipWidth) {
      //   left = param.point.x - toolTipMargin - toolTipWidth;
      // }

      var top = y + toolTipMargin;
      // if (top > height - toolTipHeight) {
      //   top = y - toolTipHeight - toolTipMargin;
      // }

      toolTip.style.left = left + 'px';
      toolTip.style.top = top + 'px';
    });

    chart.timeScale().fitContent();
    // chart.timeScale().applyOptions({
    //   borderColor: '#71649C',
    //   barSpacing: 10,
    // });
  }
  initChart3() {
    // Get the current users primary locale
    const currentLocale = window.navigator.languages[0];
    // Create a number format using Intl.NumberFormat
    const myPriceFormatter = Intl.NumberFormat(currentLocale, {
      style: 'currency',
      currency: 'USD', // Currency for data points
    }).format;
    if (this.theme == 'light') {
      this.color = 'white'
      this.textcolor = 'black'
    } else {
      this.color = '#151520'
      this.textcolor = 'white'
    }


    // var width = '100'
    // var height = 300;
    const container = document.getElementById('container2')
    this.chartOptions3 = {
      // width: width,
      // height: height,
      layout: {
        textColor: this.textcolor,
        background: { type: ColorType.Solid, color: this.color },

      },
      rightPriceScale: {

        borderVisible: true,
      },
      timeScale: {
        tickMarkFormatter: (time) => {
          const date = new Date(time * 1000);
          // console.log(date)
          if (this.type == 'hour') {
            const a = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
            return this.formatTime(a)
            // return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
          }
          else {
            const a = date
            return this.formatDateReturn(a)
          }
          // console.log(date)
          // return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
          // return `${date.toString() + "<br>" + date.toLocaleString()}`
        },
      },
      localization: {
        priceFormatter: myPriceFormatter,
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
    };

    const chart = createChart(container, this.chartOptions3);
    // const baselineSeries = chart.addBaselineSeries({
    //   baseValue: { type: 'price', price: this.price },
    //   topLineColor: 'rgba( 38, 166, 154, 1)',
    //   topFillColor1: 'rgba( 38, 166, 154, 0.28)',
    //   topFillColor2: 'rgba( 38, 166, 154, 0.05)',
    //   bottomLineColor: 'rgba( 239, 83, 80, 1)',
    //   bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
    //   bottomFillColor2: 'rgba( 239, 83, 80, 0.28)'
    // });

    const areaSeries = chart.addAreaSeries(
      {
        lineColor: '#2962FF',
        topColor: '#2962FF',
        bottomColor: 'rgba(41, 98, 255, 0)'
      }
    )
    // const volumeSeries = chart.addHistogramSeries({
    //   color: '#26a69a',
    //   priceFormat: {
    //     type: 'volume',
    //   },
    //   priceScaleId: '',
    //   scaleMargins: {
    //     top: 0,
    //     bottom: 0,
    //   },
    // });
    // const data = this.newArr
    const vdata = this.volumeArr


    // baselineSeries.setData(data);
    areaSeries.setData(vdata)
    let minimumPrice = vdata[0].value;
    let maximumPrice = minimumPrice;
    for (let i = 1; i < vdata.length; i++) {
      const price = vdata[i].value;
      if (price > maximumPrice) {
        maximumPrice = price;
      }
      if (price < minimumPrice) {
        minimumPrice = price;
      }
    }
    const avgPrice = (maximumPrice + minimumPrice) / 2;
    const startPrice = this.coinDetails?.starting_price

    const lineWidth = 2;
    const minPriceLine: PriceLineOptions = {
      price: minimumPrice,
      color: '#ef5350',
      lineWidth: lineWidth,
      lineStyle: 2,
      axisLabelVisible: true,
      title: 'min price',
      lineVisible: true
    };
    const avgPriceLine: PriceLineOptions = {
      price: avgPrice,
      color: 'black',
      lineWidth: lineWidth,
      lineStyle: 0,
      axisLabelVisible: true,
      title: 'avg price',
      lineVisible: true
    };
    const maxPriceLine: PriceLineOptions = {
      price: maximumPrice,
      color: '#26a69a',
      lineWidth: lineWidth,
      lineStyle: 2,
      axisLabelVisible: true,
      title: 'max price',
      lineVisible: true
    };
    const startingPrice: PriceLineOptions = {
      price: startPrice,
      color: '#f87774',
      lineWidth: lineWidth,
      lineStyle: 2,
      axisLabelVisible: true,
      title: 'start price',
      lineVisible: true
    };

    // areaSeries.createPriceLine(minPriceLine);
    // areaSeries.createPriceLine(avgPriceLine);
    // areaSeries.createPriceLine(maxPriceLine);
    // areaSeries.createPriceLine(startingPrice);

    var toolTipWidth = 100;
    var toolTipHeight = 80;
    var toolTipMargin = 15;
    const type = this.type

    var toolTip = document.createElement('div');
    toolTip.className = 'floating-tooltip-2';
    container.appendChild(toolTip);

    chart.subscribeCrosshairMove(function (param) {
      function formatTime(time) {
        let [hours, minutes, seconds] = time.split(':').map(x => x.padStart(2, '0'));
        return `${hours}:${minutes}:${seconds}`;
      }
      function formatNumber(data: any): string {
        const num = parseFloat(data);
        return num.toLocaleString();
      }



      // console.log(this.type)
      if (!param.time || param.point.x < 0 || param.point.y < 0) {
        toolTip.style.display = 'none';
        return;
      }
      // var dateStr = isUTCTimestamp(param.time)
      //   ? param.time.toLocaleString()
      //   : businessDayToString(param.time);
      // var dateStr = isBusinessDay(param.time)
      //   ? businessDayToString(param.time)
      //   : new Date(param.time * 1000).toLocaleDateString();
      // console.log(param.time)
      let dateStr;
      let businessDay: any = param.time
      const date = new Date(businessDay * 1000);
      if (type == 'hour') {
        const a = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        dateStr = formatTime(a)
        // dateStr = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      }
      else {
        // let b = `${date.toDateString()}`
        dateStr = `${date.toDateString()}`
      }

      toolTip.style.display = 'block';
      toolTip.style.textAlign = 'center';
      var price: any = param.seriesPrices.get(areaSeries);
      price = formatNumber(price)
      //  dateStr = param.time.toLocaleString()
      // const a = businessDayToString(param.time)
      toolTip.innerHTML = '<div style="color: #f87774">Price ($)</div>' +
        '<div style="font-size: 24px; margin: 4px 0px"> $' + price + '</div>' +
        '<div>' + dateStr + '</div>';

      var y = param.point.y;

      var left = param.point.x + toolTipMargin;
      // if (left > width - toolTipWidth) {
      //   left = param.point.x - toolTipMargin - toolTipWidth;
      // }

      var top = y + toolTipMargin;
      // if (top > height - toolTipHeight) {
      //   top = y - toolTipHeight - toolTipMargin;
      // }

      toolTip.style.left = left + 'px';
      toolTip.style.top = top + 'px';
    });

    chart.timeScale().fitContent();
    // chart.timeScale().applyOptions({
    //   borderColor: '#71649C',
    //   barSpacing: 10,
    // });
  }




  formatDate(date) {
    return moment.unix(date).format();
  }
  truncateString(str) {
    if (str.length <= 10) {
      return str;
    }
    return str.slice(0, 10) + '...';
  }

  onClick(url: any) {
    // console.log(url)
    this.router.navigateByUrl("/swap/sc_swap" + url)
  }


  daysBetween(date: string) {
    const givenDate = new Date(date);
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - givenDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  // console.log(daysBetween("2022-05-03 19:00:00"));


  changeDate(ev: any) {
    let day = parseInt(ev);
    let type, activeclass;
    switch (ev) {
      case '1':
        type = 'hour';
        activeclass = 'activeclass';
        break;
      case '7':
        type = 'day';
        activeclass = 'activeclass2';
        break;
      case '30':
        type = 'day';
        activeclass = 'activeclass3';
        break;
      case '90':
        type = 'day';
        activeclass = 'activeclass6';
        break;
      case '365':
        type = 'day';
        activeclass = 'activeclass7';
        break;
      case 'all':
        day = this.daysBetween(this.scheduled_launch)
        type = 'day';
        activeclass = 'activeclass8';
        break;
      default:
        type = 'hour';
        activeclass = 'activeclass';
    }

    this.type = type;
    // console.log(day)
    this.day0 = moment().subtract(day, 'days').format('YYYY-MM-DD HH:mm');
    this.day1 = moment().format('YYYY-MM-DD HH:mm');

    // Deactivate all active classes
    this.activeclass = false;
    this.activeclass2 = false;
    this.activeclass3 = false;
    this.activeclass6 = false;
    this.activeclass7 = false;
    this.activeclass8 = false;

    // Activate the selected class
    this[activeclass] = true;

    this.getPriceCharts();
    // console.log('day0', this.day0);
    // console.log('day1', this.day1);
  }


  // ChangeChart(ev) {
  //   // console.log(ev)
  //   if (ev == 'price') {
  //     this.activeclass4 = true
  //     this.activeclass5 = false
  //     setTimeout(() => {
  //       this.initChart2()
  //     }, 0);
  //   } else {
  //     this.activeclass4 = false
  //     this.activeclass5 = true
  //     setTimeout(() => {
  //       this.initChart3()
  //     }, 0);
  //   }

  // }
  changeChart(ev) {
    if (ev === 'price') {
      this.activeclass4 = true;
      this.activeclass5 = false;
      setTimeout(() => {
        this.initChart2();
      }, 0);
    } else {
      this.activeclass4 = false;
      this.activeclass5 = true;
      setTimeout(() => {
        this.initChart3();
      }, 0);
    }
  }

  getmybadge() {
    this.badges = this.mybadges
  }


  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 1) {

      this.getPriceCharts()
    }
    else
      if (changeEvent.nextId === 3) {
        this.getmybadge()

        // console.log(this.dropdownSettings)
        // console.log(this.badges)

        // this.cdr.detectChanges()

      }
    // this.initChart2()
  }

  goto(url) {

  }

  makePositive(num: number): number {
    return Math.abs(num);
  }

  showPlaceholder(item) {
    item.user.profile_picture = this.coinDetails?.coin_img
  }




}





@Component({
  selector: 'app-loader-modal',
  template: `
  <div class="modal-body modal-center p-5 rounded-5">
    <div class="d-flex align-items-center">
  <h3>Please Wait...</h3>
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl ms-auto"></i>
    </div>

  <!-- <br> -->
  <!-- <div class="text-center mt-3">
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl"></i>
  </div> -->
</div>
  `,
  styles: [
    `
     .text.active {
   background-color: var(--bs-primary) !important;
   color: var(--bs-text-secondary) !important;

 }

 .text {
   border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
   border-radius: 50%;
 }
    `
  ]
})
export class LoaderComponent implements OnInit {
  constructor(private modalService: NgbModal, config: NgbModalConfig,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

  // openModal(): NgbModalRef {
  //   return this.modalService.open(content);
  // }

  closeModal(modalRef: NgbModalRef): void {
    modalRef.dismiss();
  }
}
