import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, NgModule, } from '@angular/core';
import fetch from "isomorphic-fetch";
import { ComputeBudgetProgram, PublicKey, Transaction, VersionedTransaction } from '@solana/web3.js';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID, u64,
} from '@solana/spl-token';
import {
  executeSwap,
  estimateSwap,
  tokenSwapProgram,
  getTokenSwapInfo,
  getMintInfo,
  getTokenAccountInfo,
  getOrCreateAssociatedAccount,
} from 'rly-js';

import { getAssociatedTokenAddress, baseToDec, decToBase } from '../../../utils';
import { getOrCreateAssociatedTokenAccount } from '../../../utils/getOrCreateAssociatedTokenAccount';
import BN from 'bn.js';
import { EXPLORER_ROOT, NETWORK } from 'src/config';
// import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from 'src/environments/environment';
import { getAccountInfo } from 'src/utils/getAccountInfo';
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Observable, take, tap } from "rxjs";
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { WalletService } from '../../_services/wallet.service';

import { WalletName } from '@solana/wallet-adapter-base';
import Swal from 'sweetalert2';
import { ToastService } from 'angular-toastify';
import { AppServiceService } from '../../_services/app-service.service';

// import { UserModels } from '../models/user.model';
// import { AuthHTTPService } from '../../auth/services/auth-http';

declare interface coinInfo {
  img: string;
  name: string;
  token_address: string;
}
type defaultSwapValues = {
  tokenSwapInfo: string;
  tokenA: string;
  tokenB: string;
  amountIn: number;
  amountOut: number;
};
type swapResponse = {
  tx: string | null;
};

const fees_account = {
  '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk':
    'H4UvLXSWaUeeDLowYxERvxjuyz7k3g46q2VE9E1QEcAw',
  'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB': 'fMQLxC1Ugpj8UauENcbrF7TusRqi3qErYTx74rZGTNr',
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v': "GV3ES46gqBXn8r316vyyUUmpdruaCfiWYkKpP4rmqJ5x",
  "sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh": "4wUH6GfvCEQ1i5DnpRnxjhzkaRxTJdwpWqqmtjgBeAiT",
  "4yoWgpCg5KciCPuA6LxDFpJHa53Jjj6XU9RLFJCgdakL": "FbQZ7aLfsgaKQM371qeD1T7TRvnta7JJHv3Sos5iCVdS",
  "So11111111111111111111111111111111111111112": "28L7quCeKmHvQcoGiZie8gGkVDP4cTzqiAvHwgEsGj5C",
  "8kJS4DrxWx8ibuBcaJY968F8wju2u9AcdiSMWhiEiwEh": "5oKe6iiaDKzDCqszRmMzMdFA1LnEY9WpCdGjKfweWyJR",
  "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263": "29zb8hoqpsxS7ySVzA6SaTnEFwV3xUoxc2eSs3EspGzC"

};

const defaultSwapResponse = {} as swapResponse;
@Component({
  selector: 'app-scswap',
  templateUrl: './scswap.component.html',
  styleUrls: ['./scswap.component.scss']
})
export class SCSwapComponent implements OnInit {
  public isMenuCollapsed = true;
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;
  garyPrice: number | undefined;
  coin_symbol: any;
  refresh: NodeJS.Timeout | undefined;
  isLoading: boolean;
  isLoading$: Observable<boolean>;
  lamports = 0;
  swapResponseValues: swapResponse;
  recipient = '';

  title = 'Select Coin';
  img = '';
  coin2 = 'Select Coin';
  img2 = '';
  public isCollapsed = true;
  public isCollapsed2 = true;
  public menuItems: any[] = [];
  public menuItems2: any[] = [];
  public estimateOut = 0;
  public tokenABalance = this.walletservice.tokenABalance;
  public tokenBBalance = this.walletservice.tokenBBalance;
  setEstimateOut = 0;
  balance = 0;

  public defaultSwapValues: defaultSwapValues = {
    tokenSwapInfo: '',
    tokenA: '',
    tokenB: '',
    amountIn: null,
    amountOut: null,
  };

  public swapDetails = {
    swapId: '',
    tokenA: '',
    tokenB: '',
  };
  public garyswapDetails = {
    swapId: '',
    tokenA: '',
    tokenB: '',
  };
  public garyswapDetailsB = {
    swapId: '',
    tokenA: '',
    tokenB: '',
  };

  connection = this.walletservice.connection;
  public wallet = this.walletservice.wallet;

  provider: any;
  // wallet: any;
  alert: string = null;
  tnxlink: string;
  // refresh: NodeJS.Timeout;
  menuItems3: any;
  spinOn = false;
  noswap = false;
  // coin_symbol: any;
  // garyPrice: any = null;
  rlyPrice: any;
  timeOut;
  timeOutDuration = 1000;
  coinprice: any = null;
  coinprice2: any = null;
  coinprice2s: number;
  coinprices: number;
  refresh2: NodeJS.Timeout;
  neededTokens: any[];
  serror: boolean = false;
  exchange_message;
  refresh3: NodeJS.Timeout;
  canSwap: boolean = true;
  canswapMessage;
  coinfromroute: string = '';
  newMenuItems: any[];
  searchUser: boolean;
  order = 'coin_name';
  reverse = false;
  name2!: string;
  name3!: string;
  newMenuItems2: any[];
  tbctokens: any;
  private authLocalStorageToken = `user`;
  private userLocalStorageToken = `userdetails`;
  supportedJupiter = ['Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB', 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', 'So11111111111111111111111111111111111111112', '8kJS4DrxWx8ibuBcaJY968F8wju2u9AcdiSMWhiEiwEh', 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263']

  private JupiterCoins: any;
  private jupGaryToken: any;
  private tbcInputToken: any;
  private signature: string;
  private allSwaps: any;
  // user$: Observable<UserType>;
  user: any;
  custodial_wallet: string;
  custodial_wallet_publickey: PublicKey;
  signed: any;
  isAuthenticated: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    public walletservice: WalletService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private settings: AppServiceService,
    private _toastService: ToastService,
    // private auth: AuthService,
  ) {
    this.getAuth()
    this.isLoading$ = this.settings.isLoading$;
    this.coinfromroute = this.route.snapshot.paramMap.get('ref');
    if (this.coinfromroute === null) {
      this.coinfromroute = '';
    }
    config.backdrop = 'static';
    config.keyboard = false;
    if (
      this.defaultSwapValues.amountIn == null ||
      this.defaultSwapValues.amountIn == 0
    ) {
      this.defaultSwapValues.amountOut == null;
    }

    this.settings.getAllCoins().subscribe({
      next: (res: any) => {
        this.menuItems2 = [];
        this.menuItems = [];
        console.log(res.data);
        this.menuItems3 = res.data;
        for (let i = 0; i < this.menuItems3.length; i++) {
          const element = this.menuItems3[i];
          if (element.coin_symbol !== 'sRLY') {
            if (element.coin_symbol == '$GARY') {
              this.menuItems.push(element);
            } else if (this.coinfromroute.length < 1) {
              this.menuItems.push(element);
            }
            if (
              this.coinfromroute.length > 0 &&
              element.coin_symbol === this.coinfromroute
            ) {
              this.menuItems.push(element);
            }
          }

        }
        this.newMenuItems = this.menuItems;
        this.menuItems3 = this.menuItems;
        this.menuItems2 = this.menuItems;
        this.newMenuItems2 = this.menuItems2;
        console.log('menu re naw', this.menuItems);

        this.settings.getJupiterCoins().subscribe({
          next: (res: any) => {
            this.JupiterCoins = res;
            console.log(res)
            for (let i = 0; i < this.JupiterCoins.length; i++) {
              const element = {
                'coin_symbol': this.JupiterCoins[i].symbol,
                'coin_name': this.JupiterCoins[i].symbol,
                'token_address': this.JupiterCoins[i].address,
                'coin_img': this.JupiterCoins[i].logoURI,
                'decimals': this.JupiterCoins[i].decimals,
              }
              let check = this.menuItems.find(
                (t) => t.token_address == this.JupiterCoins[i].address
              );
              let check2 = this.supportedJupiter.find(
                (t) => t == this.JupiterCoins[i].address
              );
              if (check == undefined && check2 != undefined) {
                this.menuItems.push(element);
              }
            }
            // Gary is a supported jupiter token, get that.
            this.jupGaryToken = this.JupiterCoins.find(
              (t) => t.address == new PublicKey("8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk").toBase58()
            );
          }
        })

        this.settings.getTBCTokens().subscribe({
          next: (res: any) => {
            this.tbctokens = res.data;
            console.log(res.data);
            // Gary is a supported jupiter token, get that.

          }
        })

        this.settings.getAllSwaps().subscribe({
          next: (res: any) => {
            this.allSwaps = res.data;
            console.log(res.data);
            // Gary is a supported jupiter token, get that.

          }
        })



      },
    });
  }

  ngOnInit(): void {
    this.getpricewid();
    const a = JSON.parse(localStorage.getItem('userdetails'))
    // console.log('storage re naw', a)
    this.custodial_wallet = a.custodial_wallet
    // this.custodial_wallet = 'AfGSnn8cPTH3rTSDxCGopjzQ7gPM3oLoPr4XQQaLQmDy';
    this.custodial_wallet_publickey = new PublicKey(this.custodial_wallet)
    // console.log(this.user);
    this.walletservice.resetBalance();
  }

  ngOnDestroy() {
    if (this.refresh3) {
      clearInterval(this.refresh3);
    }
  }
  onDisconnect() {
    this.walletservice.onDisconnect();
  }

  onSelectWallet(walletName: WalletName<string>) {
    this.walletservice.onSelectWallet(walletName);
  }

  getpricewid() {
    this.settings.getGaryPrice().subscribe({
      next: (res: any) => {
        console.log(res);
        this.coin_symbol = res.data.coin.coin_symbol;
        this.garyPrice = res.data.price_usd.toFixed(4);
        this.rlyPrice = res.data.price.toFixed(4);
      },
    });
  }

  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  fixUnsafe(url: string) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  estimate1(event) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.defaultSwapValues.amountIn = event;
      if (
        this.custodial_wallet_publickey &&
        this.defaultSwapValues.amountIn > 0
      ) {
        this.estimate();
      }
    }, this.timeOutDuration);
  }
  estimate2(event) {
    this.defaultSwapValues.amountOut = event;
    if (
      this.custodial_wallet_publickey &&
      this.defaultSwapValues.amountOut > 0
    ) {
      this.estimate();
    }
    // console.log(event);
  }

  generateSwapValues = async () => {
    // console.log();
    if (
      this.walletservice.exchange_status == 'offline' ||
      this.canSwap == false || this.jupGaryToken == undefined
    ) {
      if (this.walletservice.exchange_status == 'offline') {
        this._toastService.warn(this.walletservice.exchange_offline_message);
      } else if (this.canSwap == false) {
        this._toastService.warn(this.canswapMessage);
      } else if (this.jupGaryToken == undefined) {
        this._toastService.warn("Could not route trade");
      }

      return {
        tokenSwap: null,
        tokenSwapInfoPubKey: null,
        amountInBN: null,
        amountOutBN: null,
        callerTokenAAccount: null,
        callerTokenBAccount: null,
        poolToken: null,
        feeAccount: null,
        tokenAccountA: null,
        tokenAccountB: null,
        tokenADecimals: null,
        tokenBDecimals: null,
        status: 'err',
      };
    }
    const { tokenA, tokenB, amountIn } = this.defaultSwapValues;
    const jupInputToken = this.JupiterCoins.find(
      (t) => t.address == tokenA
    );
    const jupOutputToken = this.JupiterCoins.find(
      (t) => t.address == tokenB
    );
    let tbcInputToken, tbcOutputToken;
    // if((tokenA == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh' && tokenB != '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk') || (tokenA != '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' && tokenB == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh')){
    tbcInputToken = this.tbctokens.find(
      (t) => t.token_address == tokenA
    );
    console.log(this.tbctokens);
    tbcOutputToken = this.tbctokens.find(
      (t) => t.token_address == tokenB
    );
    if (tokenA == '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' && tokenB == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh') {
      tbcOutputToken = {
        coin_symbol: "sRLY",
        token_address: "sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh",
      }
    } else if (tokenA == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh' && tokenB == '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk') {
      tbcInputToken = {
        coin_symbol: "sRLY",
        token_address: "sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh",
      }
    }
    // }else {
    //
    // }
    console.log(this.swapDetails.swapId.length);
    console.log(tbcOutputToken);
    console.log(tbcInputToken);
    console.log(tbcInputToken);
    console.log(jupOutputToken);
    let route = 0;
    if (tbcInputToken !== undefined && tbcOutputToken !== undefined && this.swapDetails.swapId.length > 0) {
      route = 0;
      const tokenSwapInfo = this.swapDetails.swapId;
      let tokenSwapInfoPubKey;
      let tokenAPubKey;
      let tokenBPubKey;
      const amountOut = 0;

      try {
        tokenSwapInfoPubKey = new PublicKey(tokenSwapInfo);
        tokenAPubKey = new PublicKey(tokenA);
        tokenBPubKey = new PublicKey(tokenB);
      } catch (error) {
        this._toastService.warn(error);
        this.spinOn = false;
      }
      //convert amount to proper units
      const connection = this.walletservice.connection;
      const { decimals: tokenADecimals } = await getMintInfo({
        tokenMint: tokenAPubKey,
        connection,
      });
      const { decimals: tokenBDecimals } = await getMintInfo({
        tokenMint: tokenBPubKey,
        connection,
      });

      let amountInBN;
      let amountOutBN;

      try {
        // amountInBN = baseToDec(new BN(amountIn), new BN(tokenADecimals));
        // //alert(amountIn * Math.pow(10, tokenADecimals))
        amountInBN = new BN(amountIn * Math.pow(10, tokenADecimals));

        // //alert(amountInBN);
        amountOutBN = baseToDec(new BN(amountOut), new BN(tokenBDecimals));
      } catch (error) {
        // //alert(amountIn);
        this._toastService.error(error);
        this.spinOn = false;
      }

      const provider = this.walletservice.provider;
      const tokenSwap = await tokenSwapProgram(provider);
      // alert (1);
      var { feeAccount, tokenAccountA, tokenAccountB, poolToken } =
        await getTokenSwapInfo(
          provider.connection,
          tokenSwapInfoPubKey,
          tokenSwap.programId
        );

      if (tokenA == this.swapDetails.tokenA) {
      } else {
        const ta = tokenAccountA;
        const tb = tokenAccountB;
        tokenAccountA = tb;
        tokenAccountB = ta;
      }
      let callerTokenAAccount, callerTokenBAccount;
      let status;
      this.neededTokens = [];
      this.serror = false;

      try {
        const associatedTokenA = await getAssociatedTokenAddress(
          tokenAPubKey,
          this.custodial_wallet_publickey
        );

        const associatedTokenB = await getAssociatedTokenAddress(
          tokenBPubKey,
          this.custodial_wallet_publickey
        );

        //  this. serror = false;
        //  error = false
        try {
          callerTokenAAccount = await getAccountInfo(
            this.walletservice.connection,
            associatedTokenA,
            'confirmed',
            TOKEN_PROGRAM_ID
          );
        } catch (error) {
          if (
            error.message === 'TokenAccountNotFoundError' ||
            error.message === 'TokenInvalidAccountOwnerError'
          ) {
            this.neededTokens.push({
              title: this.title,
              token_address: this.defaultSwapValues.tokenA,
            });
            this.serror = true;
          }
        }
        try {
          callerTokenBAccount = await getAccountInfo(
            this.walletservice.connection,
            associatedTokenB,
            'confirmed',
            TOKEN_PROGRAM_ID
          );
        } catch (error) {
          if (
            error.message === 'TokenAccountNotFoundError' ||
            error.message === 'TokenInvalidAccountOwnerError'
          ) {
            this.neededTokens.push({
              title: this.coin2,
              token_address: this.defaultSwapValues.tokenB,
            });
            this.serror = true;
          }
        }

        if (this.serror == true) {
          throw 'error';
        }

        callerTokenAAccount = callerTokenAAccount.address;
        callerTokenBAccount = callerTokenBAccount.address;
        console.log(callerTokenAAccount);
        console.log(callerTokenBAccount);
        console.log(tokenAccountA.toBase58());
        console.log(tokenAccountB.toBase58());
        console.log(poolToken.toBase58());
        console.log(feeAccount.toBase58());
        return {
          tokenSwap,
          tokenSwapInfoPubKey,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken,
          feeAccount,
          tokenAccountA,
          tokenAccountB,
          tokenADecimals,
          tokenBDecimals,
          status,
          route,
          jupInputToken,
          jupOutputToken,
          tbcInputToken,
          tbcOutputToken,
        };
      } catch (error) {
        // //alert(error);
        // console.log(this.neededTokens);
        const modalRef = this.modalService.open(NgbdModalContentJupiter);
        // this.modalService.open(NgbdModalContent).result;
        modalRef.componentInstance.neededTokens = this.neededTokens;
        modalRef.componentInstance.walletService = this.walletservice;
        modalRef.componentInstance.lo = this.loadingBar;
        status = 'err';
        return {
          tokenSwap,
          tokenSwapInfoPubKey,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken,
          feeAccount,
          tokenAccountA,
          tokenAccountB,
          tokenADecimals,
          tokenBDecimals,
          status,
        };
      }
      // tbc -> rly -> tbc
      // //alert("Tbc to Tbc Route")
    }

    else if (tbcInputToken !== undefined && jupOutputToken !== undefined) {
      route = 1;
      // tbc -> rly -> jupiter output
      // //alert("Heeere");
      if (tbcInputToken.token_address == this.jupGaryToken.address) {
        const {
          tokenSwap,
          tokenSwapInfoPubKey,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken,
          feeAccount,
          tokenAccountA,
          tokenAccountB,
          tokenADecimals,
          tokenBDecimals,
          status
        } = await this.simulateGaryTbcInput(tbcInputToken, this.jupGaryToken, jupOutputToken, amountIn, 1);
        return {
          tokenSwap,
          tokenSwapInfoPubKey,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken,
          feeAccount,
          tokenAccountA,
          tokenAccountB,
          tokenADecimals,
          tokenBDecimals,
          status,
          route,
          jupInputToken,
          jupOutputToken,
          tbcInputToken,
          tbcOutputToken,
        };
      } else {
        const {
          tokenSwap,
          tokenSwapInfoPubKey,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken,
          feeAccount,
          tokenAccountA,
          tokenAccountB,
          tokenADecimals,
          tokenBDecimals,
          status
        } = await this.simulateTbcInput(tbcInputToken, this.jupGaryToken, jupOutputToken, amountIn, 1);
        return {
          tokenSwap,
          tokenSwapInfoPubKey,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken,
          feeAccount,
          tokenAccountA,
          tokenAccountB,
          tokenADecimals,
          tokenBDecimals,
          status,
          route,
          jupInputToken,
          jupOutputToken,
          tbcInputToken,
          tbcOutputToken,
        };
      }

      // //alert(amountInBN);
      // console.log(callerTokenAAccount);
      // console.log(callerTokenBAccount);
      // console.log(tokenAccountA.toBase58());
      // console.log(tokenAccountB.toBase58());
      // console.log(poolToken.toBase58());
      // console.log(feeAccount.toBase58());


    }
    // we have some token and we want some TBC token in the end
    else if (jupInputToken !== undefined && tbcOutputToken !== undefined) {
      route = 2;
      const {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status
      } = await this.simulateTbcOutput(tbcOutputToken, jupInputToken, jupOutputToken, amountIn, 1);
      // //alert(amountInBN);
      // console.log(callerTokenAAccount);
      // console.log(callerTokenBAccount);
      // console.log(tokenAccountA.toBase58());
      // console.log(tokenAccountB.toBase58());
      // console.log(poolToken.toBase58());
      // console.log(feeAccount.toBase58());

      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
        route,
        jupInputToken,
        jupOutputToken,
        tbcInputToken,
        tbcOutputToken,
      };
      // jupiter input -> rly -> tbc
      // const inputAmountInSmallestUnits = Math.round(
      //   amountIn * 10 ** jupInputToken.decimals
      // );

      // const outputAmount = await simulateTbcOutput(
      //   jupInputToken,
      //   jupRlyToken,
      //   tbcOutputToken,
      //   inputAmountInSmallestUnits,
      //   SWAP_SLIPPAGE,
      //   jupiter,
      //   connection
      // );
      // return {
      //   tokenSwap: null,
      //   tokenSwapInfoPubKey: null,
      //   amountInBN: null,
      //   amountOutBN: null,
      //   callerTokenAAccount: null,
      //   callerTokenBAccount: null,
      //   poolToken: null,
      //   feeAccount: null,
      //   tokenAccountA: null,
      //   tokenAccountB: null,
      //   tokenADecimals: null,
      //   tokenBDecimals: null,
      //   status: 'err',
      //   jupInputToken,
      //   jupOutputToken,
      //   tbcInputToken,
      //   tbcOutputToken,
      // };
    }

    else if (tbcInputToken !== undefined && tbcOutputToken !== undefined && this.swapDetails.swapId.length <= 0) {
      route = 3;
      const {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status
      } = await this.simulateCrossSwap(tbcInputToken, this.jupGaryToken, tbcOutputToken, amountIn, 1);
      // //alert(amountInBN);
      console.log(callerTokenAAccount);
      console.log(callerTokenBAccount);
      console.log(tokenAccountA.toBase58());
      console.log(tokenAccountB.toBase58());
      console.log(poolToken.toBase58());
      console.log(feeAccount.toBase58());

      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
        route,
        jupInputToken: this.jupGaryToken,
        jupOutputToken,
        tbcInputToken,
        tbcOutputToken,
      };
    }
    else {
      return {
        tokenSwap: null,
        tokenSwapInfoPubKey: null,
        amountInBN: null,
        amountOutBN: null,
        callerTokenAAccount: null,
        callerTokenBAccount: null,
        poolToken: null,
        feeAccount: null,
        tokenAccountA: null,
        tokenAccountB: null,
        tokenADecimals: null,
        tokenBDecimals: null,
        status: 'err',
        route
      };

    }








  };

  async simulateTbcInput(tbcInputToken, jupInputToken, JupOutputToken, inputAmount, slippage) {
    // await

    const tokenSwapInfo = this.garyswapDetails.swapId;
    // console.log(tokenSwapInfo);
    let tokenSwapInfoPubKey;
    let tokenAPubKey;
    let tokenBPubKey;
    const amountOut = 0;
    // console.log(this.garyswapDetails);
    try {
      tokenSwapInfoPubKey = new PublicKey(tokenSwapInfo);
      tokenAPubKey = new PublicKey(tbcInputToken.token_address);
      tokenBPubKey = new PublicKey(jupInputToken.address);
    } catch (error) {
      this._toastService.warn(error);
      this.spinOn = false;
    }
    //convert amount to proper units
    const connection = this.walletservice.connection;
    const { decimals: tokenADecimals } = await getMintInfo({
      tokenMint: tokenAPubKey,
      connection,
    });
    const { decimals: tokenBDecimals } = await getMintInfo({
      tokenMint: tokenBPubKey,
      connection,
    });
    // //alert("TokenADecimals: "+tokenADecimals);
    // //alert("TokenBDecimals: "+tokenBDecimals);

    let amountInBN;
    let amountOutBN;

    try {
      amountInBN = new BN(inputAmount * Math.pow(10, tokenADecimals));

      // //alert(amountInBN);
      amountOutBN = baseToDec(new BN(amountOut), new BN(tokenBDecimals));
    } catch (error) {
      // //alert(amountIn);
      this._toastService.error(error);
      this.spinOn = false;
    }

    const provider = this.walletservice.provider;
    const tokenSwap = await tokenSwapProgram(provider);
    // alert (1);
    var { feeAccount, tokenAccountA, tokenAccountB, poolToken } =
      await getTokenSwapInfo(
        provider.connection,
        tokenSwapInfoPubKey,
        tokenSwap.programId
      );

    if (tbcInputToken.token_address == this.garyswapDetails.tokenA) {
    } else {
      const ta = tokenAccountA;
      const tb = tokenAccountB;
      tokenAccountA = tb;
      tokenAccountB = ta;
    }
    // //alert()
    let callerTokenAAccount, callerTokenBAccount;
    let status;
    this.neededTokens = [];
    this.serror = false;
    try {
      const associatedTokenA = await getAssociatedTokenAddress(
        tokenAPubKey,
        this.custodial_wallet_publickey
      );

      const associatedTokenB = await getAssociatedTokenAddress(
        tokenBPubKey,
        this.custodial_wallet_publickey
      );

      //  this. serror = false;
      //  error = false

      // //alert('pass1');
      try {
        callerTokenAAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenA,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: this.title,
            token_address: this.defaultSwapValues.tokenA,
          });
          this.serror = true;
        }
      }
      try {
        callerTokenBAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenB,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: jupInputToken.symbol,
            token_address: jupInputToken.address,
          });
          this.serror = true;
        }
      }

      if (this.serror == true) {
        throw 'error';
      }

      callerTokenAAccount = callerTokenAAccount.address;
      callerTokenBAccount = callerTokenBAccount.address;
      // //alert(callerTokenAAccount);
      // //alert(callerTokenBAccount);
      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    } catch (error) {
      // //alert(error);
      // console.log(this.neededTokens);
      const modalRef = this.modalService.open(NgbdModalContentJupiter);
      // this.modalService.open(NgbdModalContent).result;
      modalRef.componentInstance.neededTokens = this.neededTokens;
      modalRef.componentInstance.walletService = this.walletservice;
      modalRef.componentInstance.lo = this.loadingBar;
      status = 'err';
      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    }
  }
  async simulateGaryTbcInput(tbcInputToken, jupInputToken, JupOutputToken, inputAmount, slippage) {
    // await

    const tokenSwapInfo = this.garyswapDetails.swapId;
    // console.log(tokenSwapInfo);
    let tokenSwapInfoPubKey;
    let tokenAPubKey;
    let tokenBPubKey;
    const amountOut = 0;
    // console.log(this.garyswapDetails);
    try {
      // tokenSwapInfoPubKey = new PublicKey(tokenSwapInfo);
      tokenAPubKey = new PublicKey(tbcInputToken.token_address);
      tokenBPubKey = new PublicKey(jupInputToken.address);
    } catch (error) {
      this._toastService.warn(error);
      this.spinOn = false;
    }
    //convert amount to proper units
    const connection = this.walletservice.connection;
    const { decimals: tokenADecimals } = await getMintInfo({
      tokenMint: tokenAPubKey,
      connection,
    });
    const { decimals: tokenBDecimals } = await getMintInfo({
      tokenMint: tokenBPubKey,
      connection,
    });
    // //alert("TokenADecimals: "+tokenADecimals);
    // //alert("TokenBDecimals: "+tokenBDecimals);

    let amountInBN;
    let amountOutBN;

    try {
      amountInBN = new BN(inputAmount * Math.pow(10, tokenADecimals));

      // //alert(amountInBN);
      amountOutBN = baseToDec(new BN(amountOut), new BN(tokenBDecimals));
    } catch (error) {
      // //alert(amountIn);
      this._toastService.error(error);
      this.spinOn = false;
    }

    // //alert()
    let callerTokenAAccount, callerTokenBAccount;
    let status;
    this.neededTokens = [];
    this.serror = false;
    try {
      const associatedTokenA = await getAssociatedTokenAddress(
        tokenAPubKey,
        this.custodial_wallet_publickey
      );

      const associatedTokenB = await getAssociatedTokenAddress(
        tokenBPubKey,
        this.custodial_wallet_publickey
      );

      //  this. serror = false;
      //  error = false

      // //alert('pass1');
      try {
        callerTokenAAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenA,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: this.title,
            token_address: this.defaultSwapValues.tokenA,
          });
          this.serror = true;
        }
      }
      try {
        callerTokenBAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenB,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: jupInputToken.symbol,
            token_address: jupInputToken.address,
          });
          this.serror = true;
        }
      }

      if (this.serror == true) {
        throw 'error';
      }

      callerTokenAAccount = callerTokenAAccount.address;
      callerTokenBAccount = callerTokenBAccount.address;
      // //alert(callerTokenAAccount);
      // //alert(callerTokenBAccount);
      return {
        tokenSwap: null,
        tokenSwapInfoPubKey: null,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken: null,
        feeAccount: null,
        tokenAccountA: null,
        tokenAccountB: null,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    } catch (error) {
      // //alert(error);
      // console.log(this.neededTokens);
      const modalRef = this.modalService.open(NgbdModalContentJupiter);
      // this.modalService.open(NgbdModalContent).result;
      modalRef.componentInstance.neededTokens = this.neededTokens;
      modalRef.componentInstance.walletService = this.walletservice;
      modalRef.componentInstance.lo = this.loadingBar;
      status = 'err';
      return {
        tokenSwap: null,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken: null,
        feeAccount: null,
        tokenAccountA: null,
        tokenAccountB: null,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    }
  }
  async simulateTbcOutput(tbcOutputToken, jupInputToken, JupOutputToken, inputAmount, slippage) {
    // await
    let amountInBN;
    let amountOutBN;
    console.log(jupInputToken);
    amountInBN = new BN(inputAmount * Math.pow(10, jupInputToken.decimals));
    let res = await this.fetchRoutes(amountInBN, this.defaultSwapValues.tokenA, this.jupGaryToken.address, 500);
    if (res?.routePlan?.length < 1) {
      this._toastService.error('No route found for this swap');
      return {
        tokenSwap: null,
        tokenSwapInfoPubKey: null,
        amountInBN: null,
        amountOutBN: null,
        callerTokenAAccount: null,
        callerTokenBAccount: null,
        poolToken: null,
        feeAccount: null,
        tokenAccountA: null,
        tokenAccountB: null,
        tokenADecimals: null,
        tokenBDecimals: null,
        status: 'err',
        route: null,
        jupInputToken: null,
        jupOutputToken: null,
        tbcInputToken: null,
        tbcOutputToken: null,
      };
      // return false;
    } else {
      let jup_amount = res['outAmount'] / 10 ** this.jupGaryToken.decimals;
      const tokenSwapInfo = this.garyswapDetailsB.swapId;
      let tokenSwapInfoPubKey;
      let tokenAPubKey;
      let tokenBPubKey;
      const amountOut = 0;
      try {
        if (tokenSwapInfo.length > 0) {
          tokenSwapInfoPubKey = new PublicKey(tokenSwapInfo);
        }
        tokenAPubKey = new PublicKey(this.jupGaryToken.address);
        tokenBPubKey = new PublicKey(tbcOutputToken.token_address);
      } catch (error) {
        this._toastService.warn(error);
        this.spinOn = false;
      }
      //convert amount to proper units
      const connection = this.walletservice.connection;
      const { decimals: tokenADecimals } = await getMintInfo({
        tokenMint: tokenAPubKey,
        connection,
      });
      const { decimals: tokenBDecimals } = await getMintInfo({
        tokenMint: tokenBPubKey,
        connection,
      });

      try {
        console.log(jup_amount);
        amountInBN = new BN(jup_amount * Math.pow(10, tokenADecimals));

        // //alert(amountInBN);
        amountOutBN = baseToDec(new BN(amountOut), new BN(tokenBDecimals));
      } catch (error) {
        // //alert(amountIn);
        this._toastService.error(error);
        this.spinOn = false;
      }
      const provider = this.walletservice.provider;
      const tokenSwap = await tokenSwapProgram(provider);
      let callerTokenAAccount, callerTokenBAccount;
      let status;
      this.neededTokens = [];
      this.serror = false;
      try {
        const associatedTokenA = await getAssociatedTokenAddress(
          tokenAPubKey,
          this.custodial_wallet_publickey
        );

        const associatedTokenB = await getAssociatedTokenAddress(
          tokenBPubKey,
          this.custodial_wallet_publickey
        );

        //  this. serror = false;
        //  error = false

        // //alert('pass1');
        try {
          callerTokenAAccount = await getAccountInfo(
            this.walletservice.connection,
            associatedTokenA,
            'confirmed',
            TOKEN_PROGRAM_ID
          );
        } catch (error) {
          if (
            error.message === 'TokenAccountNotFoundError' ||
            error.message === 'TokenInvalidAccountOwnerError'
          ) {
            this.neededTokens.push({
              title: this.jupGaryToken.symbol,
              token_address: this.jupGaryToken.address,
            });
            this.serror = true;
          }
        }
        try {
          callerTokenBAccount = await getAccountInfo(
            this.walletservice.connection,
            associatedTokenB,
            'confirmed',
            TOKEN_PROGRAM_ID
          );
        } catch (error) {
          if (
            error.message === 'TokenAccountNotFoundError' ||
            error.message === 'TokenInvalidAccountOwnerError'
          ) {
            this.neededTokens.push({
              title: tbcOutputToken.coin_symbol,
              token_address: tbcOutputToken.token_address,
            });
            this.serror = true;
          }
        }

        if (this.serror == true) {
          throw 'error';
        }

        callerTokenAAccount = callerTokenAAccount.address;
        callerTokenBAccount = callerTokenBAccount.address;
        // //alert(callerTokenAAccount);
        // //alert(callerTokenBAccount);

      } catch (error) {
        // //alert(error);
        // console.log(this.neededTokens);
        const modalRef = this.modalService.open(NgbdModalContentJupiter);
        // this.modalService.open(NgbdModalContent).result;
        modalRef.componentInstance.neededTokens = this.neededTokens;
        modalRef.componentInstance.walletService = this.walletservice;
        modalRef.componentInstance.lo = this.loadingBar;
        status = 'err';
        return {
          tokenSwap,
          tokenSwapInfoPubKey,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken: null,
          feeAccount: null,
          tokenAccountA: null,
          tokenAccountB: null,
          tokenADecimals: null,
          tokenBDecimals: null,
          status,
        };
      }
      if (this.serror == false) {
        if (this.defaultSwapValues.tokenB != this.jupGaryToken.address) {
          var { feeAccount, tokenAccountA, tokenAccountB, poolToken } =
            await getTokenSwapInfo(
              provider.connection,
              tokenSwapInfoPubKey,
              tokenSwap.programId
            );
          if (this.jupGaryToken.address == this.garyswapDetailsB.tokenA) {
          } else {
            const ta = tokenAccountA;
            const tb = tokenAccountB;
            tokenAccountA = tb;
            tokenAccountB = ta;
          }
          return {
            tokenSwap,
            tokenSwapInfoPubKey,
            amountInBN,
            amountOutBN,
            callerTokenAAccount,
            callerTokenBAccount,
            poolToken,
            feeAccount,
            tokenAccountA,
            tokenAccountB,
            tokenADecimals,
            tokenBDecimals,
            status,
          };
        } else {
          return {
            tokenSwap,
            tokenSwapInfoPubKey: null,
            amountInBN,
            amountOutBN,
            callerTokenAAccount,
            callerTokenBAccount,
            poolToken: null,
            feeAccount: null,
            tokenAccountA: null,
            tokenAccountB: null,
            tokenADecimals,
            tokenBDecimals,
            status,
          };

        }

      } else {
        return {
          tokenSwap,
          tokenSwapInfoPubKey: null,
          amountInBN,
          amountOutBN,
          callerTokenAAccount,
          callerTokenBAccount,
          poolToken: null,
          feeAccount: null,
          tokenAccountA: null,
          tokenAccountB: null,
          tokenADecimals,
          tokenBDecimals,
          status,
        };
      }












    }



    // //alert("TokenADecimals: "+tokenADecimals);
    // //alert("TokenBDecimals: "+tokenBDecimals);






    // alert (1);



    // //alert()


  }

  async simulateCrossSwap(tbcInputToken, jupInputToken, tbcOutputToken, inputAmount, slippage) {
    // await

    const tokenSwapInfo = this.garyswapDetails.swapId;
    // console.log(tokenSwapInfo);
    let tokenSwapInfoPubKey;
    let tokenAPubKey;
    let tokenBPubKey;
    const amountOut = 0;
    // console.log(this.garyswapDetails);
    try {
      tokenSwapInfoPubKey = new PublicKey(tokenSwapInfo);
      tokenAPubKey = new PublicKey(tbcInputToken.token_address);
      tokenBPubKey = new PublicKey(jupInputToken.address);
    } catch (error) {
      this._toastService.warn(error);
      this.spinOn = false;
    }
    //convert amount to proper units
    const connection = this.walletservice.connection;
    const { decimals: tokenADecimals } = await getMintInfo({
      tokenMint: tokenAPubKey,
      connection,
    });
    const { decimals: tokenBDecimals } = await getMintInfo({
      tokenMint: tokenBPubKey,
      connection,
    });
    // //alert("TokenADecimals: "+tokenADecimals);
    // //alert("TokenBDecimals: "+tokenBDecimals);

    let amountInBN;
    let amountOutBN;

    try {
      amountInBN = new BN(inputAmount * Math.pow(10, tokenADecimals));

      // //alert(amountInBN);
      amountOutBN = baseToDec(new BN(amountOut), new BN(tokenBDecimals));
    } catch (error) {
      // //alert(amountIn);
      this._toastService.error(error);
      this.spinOn = false;
    }

    const provider = this.walletservice.provider;
    const tokenSwap = await tokenSwapProgram(provider);
    // alert (1);
    var { feeAccount, tokenAccountA, tokenAccountB, poolToken } =
      await getTokenSwapInfo(
        provider.connection,
        tokenSwapInfoPubKey,
        tokenSwap.programId
      );

    if (tbcInputToken.token_address == this.garyswapDetails.tokenA) {
    } else {
      const ta = tokenAccountA;
      const tb = tokenAccountB;
      tokenAccountA = tb;
      tokenAccountB = ta;
    }
    // //alert()
    let callerTokenAAccount, callerTokenBAccount;
    let status;
    this.neededTokens = [];
    this.serror = false;
    try {
      const associatedTokenA = await getAssociatedTokenAddress(
        tokenAPubKey,
        this.custodial_wallet_publickey
      );

      const associatedTokenB = await getAssociatedTokenAddress(
        tokenBPubKey,
        this.custodial_wallet_publickey
      );

      //  this. serror = false;
      //  error = false

      // //alert('pass1');
      try {
        callerTokenAAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenA,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: this.title,
            token_address: this.defaultSwapValues.tokenA,
          });
          this.serror = true;
        }
      }
      try {
        callerTokenBAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenB,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: jupInputToken.symbol,
            token_address: jupInputToken.address,
          });
          this.serror = true;
        }
      }

      if (this.serror == true) {
        status = 'err';
        throw 'error';
      }

      callerTokenAAccount = callerTokenAAccount.address;
      callerTokenBAccount = callerTokenBAccount.address;
      // //alert(callerTokenAAccount);
      // //alert(callerTokenBAccount);
      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    } catch (error) {
      // //alert(error);
      console.log(this.neededTokens);
      const modalRef = this.modalService.open(NgbdModalContentJupiter);
      // this.modalService.open(NgbdModalContent).result;
      // console.log(this.neededTokens);
      modalRef.componentInstance.neededTokens = this.neededTokens;
      modalRef.componentInstance.walletService = this.walletservice;
      modalRef.componentInstance.lo = this.loadingBar;
      status = 'err';
      return {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        status,
      };
    }
  }
  async simulateCrossSwapOutput(tbcOutputToken, jupInputToken, JupOutputToken, inputAmount, slippage) {
    // await
    let amountInBN;
    let amountOutBN;
    // console.log(jupInputToken);
    amountInBN = new BN(inputAmount * Math.pow(10, jupInputToken.decimals));
    const tokenSwapInfo = this.garyswapDetailsB.swapId;
    let tokenSwapInfoPubKey;
    let tokenAPubKey;
    let tokenBPubKey;
    const amountOut = 0;
    try {
      tokenSwapInfoPubKey = new PublicKey(tokenSwapInfo);
      tokenAPubKey = new PublicKey(this.jupGaryToken.address);
      tokenBPubKey = new PublicKey(tbcOutputToken.token_address);
    } catch (error) {
      this._toastService.warn(error);
      this.spinOn = false;
    }
    //convert amount to proper units
    const connection = this.walletservice.connection;
    const { decimals: tokenADecimals } = await getMintInfo({
      tokenMint: tokenAPubKey,
      connection,
    });
    const { decimals: tokenBDecimals } = await getMintInfo({
      tokenMint: tokenBPubKey,
      connection,
    });

    try {
      // console.log(jup_amount);
      // amountInBN = new BN(jup_amount * Math.pow(10, tokenADecimals));

      // //alert(amountInBN);
      amountOutBN = baseToDec(new BN(amountOut), new BN(tokenBDecimals));
    } catch (error) {
      // //alert(amountIn);
      this._toastService.error(error);
      this.spinOn = false;
    }
    const provider = this.walletservice.provider;
    const tokenSwap = await tokenSwapProgram(provider);
    var { feeAccount, tokenAccountA, tokenAccountB, poolToken } =
      await getTokenSwapInfo(
        provider.connection,
        tokenSwapInfoPubKey,
        tokenSwap.programId
      );
    if (this.jupGaryToken.address == this.garyswapDetailsB.tokenA) {
    } else {
      const ta = tokenAccountA;
      const tb = tokenAccountB;
      tokenAccountA = tb;
      tokenAccountB = ta;
    }
    let callerTokenAAccount, callerTokenBAccount;
    let status;
    this.neededTokens = [];
    this.serror = false;
    // //alert('1')
    try {
      const associatedTokenA = await getAssociatedTokenAddress(
        tokenAPubKey,
        this.custodial_wallet_publickey
      );

      const associatedTokenB = await getAssociatedTokenAddress(
        tokenBPubKey,
        this.custodial_wallet_publickey
      );

      //  this. serror = false;
      //  error = false

      // //alert('pass1');
      try {
        callerTokenAAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenA,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        console.log(error);
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: this.jupGaryToken.symbol,
            token_address: this.jupGaryToken.address,
          });
          this.serror = true;
        }
      }
      try {
        callerTokenBAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenB,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
      } catch (error) {
        console.log(error);
        if (
          error.message === 'TokenAccountNotFoundError' ||
          error.message === 'TokenInvalidAccountOwnerError'
        ) {
          this.neededTokens.push({
            title: tbcOutputToken.coin_symbol,
            token_address: tbcOutputToken.token_address,
          });
          this.serror = true;
        }
      }

      if (this.serror == true) {
        status = 'err';
        throw 'error';
      }

      callerTokenAAccount = callerTokenAAccount.address;
      callerTokenBAccount = callerTokenBAccount.address;
      // //alert(callerTokenAAccount);
      // //alert(callerTokenBAccount);
      return {
        stokenSwap: tokenSwap,
        stokenSwapInfoPubKey: tokenSwapInfoPubKey,
        samountInBN: amountInBN,
        samountOutBN: amountOutBN,
        scallerTokenAAccount: callerTokenAAccount,
        scallerTokenBAccount: callerTokenBAccount,
        spoolToken: poolToken,
        sfeeAccount: feeAccount,
        stokenAccountA: tokenAccountA,
        stokenAccountB: tokenAccountB,
        stokenADecimals: tokenADecimals,
        stokenBDecimals: tokenBDecimals,
        sstatus: status,
      };
    } catch (error) {
      // //alert(error);
      // console.log(this.neededTokens);
      const modalRef = this.modalService.open(NgbdModalContentJupiter);
      // this.modalService.open(NgbdModalContent).result;
      modalRef.componentInstance.neededTokens = this.neededTokens;
      modalRef.componentInstance.walletService = this.walletservice;
      modalRef.componentInstance.lo = this.loadingBar;
      status = 'err';
      return {
        stokenSwap: tokenSwap,
        stokenSwapInfoPubKey: tokenSwapInfoPubKey,
        samountInBN: amountInBN,
        samountOutBN: amountOutBN,
        scallerTokenAAccount: callerTokenAAccount,
        scallerTokenBAccount: callerTokenBAccount,
        spoolToken: poolToken,
        sfeeAccount: feeAccount,
        stokenAccountA: tokenAccountA,
        stokenAccountB: tokenAccountB,
        stokenADecimals: tokenADecimals,
        stokenBDecimals: tokenBDecimals,
        sstatus: status,
      };
    }



  }


  estimateSwapValues = async () => {
    let {
      tokenSwap,
      tokenSwapInfoPubKey,
      amountInBN,
      amountOutBN,
      callerTokenAAccount,
      callerTokenBAccount,
      poolToken,
      feeAccount,
      tokenAccountA,
      tokenAccountB,
      tokenADecimals,
      tokenBDecimals,
      status,
      route,
      jupInputToken,
      jupOutputToken,
      tbcInputToken,
      tbcOutputToken
    } = await this.generateSwapValues();
    if (status == 'err') {
      return {
        amountA: 0,
        amountB: 0,
      };
    }
    // tokenSwap.methods.swap(1,1).accounts({
    //
    // }).instruction()
    let tokenABalance, tokenBBalance;
    try {

      if (this.defaultSwapValues.tokenA == 'So11111111111111111111111111111111111111112') {
        tokenABalance =
          (await this.walletservice.connection.getBalance(
            this.custodial_wallet_publickey
          ));

      } else {
        tokenABalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenAAccount
          )
        ).value.amount;
      }
      // var
    } catch (error) {
      tokenABalance = '0';
    }
    // console.log(route);

    try {
      if (this.defaultSwapValues.tokenB == 'So11111111111111111111111111111111111111112') {
        tokenBBalance =
          (await this.walletservice.connection.getBalance(
            this.custodial_wallet_publickey
          ));

      } else {
        tokenBBalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenBAccount
          )
        ).value.amount;
      }
      // var tokenBBalance = (
      //   await this.walletservice.connection.getTokenAccountBalance(
      //     callerTokenBAccount
      //   )
      // ).value.amount;
    } catch (error) {
      tokenBBalance = '0';
    }
    console.log(amountInBN + "    " + tokenABalance)
    console.log("Out    " + tokenBBalance)
    if (amountInBN.gt(new BN(tokenABalance)) && (route != 2)) {
      // this.stopLoading();
      this._toastService.error('Insufficient Fund');
      return {
        amountA: 0,
        amountB: 0,
      };
      // console.log(tokenABalance);
      // console.log(amountInBN);
    } else if (route == 2) {
      if (new BN(this.defaultSwapValues.amountIn * Math.pow(10, jupInputToken.decimals)).gt(new BN(tokenABalance))) {
        this.noswap = true;
      } else {
        this.noswap = false;
      }
      // this.canSwap  = true;

    } else {
      this.noswap = false;
    }
    try {
      if (route == 0) {
        //alert(1)
        const connection = this.walletservice.connection;
        const walletPubKey = this.custodial_wallet_publickey;
        //alert(walletPubKey)
        const { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
          {
            tokenSwap,
            tokenSwapInfo: tokenSwapInfoPubKey,
            amountIn: amountInBN,
            amountOut: amountOutBN,
            userTransferAuthority: this.custodial_wallet_publickey,
            userSourceTokenAccount: callerTokenAAccount,
            userDestinationTokenAccount: callerTokenBAccount,
            swapSourceTokenAccount: tokenAccountA,
            swapDestinationTokenAccount: tokenAccountB,
            poolMintAccount: poolToken,
            poolFeeAccount: feeAccount,
            walletPubKey: walletPubKey,
            connection,
          }
        );
        // console.log(amountTokenAPostSwap.toString());
        // console.log(amountTokenBPostSwap.toString());

        return {
          amountA: decToBase(
            new BN(tokenABalance).sub(new BN(amountTokenAPostSwap)),
            // new BN(amountTokenAPostSwap.toString()),
            new BN(tokenADecimals)
          ),
          amountB: decToBase(
            new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
            // new BN(amountTokenBPostSwap.toString()),
            new BN(tokenBDecimals)
          ),
        };
      }
      else if (route == 1) {
        const connection = this.walletservice.connection;
        const walletPubKey = this.custodial_wallet_publickey;
        let amountA, amountB;
        let res;
        if (this.defaultSwapValues.tokenA != this.jupGaryToken.address) {
          const { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
            {
              tokenSwap,
              tokenSwapInfo: tokenSwapInfoPubKey,
              amountIn: amountInBN,
              amountOut: amountOutBN,
              userTransferAuthority: this.custodial_wallet_publickey,
              userSourceTokenAccount: callerTokenAAccount,
              userDestinationTokenAccount: callerTokenBAccount,
              swapSourceTokenAccount: tokenAccountA,
              swapDestinationTokenAccount: tokenAccountB,
              poolMintAccount: poolToken,
              poolFeeAccount: feeAccount,
              walletPubKey: walletPubKey,
              connection,
            }
          );
          // amountA = decToBase(
          //   new BN(tokenABalance).sub(new BN(amountTokenAPostSwap)),
          //   // new BN(amountTokenAPostSwap.toString()),
          //   new BN(tokenADecimals)
          // )
          // amountB  =  decToBase(
          //   new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
          //   // new BN(amountTokenBPostSwap.toString()),
          //   new BN(tokenBDecimals)
          // )
          tokenBBalance = (
            await this.walletservice.connection.getTokenAccountBalance(
              callerTokenBAccount
            )
          ).value.amount;
          console.log(callerTokenBAccount.toBase58());
          res = await this.fetchRoutes(new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)), this.jupGaryToken.address, this.defaultSwapValues.tokenB, 500);

        } else {

          res = await this.fetchRoutes(amountInBN, this.jupGaryToken.address, this.defaultSwapValues.tokenB, 500);

        }
        // console.log(amountTokenAPostSwap.toString());
        // console.log(amountTokenBPostSwap.toString());


        // //alert(new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)));
        if (res?.routePlan?.length < 1) {
          this._toastService.error('No route found for this swap');
          return {
            amountA: decToBase(amountInBN, new BN(tokenADecimals)),
            amountB: 0,
          };
        } else {
          let jup_amount = res['outAmount'] / 10 ** jupOutputToken.decimals;
          return {
            amountA: decToBase(
              amountInBN,
              // new BN(amountTokenAPostSwap.toString()),
              new BN(tokenADecimals)
            ),
            amountB: jup_amount
            ,
          };
        }



      }
      else if (route == 2) {
        const connection = this.walletservice.connection;
        const walletPubKey = this.custodial_wallet_publickey;
        if (this.defaultSwapValues.tokenB == this.jupGaryToken.address) {
          console.log("here");
          return {
            amountA: this.defaultSwapValues.amountIn,
            amountB: decToBase(
              new BN(amountInBN),
              // new BN(amountTokenBPostSwap.toString()),
              new BN(tokenADecimals)
            ),
          };
        } else {
          const { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
            {
              tokenSwap,
              tokenSwapInfo: tokenSwapInfoPubKey,
              amountIn: amountInBN,
              amountOut: amountOutBN,
              userTransferAuthority: new PublicKey('HWpdfSfuS3LDBVJCvLvAwNw6ffHYyTKnfUF86sZgUKXT'),
              userSourceTokenAccount: new PublicKey('GoV7a7iiJgQ4BUMFFsaodMdr9pCm3oFxUcjQ8VqawGug'),
              userDestinationTokenAccount: callerTokenBAccount,
              swapSourceTokenAccount: tokenAccountA,
              swapDestinationTokenAccount: tokenAccountB,
              poolMintAccount: poolToken,
              poolFeeAccount: feeAccount,
              walletPubKey: walletPubKey,
              connection,
            }
          );
          console.log(amountTokenAPostSwap.toString());
          console.log(amountTokenBPostSwap.toString());

          return {
            amountA: this.defaultSwapValues.amountIn,
            amountB: decToBase(
              new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
              // new BN(amountTokenBPostSwap.toString()),
              new BN(tokenBDecimals)
            ),
          };
        }

      }
      else if (route == 3) {

        const connection = this.walletservice.connection;
        const walletPubKey = this.custodial_wallet_publickey;
        let { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
          {
            tokenSwap,
            tokenSwapInfo: tokenSwapInfoPubKey,
            amountIn: amountInBN,
            amountOut: amountOutBN,
            userTransferAuthority: this.custodial_wallet_publickey,
            userSourceTokenAccount: callerTokenAAccount,
            userDestinationTokenAccount: callerTokenBAccount,
            swapSourceTokenAccount: tokenAccountA,
            swapDestinationTokenAccount: tokenAccountB,
            poolMintAccount: poolToken,
            poolFeeAccount: feeAccount,
            walletPubKey: walletPubKey,
            connection,
          }
        );
        // alert(4.1)
        // console.log(amountTokenAPostSwap.toString());
        // console.log(amountTokenBPostSwap.toString());
        let amountA, amountB;
        amountA = this.defaultSwapValues.amountIn;

        amountB = decToBase(
          new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
          // new BN(amountTokenBPostSwap.toString()),
          new BN(tokenBDecimals)
        )
        let {
          stokenSwap,
          stokenSwapInfoPubKey,
          samountInBN,
          samountOutBN,
          scallerTokenAAccount,
          scallerTokenBAccount,
          spoolToken,
          sfeeAccount,
          stokenAccountA,
          stokenAccountB,
          stokenADecimals,
          stokenBDecimals,
          sstatus
        } = await this.simulateCrossSwapOutput(tbcOutputToken, jupInputToken, jupOutputToken, amountB, 1);
        if (sstatus == 'err') {
          return {
            amountA: 0,
            amountB: 0,
          };
        }
        let samountTokenAPostSwap: any, samountTokenBPostSwap: any;
        ({ amountTokenAPostSwap = samountTokenAPostSwap, amountTokenBPostSwap = samountTokenBPostSwap } = await estimateSwap(
          {
            tokenSwap: stokenSwap,
            tokenSwapInfo: stokenSwapInfoPubKey,
            amountIn: samountInBN,
            amountOut: samountOutBN,
            userTransferAuthority: new PublicKey('HWpdfSfuS3LDBVJCvLvAwNw6ffHYyTKnfUF86sZgUKXT'),
            userSourceTokenAccount: new PublicKey('GoV7a7iiJgQ4BUMFFsaodMdr9pCm3oFxUcjQ8VqawGug'),
            userDestinationTokenAccount: scallerTokenBAccount,
            swapSourceTokenAccount: stokenAccountA,
            swapDestinationTokenAccount: stokenAccountB,
            poolMintAccount: spoolToken,
            poolFeeAccount: sfeeAccount,
            walletPubKey: walletPubKey,
            connection,
          }
        ));
        // console.log(amountTokenAPostSwap.toString());
        // console.log(amountTokenBPostSwap.toString());
        try {
          var stokenABalance = (
            await this.walletservice.connection.getTokenAccountBalance(
              scallerTokenAAccount
            )
          ).value.amount;
        } catch (error) {
          stokenABalance = '0';
        }
        console.log(stokenABalance);

        try {
          var stokenBBalance = (
            await this.walletservice.connection.getTokenAccountBalance(
              scallerTokenBAccount
            )
          ).value.amount;
        } catch (error) {
          stokenBBalance = '0';
        }
        let samountA, samountB;
        samountA = this.defaultSwapValues.amountIn;

        samountB = decToBase(
          new BN(amountTokenBPostSwap).sub(new BN(stokenBBalance)),
          // new BN(amountTokenBPostSwap.toString()),
          new BN(stokenBDecimals)
        )
        return {
          amountA: this.defaultSwapValues.amountIn,
          amountB: samountB
        };


      }
      else {
        const connection = this.walletservice.connection;
        const walletPubKey = this.custodial_wallet_publickey;
        const { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
          {
            tokenSwap,
            tokenSwapInfo: tokenSwapInfoPubKey,
            amountIn: amountInBN,
            amountOut: amountOutBN,
            userTransferAuthority: this.custodial_wallet_publickey,
            userSourceTokenAccount: callerTokenAAccount,
            userDestinationTokenAccount: callerTokenBAccount,
            swapSourceTokenAccount: tokenAccountA,
            swapDestinationTokenAccount: tokenAccountB,
            poolMintAccount: poolToken,
            poolFeeAccount: feeAccount,
            walletPubKey,
            connection,
          }
        );
        console.log(amountTokenAPostSwap.toString());
        console.log(amountTokenBPostSwap.toString());

        return {
          amountA: decToBase(
            new BN(tokenABalance).sub(new BN(amountTokenAPostSwap)),
            // new BN(amountTokenAPostSwap.toString()),
            new BN(tokenADecimals)
          ),
          amountB: decToBase(
            new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
            // new BN(amountTokenBPostSwap.toString()),
            new BN(tokenBDecimals)
          ),
        };
      }

    } catch (error) {
      console.log(error);
      this._toastService.error('invalid amounts');
      return {
        amountA: decToBase(amountInBN, new BN(tokenADecimals)),
        amountB: decToBase(amountOutBN, new BN(tokenBDecimals)),
      };
    }
  };

  getcoinprice(coin) {
    this.coinprice = 0;
    this.coinprices = 0;
    this.settings.getcoinprice2(coin).subscribe({
      next: (res: any) => {
        console.log(res);
        this.coinprice = parseFloat(res.data.price_usd).toFixed(4);
        this.coinprices = parseFloat(res.data.price_usd);
      },
    });
  }

  getcoinprice2(coin) {
    this.coinprice2 = 0;
    this.coinprice2s = 0;

    this.settings.getcoinprice2(coin).subscribe({
      next: (res: any) => {
        console.log(res);
        this.coinprice2 = parseFloat(res.data.price_usd).toFixed(4);
        this.coinprice2s = parseFloat(res.data.price_usd);
      },
    });
  }
  search2() {
    this.newMenuItems = this.menuItems;
    if (this.name2 === '') {
      this.newMenuItems = this.menuItems;
      this.searchUser = false;
    } else {
      this.newMenuItems = this.newMenuItems.filter((resp) =>
        String(resp.coin_name)
          .toLocaleLowerCase()
          .match(this.name2.toLocaleLowerCase())
      );
    }
  }

  search3() {
    this.newMenuItems2 = this.menuItems2;
    if (this.name3 === '') {
      this.newMenuItems2 = this.menuItems2;
      this.searchUser = false;
    } else {
      this.newMenuItems2 = this.newMenuItems2.filter((resp) =>
        String(resp.coin_name)
          .toLocaleLowerCase()
          .match(this.name3.toLocaleLowerCase())
      );
    }
  }

  async fetchRoutes(amount, tokenA, tokenB, slippage) {
    // swapping SOL to USDC with input 0.1 SOL and 0.5% slippage
    // let amount = this.defaultSwapValues.amountIn * 1000000000;
    const data = await (
      await fetch(
        'https://quote-api.jup.ag/v6/quote?inputMint=' +
        tokenA +
        '&outputMint=' +
        tokenB +
        '&amount=' +
        amount +
        `&slippageBps=${slippage}&platformFeeBps=10&asLegacyTransaction=false`
      )
    ).json();

    return data;
  }
  async selectCoin(name: any, img: any, token_address) {
    this.isCollapsed = true;
    this.title = name;
    this.img = img;
    this.defaultSwapValues.tokenA = token_address;
    this.menuItems2 = [];
    this.getcoinprice(name);
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;
    this.swapDetails.swapId = '';
    this.swapDetails.tokenA = '';
    this.swapDetails.tokenB = '';

    for (let i = 0; i < this.menuItems3.length; i++) {
      const element = this.menuItems3[i];
      // if (
      //   token_address === '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
      //   token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' || token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'
      // ) {
      //   if (token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' || token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh') {
      //     if (
      //       element.coin_symbol !== name &&
      //       element.token_address ===
      //         '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
      //     ) {
      //       this.menuItems2.push(element);
      //     }
      //   } else {
      //     if (element.coin_symbol !== name) {
      //       this.menuItems2.push(element);
      //     }
      //   }
      // } else {
      //   if (
      //     element.coin_symbol !== name &&
      //     element.token_address ===
      //       '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
      //   ) {
      //     this.menuItems2.push(element);
      //   }
      // }
      if (element.coin_symbol !== name) {
        this.menuItems2.push(element);
      }
      this.newMenuItems2 = this.menuItems2;

    }
    // console.log(this.menuItems2);
    let swapdetails;
    console.log(this.allSwaps);
    swapdetails = this.allSwaps.find(
      (t) => t.tokena_address == this.defaultSwapValues.tokenA && t.tokenb_address == this.defaultSwapValues.tokenB
    );
    if (swapdetails == undefined) {
      swapdetails = this.allSwaps.find(
        (t) => t.tokena_address == this.defaultSwapValues.tokenB && t.tokenb_address == this.defaultSwapValues.tokenA
      );
    }
    if (swapdetails !== undefined) {
      this.swapDetails.swapId = swapdetails.swap_id;
      this.swapDetails.tokenA = swapdetails.tokena_address;
      this.swapDetails.tokenB = swapdetails.tokenb_address;
    }
    let gswapdetails;
    gswapdetails = this.allSwaps.find(
      (t) => t.tokena_address == this.defaultSwapValues.tokenA && t.tokenb_address == this.jupGaryToken.address
    );
    if (gswapdetails == undefined) {
      gswapdetails = this.allSwaps.find(
        (t) => t.tokena_address == this.jupGaryToken.address && t.tokenb_address == this.defaultSwapValues.tokenA
      );
    }
    if (gswapdetails != undefined) {
      this.garyswapDetails.swapId = gswapdetails.swap_id;
      this.garyswapDetails.tokenA = gswapdetails.tokena_address;
      this.garyswapDetails.tokenB = gswapdetails.tokenb_address;
    }

    let gswapdetailsb;
    gswapdetailsb = this.allSwaps.find(
      (t) => t.tokena_address == this.defaultSwapValues.tokenB && t.tokenb_address == this.jupGaryToken.address
    );
    if (gswapdetailsb == undefined) {
      gswapdetailsb = this.allSwaps.find(
        (t) => t.tokena_address == this.jupGaryToken.address && t.tokenb_address == this.defaultSwapValues.tokenB
      );
    }
    if (gswapdetailsb != undefined) {
      this.garyswapDetailsB.swapId = gswapdetailsb.swap_id;
      this.garyswapDetailsB.tokenA = gswapdetailsb.tokena_address;
      this.garyswapDetailsB.tokenB = gswapdetailsb.tokenb_address;
    }
    // this.app
    //   .getSwap(this.defaultSwapValues.tokenA, this.defaultSwapValues.tokenB)
    //   .subscribe({
    //     next: (res: any) => {
    //       // console.log(res);
    //       if (res.data !== null) {
    //         this.swapDetails.swapId = res.data.swap_id;
    //         this.swapDetails.tokenA = res.data.tokena_address;
    //         this.swapDetails.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });

    // this.app
    //   .getSwap(this.defaultSwapValues.tokenA, this.jupGaryToken.address)
    //   .subscribe({
    //     next: (res: any) => {
    //       console.log(res);
    //       if (res.data !== null) {
    //         this.garyswapDetails.swapId = res.data.swap_id;
    //         this.garyswapDetails.tokenA = res.data.tokena_address;
    //         this.garyswapDetails.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });

    // this.app
    //   .getSwap(this.defaultSwapValues.tokenB, this.jupGaryToken.address)
    //   .subscribe({
    //     next: (res: any) => {
    //       console.log(res);
    //       if (res.data !== null) {
    //         this.garyswapDetailsB.swapId = res.data.swap_id;
    //         this.garyswapDetailsB.tokenA = res.data.tokena_address;
    //         this.garyswapDetailsB.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });
    // console.log('title', this.title);
    const callerTokenAAccount = await getAssociatedTokenAddress(
      new PublicKey(token_address),
      this.custodial_wallet_publickey
    );
    // console.log('hey ' + callerTokenAAccount);
    try {
      if (token_address == 'So11111111111111111111111111111111111111112') {
        this.walletservice.tokenABalance = (
          await this.walletservice.connection.getBalance(
            this.custodial_wallet_publickey
          ));
        this.walletservice.tokenABalance =
          this.walletservice.tokenABalance / 1000000000;
        console.log(this.tokenABalance);
      } else {
        this.walletservice.tokenABalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenAAccount
          )
        ).value.uiAmount;
        console.log(this.tokenABalance);
      }

    } catch (error) {
      this.walletservice.tokenABalance = 0;
    }

    // this.defaultSwapValues.tokenA = name;
    const data = {
      key: this.publicKey$,
      address: token_address,
    };
    // this.app.getBalance(data).subscribe({
    //   next: (res: any) => {
    //     console.log(res);
    //     this.balance = res.data;
    //   },
    // });
    // this.publicKey$, token_address
  }
  async estimate() {
    this.spinOn = true;
    const { amountA, amountB } = await this.estimateSwapValues();
    // console.log(amountB);
    this.defaultSwapValues.amountIn = Number(amountA);
    this.defaultSwapValues.amountOut = Number(amountB);
    this.spinOn = false;
  }

  async anothercoin(name: any, img: any, token_address) {
    this.getcoinprice2(name);
    this.isCollapsed2 = true;
    this.coin2 = name;
    this.img2 = img;
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;
    this.defaultSwapValues.tokenB = token_address;
    this.swapDetails.swapId = '';
    this.swapDetails.tokenA = '';
    this.swapDetails.tokenB = '';

    this.canSwap = true;
    this.settings.checkResc(token_address).subscribe({
      next: (res: any) => {
        console.log(res);
        res.data.forEach(async (element) => {
          let check = 0;
          try {
            const associatedTokenA = await getAssociatedTokenAddress(
              new PublicKey(element.required_address),
              this.custodial_wallet_publickey
            );
            check = (
              await this.walletservice.connection.getTokenAccountBalance(
                associatedTokenA
              )
            ).value.uiAmount;
            // //alert(check);
          } catch (error) {
            // //alert(error);/
            // this.alertService.danger(error)
            check = 0;
          }
          // console.log('check', check);

          if (check < parseFloat(element.amount)) {
            this.canSwap = false;
            this.canswapMessage = element.message;
          }
        });
      },
    });
    this.menuItems = [];
    for (let i = 0; i < this.menuItems3.length; i++) {
      const element = this.menuItems3[i];
      // if (
      //   token_address === '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk' ||
      //   token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' || token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh'
      // ) {
      //   if (token_address === 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq' || token_address == 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh') {
      //     if (
      //       element.coin_symbol !== name &&
      //       element.token_address ===
      //         '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
      //     ) {
      //       this.menuItems.push(element);
      //     }
      //   } else {
      if (element.coin_symbol !== name) {
        this.menuItems.push(element);
      }
      //   }
      // } else {
      //   if (
      //     element.coin_symbol !== name &&
      //     element.token_address ===
      //       '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk'
      //   ) {
      //     this.menuItems.push(element);
      //   }
      // }
      this.newMenuItems = this.menuItems;
    }
    // for (let i = 0; i < this.menuItems3.length; i++) {
    //   const element = this.menuItems3[i];
    //   if (element.coin_symbol !== name2) {
    //     // console.log(element);
    //     this.menuItems.push(element);
    //   }
    // }

    // this.app
    //   .getSwap(this.defaultSwapValues.tokenA, this.defaultSwapValues.tokenB)
    //   .subscribe({
    //     next: (res: any) => {
    //       // console.log(res);
    //       if (res.data !== null) {
    //         this.swapDetails.swapId = res.data.swap_id;
    //         this.swapDetails.tokenA = res.data.tokena_address;
    //         this.swapDetails.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });

    // this.app
    //   .getSwap(this.defaultSwapValues.tokenA, this.jupGaryToken.address)
    //   .subscribe({
    //     next: (res: any) => {
    //       console.log(res);
    //       if (res.data !== null) {
    //         this.garyswapDetails.swapId = res.data.swap_id;
    //         this.garyswapDetails.tokenA = res.data.tokena_address;
    //         this.garyswapDetails.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });

    // this.app
    //   .getSwap(this.defaultSwapValues.tokenB, this.jupGaryToken.address)
    //   .subscribe({
    //     next: (res: any) => {
    //       console.log(res);
    //       if (res.data !== null) {
    //         this.garyswapDetailsB.swapId = res.data.swap_id;
    //         this.garyswapDetailsB.tokenA = res.data.tokena_address;
    //         this.garyswapDetailsB.tokenB = res.data.tokenb_address;
    //       }
    //     },
    //   });
    let swapdetails;
    console.log(this.allSwaps);
    swapdetails = this.allSwaps.find(
      (t) => t.tokena_address == this.defaultSwapValues.tokenA && t.tokenb_address == this.defaultSwapValues.tokenB
    );
    if (swapdetails == undefined) {
      swapdetails = this.allSwaps.find(
        (t) => t.tokena_address == this.defaultSwapValues.tokenB && t.tokenb_address == this.defaultSwapValues.tokenA
      );
    }
    if (swapdetails !== undefined) {
      this.swapDetails.swapId = swapdetails.swap_id;
      this.swapDetails.tokenA = swapdetails.tokena_address;
      this.swapDetails.tokenB = swapdetails.tokenb_address;
    }
    let gswapdetails;
    gswapdetails = this.allSwaps.find(
      (t) => t.tokena_address == this.defaultSwapValues.tokenA && t.tokenb_address == this.jupGaryToken.address
    );
    if (gswapdetails == undefined) {
      gswapdetails = this.allSwaps.find(
        (t) => t.tokena_address == this.jupGaryToken.address && t.tokenb_address == this.defaultSwapValues.tokenA
      );
    }
    if (gswapdetails !== undefined) {
      this.garyswapDetails.swapId = gswapdetails.swap_id;
      this.garyswapDetails.tokenA = gswapdetails.tokena_address;
      this.garyswapDetails.tokenB = gswapdetails.tokenb_address;
    }

    let gswapdetailsb;
    gswapdetailsb = this.allSwaps.find(
      (t) => t.tokena_address == this.defaultSwapValues.tokenB && t.tokenb_address == this.jupGaryToken.address
    );
    if (gswapdetailsb == undefined) {
      gswapdetailsb = this.allSwaps.find(
        (t) => t.tokena_address == this.jupGaryToken.address && t.tokenb_address == this.defaultSwapValues.tokenB
      );
    }
    console.log(swapdetails);
    console.log(gswapdetails);
    console.log(gswapdetailsb);
    if (gswapdetailsb != undefined) {
      this.garyswapDetailsB.swapId = gswapdetailsb.swap_id;
      this.garyswapDetailsB.tokenA = gswapdetailsb.tokena_address;
      this.garyswapDetailsB.tokenB = gswapdetailsb.tokenb_address;
    }
    // console.log(swapdetails);
    // console.log('coin2', this.coin2);
    const callerTokenBAccount = await getAssociatedTokenAddress(
      new PublicKey(token_address),
      this.custodial_wallet_publickey
    );

    try {
      if (token_address == 'So11111111111111111111111111111111111111112') {
        this.walletservice.tokenBBalance = (
          await this.walletservice.connection.getBalance(
            this.custodial_wallet_publickey
          ));
        this.walletservice.tokenBBalance =
          this.walletservice.tokenBBalance / 1000000000;
        console.log(this.tokenBBalance);
      } else {
        this.walletservice.tokenBBalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenBAccount
          )
        ).value.uiAmount;
      }
    } catch (error) {
      this.walletservice.tokenBBalance = 0;
    }

    // this.defaultSwapValues.tokenB = name2;
  }

  async swap() {
    const a = this.title;
    const b = this.coin2;
    const c = this.img;
    const d = this.img2;
    // this.coinprice = null;

    const title1 = this.title;
    const img1 = this.img;
    const token_address1 = this.defaultSwapValues.tokenA;

    const title2 = this.coin2;
    const img2 = this.img2;
    const token_address2 = this.defaultSwapValues.tokenB;

    await this.selectCoin(title2, img2, token_address2);
    await this.anothercoin(title1, img1, token_address1);
    this.defaultSwapValues.amountIn = 0;
    this.defaultSwapValues.amountOut = 0;
    // this.title = b;
    // this.coin2 = a;
    // this.img = d;
    // this.img2 = c;
  }

  test() {
    const html = `<a href="https://google.com" target="_blank">Open</a>`;
    this._toastService.success(html);
  }
  async handleSubmit(e: any) {
    this.startLoading();
    this.noswap = true;
    this.spinOn = true;
    e.preventDefault();
    // console.log('here');
    if (!this.custodial_wallet_publickey) {
      this._toastService.error('wallet not active');
      this.stopLoading();
      this.noswap = false;
      this.spinOn = false;
    }
    else {
      let {
        tokenSwap,
        tokenSwapInfoPubKey,
        amountInBN,
        amountOutBN,
        callerTokenAAccount,
        callerTokenBAccount,
        poolToken,
        feeAccount,
        tokenAccountA,
        tokenAccountB,
        tokenADecimals,
        tokenBDecimals,
        route,
        jupInputToken,
        jupOutputToken,
        tbcInputToken,
        tbcOutputToken,
      } = await this.generateSwapValues();
      let tbcSwapIx
      let body = '';
      let res;
      let response;
      const wallet = this.walletservice.wallet;
      const walletPubKey = this.custodial_wallet_publickey
      const connection = this.walletservice.connection;
      let result;
      let signedTxn = [];
      let serializedTransaction;
      let base64Transaction;
      // let signature;
      if (route == 0) {

        try {
          const [swapAuthorityPDA] = await PublicKey.findProgramAddress(
            [tokenSwapInfoPubKey.toBuffer()],
            new PublicKey(
              "TBCwReYDDw8SvwVVKJHgatzeXKrLHnaTPyDGwkUoBsq"
            )
          );
          tbcSwapIx = await tokenSwap.methods
            .swap(amountInBN, new u64(0))
            .accounts({
              tokenSwap: tokenSwapInfoPubKey,
              swapAuthority: swapAuthorityPDA,
              userTransferAuthority: this.custodial_wallet_publickey,
              source: callerTokenAAccount,
              destination: callerTokenBAccount,
              swapSource: tokenAccountA,
              swapDestination: tokenAccountB,
              poolMint: poolToken,
              poolFee: feeAccount,
              tokenProgram: TOKEN_PROGRAM_ID,
            })
            .instruction();

          let tx = new Transaction();
          tx.add(tbcSwapIx);
          let blockHash = await this.walletservice.connection.getLatestBlockhash();
          tx.feePayer = this.custodial_wallet_publickey;
          // // alert(tx.feePayer)
          tx.recentBlockhash = await blockHash.blockhash;
          serializedTransaction = await tx.serialize({
            verifySignatures: false,
            requireAllSignatures: false,
          });
          base64Transaction = serializedTransaction.toString('base64');
          signedTxn.push(base64Transaction);
          // console.log(signedTxn);
          //  let data = {
          //     transactions: signedTxn,
          //   }
          // console.log(data);
          // await this.walletservice.getSignedTnx(data).subscribe({
          //   next: (res: any) => {
          //     console.log('signed re naw', res)
          //     this.signed = res.data.signedTxns
          //   },
          //   error: (err: any) => {
          //     Swal.fire('Failed to generate transaction', err.error.message, 'error');
          //     console.log(err);
          //     // this.isLoading$$.next(false);
          //   },
          //   complete: async () => {
          //     try {
          //       const signed = this.signed

          //       // Call here

          //       // replace 'signed' with the response from the function
          //       // const signed = {'jjk':''}

          //       for (let singleTxn of signed) {
          //         let signature = await this.walletservice.connection.sendRawTransaction(
          //           Transaction.from(Buffer.from(singleTxn, 'base64')).serialize(),
          //           { skipPreflight: false, preflightCommitment: 'confirmed' }
          //         );
          //         console.log(signature);
          //         let response =
          //           await this.walletservice.connection.confirmTransaction(
          //             signature,
          //             'confirmed'
          //           );
          //         // console.log(signed);
          //         this.signature = signature;
          //       }
          //     } catch (error: any) {
          //       // this.isLoading$$.next(false);
          //       // state.stop();
          //       if(error.message == 'failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1'){
          //         Swal.fire('Failed', "You do not have sufficient funds to complete the payment!", 'error')
          //       }else{
          //         Swal.fire('Failed', error.message, 'error')
          //       }

          //       // this.showLoad = false
          //       throw new Error(error);
          //       // Swal.fire('Failed', error, 'error')
          //     }
          //     console.log(this.signature);
          //     // state.stop();
          //     // this.showLoad = false
          //     // Swal.fire('', 'Please wait while we verify your transaction', 'info')
          //     // this.payInfo = 'Verifying transaction'
          //   }
          // })

        } catch (e) {
          this.stopLoading();
          this.noswap = false;
          this.spinOn = false;
          // this._toastService.error(e)
          Swal.fire('Failed', e.message, 'error')
          return;
        }

      }
      else if (route == 1) {
        if (this.defaultSwapValues.tokenA != this.jupGaryToken.address) {
          const { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
            {
              tokenSwap,
              tokenSwapInfo: tokenSwapInfoPubKey,
              amountIn: amountInBN,
              amountOut: amountOutBN,
              userTransferAuthority: this.custodial_wallet_publickey,
              userSourceTokenAccount: callerTokenAAccount,
              userDestinationTokenAccount: callerTokenBAccount,
              swapSourceTokenAccount: tokenAccountA,
              swapDestinationTokenAccount: tokenAccountB,
              poolMintAccount: poolToken,
              poolFeeAccount: feeAccount,
              walletPubKey,
              connection,
            }
          );
          try {
            var tokenABalance = (
              await this.walletservice.connection.getTokenAccountBalance(
                callerTokenAAccount
              )
            ).value.amount;
          } catch (error) {
            tokenABalance = '0';
          }
          console.log(tokenABalance);

          try {
            var tokenBBalance = (
              await this.walletservice.connection.getTokenAccountBalance(
                callerTokenBAccount
              )
            ).value.amount;
          } catch (error) {
            tokenBBalance = '0';
          }
          console.log(amountInBN + "    " + tokenABalance)
          // console.log(amountTokenAPostSwap.toString());
          // console.log(amountTokenBPostSwap.toString());
          let amountA, amountB;
          amountA = decToBase(
            new BN(tokenABalance).sub(new BN(amountTokenAPostSwap)),
            // new BN(amountTokenAPostSwap.toString()),
            new BN(tokenADecimals)
          )
          amountB = decToBase(
            new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
            // new BN(amountTokenBPostSwap.toString()),
            new BN(tokenBDecimals)
          )
          // //alert(new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)));
          res = await this.fetchRoutes(new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)), this.jupGaryToken.address, this.defaultSwapValues.tokenB, 500);

        } else {
          res = await this.fetchRoutes(amountInBN, this.jupGaryToken.address, this.defaultSwapValues.tokenB, 500);

        }

        if (res?.routePlan?.length < 1) {
          this._toastService.error('No route found for this swap');
        } else {
          let jup_amount
          if (this.defaultSwapValues.tokenA != this.jupGaryToken.address) {
            const [swapAuthorityPDA] = await PublicKey.findProgramAddress(
              [tokenSwapInfoPubKey.toBuffer()],
              new PublicKey(
                "TBCwReYDDw8SvwVVKJHgatzeXKrLHnaTPyDGwkUoBsq"
              )
            );
            console.log(swapAuthorityPDA.toBase58());
            jup_amount = res['outAmount'] / 10 ** jupOutputToken.decimals;
            tbcSwapIx = await tokenSwap.methods
              .swap(amountInBN, new u64(0))
              .accounts({
                tokenSwap: tokenSwapInfoPubKey,
                swapAuthority: swapAuthorityPDA,
                userTransferAuthority: this.custodial_wallet_publickey,
                source: callerTokenAAccount,
                destination: callerTokenBAccount,
                swapSource: tokenAccountA,
                swapDestination: tokenAccountB,
                poolMint: poolToken,
                poolFee: feeAccount,
                tokenProgram: TOKEN_PROGRAM_ID,
              })
              .instruction();
          }




        }



      }
      else if (route == 2) {
        res = await this.fetchRoutes(new BN(this.defaultSwapValues.amountIn * Math.pow(10, jupInputToken.decimals)), this.defaultSwapValues.tokenA, this.jupGaryToken.address, 1);

        if (res?.routePlan?.length < 1) {
          this._toastService.error('No route found for this swap');
        } else {
          if (this.defaultSwapValues.tokenB != this.jupGaryToken.address) {
            const [swapAuthorityPDA] = await PublicKey.findProgramAddress(
              [tokenSwapInfoPubKey.toBuffer()],
              new PublicKey(
                "TBCwReYDDw8SvwVVKJHgatzeXKrLHnaTPyDGwkUoBsq"
              )
            );
            console.log(swapAuthorityPDA.toBase58());
            // let jup_amount = res['routePlan'][0]['swapInfo'].outAmount / 10 ** jupOutputToken.decimals;
            let jup_amount = res['outAmount'];
            console.log(new BN(jup_amount))
            tbcSwapIx = await tokenSwap.methods
              .swap(new BN(jup_amount), new u64(0))
              .accounts({
                tokenSwap: tokenSwapInfoPubKey,
                swapAuthority: swapAuthorityPDA,
                userTransferAuthority: this.custodial_wallet_publickey,
                source: callerTokenAAccount,
                destination: callerTokenBAccount,
                swapSource: tokenAccountA,
                swapDestination: tokenAccountB,
                poolMint: poolToken,
                poolFee: feeAccount,
                tokenProgram: TOKEN_PROGRAM_ID,
              })
              .instruction();
          }




        }


      }
      else if (route == 3) {
        // alert(1)
        const { amountTokenAPostSwap, amountTokenBPostSwap } = await estimateSwap(
          {
            tokenSwap,
            tokenSwapInfo: tokenSwapInfoPubKey,
            amountIn: amountInBN,
            amountOut: amountOutBN,
            userTransferAuthority: this.custodial_wallet_publickey,
            userSourceTokenAccount: callerTokenAAccount,
            userDestinationTokenAccount: callerTokenBAccount,
            swapSourceTokenAccount: tokenAccountA,
            swapDestinationTokenAccount: tokenAccountB,
            poolMintAccount: poolToken,
            poolFeeAccount: feeAccount,
            walletPubKey: walletPubKey,
            connection,
          }
        );
        // alert(1.1)
        try {
          var tokenABalance = (
            await this.walletservice.connection.getTokenAccountBalance(
              callerTokenAAccount
            )
          ).value.amount;
        } catch (error) {
          tokenABalance = '0';
        }
        // alert(2)
        console.log(tokenABalance);

        try {
          var tokenBBalance = (
            await this.walletservice.connection.getTokenAccountBalance(
              callerTokenBAccount
            )
          ).value.amount;
        } catch (error) {
          tokenBBalance = '0';
        }
        console.log(amountInBN + "    " + tokenABalance)
        // console.log(amountTokenAPostSwap.toString());
        // console.log(amountTokenBPostSwap.toString());
        let amountA, amountB;
        amountA = decToBase(
          new BN(tokenABalance).sub(new BN(amountTokenAPostSwap)),
          // new BN(amountTokenAPostSwap.toString()),
          new BN(tokenADecimals)
        )
        amountB = decToBase(
          new BN(amountTokenBPostSwap).sub(new BN(tokenBBalance)),
          // new BN(amountTokenBPostSwap.toString()),
          new BN(tokenBDecimals)
        )

        let [swapAuthorityPDA] = await PublicKey.findProgramAddress(
          [tokenSwapInfoPubKey.toBuffer()],
          new PublicKey(
            "TBCwReYDDw8SvwVVKJHgatzeXKrLHnaTPyDGwkUoBsq"
          )
        );
        // alert(3)
        console.log(swapAuthorityPDA.toBase58());
        tbcSwapIx = await tokenSwap.methods
          .swap(amountInBN, new u64(0))
          .accounts({
            tokenSwap: tokenSwapInfoPubKey,
            swapAuthority: swapAuthorityPDA,
            userTransferAuthority: this.custodial_wallet_publickey,
            source: callerTokenAAccount,
            destination: callerTokenBAccount,
            swapSource: tokenAccountA,
            swapDestination: tokenAccountB,
            poolMint: poolToken,
            poolFee: feeAccount,
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .instruction();
        let {
          stokenSwap,
          stokenSwapInfoPubKey,
          samountInBN,
          samountOutBN,
          scallerTokenAAccount,
          scallerTokenBAccount,
          spoolToken,
          sfeeAccount,
          stokenAccountA,
          stokenAccountB,
          stokenADecimals,
          stokenBDecimals,
          sstatus
        } = await this.simulateCrossSwapOutput(tbcOutputToken, jupInputToken, jupOutputToken, amountB, 1);

        let [sswapAuthorityPDA] = await PublicKey.findProgramAddress(
          [stokenSwapInfoPubKey.toBuffer()],
          new PublicKey(
            "TBCwReYDDw8SvwVVKJHgatzeXKrLHnaTPyDGwkUoBsq"
          )
        );
        // alert(4)
        console.log(sswapAuthorityPDA.toBase58());
        let stbcSwapIx = await tokenSwap.methods
          .swap(samountInBN, new u64(0))
          .accounts({
            tokenSwap: stokenSwapInfoPubKey,
            swapAuthority: sswapAuthorityPDA,
            userTransferAuthority: this.custodial_wallet_publickey,
            source: scallerTokenAAccount,
            destination: scallerTokenBAccount,
            swapSource: stokenAccountA,
            swapDestination: stokenAccountB,
            poolMint: spoolToken,
            poolFee: sfeeAccount,
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .instruction();
        // console.log(stbcSwapIx);
        console.log("here");
        // let signature;
        // let signedTxn = [];
        let tx = new Transaction();
        tx.add(tbcSwapIx);

        let blockHash = await this.walletservice.connection.getLatestBlockhash();
        tx.feePayer = this.custodial_wallet_publickey;
        // alert(tx.feePayer)
        tx.recentBlockhash = await blockHash.blockhash;
        serializedTransaction = await tx.serialize({
          verifySignatures: false,
          requireAllSignatures: false,
        });
        base64Transaction = serializedTransaction.toString('base64');
        signedTxn.push(base64Transaction);
        console.log(signedTxn);
        tx = new Transaction();
        tx.add(stbcSwapIx);
        blockHash = await this.walletservice.connection.getLatestBlockhash();
        tx.feePayer = this.custodial_wallet_publickey;
        tx.recentBlockhash = await blockHash.blockhash;
        serializedTransaction = await tx.serialize({
          verifySignatures: false,
          requireAllSignatures: false,
        });
        base64Transaction = serializedTransaction.toString('base64');
        signedTxn.push(base64Transaction);

      }
      else {

      }
      console.log(signedTxn);
      if (res !== undefined) {
        if (route == 2 && fees_account[this.jupGaryToken.address] != undefined) {
          body = JSON.stringify({
            // route from /quote api
            quoteResponse: res,
            // user public key to be used for the swap
            userPublicKey: this.custodial_wallet_publickey.toString(),
            // auto wrap and unwrap SOL. default is true
            wrapUnwrapSOL: true,
            // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
            // This is the ATA account for the output token where the fee will be sent to. If you are swapping from SOL->USDC then this would be the USDC ATA you want to collect the fee.
            feeAccount: fees_account[this.jupGaryToken.address],
            asLegacyTransaction: false
          })
        }
        else if (route == 1 && fees_account[this.defaultSwapValues.tokenB] != undefined) {
          body = JSON.stringify({
            // route from /quote api
            quoteResponse: res,
            // user public key to be used for the swap
            userPublicKey: this.custodial_wallet_publickey.toString(),
            // auto wrap and unwrap SOL. default is true
            wrapUnwrapSOL: true,
            // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
            // This is the ATA account for the output token where the fee will be sent to. If you are swapping from SOL->USDC then this would be the USDC ATA you want to collect the fee.
            feeAccount: fees_account[this.defaultSwapValues.tokenB],
            asLegacyTransaction: false
          })
        }
        else {
          body = JSON.stringify({
            // route from /quote api
            quoteResponse: res,
            // user public key to be used for the swap
            userPublicKey: this.custodial_wallet_publickey.toString(),
            // auto wrap and unwrap SOL. default is true
            wrapUnwrapSOL: true,
            asLegacyTransaction: false,
            // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
            // This is the ATA account for the output token where the fee will be sent to. If you are swapping from SOL->USDC then this would be the USDC ATA you want to collect the fee.
            feeAccount: fees_account[this.defaultSwapValues.tokenB],
          })
        }
        // body = JSON.stringify({
        //   // route from /quote api
        //   route: res[0],
        //   // user public key to be used for the swap
        //   userPublicKey: this.custodial_wallet_publickey.toString(),
        //   // auto wrap and unwrap SOL. default is true
        //   wrapUnwrapSOL: true,
        //   // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
        //   // This is the ATA account for the output token where the fee will be sent to. If you are swapping from SOL->USDC then this would be the USDC ATA you want to collect the fee.
        //   // feeAccount: fees_account[this.defaultSwapValues.tokenB],
        // })

        const transactions = await (
          await fetch('https://quote-api.jup.ag/v6/swap', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: body,
          })
        ).json();
        const { setupTransaction, swapTransaction, cleanupTransaction } =
          transactions;
        let transactions_list;
        let signature;
        // let signedTxn = [];
        const PRIORITY_RATE = this.settings.priority_fee; // MICRO_LAMPORTS
        const PRIORITY_FEE_IX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: PRIORITY_RATE });

        if (route == 1) {
          try {

            if (this.defaultSwapValues.tokenA != this.jupGaryToken.address) {
              let tx = new Transaction();

              tx.add(PRIORITY_FEE_IX);
              tx.add(tbcSwapIx);
              const blockHash = await this.walletservice.connection.getLatestBlockhash();
              tx.feePayer = this.custodial_wallet_publickey;
              tx.recentBlockhash = await blockHash.blockhash;
              serializedTransaction = await tx.serialize({
                verifySignatures: false,
                requireAllSignatures: false,
              });
              base64Transaction = serializedTransaction.toString('base64');
              signedTxn.push(base64Transaction);
              // signedTxn.push(tx.serialize().toString('base64'));
            }

            for (let serializedTransaction of [setupTransaction, swapTransaction, cleanupTransaction].filter(Boolean)) {
              // signedTxn.push(Transaction.from(Buffer.from(serializedTransaction, 'base64')))
              signedTxn.push(serializedTransaction)
            }

          } catch (e) {
            console.log(e);
          }
        }
        else if (route == 2) {
          for (let serializedTransaction of [setupTransaction, swapTransaction, cleanupTransaction].filter(Boolean)) {
            // signedTxn.push(Transaction.from(Buffer.from(serializedTransaction, 'base64')))
            signedTxn.push(serializedTransaction)
          }
          if (this.defaultSwapValues.tokenB != this.jupGaryToken.address) {
            let tx = new Transaction();
            tx.add(PRIORITY_FEE_IX);
            tx.add(tbcSwapIx);
            const blockHash = await this.walletservice.connection.getLatestBlockhash();
            tx.feePayer = this.custodial_wallet_publickey;
            // alert(tx.feePayer)
            tx.recentBlockhash = await blockHash.blockhash;
            // signedTxn.push(tx.serialize().toString('base64'));
            serializedTransaction = await tx.serialize({
              verifySignatures: false,
              requireAllSignatures: false,
            });
            base64Transaction = serializedTransaction.toString('base64');
            signedTxn.push(base64Transaction);
            console.log(signedTxn);
          }
        }



      }

      try {
        console.log(signedTxn);
        // const signed = await this.walletservice.wallet.signAllTransactions(signedTxn);
        let data = {
          transactions: signedTxn,
        }

        await this.walletservice.getSignedTnx(data).subscribe({
          next: (res: any) => {
            console.log('signed re naw', res)
            this.signed = res.data.signedTxns
          },
          error: (err: any) => {
            Swal.fire('Failed to generate transaction', err.error.message, 'error');
            console.log(err);
            // this.isLoading$$.next(false);
          },
          complete: async () => {
            try {
              const signed = this.signed

              // Call here

              // replace 'signed' with the response from the function
              // const signed = {'jjk':''}

              for (let singleTxn of signed) {
                let signature
                try {
                  signature = await this.walletservice.connection.sendRawTransaction(
                    Transaction.from(Buffer.from(singleTxn, 'base64')).serialize(),
                    { skipPreflight: false, preflightCommitment: 'confirmed' }
                  );
                } catch (e) {
                  let tx = VersionedTransaction.deserialize(Buffer.from(singleTxn, 'base64'));
                  signature = await this.walletservice.connection.sendRawTransaction(
                    tx.serialize(),
                    { skipPreflight: false, preflightCommitment: 'confirmed' }
                  );
                }

                console.log(signature);
                let response =
                  await this.walletservice.connection.confirmTransaction(
                    signature,
                    'confirmed'
                  );
                // console.log(signed);
                this.signature = signature;
              }

              callerTokenAAccount = await getAssociatedTokenAddress(
                new PublicKey(this.defaultSwapValues.tokenA),
                this.custodial_wallet_publickey
              );

              callerTokenBAccount = await getAssociatedTokenAddress(
                new PublicKey(this.defaultSwapValues.tokenB),
                this.custodial_wallet_publickey
              );


              this.refresh = setInterval(async () => {
                let a, b;
                // console.log(this.defaultSwapValues.tokenA);
                // console.log(this.defaultSwapValues.tokenB);
                if (this.defaultSwapValues.tokenA == 'So11111111111111111111111111111111111111112' || this.defaultSwapValues.tokenB == 'So11111111111111111111111111111111111111112') {
                  // console.log('here')
                  if (this.defaultSwapValues.tokenA == 'So11111111111111111111111111111111111111112') {
                    // console.log('here2');
                    a =
                      (await this.walletservice.connection.getBalance(
                        this.custodial_wallet_publickey
                      )) / 1000000000;
                    b = (
                      await this.walletservice.connection.getTokenAccountBalance(
                        callerTokenBAccount
                      )
                    ).value.uiAmount;
                  } else {
                    b =
                      (await this.walletservice.connection.getBalance(
                        this.custodial_wallet_publickey
                      )) / 1000000000;
                  }

                } else {
                  a = (
                    await this.walletservice.connection.getTokenAccountBalance(
                      callerTokenAAccount
                    )
                  ).value.uiAmount;
                  b = (
                    await this.walletservice.connection.getTokenAccountBalance(
                      callerTokenBAccount
                    )
                  ).value.uiAmount;
                }
                if (a !== this.walletservice.tokenABalance) {
                  this.walletservice.tokenABalance = a;
                  this.walletservice.tokenBBalance = b;
                  clearInterval(this.refresh);
                }
              }, 5000);

              this.swapResponseValues = { tx: this.signature };
              this.tnxlink = `${EXPLORER_ROOT}/tx/${this.swapResponseValues.tx}`;
              this.alert = `swap successfully executed! `;
              // const html = `
              //   <h4>${this.alert}</h4>
              //   <a href=${this.tnxlink} target="_blank">view transaction</a>`;
              // this.alertService.success({ html });
              Swal.fire({
                title: '<strong>Swap <u>Successful</u></strong>',
                icon: 'success',
                html: `
        <h4>${this.alert}</h4>
        <a href=${this.tnxlink} target="_blank">view transaction</a>`

              })
              this.defaultSwapValues.amountIn = 0;
              this.defaultSwapValues.amountOut = 0;
              // console.log(callerTokenAAccount);
              this.stopLoading();
              this.spinOn = false;
              this.noswap = false;
              console.log("Txid " + this.signature);
              // console.log(res);
              console.log("Done");

            } catch (error: any) {
              // this.isLoading$$.next(false);
              // state.stop();
              if (error.message == 'failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1') {
                Swal.fire('Failed', "You do not have sufficient funds to complete the payment!", 'error')
              } else {
                Swal.fire('Failed', error.message, 'error')
              }

              // this.showLoad = false
              throw new Error(error);
              // Swal.fire('Failed', error, 'error')
            }
            console.log(this.signature);
            // state.stop();
            // this.showLoad = false
            // Swal.fire('', 'Please wait while we verify your transaction', 'info')
            // this.payInfo = 'Verifying transaction'
          }
        })
        // for (let singleTxn of signed) {
        //   // await signed.forEach(async (singleTxn) => {
        //   // console.log(singleTxn)
        //   signature = await this.walletservice.connection.sendRawTransaction(
        //     singleTxn.serialize(), { 'skipPreflight': false, preflightCommitment: 'confirmed' }
        //   );
        //   console.log(signature)
        //   response = await this.walletservice.connection.confirmTransaction(
        //     signature,
        //     'confirmed'
        //   );
        //   // console.log(signed);
        //   this.signature = signature;
        // }
        // callerTokenAAccount = await getAssociatedTokenAddress(
        //   new PublicKey(this.defaultSwapValues.tokenA),
        //   this.custodial_wallet_publickey
        // );

        // callerTokenBAccount = await getAssociatedTokenAddress(
        //   new PublicKey(this.defaultSwapValues.tokenB),
        //   this.custodial_wallet_publickey
        // );


        // return res;
        // return 'paid';

      } catch (e) {
        this.stopLoading();
        this.noswap = false;
        this.spinOn = false;
        this._toastService.error(e);
        throw e;
        return;
      }

      // this.refresh = setInterval(async () => {
      //   let a, b;
      //   // console.log(this.defaultSwapValues.tokenA);
      //   // console.log(this.defaultSwapValues.tokenB);
      //   if (this.defaultSwapValues.tokenA == 'So11111111111111111111111111111111111111112' || this.defaultSwapValues.tokenB == 'So11111111111111111111111111111111111111112') {
      //     // console.log('here')
      //     if (this.defaultSwapValues.tokenA == 'So11111111111111111111111111111111111111112') {
      //       // console.log('here2');
      //       a =
      //         (await this.walletservice.connection.getBalance(
      //           this.custodial_wallet_publickey
      //         )) / 1000000000;
      //       b = (
      //         await this.walletservice.connection.getTokenAccountBalance(
      //           callerTokenBAccount
      //         )
      //       ).value.uiAmount;
      //     } else {
      //       b =
      //         (await this.walletservice.connection.getBalance(
      //           this.custodial_wallet_publickey
      //         )) / 1000000000;
      //     }

      //   } else {
      //     a = (
      //       await this.walletservice.connection.getTokenAccountBalance(
      //         callerTokenAAccount
      //       )
      //     ).value.uiAmount;
      //     b = (
      //       await this.walletservice.connection.getTokenAccountBalance(
      //         callerTokenBAccount
      //       )
      //     ).value.uiAmount;
      //   }
      //   if (a !== this.walletservice.tokenABalance) {
      //     this.walletservice.tokenABalance = a;
      //     this.walletservice.tokenBBalance = b;
      //     clearInterval(this.refresh);
      //   }
      // }, 5000);


    }
  }




  setMax() {
    this.defaultSwapValues.amountIn = Math.floor(this.walletservice.tokenABalance);
    if (
      this.custodial_wallet_publickey &&
      this.defaultSwapValues.amountIn > 0
    ) {
      this.estimate();
    }
    // this.estimate();
  }

  setHalf() {
    let a = Math.abs(this.walletservice.tokenABalance) / 2
    this.defaultSwapValues.amountIn = Math.floor(a);
    console.log(this.defaultSwapValues.amountIn)
    if (
      this.custodial_wallet_publickey &&
      this.defaultSwapValues.amountIn > 0
    ) {
      this.estimate();
    }
    // this.estimate();
  }
  setMin() {
    this.defaultSwapValues.amountIn = null;
    this.defaultSwapValues.amountOut = null;
  }

  startLoading() {
    this.loadingBar.start();
  }

  stopLoading() {
    this.loadingBar.complete();
  }
  getAuth() {
    const auth = this.settings.getAuth()
    console.log(auth)
    if (auth) {
      this.isAuthenticated = true
    } else {

      this.isAuthenticated = false
      localStorage.removeItem(this.authLocalStorageToken);
      localStorage.removeItem(this.userLocalStorageToken);
    }
  }
  login() {
    this.router.navigate(['/login'])
  }

  // getpricewid() {
  //   this.app.getGaryPrice().subscribe({
  //     next: (res: any) => {
  //       console.log(res);
  //       this.coin_symbol = res.data.coin.coin_symbol;
  //       this.garyPrice = res.data.price_usd.toFixed(4);
  //       this.rlyPrice = res.data.price.toFixed(4);
  //     },
  //   });
  // }
}



@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Associated Token Account Required!</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <p>
        You need to create an associated token account for the following tokens
        to enable you swap.
      </p>
      <ul>
        <li *ngFor="let item of neededTokens">
          <p>{{ item.title }} - {{ item.token_address }}</p>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="onClose()">
        Close
      </button>
      <button class="btn btn-primary" (click)="onClick()">
        Accept<span class="load" *ngIf="spinOn"
          ><i class="fa-solid fa-spinner fa-spin"></i
        ></span>
      </button>
    </div>
  `,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NgbdModalContentJupiter {
  // @Input() name;
  @Input() neededTokens: any;
  @Input() walletService: any;
  @Input() lo: any;
  spinOn: boolean;
  error: boolean;
  id: string;
  custodial_wallet: any;
  custodial_wallet_publickey: PublicKey;
  AtaTnx: any;
  signed: any;
  signature: any;
  // custodial_wallet_publickey: PublicKey;

  constructor(public activeModal: NgbActiveModal, private _toastService: ToastService) {
    const a = JSON.parse(localStorage.getItem('userdetails'))
    // console.log('storage re naw', a)
    this.custodial_wallet = a.custodial_wallet
    // this.custodial_wallet = 'AfGSnn8cPTH3rTSDxCGopjzQ7gPM3oLoPr4XQQaLQmDy';
    this.custodial_wallet_publickey = new PublicKey(this.custodial_wallet)
    // console.log(this.neededTokens);
    this.spinOn = false;

    console.log(this.custodial_wallet)
    // alert(this.custodial_wallet)

  }

  async onClick() {
    // console.log("HOOOOOOOO")
    this.spinOn = true;
    try {
      this.spinOn = true;
      const data = {
        sender: this.custodial_wallet,
        splToken: true,
        token_address: this.neededTokens[0].token_address,
        merchant_wallet: 'CVdSH1tJmjtfdJzpGAw5V41Rx9ovJc5FWcUGzWWyt4RU',
        provider_wallet: 'DuGjnPTVf4B84ssCqFRP12hBtNS1tucUGyzavTnwrH2F',
        merchant_amount: 0,
        provider_amount: 0,
        reference: 0,
        merchant_memo: 0,
      };
      await this.walletService.validateAta(data).subscribe({
        next: (res: any) => {
          console.log(res)
          if (res.transaction !== undefined) {
            this.AtaTnx = res.transaction
          }
        },
        error: (err) => {
          // this.showLoad = false
          console.log(err)
        },
        complete: async () => {
          let signature;
          let signedTxn = [];
          let tx = new Transaction();
          const blockHash =
            await this.walletService.connection.getLatestBlockhash();
          tx.feePayer = this.custodial_wallet;
          tx.recentBlockhash = await blockHash.blockhash;
          if (this.AtaTnx !== null && this.AtaTnx !== undefined) {
            signedTxn.push(this.AtaTnx);
          }
          console.log(signedTxn);
          // state.start();
          let data = {
            transactions: signedTxn,
          }

          await this.walletService.getSignedTnx(data).subscribe({
            next: (res: any) => {
              console.log('signed re naw', res)
              this.signed = res.data.signedTxns
            },
            error: (err: any) => {
              Swal.fire('Failed to generate transaction', err.error.message, 'error');
              console.log(err);
              // this.isLoading$$.next(false);
            },
            complete: async () => {
              try {
                const signed = this.signed

                // Call here

                // replace 'signed' with the response from the function
                // const signed = {'jjk':''}

                for (let singleTxn of signed) {
                  signature = await this.walletService.connection.sendRawTransaction(
                    Transaction.from(Buffer.from(singleTxn, 'base64')).serialize(),
                    { skipPreflight: false, preflightCommitment: 'confirmed' }
                  );
                  console.log(signature);
                  let response =
                    await this.walletService.connection.confirmTransaction(
                      signature,
                      'confirmed'
                    );
                  // console.log(signed);
                  this.signature = signature;
                }


                Swal.fire('Success', "Associated Account created successfully!", 'success')
              } catch (error: any) {
                // this.isLoading$$.next(false);
                // state.stop();
                if (error.message == 'failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1') {
                  Swal.fire('Failed', "You do not have sufficient funds to complete the payment!", 'error')
                } else {
                  Swal.fire('Failed', error.message, 'error')
                }

                // this.showLoad = false
                throw new Error(error);
                // Swal.fire('Failed', error, 'error')
              }
              console.log(this.signature);
              // state.stop();
              // this.showLoad = false
              // Swal.fire('', 'Please wait while we verify your transaction', 'info')
              // this.payInfo = 'Verifying transaction'
            }
          })

          // this.findTrans()


        },
      })
      // this._toastService.success('Associated Account created successfully');

    } catch (error) {
      this.spinOn = false;
      this.lo.complete();
      Swal.fire('Failed', error.message, 'error');
      return;
    }
    if (this.neededTokens.length > 1) {
      try {
        this.spinOn = true;
        // await getOrCreateAssociatedTokenAccountCustodial(
        //   this.walletService.connection,
        //   this.custodial_wallet_publickey,
        //   new PublicKey(this.neededTokens[1].token_address),
        //   this.custodial_wallet_publickey,
        //   this.walletService.wallet.signTransaction
        // );
        const data = {
          sender: this.custodial_wallet,
          splToken: true,
          token_address: this.neededTokens[1].token_address,
          merchant_wallet: 'CVdSH1tJmjtfdJzpGAw5V41Rx9ovJc5FWcUGzWWyt4RU',
          provider_wallet: 'DuGjnPTVf4B84ssCqFRP12hBtNS1tucUGyzavTnwrH2F',
          merchant_amount: 0,
          provider_amount: 0,
          reference: 0,
          merchant_memo: 0,
        };
        await this.walletService.validateAta(data).subscribe({
          next: (res: any) => {
            console.log(res)
            if (res.transaction !== undefined) {
              this.AtaTnx = res.transaction
            }
          },
          error: (err) => {
            // this.showLoad = false
            console.log(err)
          },
          complete: async () => {
            let signature;
            let signedTxn = [];
            let tx = new Transaction();
            const blockHash =
              await this.walletService.connection.getLatestBlockhash();
            tx.feePayer = this.custodial_wallet;
            tx.recentBlockhash = await blockHash.blockhash;
            if (this.AtaTnx !== null && this.AtaTnx !== undefined) {
              signedTxn.push(this.AtaTnx);
            }
            console.log(signedTxn);
            // state.start();
            let data = {
              transactions: signedTxn,
            }

            await this.walletService.getSignedTnx(data).subscribe({
              next: (res: any) => {
                console.log('signed re naw', res)
                this.signed = res.data.signedTxns
              },
              error: (err: any) => {
                Swal.fire('Failed to generate transaction', err.error.message, 'error');
                console.log(err);
                // this.isLoading$$.next(false);
              },
              complete: async () => {
                try {
                  const signed = this.signed

                  // Call here

                  // replace 'signed' with the response from the function
                  // const signed = {'jjk':''}

                  for (let singleTxn of signed) {
                    signature = await this.walletService.connection.sendRawTransaction(
                      Transaction.from(Buffer.from(singleTxn, 'base64')).serialize(),
                      { skipPreflight: false, preflightCommitment: 'confirmed' }
                    );
                    console.log(signature);
                    let response =
                      await this.walletService.connection.confirmTransaction(
                        signature,
                        'confirmed'
                      );
                    // console.log(signed);
                    this.signature = signature;
                  }

                } catch (error: any) {
                  // this.isLoading$$.next(false);
                  // state.stop();
                  if (error.message == 'failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1') {
                    Swal.fire('Failed', "You do not have sufficient funds to complete the payment!", 'error')
                  } else {
                    Swal.fire('Failed', error.message, 'error')
                  }

                  // this.showLoad = false
                  throw new Error(error);
                  // Swal.fire('Failed', error, 'error')
                }
                console.log(this.signature);
                // state.stop();
                // this.showLoad = false
                // Swal.fire('', 'Please wait while we verify your transaction', 'info')
                // this.payInfo = 'Verifying transaction'
              }
            })

            // this.findTrans()


          },
        })
        // this._toastService.success('Associated Account created successfully');
        Swal.fire('Success', "Associated Account created successfully!", 'success')
        this.spinOn = false;
        this.lo.complete();
        this.activeModal.dismiss();
      } catch (error) {
        this.spinOn = false;
        this.lo.complete();
        // this._toastService.error(error);
        Swal.fire('Failed', error.message, 'error');
        return;
      }
    } else {
      this.spinOn = false;
      this.lo.complete();
      this.activeModal.dismiss();
    }

  }

  onClose() {
    this.lo.complete();
    this.activeModal.close('Close click');
  }




}
