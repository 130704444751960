import { AppServiceService } from 'src/app/_services/app-service.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

enum ErrorStates {
  NotSubmitted,
  HasError,
  NoError,
}
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  errorState: ErrorStates = ErrorStates.NotSubmitted;
  errorStates = ErrorStates;
  isLoading$: Observable<boolean>;
  theme: string;
  constructor(
    private fb: FormBuilder,
    private settings: AppServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getTheme()
    this.initForm();
  }

  getTheme() {
    if (localStorage.getItem('theme') == null) {
      this.theme = 'dark-theme';
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    } else {
      this.theme = localStorage.getItem('theme') || 'light';
    }

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  initForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
    });
  }

  submit() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton:
          'btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger',
      },
      buttonsStyling: false,
    });
    this.errorState = ErrorStates.NotSubmitted;
    localStorage.setItem('email', this.f.email.value);
    const forgotPasswordSubscr = this.settings
      .forgotPassword(this.f.email.value)
      .pipe(first())
      .subscribe({
        next: (result: boolean) => {
          this.errorState = result ? ErrorStates.NoError : ErrorStates.HasError;
        },
        error: (err: any) => {
          Swal.fire('Failed!', err.error.message, 'error')
        },
        complete: () => {
          Swal.fire('', 'A reset code has been sent to your email', 'info');
          // this.unsubscribe.push(forgotPasswordSubscr);
          this.router.navigateByUrl('/reset_password');
        },
      });
    // .subscribe((result: boolean) => {
    //   this.errorState = result ? ErrorStates.NoError : ErrorStates.HasError;
    // });
  }

}
