<div class="row  g-0" *ngIf="marquee2.length > 0">
  <div class="col-md-2">
    <div class="trending d-flex align-items-center justify-content-center">
      <div class="ms-5 me-3"><i class="fa-solid fa-fire txt-white fa-2x"></i></div>
      <h3>Trending</h3>
    </div>
  </div>
  <div class="col-md-10 scroll">

    <div class="marquee">
      <ul class="list-unstyled" id="list">
        <li *ngFor="let item of marquee2">
          <div class="slide">
            <a [href]="['/tokens/'+item?.coin_symbol]">
              <div class="inline">
                <div class="img">
                  <img [src]="item?.coin_image" alt="" srcset="">
                </div>
                <div class="block">

                  <h4> {{ item?.coin_symbol }} </h4>

                  <p *ngIf="item?.percentageChange > 0" class="text-success"> {{ item?.percentageChange | number:
                    '1.0-2'
                    }}% </p>
                  <p *ngIf="item?.percentageChange < 0" class="text-danger"> {{ item?.percentageChange | number: '1.0-2'
                    }}%
                  </p>
                  <p *ngIf="item?.percentageChange == 0"> {{ item?.percentageChange | number: '1.0-2' }}% </p>
                </div>
              </div>
            </a>
          </div>
        </li>

      </ul>
    </div>



  </div>

</div>



<div class="container-fluid">
  <div class="row mt-5 align-items-center">
    <div class="col-md-8">
      <h3>Eco Stats</h3>
    </div>
    <div class="col-md-4 text-end">

      <div class="form-check form-switch d-flex align-items-center justify-content-md-end">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
          [(ngModel)]="isChecked" name="statsDisplay" (ngModelChange)="checkStats()">
        <label class="form-check-label" for="flexSwitchCheckDefault">Hide stats</label>
      </div>

    </div>
  </div>


  <div class="row mt-5" *ngIf="showStats">
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="my-3 mx-5 text-end">
          <p class="text-muted mb-0">24h</p>
        </div>
        <div class="card-text">
          <h1>{{ analytics?.holder?.value_holder }}</h1>
          <p>Total Holders</p>
        </div>

        <div class="my-3 mx-5 text-end">
          <p *ngIf="analytics?.holder?.percentage_change > 0" class="text-success mb-0">
            {{analytics?.holder?.percentage_change}}% <i class="fa-solid fa-arrow-up"></i></p>
          <p *ngIf="analytics?.holder?.percentage_change < 0" class="text-danger mb-0">
            {{analytics?.holder?.percentage_change}}% <i class="fa-solid fa-arrow-down"></i></p>
          <p *ngIf="analytics?.holder?.percentage_change == 0" class=" mb-0">
            {{analytics?.holder?.percentage_change}}% <i class="fa-solid fa-minus"></i></p>
        </div>

      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="my-3 mx-5 text-end">
          <p class="text-muted mb-0">24h</p>
        </div>
        <div class="card-text">
          <h1> {{ analytics?.gary_locked?.value_locked }} </h1>
          <p>Total $GARY Locked</p>
        </div>

        <div class="my-3 mx-5 text-end">
          <p *ngIf="analytics?.gary_locked?.percentage_change > 0" class="text-success mb-0">
            {{analytics?.gary_locked?.percentage_change}}% <i class="fa-solid fa-arrow-up"></i></p>
          <p *ngIf="analytics?.gary_locked?.percentage_change < 0" class="text-danger mb-0">
            {{analytics?.gary_locked?.percentage_change}}% <i class="fa-solid fa-arrow-down"></i></p>
          <p *ngIf="analytics?.gary_locked?.percentage_change == 0" class=" mb-0">
            {{analytics?.gary_locked?.percentage_change}}% <i class="fa-solid fa-minus"></i></p>
        </div>

      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="my-3 mx-5 text-end">
          <p class="text-muted mb-0">24h</p>
        </div>
        <div class="card-text">
          <h1>${{ analytics?.locked?.value_locked }}</h1>
          <p>Total Value Locked</p>
        </div>

        <div class="my-3 mx-5 text-end">
          <p *ngIf="analytics?.locked?.percentage_change > 0" class="text-success mb-0">
            {{analytics?.locked?.percentage_change}}% <i class="fa-solid fa-arrow-up"></i></p>
          <p *ngIf="analytics?.locked?.percentage_change < 0" class="text-danger mb-0">
            {{analytics?.locked?.percentage_change}}% <i class="fa-solid fa-arrow-down"></i></p>
          <p *ngIf="analytics?.locked?.percentage_change == 0" class=" mb-0">
            {{analytics?.locked?.percentage_change}}% <i class="fa-solid fa-minus"></i></p>
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-5 align-items-center">
    <div class="col-md-8">
      <h3>Social Tokens</h3>
    </div>
    <div class="col-md-4 text-end">
      <div class="input-group">
        <input type="search" name="userFilter" class="form-control" placeholder="Search..."
          [(ngModel)]="userFilter.coin_symbol">
        <span class="input-group-text" id="basic-addon1"><i
            class="fa-solid fa-magnifying-glass text-primary"></i></span>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <div class="table-responsive">
      <div class="overlay" style="height: 400px;" *ngIf="loading">
      </div>
      <table class="table table-hover align-middle">
        <thead>
          <tr>
            <th class="ps-5 py-3" (click)="sort('coin_symbol')" style="cursor: pointer;">Coin
              &nbsp;
              <span *ngIf="order == 'coin_symbol'" class="text-primary">
                <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
              </span>
            </th>
            <th class="py-3" (click)="sort('token_price_usd')" style="cursor: pointer;"> Price
              &nbsp;
              <span *ngIf="order == 'token_price_usd'" class="text-primary">
                <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
              </span>
            </th>
            <th class="py-3" (click)="sort('percentage_change')" style="cursor: pointer;"> 24h%
              &nbsp;
              <span *ngIf="order == 'percentage_change'" class="text-primary">
                <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
              </span>
            </th>
            <th class="py-3" (click)="sort('percentage_change_7_days')" style="cursor: pointer;">7d%&nbsp;
              <span *ngIf="order == 'percentage_change_7_days'" class="text-primary">
                <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
              </span>
            </th>
            <th class="py-3" (click)="sort('gary_locked')" style="cursor: pointer;">$GARY Locked&nbsp;
              <span *ngIf="order == 'gary_locked'" class="text-primary">
                <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
              </span>
            </th>
            <!-- <th class="py-3" (click)="sort('score')" style="cursor: pointer;">Score &nbsp;
              <span *ngIf="order == 'score'" class="text-primary">
                <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
              </span>
            </th> -->
            <th class="py-3" (click)="sort('total_holders')" style="cursor: pointer;">Supporters&nbsp;
              <span *ngIf="order == 'total_holders'" class="text-primary">
                <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
              </span>
            </th>
            <th class="py-3" style="width: 20% !important;">Community</th>
            <th class="py-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of tokenLists| orderBy: order: reverse| filterBy: userFilter  | paginate: { itemsPerPage: perPage, currentPage: 1}">
            <td class="ps-5" style="min-width: 200px !important;">
              <a [routerLink]="['/tokens/'+item?.coin_symbol]" routerLinkActive="router-link-active">
                <span class="me-2">
                  <img [src]="item?.coin_image" class="img-fluid" alt="" width="30" height="30" class="border-radius">
                </span>
                <span>{{ item?.coin_symbol }} </span>
              </a>
            </td>
            <td> ${{ item?.token_price_usd| number:'1.0-4' }} </td>
            <td>
              <span *ngIf="item?.percentage_change > 0" class="text-success">{{item?.percentage_change}}%</span>
              <span *ngIf="item?.percentage_change < 0" class="text-danger">{{item?.percentage_change}}%</span>
              <span *ngIf="item?.percentage_change == 0">{{item?.percentage_change}}%</span>
            </td>
            <!-- <td>
              <span *ngIf="item?.percentage_change_3_days > 0"
                class="text-success">{{item?.percentage_change_3_days}}%</span>
              <span *ngIf="item?.percentage_change_3_days < 0"
                class="text-danger">{{item?.percentage_change_3_days}}%</span>
              <span *ngIf="item?.percentage_change_3_days == 0">{{item?.percentage_change_3_days}}%</span>
            </td> -->
            <td>
              <span *ngIf="item?.percentage_change_7_days > 0"
                class="text-success">{{item?.percentage_change_7_days}}%</span>
              <span *ngIf="item?.percentage_change_7_days < 0"
                class="text-danger">{{item?.percentage_change_7_days}}%</span>
              <span *ngIf="item?.percentage_change_7_days == 0">{{item?.percentage_change_7_days}}%</span>
            </td>
            <td>
              <span>
                {{ item?.gary_locked | number: '1.2-2' }}
              </span>
              <span *ngIf="item?.badge_eligible === 'yes'" class="">
                <img src="assets/imgs/badge_locked.png" width="20" alt="" style="vertical-align: sub !important;">
              </span>
            </td>
            <!-- <td>
              {{ item?.score | number: '0.0-2' }}
            </td> -->
            <td>
              {{ item?.total_holders }}
            </td>
            <td style="width: 20% !important;">
              <ngb-accordion #acc="ngbAccordion">
                <ngb-panel>
                  <ng-template ngbPanelHeader let-opened="opened">
                    <button ngbPanelToggle class="btn btn-clear p-0">
                      <span class="me-2"><i class="fa-solid fa-globe"></i></span>
                      <span> {{ opened ? 'Hide' : 'View' }} Links</span>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent class="ps-0">
                    <a class="btn btn-clear me-1 ps-0" *ngIf="item?.links?.facebook_url"
                      [href]="item?.links?.facebook_url" target="_blank" placement="bottom" ngbTooltip="Facebook">
                      <i class="fa-brands fa-facebook "></i>
                    </a>
                    <a class="btn  btn-clear me-1" *ngIf="item?.links?.twitter_url" [href]="item?.links?.twitter_url"
                      target="_blank" placement="bottom" ngbTooltip="Twitter">
                      <i class="fa-brands fa-twitter "></i>
                    </a>
                    <a class="btn  btn-clear me-1" *ngIf="item?.links?.instagram_url"
                      [href]="item?.links?.instagram_url" target="_blank" placement="bottom" ngbTooltip="Instagram">
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                    <a class="btn  btn-clear me-1" *ngIf="item?.links?.discord_url" [href]="item?.links?.discord_url"
                      target="_blank" placement="bottom" ngbTooltip="Discord">
                      <i class="fa-brands fa-discord"></i>
                    </a>
                    <a class="btn  btn-clear me-1" *ngIf="item?.links?.youtube_url" [href]="item?.links?.youtube_url"
                      target="_blank" placement="bottom" ngbTooltip="Youtube">
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a class="btn  btn-clear me-1" *ngIf="item?.links?.website_url" [href]="item?.links?.website_url"
                      target="_blank" placement="bottom" ngbTooltip="Website">
                      <i class="fa-solid fa-globe"></i>
                    </a>
                    <a class="btn  btn-clear me-1" *ngIf="item?.links?.external_url" [href]="item?.links?.external_url"
                      target="_blank" placement="bottom" ngbTooltip="Website">
                      <i class="fa-solid fa-globe"></i>
                    </a>
                  </ng-template>

                </ngb-panel>

              </ngb-accordion>


            </td>
            <td>
              <button class="btn btn-outline-dark rounded-pill me-3" (click)="onClick(item?.coin_symbol)">SWAP</button>
              <!-- <button class="btn btn-outline-dark rounded-pill " *ngIf="item?.has_offers"
                (click)="open(content, item)">BUY</button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="text-end mt-3 " *ngIf="!loading">
    <button *ngIf="!hideLoad" class="btn btn-dark rounded-pill py-2 px-3" (click)="getMoreTokens()">Load More <i
        class="fa-solid fa-chevron-down"></i></button>
    <button *ngIf="hideLoad" class="btn btn-dark rounded-pill py-2 px-3" (click)="loadLess()">Show Less <i
        class="fa-solid fa-chevron-up"></i></button>
  </div>


  <!-- <br><br><br><br><br><br><br><br><br><br> -->
</div>


<ng-template #content let-modal>
  <div class="modal-header text-center">
    <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
    <div class="d-flex  align-items-center justify-content-center text-center">
      <div class="me-3">
        <img [src]="token.coin_image" width="50" height="50" class="rounded-circle" alt="" srcset="">
      </div>
      <h5 class="mb-0">{{ token.coin_symbol }} </h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-center" *ngIf="offers.length > 0">
    <h4 class="mdl">
      Quantity of token
    </h4>

    <ul class="list-unstyled mt-4" *ngFor="let item of filteredoffers | orderBy: order3">
      <li class="mb-3">
        <a [href]="item?.link" target="_blank" class="btn btn-outline-primary btn-block py-3 monument">{{ item?.amount
          }}
          {{ token.coin_symbol }}</a>
      </li>
      <!-- <li class="mb-3">
        <button class="btn btn-outline-primary btn-block py-3 monument">100 $ELEVATE</button>
      </li>
      <li>
        <button class="btn btn-outline-primary btn-block py-3 monument">200 $ELEVATE</button>
      </li> -->
    </ul>
  </div>
  <div class="modal-body text-center" *ngIf="offers.length <= 0">
    <h4>There are no offers at this time</h4>
    <h4>Please try later</h4>
  </div>
  <div class="modal-footer text-center justify-content-center" *ngIf="offers.length > 0">
    <p>You are heading to an external site!</p>
  </div>
</ng-template>