import { Component, OnInit, ChangeDetectorRef, ViewChild, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { UserType } from 'src/app/modules/auth';
import { first } from 'rxjs/operators';
import { EventService } from '../../../_services/events.service';
import Swal from 'sweetalert2';
import { CompleteregService } from '../../../_services/completereg.service';
import { ModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';
import { AppServiceService, UserType } from 'src/app/_services/app-service.service';
import { ModalDismissReasons, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';


class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-completereg',
  templateUrl: './completereg.component.html',
  styleUrls: ['./completereg.component.css']
})


export class CompleteregComponent implements OnInit {
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public walletModal: boolean = false;
  isLoading$: Observable<boolean>;
  hasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private unsubscribe: Subscription[] = [];
  isLoading: boolean;
  isLoadingMon: boolean;
  public userDetails: any = "";
  xLinked: boolean = false;
  twitterActive: boolean;
  walletUploaded: boolean = false;
  profileUploaded: boolean = false;
  monetizationActivated: boolean = false;
  stake_status: boolean = false;
  continueToDashoard: boolean = false;
  disableMonetization: boolean = false;
  modalRef: NgbModalRef;
  stakeDetForm: FormGroup;
  perksForm: FormGroup;
  stake_details: any;
  twitterlink: any;
  status: any;
  perks: any;
  id: any;
  html: '';
  actStatus: any;
  imgFile: any;
  userData: any;
  minForm: any;
  test: any;
  message: any;
  socialForm: any;
  solBalance: any;
  totalBalance: any;
  solPrice: any;
  custodian_wallet: any = [];
  selectedFile: ImageSnippet;

  user$: Observable<UserType>;

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.totalBalance) {
    //   this.walletUploaded = true
    // }
  }

  @ViewChild(ModalComponent) modalPopup: ModalComponent;


  constructor(
    private cdr: ChangeDetectorRef,
    private settings: AppServiceService,
    private evtSvc: EventService,
    private service: CompleteregService,
    private modalService: NgbModal,
    private router: Router,
    config: NgbModalConfig,
  ) { }

  ngOnInit() {
    this.user$ = this.settings.getUserDetails();
    // console.log('user', this.user$)
    this.stake_status = JSON.parse(localStorage.getItem('stake_status'))
    console.log(this.stake_status);
    this.getWalletBlances();
    this.getSolBalanceMain();
    this.getStakeStatus();

    this.user$.subscribe((_user) => {
      // console.log('user', _user)
      if (_user?.sc_profile_picture !== '') {
        this.profileUploaded = true
      }
      if (this.solBalance >= 0.05) {
        this.walletUploaded = true
      }
      if (_user?.twitter_username
      ) {
        this.xLinked = true
      }
      if (this.stake_status) {
        this.monetizationActivated = true
      }
      if ((this.profileUploaded && this.walletUploaded) && (this.xLinked && this.monetizationActivated)) {
        this.continueToDashoard = true
      }
      if (!this.profileUploaded || !this.walletUploaded) {
        this.disableMonetization = true
      }
    })

    this.userData = JSON.parse(localStorage.getItem('userdetails'))
    this.getUser();
    // this.checkStatus()
  }

  loadWallet() {
    this.walletModal = !this.walletModal
    console.log(this.walletModal)
  }
  continue() {
    localStorage.setItem("onboarding_process", "completed");
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ',
        cancelButton:
          'btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Your account is succesfully setup',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'View Gary.club Profile',
        cancelButtonText: 'Continue to Social Connector',
        reverseButtons: true,
      })
      .then((result) => {
        // this.router.navigateByUrl('/sc/account/settings');
        if (result.isConfirmed) {
          this.user$.subscribe((_user) => {
            const url = 'https://gary.club/' + _user.social_tag;
            window.open(url, '_blank');
            this.router.navigateByUrl('/dashboard');
          });
        } else {
          this.router.navigateByUrl('/dashboard');
        }
      });
  }

  skip() {
    localStorage.setItem("onboarding_process", "completed");
    this.router.navigateByUrl('/home');

  }

  getSolBalanceMain() {
    this.settings.getSolBalance().subscribe({
      next: (res: any) => {
        console.log(res)
        this.solBalance = res.data.balance
        if (this.solBalance >= 0.05) {
          this.walletUploaded = true
          if (this.profileUploaded && this.walletUploaded) {
            this.disableMonetization = false
          }
        } else {
          setTimeout(() => {
            this.getSolBalanceMain()
          }, 10000);
        }
      },
      error: (err: any) => { },
      complete: () => { }
    })

  }
  getStakeStatus() {
    this.service.checkStakeStatus().subscribe({
      next: (res: any) => {
        let status = res.data
        if (status?.activated_stake) {
          this.monetizationActivated = true;
          this.continueToDashoard = true;
        }



      },
      error: (err: any) => {
        // console.log(err)
      }
    })
  }
  getWalletBlances() {
    this.settings.getUserWalletBalances().subscribe({
      next: (res: any) => {
        console.log(res)
        let data = res.data
        this.custodian_wallet = data
        // console.log(this.custodian_wallet)
      },
      error: (err: any) => { },
      complete: () => {
        let totalBalanceUsd = 0;

        for (let i = 0; i < this.custodian_wallet.length; i++) {
          totalBalanceUsd += this.custodian_wallet[i].balance_usd;
        }
        this.totalBalance = totalBalanceUsd
        this.settings.getSolBalance().subscribe({
          next: (res: any) => {
            console.log(res)
            this.solBalance = res.data.balance
            if (this.solBalance >= 0.05) {
              this.walletUploaded = true
              if (this.profileUploaded && this.walletUploaded) {
                this.disableMonetization = false
              }
            } else {

            }
            this.settings.getSolPrice().subscribe({
              next: (res: any) => {
                this.solPrice = res?.data?.price;
                this.totalBalance += this.solBalance * this.solPrice
              },
              error: (err: any) => { },
              complete: () => { }
            })
          },
          error: (err: any) => { },
          complete: () => { }
        })

      }
    })
  }


  getUser() {
    const b = setInterval(() => {
      const a = this.settings.getUser();
      if (a !== undefined) {
        this.isLoading$$.next(false);
        clearInterval(b);
      }
      // console.log(a);
      this.userDetails = a;
      if (
        !this.userDetails?.twitter_username
      ) {
        this.twitterActive = false;
      } else {
        this.twitterActive = true;
        this.xLinked = true
      }
      this.imgFile = this.userDetails?.sc_profile_picture;
      let social_media_links = this.userDetails?.social_media_links
      social_media_links?.forEach(link => {
        const platformKey = link.platform.toLowerCase().replace(' ', '');
        // Convert platform to lowercase and remove spaces
        if (this.socialForm.controls[platformKey]) {
          this.socialForm.controls[platformKey].setValue(link.link);
        }
      });
    }, 1000);
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event: any) => {

      this.selectedFile = new ImageSnippet(_event.target.result, file);
      this.imgFile = reader.result;
      this.cdr.detectChanges();
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn  btn-outline-danger',
        },
        buttonsStyling: false,
      });
      this.user$.subscribe((_user) => {
        const name = _user?.name;
        const bio = _user?.bio;
        console.log(name, bio);

        if (this.selectedFile === undefined || this.selectedFile === null) {
          this.test = this.imgFile;
        } else {
          this.test = this.selectedFile.file;
        }
        const profile_picture = this.test;
        this.settings
          .updateProfile(profile_picture, name, bio)
          .pipe(first())
          .subscribe({
            next: (res: any) => {
              if (res.success === true) {
              }
            }, complete: () => {
              let mssg = 'Changes made successfully';
              this.profileUploaded = true
              this.settings.getUserDetails().subscribe((rs) => {
                this.evtSvc.emitChildEvent('true');
                this.getUser();
              });
              this.isLoading$$.next(false);
              this.cdr.detectChanges();
            },
            error: (err: any) => {
              this.message = err.error.message;
              this.cdr.detectChanges();
              if (err.error.errors?.name) {
                swalWithBootstrapButtons.fire(
                  'Failed!',
                  err.error.errors?.name[0],
                  'error'
                );
              } else if (err.error.errors?.profile_picture) {
                swalWithBootstrapButtons.fire(
                  'Failed!',
                  err.error.errors?.profile_picture[0],
                  'error'
                );
              } else {
                swalWithBootstrapButtons.fire(
                  'Failed!',
                  err.error.message,
                  'error'
                );
              }
              this.isLoading = false;
              this.isLoading$$.next(false);
            },
          });
      });
    }
    this.cdr.reattach();
  }

  linkTwitter() {
    this.isLoading = true
    const detailsSubscr = this.settings.linkTwitter().subscribe({
      next: (res: any) => {
        if (res.success === true) {
          this.twitterlink = res.data.link;
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.href = this.twitterlink;
          a.click();
          document.body.removeChild(a);
          this.isLoading = false
          this.xLinked = true
        }
      },

      error: (err: any) => {
        this.isLoading$$.next(false);
        this.isLoading = false;
        this.message = err.error.message;
        this.hasError.next(true);
      },
    });
    this.unsubscribe.push(detailsSubscr);
  }

  checkStatus() {
    this.service.checkStakeStatus().subscribe({
      next: (res: any) => {
        console.log('stake status', res)
        this.status = res.data
        localStorage.setItem('stake status', JSON.stringify(this.status.activated_stake))
        if (this.status.activated_stake === true) {
          this.listPerks()
        }

        this.stake_details = res.data?.stake_details
        this.stakeDetForm.controls.royalty.setValue(this.stake_details?.royalty)
        this.stakeDetForm.controls.telegram_group_id.setValue(this.stake_details?.telegram_group_id)
        this.stakeDetForm.controls.welcome_email.setValue(this.stake_details?.welcome_email)
        this.html = this.stake_details?.welcome_email;
        if (this.stake_details?.fee_payer == 'client') {
          this.stakeDetForm.controls.fee_payer.setValue(false)

        }
        if (this.stake_details?.fee_payer == 'merchant') {
          this.stakeDetForm.controls.fee_payer.setValue(true)

        }

        if (this.stake_details?.buy_status == 'active') {
          this.stakeDetForm.controls.purchase_status.setValue(true)
        }

        if (this.stake_details?.stake_status == 'active') {
          this.stakeDetForm.controls.stake_status.setValue(true)
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  listPerks() {
    this.service.listPerks().subscribe({
      next: (res: any) => {
        // Swal.fire('Success', res.message, 'success')
        this.perks = res.data
      },
      error: (err: any) => {
        // Swal.fire('Failed', err.error.message, 'error')
        console.log(err)
      },
    })
  }


  checkActivation() {
    this.service.checkActivationStatus().subscribe({
      next: (res: any) => {
        this.actStatus = res.data.status
        if (this.actStatus === 'completed') {
          console.log('success', this.actStatus)
          // clearInterval(this.refresh)
          this.modalRef.dismiss()
          this.monetizationActivated = true
          this.continueToDashoard = true;
          // this.checkStatus()
        }
        else if (this.actStatus === "failed") {
          console.log('failed', this.actStatus)
          this.modalRef.dismiss()
          // clearInterval(this.refresh)
          Swal.fire('Failed to activate Token', '', 'error')
        }
        else {
          setTimeout(() => {
            console.log('pending', this.actStatus)
            this.checkActivation()
          }, 5000);

        }

      },
      error: (err: any) => {
        Swal.fire('Failed', err.error.message, 'error')
        this.modalRef.dismiss()
        console.log(err)

      }
    })
  }

  activate() {
    this.modalRef = this.modalService.open(LoaderModalComponent, { centered: true });
    this.isLoadingMon = true
    this.service.activateStake().subscribe({
      next: (res: any) => {
        // Swal.fire(res.data, '', 'success')

        this.isLoadingMon = false
      },
      error: (err: any) => {
        this.modalRef.dismiss()
        Swal.fire('Failed', err.error.message, 'error')
      },
      complete: () => {
        this.checkActivation()
      }
    })
  }

}

@Component({
  selector: 'app-loader-modal',
  template: `
  <div class="modal-body modal-center p-10 rounded-5">
    <div class="d-flex align-items-center">
  <h3>Activating Token...</h3>
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl ms-auto"></i>
    <p>This process may take a couple of minutes</p>
    </div>

  <!-- <br>
  <div class="text-center mt-3">
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl"></i>
  </div> -->
</div>
  `,
  styles: [
    `
     .text.active {
   background-color: var(--bs-primary) !important;
   color: var(--bs-text-secondary) !important;

 }

 .text {
   border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
   border-radius: 50%;
 }
    `
  ]
})

export class LoaderModalComponent implements OnInit {
  constructor(private modalService: NgbModal, config: NgbModalConfig,) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;
  }

  ngOnInit(): void {
  }

  closeModal(modalRef: NgbModalRef): void {
    modalRef.dismiss();
  }



}
