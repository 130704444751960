import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pow-item',
  templateUrl: './pow-item.component.html',
  styleUrls: ['./pow-item.component.scss']
})
export class PowItemComponent implements OnInit {
  @Input() item: any; // Define an Input property to receive data from the parent component
  @Input() app: any;
  @Input() user: any;
  constructor() { }

  ngOnInit(): void {
  }

}
