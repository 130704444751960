import { Component, OnInit } from '@angular/core';
import { POWService } from './pow.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AppServiceService } from '../_services/app-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pow',
  templateUrl: './pow.component.html',
  styleUrls: ['./pow.component.scss']
})
export class PowComponents implements OnInit {
  allPowconfigs: any;
  size: any;
  filledPOW: any;
  filledToday: any;
  hour: string;
  mins: string;
  seconds: string;
  powForm: FormGroup
  work_summaryForm: FormGroup;
  showSwitchCustom: boolean;
  resdata: any;
  users: any;
  constructor(
    private powService: POWService,
    private fb: FormBuilder,
    private service: AppServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getUser()
    this.getStoredPOW();
    this.countdown();
    this.initForm()
    setInterval(() => {
      this.countdown();
    }, 1000);
  }

  async getUser() {
    const a = await this.service.getUser()
    this.users = a
    console.log('Userdetails', this.users)


  }
  getStoredPOW() {
    this.powService.getUserPow().subscribe({
      next: (res: any) => {
        this.allPowconfigs = res;
        console.log('configs', res)
        this.size = this.allPowconfigs.length
      }, complete: () => {
        this.powService.getConfigPOW(this.allPowconfigs[0].hash).subscribe({
          next: (res: any) => {
            console.log(res);
            this.filledPOW = res

            // console.log(this.filledPOW[0])
          },
          complete: () => {
            const today = new Date();
            const todayUTCPlus1 = new Date(today.getTime() + (1 * 60 * 60 * 1000)); // Adding 1 hour to current time for UTC+1
            const todayUTCPlus1Date = todayUTCPlus1.toISOString().split('T')[0]; // Extracting only the date portion

            const bool = this.filledPOW.some(item => {
              const itemDate = item.date_added.split(' ')[0]; // Extracting only the date portion from the item's date_added
              return itemDate === todayUTCPlus1Date;
            });
            this.filledToday = bool

            // console.log(bool)
          }
        })
      }
    })
  }
  initForm() {
    this.powForm = this.fb.group({
      make_money: ['', Validators.compose([Validators.required])],
      grow_audience: ['', Validators.compose([Validators.required])],
      intensity: ['', Validators.compose([Validators.required])],
      // proof_hash: ['', Validators.compose([Validators.required])],
    });
    this.work_summaryForm = this.fb.group({
      names: this.fb.array([this.add_custom()]),
    });
  }

  countdown() {
    let targetTime = new Date();
    targetTime.setUTCHours(23, 0, 0, 0);

    const now = new Date();

    // Check if the target time has passed
    if (now >= targetTime) {
      // If it has, add one day to the target time and reset the countdown
      targetTime = new Date(targetTime.getTime() + 24 * 60 * 60 * 1000);
    }

    // Calculate the difference in milliseconds between now and the target time
    const timeDiff = targetTime.getTime() - now.getTime();

    // Calculate the remaining hours, minutes, and seconds
    const remainingHours = Math.floor(timeDiff / (1000 * 60 * 60)).toString().padStart(2, '0');
    const remainingMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const remainingSeconds = Math.floor((timeDiff % (1000 * 60)) / 1000).toString().padStart(2, '0');

    this.hour = remainingHours;
    this.mins = remainingMinutes;
    this.seconds = remainingSeconds;
  }
  resetForm() {
    this.powForm.reset({
      make_money: null,
      grow_audience: null,
      intensity: null,
      proof_hash: null,
      work_summary: []
    });
    this.names.clear();
  }

  get names(): FormArray {
    return <FormArray>this.work_summaryForm.get('names');
  }
  add_custom() {
    return this.fb.group({
      name: [],
    });
  }
  addFields() {
    this.names.push(this.add_custom());
  }
  removeCustom(index: any) {
    this.names.removeAt(index);
  }
  toggleCustomForm(show: boolean) {
    this.showSwitchCustom = show;
  }
  submit() {
    let customs;
    customs = this.names?.value.map(function (item: any) {
      return item['name'];
    });
    for (let i = 0; i < customs.length; i++) {
      const element = customs[i];
      // console.log(element)
      if (!element) {
        customs.splice(customs.indexOf(element), 1);
        // customs.filter((item: any) => item !== element)
      }
    }
    let payload = this.powForm.value;
    payload.proof_hash = this.allPowconfigs[0].hash
    payload.work_summary = customs;
    console.log('payload ', payload)

    this.powService.saveToPOWConfig(payload).subscribe({
      next: (res: any) => {
        this.resetForm();
        Swal.fire('Success', res.message, 'success')
        console.log(res)
        this.resdata = res.data
      },
      error: (err: any) => {
        console.log(err)
        Swal.fire('Failed!', err.error.message, 'error')
        // this.resetForm()
      },
      complete: () => {
        this.router.navigateByUrl('/' + this.users.social_tag)
        this.resetForm()
      }
    })
  }

}
