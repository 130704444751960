<div class="container pt-5">
  <div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="row mb-4">
          <div class="col-md-8 col-3">
            <div class="row">
              <div class="col-md-2">
                <img [src]="user?.sc_profile_picture" (click)="goToProfile(user?.social_tag)" alt=""
                  class="img-fluid prof-img">
              </div>
              <div class="col-md-10 d-none d-md-block pt-3">
                <h4 (click)="goToProfile(user?.social_tag)">
                  {{ getGreeting() }} {{ user?.social_tag }}
                </h4>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-9">
            <button class="btn btn-primary create-post float-right" (click)="createModal(content)">Create
              Post</button>
          </div>
        </div>
        <div class="row" *ngIf="posts$ | async as posts" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50" (scrolled)="loadMoreProducts(posts)">
          <div class="col-md-12 mb-4" *ngFor="let post of feeds; trackBy: trackByFunction">
            <div class="card pt-4 post-card">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-1 col-3">
                        <img [src]="post?.user?.sc_profile_picture" alt=""
                          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" class="img-fluid prof-img"
                          style="height: 34px !important; width: 34px !important;"
                          (click)="goToProfile(post?.user?.social_tag)">
                      </div>
                      <div class="col-md-11 col-9">
                        <div class="row">
                          <div class="col-md-6 col-6">
                            <h5 class="name" (click)="goToProfile(post?.user?.social_tag)">
                              {{ post?.user?.social_tag }}
                            </h5>
                            <p>
                              {{ post?.created_at | dateAsAgo}}
                            </p>
                          </div>
                          <div class="col-md-6 col-6" *ngIf="user?.social_tag == post?.user?.social_tag">
                            <div class="row float-right">
                              <div class="col float-right">
                                <div ngbDropdown class="d-inline-block">
                                  <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
                                    <i class="fa-solid fa-ellipsis fa-1x ico"></i>
                                  </button>
                                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <button class="ico2" ngbDropdownItem (click)="deletePost(post)">Delete
                                      Post</button>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <p class="mb-3 post-body" [innerHTML]="app.sanitizeHTML(post?.body)"
                          (click)="commentPost(post?.id)">
                        </p>
                        <img [src]="post?.postImages[0]?.path" *ngIf="post?.postImages?.length > 0" alt=""
                          class="img-fluid mb-4 d-none d-md-block" style="border-radius: 10px; max-width:400px;"
                          (click)="commentPost(post?.id)">
                        <img [src]="post?.postImages[0]?.path" *ngIf="post?.postImages?.length > 0" alt=""
                          class="img-fluid mb-4 d-block d-md-none" style="border-radius: 10px;"
                          (click)="commentPost(post?.id)">
                        <div class="row mb-4">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-9">
                                <div class="row">
                                  <div class="col-md-4 col-4">
                                    <div (click)="commentPost(post?.id)">
                                      <span class="pr-2 comment-icon">
                                        <img src="../../assets/imgs/comment.svg" alt="">
                                      </span>
                                      <span>{{ post?.comments_count }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-4">
                                    <span class="pr-2 like-icon" (click)="likePost(post.id)">
                                      <img src="../../assets/imgs/like.svg" alt="" *ngIf="post?.user_likes_count < 1">
                                      <img src="../../assets/imgs/liked.svg" alt="" *ngIf="post?.user_likes_count > 0">
                                    </span>
                                    <span>{{ post?.likes_count }}</span>
                                  </div>
                                  <div class="col-md-4 col-4">
                                    <span class="pr-2 like-icon">
                                      <img src="../../assets/imgs/dislike.svg" alt="" (click)="dislikePost(post.id)"
                                        *ngIf="post?.user_dis_likes_count < 1">
                                      <img src="../../assets/imgs/disliked.svg" alt="" (click)="dislikePost(post.id)"
                                        *ngIf="post?.user_dis_likes_count > 0">
                                    </span>
                                    <span>
                                      {{ post?.dis_likes_count }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="container d-none d-md-block">
    <div class="modal-body">
      <h3 class="text-center mb-3" style="color: white; padding-top: 10px;">
        Create Post
      </h3>
      <div class="row mb-4">
        <div class="col-md-2 col-3">
          <img [src]="user?.sc_profile_picture" alt="" class="img-fluid prof-img"
            style="height: 65px !important; width: 65px !important;">
        </div>
        <div class="col-md-10 col-9">
          <h4 class="mb-2" style="color: white !important;">
            {{ user?.social_tag }}
          </h4>
          <div class="mb-3">
            <select name="" id="" [(ngModel)]="post.type">
              <option value="public">Public</option>
              <option value="supporters">Supporters</option>
            </select>
          </div>
          <div class="post-details">
            <textarea name="" id="" cols="35" rows="5" placeholder="What's going on?" (click)="showEmojiPicker = false"
              (blur)="showEmojiPicker = false" [(ngModel)]="post.body"></textarea>
          </div>
          <div class="post-img" style="text-align:center;">
            <img *ngIf="imgFile" [src]="imgFile" style="
                        width: 50%;
                        height: 50%;
                        background-position: center;
                        background-size: cover" alt="" />
            <input class="test" #imageInput style="display: none;" type="file" accept="image/*" id="file"
              (change)="processFile(imageInput)" />
          </div>
        </div>
      </div>
      <div class="row" style="padding-bottom: 10px;">
        <div class="col-md-6">
          <div class="row">

            <div class="col-md-3">
              <span class="like-icon">
                <img src="../../assets/imgs/videoo.svg" alt="" (click)="showEmojiPicker = false; openFileExplorer()">
              </span>
            </div>
            <div class="col-md-3">
              <span class="like-icon">
                <img src="../../assets/imgs/gif.svg" alt="" (click)="showEmojiPicker = false;">
              </span>
            </div>
            <div class="col-md-3">
              <span class="like-icon">
                <img src="../../assets/imgs/smileee.svg" alt="" (click)="toggleEmojiPicker()">
              </span>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
            [disabled]="post.body == ''">
            Post</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="emojis">
            <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
              title="Pick your emoji…"></emoji-mart>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="container d-block d-md-none">
    <div class="modal-body pt-4 pb-4">
      <div class="row mb-4">
        <div class="col-md-2 col-3">
          <img [src]="user?.sc_profile_picture" alt="" class="img-fluid prof-img"
            style="height: 65px !important; width: 65px !important;">
        </div>
        <div class="col-md-10 col-9">

          <div class="mb-3">
            <select name="" id="" [(ngModel)]="post.type">
              <option value="public">Public</option>
              <option value="supporters">Supporters</option>
            </select>
          </div>
          <div class="post-details">
            <textarea name="" id="post-field" cols="35" rows="5" placeholder="What's going on?" [(ngModel)]="post.body"
              (input)="countCharacters()" (click)="showEmojiPicker = false" (blur)="showEmojiPicker = false"></textarea>
          </div>
          <div class="post-img" style="text-align:center;">
            <img *ngIf="imgFile" [src]="imgFile" style="
                        width: 50%;
                        height: 50%;
                        background-position: center;
                        background-size: cover" alt="" />
            <input class="test" #imageInput style="display: none;" type="file" id="file"
              (change)="processFile(imageInput)" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <div class="row">
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/image.svg" alt="" (click)="showEmojiPicker = false; openFileExplorer()">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/videoo.svg" alt="" (click)="showEmojiPicker = false; openFileExplorer()">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/gif.svg" alt="" (click)="showEmojiPicker = false;">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/smileee.svg" alt="" (click)="toggleEmojiPicker()">
              </span>
            </div>
          </div>

        </div>
        <div class="col-md-6 col-6">
          <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
            [disabled]="post.body == ''">
            Post</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="emojis">
            <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
              title="Pick your emoji…"></emoji-mart>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-template>
