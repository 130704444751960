import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { AppServiceService } from '../_services/app-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { PusherService } from '../_services/pusher.service';
import { ToastService } from 'angular-toastify';
import { DomSanitizer } from '@angular/platform-browser';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, Observable, scan, switchMap, tap } from 'rxjs';
import Swal from 'sweetalert2';
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  activetab = 1
  title = 'Pusher Liker';
  feeds: any
  user: any
  message: any
  post: any = {
    body: "",
    type: "Public",
    title: "new_post" + this.generateRandomToken(12),
    paid_post: false,
    amount: 0,
    files: []
  };
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  imageErrValue: string;
  imageError: boolean;
  qqq: boolean;
  maxFileSize = 15 * 1024 * 1024;
  selectedFile: ImageSnippet;
  imgFile: any;
  test: any;
  currentPage: any = 1;
  pageSize = 10;
  totalLength: any;
  nextCursor: any;
  loading: boolean = false;
  endOfData: boolean = false;
  page: any = 1;
  public posts$: Observable<any>;
  public loading$ = new BehaviorSubject(true);
  private page$ = new BehaviorSubject(1);
  characterCount: number = 0;
  showpost: boolean;
  postImages: any = [];
  showPrice: boolean = false;
  filterParam: any;
  selectedFiles: any = [];
  selectedImageUrl: any = [];
  diasable: boolean;

  constructor(
    private app: AppServiceService,
    private sanitizer: DomSanitizer,
    private _toastService: ToastService,
    private pusherService: PusherService,
    private cdr: ChangeDetectorRef,
    private modal: NgbModal,
    private service: AppServiceService,
    private router: Router
  ) { }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      this.createPost();
    }
  }
  ngOnInit(): void {
    this.pusherService.channel.bind('media-updates', data => {
      console.log(data)
      if (data.type == 'post') {
        let newData = data.data.post_info[0]
        // console.log(newData)
        this.app.updateObjectById(this.app.activityLogs, newData.id, newData, this.user.id, data.creatorId);
      }
    });

    this.getUser();
    // this.getPostFeed();
    this.posts$ = this.getPostFeedNew$();

    setTimeout(() => {
      this.showpost = true
    }, 5000);
  }

  private getPostFeedNew$(): Observable<any> {
    return this.page$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap((page) => this.app.getPostFeedsNew$(page)),
      scan(this.updatePosts, { items: [], page: 0, hasMorePages: true }),
      tap(() => this.loading$.next(false)),
    );
  }


  setPostType(value) {
    this.post.type = value;
    if (value == 'Paid') {
      this.post.paid_post = true
    } else {
      this.post.paid_post = false
    }
  }

  trackByFunction(index, post) {
    // console.log(post)
    return post.slug; // Use a unique identifier from your data
  }

  private updatePosts = (accumulator, value) => {

    if (value.page === 1) {
      accumulator.items = value.items
      this.feeds = value.items;
      return value;
    }

    accumulator.items.push(...value.items);
    // console.log(value.items);
    // console.log(this.feeds);
    // this.feeds.push(...value.items)
    // console.log(this.feeds);
    accumulator.page = value.page;
    accumulator.hasMorePages = value.hasMorePages;

    return accumulator;
  }

  public loadMoreProducts(posts) {
    console.log(posts)
    // if (!posts.hasMorePages) {
    //   return;
    // }
    this.page$.next(posts.page + 1);
  }

  // linkify(text: string): string {
  //   const regex = /(\b(?:https?|ftp):\/\/\S+)/gi;
  //   return text.replace(regex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
  // }
  // sanitizeHTML(text: string): any {
  //   if (text != null) {
  //     return this.sanitizer.bypassSecurityTrustHtml(this.linkify(text.replace(/(?:\r\n|\r|\n)/g, '<br>')));
  //   }
  // }
  updateObjectById(array, idToFind, updatedValues) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      // Update the object with the new values
      console.log('found')
      array[index] = { ...array[index], ...updatedValues };

    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }
  updateSingleObjectById(array, idToFind) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      let updatedValues = {};
      // Define the updated values within the function
      if (array[index]['user_likes_count'] < 1) {
        updatedValues = {
          likes_count: array[index]['likes_count'] + 1,
          user_likes_count: array[index]['user_likes_count'] + 1,
          dis_likes_count: array[index]['user_dis_likes_count'] >= 1 ? array[index]['dis_likes_count'] - 1 : array[index]['dis_likes_count'],
          user_dis_likes_count: array[index]['user_dis_likes_count'] >= 1 ? array[index]['user_dis_likes_count'] - 1 : 0,

          // Add more key-value pairs as needed
        };
        array[index] = { ...array[index], ...updatedValues };
      } else {
        updatedValues = {
          likes_count: array[index]['likes_count'] - 1,
          user_likes_count: array[index]['user_likes_count'] - 1,
        }
        array[index] = { ...array[index], ...updatedValues };
      }

      // Update the object with the new values
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  updateSingleObjectByIdDislike(array, idToFind) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      let updatedValues = {};
      // Define the updated values within the function
      if (array[index]['user_dis_likes_count'] < 1) {
        updatedValues = {
          dis_likes_count: array[index]['dis_likes_count'] + 1,
          user_dis_likes_count: array[index]['user_dis_likes_count'] + 1,
          likes_count: array[index]['user_likes_count'] >= 1 ? array[index]['likes_count'] - 1 : array[index]['likes_count'],
          user_likes_count: array[index]['user_likes_count'] >= 1 ? array[index]['user_likes_count'] - 1 : 0,
          // Add more key-value pairs as needed
        };
        array[index] = { ...array[index], ...updatedValues };
      } else {
        updatedValues = {
          dis_likes_count: array[index]['dis_likes_count'] - 1,
          user_dis_likes_count: array[index]['user_dis_likes_count'] - 1,
        }
        array[index] = { ...array[index], ...updatedValues };
      }

      // Update the object with the new values
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  getPostFeed() {
    this.app.getPostFeeds(this.currentPage).subscribe({
      next: (res: any) => {
        this.feeds = res.data.data;
        this.totalLength = this.feeds.length
        this.nextCursor = res.data.meta.current_page + 1

      },
      error: (err: any) => {
      },
      complete: () => {
        // this.showpage = true
      }
    })
  }
  countCharacters() {
    this.characterCount = this.post.body.length;
  }
  getMoreFeeds() {

    if (this.nextCursor != null) {
      this.loading = true
      this.app.getPostFeeds(this.nextCursor).subscribe({
        next: (res: any) => {
          this.feeds = res.data.data;
          this.totalLength = this.feeds.length
          this.nextCursor = res.data.meta.current_page + 1
          // this.tokenLists = this.orderPipe.transform(this.totalTokenList, this.order)
          // this.feeds.push(...res.data.data);


          // this.filteredTokenLists = this.orderPipe.transform(this.filteredTokenLists, this.order)

        },
        complete: () => {
          this.loading = false
          // this.initMarquee()
        }
      })
    }


  }

  createModal(cont) {
    const modalRef = this.modal.open(cont, { centered: true, backdrop: true })

  }
  convertTime(input) {
    var now = new Date();
    var timestamp = new Date(input);

    var timeDifference = now.getTime() - timestamp.getTime(); // Get the time in milliseconds
    var seconds = Math.floor(timeDifference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    if (days > 1) {
      return days + ' days ago';
    } else if (hours > 1) {
      return hours + ' hours ago';
    } else if (minutes > 1) {
      return minutes + ' mins ago';
    } else {
      return 'just now';
    }
  };
  getGreeting(): string {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    let greeting: string;
    let emoji: string;

    if (currentHour >= 5 && currentHour < 12) {
      greeting = 'Good Morning';
      emoji = '☀️';
    } else if (currentHour >= 12 && currentHour < 17) {
      greeting = 'Good Afternoon';
      emoji = '🌞';
    } else {
      greeting = 'Good Evening';
      emoji = '🌙';
    }

    return `${greeting} ${emoji},`;
  }

  async getUser() {
    const a = await this.service.getUser()
    this.user = a


  }
  likePost(id) {
    this.updateSingleObjectById(this.feeds, id)
    this.app.likePost(id).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
      },
      complete: () => {
        // this.showpage = true
      }
    })
  }
  openFileExplorer() {
    document.getElementById("file").click();
  }
  processFiles(event: any) {
    this.imageErrValue = "";
    this.imageError = false;
    this.qqq = true;
    this.cdr.detach();

    // Ensure that event.target is of type HTMLInputElement
    const inputElement = event.target as HTMLInputElement;

    // Check if files property exists on the input element
    if (inputElement.files) {
      // Initialize an array to store ImageSnippet objects
      this.postImages = [];
      this.selectedImageUrl = [];

      // Iterate through each file in the FileList
      for (let i = 0; i < inputElement.files.length; i++) {
        const file: File = inputElement.files[i];

        if (file && file.size <= this.maxFileSize) {
          const reader = new FileReader();

          reader.addEventListener('load', (readerEvent: any) => {
            const img = new Image();
            img.src = readerEvent.target.result;

            img.onload = () => {
              // Create an ImageSnippet object and push it to the array
              const imageSnippet = new ImageSnippet(readerEvent.target.result, file);
              this.uploadImage(imageSnippet);
              this.postImages.push(imageSnippet);
              console.log(this.postImages);
              this.imageError = false;
              this.cdr.detectChanges();
            };
          });

          reader.readAsDataURL(file);
        } else {
          this.imageError = true;
          this.imageErrValue = "File size should not exceed 3mb";
          this._toastService.error(this.imageErrValue);
        }
      }
    }

    this.cdr.reattach();
  }

  uploadImage(image) {
    this.diasable = true
    let payload = {
      file: image.file
    }
    this.app.uploadImage(payload).subscribe({
      next: (res: any) => {
        console.log(res);
        this.selectedImageUrl.push(res.data.file_url);
        console.log(this.selectedImageUrl);
      },
      error: (err: any) => {
        console.log(err.response);

      },
      complete: () => {
        this.diasable = false
        this.post.files = this.selectedImageUrl;
        // this.showpage = true
      }
    })
  }

  dislikePost(id) {
    // console.log(posts)
    this.updateSingleObjectByIdDislike(this.feeds, id)
    this.app.dislikePost(id).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
      },
      complete: () => {
        // this.showpage = true
      }
    })
  }
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    const text = `${this.post.body}${event.emoji.native}`;
    this.post.body = text;
    // this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }
  onBlur() {
    this.showEmojiPicker = false;
  }
  filter(param) {
    this.filterParam = param;
    this.service.triggerButtonClick(param);
  }
  setPrice(ev) {
    if (this.post.type === 'paid') {
      this.showPrice = true
      this.post.paid_post = true
    } else {
      this.showPrice = false;
      this.post.amount = 0
      this.post.paid_post = false
    }


  }

  createPost() {
    // console.log(this.post)
    if (this.post.body !== "") {

      // this.post.files = this.selectedImageUrl;
      console.log(this.post)
      this.app.createPost(this.post).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            this.post.body = "";
            this.post.paid_post = false;
            this.modal.dismissAll();
            this.selectedImageUrl = [];
            this.postImages = [];
            this.post.amount = null;
            this.getPostFeed();
            // Swal.fire('Success', res.message, 'success')
            // this.alert.success(res.message);
          }
        },
        error: (err: any) => {
          console.log(err);
          // this.alert.danger(err.error.message);
          // this.modalService.dismissAll();
          Swal.fire('Error', err.error.message, 'error')
        },
        complete: () => {
          window.location.reload()
          // this.modal.dismissAll()
        }
      });
    }
  }
  commentPost(id) {
    this.router.navigateByUrl(`/post/${id}`);
  }
  goToProfile(social_tag) {
    this.router.navigateByUrl(`/${social_tag}`);

  }

  deletePost(post) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this post?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.app.deletePost(post).subscribe({
          next: (res: any) => {
            if (res.success === true) {
              this.getPostFeed();
            }
          },
          error: (err: any) => {
            //console.log(err);
            // this.alert.danger(err.error.message);
            // this.modalService.dismissAll();
          },
        });
      } else {
        // User clicked "No" or closed the dialog
      }
    });
  }


  generateRandomToken(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }

    return token;
  }

}
