<div class="container-fluid" *ngIf="!profile">
  <div class="overlay" style="height: 100vh;"></div>
</div>

<div class="container-fluid fade" [class]="profile? 'fadein' : '' ">
  <!-- Supporting and stop supporting part -->
  <div class="row mt-3 mb-3 d-none d-md-flex">
    <div class="col-md-12 text-sm-end">
      <button *ngIf="cansupport" class="btn btn-custom rounded-pill me-3 custombtn"
        (click)="buyStake(content3, content2, content8)">
        <i *ngIf="loadingbuy" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Become a Supporter
      </button>
      <button *ngIf="showSel" class="btn btn-outline-primary rounded-pill me-3 custombtn"
        (click)="sellStake(content4, content2)">
        <i *ngIf="loadingsell" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Stop Supporting
      </button>
      <button *ngIf="isCreator  &&  profile?.minimum_note_amount !== null"
        class="btn btn-outline-primary rounded-pill custombtn" (click)="sendNote(content, content2)">
        Send a Note
      </button>
    </div>
  </div>

  <!-- New Profile design -->
  <div class="d-block d-md-flex mt-3 mt-sm-0">
    <div class="me-md-6 mb-3 mb-md-0">
      <div class="d-flex align-items-center">
        <div class="d-block me-3 text-center ">

          <img class="profilepic" [src]="
                  profile?.sc_profile_picture
                    ? profile?.sc_profile_picture
                    : 'assets/imgs/placeholder.png'
                " alt="" srcset="" />
          <div class="supportBg m-3 px-3 py-2" *ngIf="checkIfSupport?.fam == true">
            <p class="">Fam</p>
          </div>
          <div class="supportBg m-3 px-3 py-2"
            *ngIf="checkIfSupport?.is_supporter == true && checkIfSupport?.fam == false ">
            <p class="">Supports You</p>
          </div>
          <div class="joinTelegram m-3 px-3 py-2"
            *ngIf="getIfSupporters?.viewer_already_a_member == true && supportersInfo?.telegram_chat == true"
            (click)=" joinTelegram()">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/242px-Telegram_2019_Logo.svg.png"
              alt="" class="img-fluid" width="20%">
            <p class="">Join Telegram</p>
          </div>

          <div class="joinTelegram m-3 px-3 py-2 bg-primary border-0"
            *ngIf="getIfSupporters?.viewer_already_a_member == false && supportersInfo?.telegram_chat == true"
            (click)=" buyStake(content3, content2, content8)">
            <span><i class="fa-solid fa-lock"></i></span>
            <p class="">Private Chat</p>
          </div>
        </div>

        <div class="d-block classprofile relative">
          <div class="ogbadge" *ngIf="profile?.is_og">
            <img src="assets/imgs/og_badge.png" alt="">
          </div>
          <div class="ogbadge" *ngIf="!profile?.is_og && profile?.is_giraffe_owner">
            <img src="assets/imgs/is_giraffe_owner.png" alt="">
          </div>
          <h4>{{ profile?.social_tag }}</h4>
          <div *ngIf="creatorprice">
            <p>Price</p>
            <h5>${{ creatorprice?.total_gary_usd | number :'1.0-2' }} </h5>
          </div>
          <div *ngIf="supportersInfo?.rank > 0">
            <p>Rank</p>
            <h5>#{{ supportersInfo?.rank }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div>
        <p *ngIf="profile?.bio != 'null'">{{ profile?.bio }}</p>
      </div>
      <div class="d-md-flex align-items-center mt-3">
        <!-- <a class="me-3 text-md" *ngIf="profile?.city != null && profile?.country != null">
          <i class="fa-solid fa-location-dot"></i>
          {{ profile?.city }}, {{ profile?.country }}
        </a> -->
        <a *ngIf="profile?.twitter_user_id" class="btn btn-clear" target="_blank"
          [href]="'https://twitter.com/' + profile?.twitter_user_id">
          <i class="fa-brands fa-twitter"></i>
        </a>
      </div>

      <div class="d-flex align-items-end classprofile mt-3" *ngIf="stakeStats">
        <div class="d-block me-auto me-sm-3" *ngIf="stakeStats?.total_earned > 0">
          <p>$GARY Earned</p>
          <h5 class="mb-0">
            {{ stakeStats?.total_earned | number : "1.0-2" }}
          </h5>
        </div>
        <div class="me-sm-3" *ngIf="stakeStats?.supporters > 0">
          <p>
            <span *ngIf="stakeStats?.supporters > 1">Supporters</span>
            <span *ngIf="stakeStats?.supporters === 1">Supporter</span>
          </p>
          <h5 class="classsup">{{ stakeStats?.supporters }}

          </h5>
        </div>
        <div class="ms-auto ms-sm-0" *ngIf="supportersInfo.points > 0">
          <p>Points</p>
          <h5 class="classsup">{{ supportersInfo.points }} </h5>
        </div>
      </div>
    </div>
  </div>

  <!-- Supporting and stop supporting part Mobile view -->
  <div class="row mt-3 mb-3 d-flex d-md-none">
    <div class="col-md-12 text-sm-center">
      <button *ngIf="cansupport" class="btn btn-custom rounded-pill me-3 custombtn"
        (click)="buyStake(content3, content2, content8)">
        Become a Supporter
      </button>
      <button *ngIf="showSel" class="btn btn-outline-primary rounded-pill me-3 custombtn"
        (click)="sellStake(content4, content2)">
        Stop Supporting
      </button>
      <button *ngIf="isCreator  &&  profile?.minimum_note_amount !== null"
        class="btn btn-outline-primary rounded-pill custombtn" (click)="sendNote(content, content2)">
        Send a Note
      </button>
    </div>
  </div>


  <!-- Supporters info -->
  <div class="row mt-5">
    <div class="col-12">
      <div class="card px-md-5 py-md-3">
        <div class="card-body">
          <div class="row mb-5">
            <div class="col-md-6">
              <h3>Supporters</h3>
            </div>

            <div class="col-md-6 text-md-end">
              <div class="row">
                <div ngbDropdown display="dynamic" placement="bottom-end" class="d-inline-block">
                  <button type="button" class="btn dropdownBtn" id="dropdownBasic1" ngbDropdownToggle>
                    {{ filt }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="filterSupporter('first_purchase')">
                      First Support Date
                    </button>
                    <button ngbDropdownItem (click)="filterSupporter('owned')">
                      Supporter Level
                    </button>
                    <button ngbDropdownItem (click)="filterSupporter('last_purchase')" s>
                      Most Recent Purchase
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="supporters?.length <= 0">
            <p class="text-center">
              This creator has no supporters
            </p>
          </div>
          <div *ngIf="supporters?.length > 0">
            <div class="row mt-5 supporters" #supCard>

              <div class="col-lg-2 col-md-2 col-sm-3 col-4 text-center mb-4 text-center" *ngFor="let item of supporters"
                style="cursor: pointer" (click)="viwCreator(item, content5)">
                <div class="mb-2">
                  <img [src]="item?.profile_img" class="roundedd" width="80" height="80" alt=""
                    onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
                </div>
                <h6 class="mb-1">{{ item?.social_tag }}</h6>
              </div>

            </div>
          </div>



          <div class="text-end mt-3">
            <button class="btn btn-clear text-primary" *ngIf="csupanScrollUp" (click)="supscrollUp()">
              Back to top
            </button>
            <button class="btn btn-clear text-primary" *ngIf="csupanScrollDown" (click)="supscrollDown()">
              Load more
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Activity feeds -->
  <div class="row my-5">
    <div class="col-12">
      <div class="card px-md-5 py-md-3">
        <div class="card-body">
          <h3>Activity Feed</h3>
          <br />
          <div *ngIf="activityLog?.length<=0">
            <p class="text-center">
              No data to display
            </p>
          </div>

          <div class="table-responsive overflow rounded" *ngIf="activityLog?.length>0">
            <table class="table table-striped">
              <tbody>
                <tr *ngFor="let item of activityLog">
                  <td>
                    <div class="row">
                      <div class="col-md-1">

                        <a [href]="item.image_link" *ngIf="item?.image_link">
                          <img
                            [src]=" item.image ?? 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg' "
                            alt="" class="rounded-circle me-3" width="50" height="50" />
                        </a>
                        <span *ngIf="!item?.image_link">
                          <img
                            [src]=" item.image ?? 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg' "
                            alt="" class="rounded-circle me-3" width="50" height="50" />
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>
                          <span [innerHTML]="item.text"></span>
                          <br />
                          <span style="color: #848484; font-size: 14px">{{
                            item.date
                            }}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="text-end">
          <button class="btn btn-clear text-primary" (click)="getMoreActivities()">
            Load more
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- New Supported community $ supported creator -->
  <!-- *ngIf="supportersInfo?.creator_i_support?.length > 0   || supportedCommunities?.length > 0" -->

  <div class="row mt-5">
    <div class="col-12">
      <div class="card px-md-5 py-md-3 communityCard">
        <div class="card-body">
          <h3>Supports</h3>
          <br />

          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <ng-container ngbNavItem>
              <a ngbNavLink class="about-text" style="
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                  " (click)="newNavTo('creators')">Creators</a>
              <ng-template ngbNavContent> </ng-template>
            </ng-container>

            <ng-container ngbNavItem>
              <a ngbNavLink class="about-text" style="
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                  " (click)="newNavTo('communities')">Communities</a>
              <ng-template ngbNavContent> </ng-template>
            </ng-container>
          </ul>

          <div *ngIf="newnavto == 'creators'" class="mt-4">
            <div *ngIf="supportersInfo?.creator_i_support?.length  <=0 ">
              <p class="text-center">
                No data to display
              </p>
            </div>
            <div *ngIf="supportersInfo?.creator_i_support?.length  >0 "
              class="table-responsive overflow rounded align-items-center" #creatorIcard>
              <table class="table table-striped">
                <thead class="head">
                  <tr>
                    <th colspan="2" class="py-3 ps-1 ps-md-5">Creator</th>
                    <th class="py-3">Rank</th>
                    <th class="py-3">Price</th>
                    <th class="py-3">Quantity</th>
                    <th class="py-3">Supporter Since</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="align-middle" *ngFor="let item of supportersInfo?.creator_i_support">
                    <td class="ps-1 ps-md-5" style="width: 50px !important;">
                      <a [href]="['/'+ item?.social_tag]">
                        <img [src]="
                              item?.creator_img
                                ? item?.creator_img
                                : 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg'
                            " alt="" class="rounded-circle me-3" width="50" height="50" />

                      </a>
                    </td>
                    <td>
                      <a [href]="['/'+ item?.social_tag]">

                        {{ item?.social_tag }}
                      </a>
                    </td>
                    <td> {{ item?.current_rank }} </td>
                    <td>
                      ${{ item?.price || '-' }}
                    </td>
                    <td>
                      {{ item?.total }}
                    </td>

                    <td> {{ item?.first_purchase | date : 'MMM dd, YYYY' }} </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="text-end">
              <button class="btn btn-clear text-primary" *ngIf="creatorIcanScrollUp" (click)="creatorIscrollUp()">
                Back to top
              </button>
              <button class="btn btn-clear text-primary" *ngIf="creatorIScrollDown" (click)="creatorIscrollDown()">
                Load more
              </button>
            </div>
          </div>
          <div *ngIf="newnavto == 'communities'" class="mt-4">
            <div *ngIf="supportedCommunities?.length  <=0 ">
              <p class="text-center">
                No data to display
              </p>
            </div>
            <div *ngIf="supportedCommunities?.length > 0" class="table-responsive overflow rounded" #card>
              <table class="table table-striped">
                <thead class="head">
                  <tr>
                    <!-- <th class="py-3"></th> -->
                    <th colspan="2" class="ps-1 ps-md-5 py-3">Token</th>
                    <th class="py-3">Price</th>
                    <th class="py-3">Quantity</th>
                    <th class="py-3">Badges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="align-middle" *ngFor="let item of supportedCommunities">
                    <td style="width: 50px !important;" class="ps-1 ps-md-5">
                      <a [href]="['/tokens/'+ item?.coin?.coin_symbol]">
                        <img
                          [src]="item?.coin?.coin_image ? item?.coin?.coin_image : 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg' "
                          alt="" class="rounded-circle me-3" width="50" height="50" />
                      </a>
                    </td>
                    <td *ngIf="item?.coin?.coin_symbol !== '$GARY'">
                      <a [href]="['/tokens/'+ item?.coin?.coin_symbol]">

                        {{ item?.coin?.coin_symbol }}
                      </a>
                    </td>
                    <td>
                      ${{ item?.coin.price_usd || '-' }}
                    </td>

                    <td>
                      {{ item?.balance }}
                    </td>

                    <td class="py-3">
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-col me-2" *ngFor="let item of item.user_badges.slice(0, 5)">
                          <span class="">
                            <ng-template class="text-center" #tipContent>
                              <h4 class="mb-3">{{ item?.badge_name }}</h4>
                              <p [innerHTML]="item?.description"></p>
                            </ng-template>
                            <img [src]="item.badge_image" width="40" height="40" class="rounded-circle" alt=""
                              [ngbTooltip]="tipContent" />
                          </span>
                        </div>
                        <span>
                          <ng-template class="text-start" #hoverContent>
                            <ul class="text-start list-unstyled">
                              <li *ngFor="let itemss of item.user_badges.slice(5)" class="mb-2">
                                <img [src]="itemss?.badge_image" class="rounded-circle" width="40" height="40" class=""
                                  alt="" />
                                {{ itemss?.badge_name }}
                              </li>
                            </ul>
                          </ng-template>
                        </span>
                        <div *ngIf="item.user_badges?.length > 5" class="mp-4 rounded-circle bg-secondary"
                          style="cursor: pointer" [ngbTooltip]="hoverContent">
                          +{{ item.user_badges?.length - 5 }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="text-end">
              <button class="btn btn-clear text-primary" *ngIf="canScrollUp" (click)="scrollUp()">
                Back to top
              </button>
              <button class="btn btn-clear text-primary" *ngIf="canScrollDown" (click)="scrollDown()">
                Load more
              </button>
            </div>
          </div>

          <div [ngbNavOutlet]="nav" class="mt-4"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Featured pages (social Pay and  mint pages) -->
  <!-- *ngIf="isCreator && mintPages?.length > 0 || socialPayPages?.length > 0" -->

  <div class="row mt-5">
    <div class="col-12">
      <div class="card px-md-5 py-md-3">
        <div class="card-body">
          <h3>Featured Offers</h3>
          <br />
          <ul ngbNav #nav="ngbNav" class="nav-tabs mb-5">
            <ng-container ngbNavItem>
              <a ngbNavLink class="about-text" style="
                                padding-left: 0px !important;
                                padding-right: 0px !important;
                              " (click)="colNavTo('buy')">Buy</a>
              <ng-template ngbNavContent> </ng-template>
            </ng-container>

            <ng-container ngbNavItem>
              <a ngbNavLink class="about-text" style="
                                padding-left: 0px !important;
                                padding-right: 0px !important;
                              " (click)="colNavTo('mint')">Mint</a>
              <ng-template ngbNavContent> </ng-template>
            </ng-container>
          </ul>

          <div *ngIf="colnavto == 'buy'">
            <div class="row">
              <div class="col-12" *ngIf="socialPayPages?.length <= 0">
                <p class="text-center">No data to diaplay</p>
              </div>
            </div>
            <div class="row" *ngIf="socialPayPages?.length > 0">
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-3" *ngFor="let item of socialPayPages?.slice(0, 4)">
                <div class="slide">
                  <a [href]="social_pay_url + item.page_link" target="_blank">
                    <div class="card offer-card h-100">
                      <img [src]="item.payment_image" onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
                        class="card-img-top" />
                      <div class="card-body">
                        <h5 class="card-title truncate">
                          {{ item.page_name }}
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          </div>

          <div *ngIf="colnavto == 'mint'">
            <div class="row">
              <div class="col-12" *ngIf="mintPages?.length <= 0">
                <p class="text-center">No data to diaplay</p>
              </div>
            </div>
            <div class="row" *ngIf="mintPages?.length > 0">
              <div class="col-lg-3 col-md-4 mb-3" *ngFor="let item of mintPages?.slice(0, 4)">
                <div class="slide">
                  <a [href]="mint_page_url + item.hash" target="_blank">
                    <div class="card offer-card h-100">
                      <img [src]="item.nft?.image" onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
                        class="card-img-top img-fluid" />
                      <div class="card-body">
                        <h5 class="card-title">{{ item.nft?.name }}</h5>

                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          </div>


          <div [ngbNavOutlet]="nav" class="mt-4"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Events -->

  <!-- *ngIf="createdEvents?.length > 0 || rsvpEvents?.length > 0" -->

  <div class="row mt-5">
    <div class="col-12">
      <div class="card px-md-5 py-md-3">
        <div class="card-body">
          <h3>Events</h3>
          <br />
          <nav ngbNav #nav="ngbNav" class="nav-tabs mb-5">
            <ng-container ngbNavItem>
              <a ngbNavLink class="about-text" style="
                  padding-left: 0px !important;
                  padding-right: 0px !important;
                " (click)="navto('attending')">
                Attending</a>

              <ng-template ngbNavContent> </ng-template>
            </ng-container>

            <ng-container ngbNavItem>
              <a ngbNavLink class="about-text" style="
                  padding-left: 0px !important;
                  padding-right: 0px !important;
                " (click)="navto('hosting')">Hosting</a>
              <ng-template ngbNavContent> </ng-template>
            </ng-container>
          </nav>
          <div *ngIf="navti == 'hosting'" class="mt-4">
            <div *ngIf="createdEvents?.length <= 0">
              <p class="text-center">No data to display</p>
            </div>
            <div class="row" *ngIf="createdEvents?.length <= 3">
              <div class="col-md-4 mb-3" *ngFor="let item of createdEvents">
                <a [href]="['/events/'+ item.id]">
                  <img [src]="
                      item?.image ? item?.image : 'assets/imgs/background.jpg'
                    " class="eventimg" alt="" />
                  <h5 class="mt-3">{{ item.name }}</h5>
                </a>
              </div>
            </div>
            <owl-carousel-o [options]="customOptions2" *ngIf="createdEvents?.length > 3">
              <ng-template carouselSlide *ngFor="let item of createdEvents">
                <div class="slide">
                  <a [href]="['/events/'+ item.id]">
                    <img [src]="
                        item?.image ? item?.image : 'assets/imgs/background.jpg'
                      " class="eventimg" alt="" />
                    <h5 class="mt-3">{{ item.name }}</h5>
                  </a>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>

          <div *ngIf="navti == 'attending'" class="mt-4">
            <div *ngIf="rsvpEvents?.length <= 0">
              <p class="text-center">No data to display</p>
            </div>
            <div class="row" *ngIf="rsvpEvents?.length <= 3">
              <div class="col-md-4 mb-3" *ngFor="let item of rsvpEvents">
                <a [href]="['/events/'+ item.id]">
                  <img [src]="
                      item?.image ? item?.image : 'assets/imgs/background.jpg'
                    " class="eventimg" alt="" />
                  <h5 class="mt-3">{{ item.name }}</h5>
                </a>
              </div>
            </div>
            <owl-carousel-o [options]="customOptions2" *ngIf="rsvpEvents?.length > 3">
              <ng-template carouselSlide *ngFor="let item of rsvpEvents">
                <div class="slide">
                  <a [href]="['/events/'+ item.id]">
                    <img [src]="
                        item?.image ? item?.image : 'assets/imgs/background.jpg'
                      " class="eventimg" alt="" />
                    <h5 class="mt-3">{{ item.name }}</h5>
                  </a>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
          <div [ngbNavOutlet]="nav" class="mt-4"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- collectibles -->

  <div class="row mt-5" *ngIf="collectibles?.length > 0">
    <div class="col-12">
      <div class="card px-md-5 py-md-3">
        <div class="card-body">
          <h3>Collectibles</h3>
          <br />
          <div class="row" *ngIf="collectibles?.length <= 3">
            <div class="col-md-3 mb-3" *ngFor="let item of collectibles?.slice(0, 4)">
              <div class="card offer-card" (click)="viewCollectible(item, content6)" style="cursor: pointer">
                <img *ngIf="!item.image" [src]="getSrc(item.address)" class="nftimg img-fluid" alt="nft image"
                  onerror="this.onerror=null;this.src='assets/imgs/nft.jpg';" />
                <img *ngIf="item.image" [src]="item?.image" class="nftimg img-fluid" alt="nft image"
                  onerror="this.onerror=null;this.src='assets/imgs/nft.jpg';" />
                <div class="card-body">
                  <h5 class="card-title">{{ item.name }}</h5>
                </div>
              </div>
            </div>
          </div>
          <owl-carousel-o [options]="customOptions" *ngIf="collectibles?.length > 3">
            <ng-template carouselSlide *ngFor="let item of collectibles">
              <div class="slide">
                <div class="card offer-card" (click)="viewCollectible(item, content6)" style="cursor: pointer">
                  <img *ngIf="!item.image" [src]="getSrc(item.address)" class="img-fluid nftimg" alt="nft image"
                    onerror="this.onerror=null;this.src='assets/imgs/nft.jpg';" />
                  <img *ngIf="item.image" [src]="item?.image" class="nftimg img-fluid" alt="nft image"
                    onerror="this.onerror=null;this.src='assets/imgs/nft.jpg';" />
                  <div class="card-body">
                    <h5 class="card-title">{{ item.name }}</h5>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>

  <br />

  <!-- Proof of work  -->
  <div class="row mt-5" *ngIf="isCreator">
    <div class="col-12">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h3>Proof of Work</h3>
        </div>
        <div class="col-md-4">
          <div class="update">
            <div class="row align-items-center">
              <div class="col">
                <div class="circle ms-auto me-auto" [ngClass]="POWStats?.qualifies_for_reward ? 'green' : 'red'"></div>
              </div>
              <!-- <div class="col">
                        <p>Live Score</p>
                        <h4>{{ POWStats?.active_score }}</h4>
                      </div> -->
              <div class="col">
                <p>$GARY earned</p>
                <h4>{{ POWStats?.total_gary_earned | number : "0.0-2" }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <app-pow2 [social_tag]="social_tag"></app-pow2>
    </div>
  </div>
</div>

<!-- Send Note -->
<ng-template #content let-modal>
  <div class="modal-body px-5 py-3 rounded-3">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
    </div>
    <div class="text-center">
      <h3 class="mfw-bold">Send a note to {{ social_tag }}</h3>
    </div>
    <form [formGroup]="noteForm">
      <div class="form-group">
        <label for="" class="form-label">Amount (Amount should not be less than
          {{ profile?.minimum_note_amount | number : "1.0-2" }} $GARY)</label>
        <input type="text" class="form-control" formControlName="amount" name="amount" />
      </div>

      <div class="form-group">
        <label for="" class="form-label">Note</label>
        <!-- <input type="text" class="form-control" name="note" formControlName="note"> -->
        <textarea class="form-control" rows="5" name="message" formControlName="message"
          placeholder="Type your thoughtful note here..."></textarea>
      </div>
    </form>
    <div class="text-center mt-3">
      <button class="btn btn-custom btn-block" (click)="submit()" [disabled]="noteForm.invalid">
        Send Note
      </button>
    </div>
  </div>
</ng-template>

<!-- Login -->
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
  </div>
  <div class="modal-body">
    <h6>Enter your Social Connector details to login</h6>
    <form [formGroup]="joinForm">
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" name="email" class="form-control" formControlName="email"
          placeholder="Enter Social Connector Email" />
      </div>
      <div class="form-group">
        <label for="" class="form-label">Password &nbsp;
          <span><a href="https://app.socialconnector.io/auth/forgot-password">Forgot Password?</a></span>
        </label>
        <input type="password" name="password" class="form-control" formControlName="password"
          placeholder="Enter Social Connector Password" />
      </div>
    </form>

    <p class="pt-3" style="float:right;">
      New here?
      <a href="https://app.socialconnector.io/auth/registration" target="_blank">Create a FREE Account</a>
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="login()" [disabled]="joinForm.invalid">
      Submit
    </button>
  </div>
</ng-template>

<ng-template #hoverContent>
  <div class="row">
    <div class="col-6 text-start">
      <p>Coin Price</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        {{ estimate.amount_gary }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        {{ estimate.amount_gary }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'SOL'">
        {{ estimate.amount_sol }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDT'">
        {{ estimate.amount_usd }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        {{ estimate.amount_usd }} {{ selected_coin }}
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p c>Creator Royalty</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        <span class="text-primary">({{ supportersInfo.fee_percentage }}%)</span>
        {{ feeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        <span class="text-primary">({{ supportersInfo.fee_percentage }}%)</span>
        {{ feeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <!-- Calculate for sol -->
      <h6 *ngIf="selected_coin === 'SOL'">
        <span class="text-primary">({{ supportersInfo.fee_percentage }}%)</span>
        {{ feeSOL | number : "1.0-2" }}
        {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDT'">
        <span class="text-primary">({{ supportersInfo.fee_percentage }}%)</span>
        {{ feeUSD | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        <span class="text-primary">({{ supportersInfo.fee_percentage }}%)</span>
        {{ feeUSD | number : "1.0-2" }} {{ selected_coin }}
      </h6>
    </div>
  </div>

  <div class="row">
    <div class="col-6 text-start">
      <p>Platform Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        <span class="text-primary">(1.5%)</span>
        {{ appFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        <span class="text-primary">(1.5%)</span>
        {{ appFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'SOL'">
        <span class="text-primary">(1.5%)</span>
        {{ appFeeSOl | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'USDT'">
        <span class="text-primary">(1.5%)</span>
        {{ appFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        <span class="text-primary">(1.5%)</span>
        {{ appFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p>Referral Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        <span class="text-primary">(1.5%)</span>
        {{ referralFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        <span class="text-primary">(1.5%)</span>
        {{ referralFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'SOL'">
        <span class="text-primary">(1.5%)</span>
        {{ referralFeeSOl | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'USDT'">
        <span class="text-primary">(1.5%)</span>
        {{ referralFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        <span class="text-primary">(1.5%)</span>
        {{ referralFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p class="">Total</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        {{ total_gary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        {{ total_gary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'SOL'">
        {{ sol_value | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDT'">
        {{ usd_value | number : "1.0-2" }} {{ selected_coin }}}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        {{ usd_value | number : "1.0-2" }} {{ selected_coin }}}
      </h6>
    </div>
  </div>
</ng-template>
<!-- Buy Stake -->
<ng-template #content3 let-modal>
  <div class="modal-body px-5 py-3 rounded-3 relative">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="clear()"></button>
    </div>
    <div class="text-center">
      <h3 class="fw-bold">Support {{ social_tag }}</h3>
      <p class="text-primary">
        You own {{ coin_balance }} Coins worth
        {{ estimate.amount_gary * coin_balance }} $GARY
      </p>
    </div>
    <div class="alert alert-warning mt-2" *ngIf="!profile?.twitter">This account is not linked to X so please DYOR prior
      to
      supporting to ensure this is the creator you want to support
    </div>
    <div class="row mt-3">
      <div class="col-sm-5">
        <div class="d-flex align-items-center">
          <h3 class="mb-0 font-lato">Price</h3>
          <button class="btn btn-clear btn-icon ms-1 btn-sm" [ngbTooltip]="hoverContent" triggers="manual"
            #t="ngbTooltip" (click)="t.open()">
            <i class="fa-solid fa-circle-info"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-7 text-end">
        <h3 class="fw-bold mb-0">
          <span *ngIf="selected_coin === '$GARY' || selected_coin === '$DEV'">
            {{ total_gary | number : "1.0-2" }} {{ selected_coin }}
          </span>
          <span *ngIf="selected_coin === 'SOL'">
            {{ sol_value | number : "1.0-2" }} {{ selected_coin }}
          </span>
          <span *ngIf="selected_coin === 'USDT'">
            {{ usd_value | number : "1.0-2" }} {{ selected_coin }}
          </span>
          <span *ngIf="selected_coin === 'USDC'">
            {{ usd_value | number : "1.0-2" }} {{ selected_coin }}
          </span>
        </h3>
        <p class="text-sm">
          You have {{ tokenBalance | number : "1.0-2" }} {{ selected_coin }} in
          your wallet
        </p>
      </div>
    </div>

    <div ngbDropdown class=" ">
      <button type="button" class="btn paydropdown mt-3" id="dropdownBasic1" ngbDropdownToggle>
        Pay in {{ selected_coin }}

        <i _ngcontent-apx-c69="" class="fa-solid fa-chevron-down ms-auto"></i>
      </button>
      <div ngbDropdownMenu class="full" aria-labelledby="dropdownBasic1">
        <div *ngFor="let item of tokens">
          <button ngbDropdownItem (click)="getCurrency(item)">
            {{ item?.coin_symbol }}
          </button>
        </div>
      </div>
    </div>



    <button class="btn btn-custom2 mt-4 btn-lg btn-block support-btn mb-4 mt-4" (click)="Support(content8)"
      [disabled]="disableSupport">
      Support
    </button>
  </div>
</ng-template>

<!-- Sell Stake -->
<ng-template #content4 let-modal>
  <div class="modal-body  px-5 py-3 rounded-3 relative">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="clear()"></button>
    </div>
    <div class="text-center mb-3">
      <h3 class="fw-bold">Support {{ social_tag }}</h3>
      <p class="text-primary">
        You own {{ coin_balance }} Coins worth
        {{ estimate2?.amount_gary | number : '1.0-2' }} $GARY
      </p>
    </div>

    <form [formGroup]="sellForm">
      <div class="form-group">
        <label for="" class="form-label">Quantity</label>
        <div class="row mb-3" style="padding-left: 0px !important">
          <div class="col-md-9">
            <input type="text" class="form-control" name="quantity" formControlName="quantity"
              (keyup)="getsellEstimate($event)" style="padding-top: 10px; padding-bottom: 10px" />
          </div>
          <div class="col-md-3">
            <button class="btn max-btn float-left" type="button" id="button-addon2" (click)="max()">
              Max
            </button>
          </div>
        </div>
      </div>

      <div class="pricedisplay my-4">
        <div class="row">
          <div class="col-md-4">
            <div class="d-flex">
              <h3 class="mb-0">Price</h3>
              <button class="btn btn-clear btn-icon ms-1 btn-sm" [ngbTooltip]="hovercontent2" triggers="manual"
                #t="ngbTooltip" (click)="t.open()">
                <i class="fa-solid fa-circle-info"></i>
              </button>
            </div>

          </div>
          <div class="col-md-8 text-end">
            <h3 class="mb-0">{{ sell_total_gary }} $GARY</h3>
          </div>
        </div>
      </div>

    </form>
    <button class="btn btn-custom2 btn-lg btn-block support-btn my-4 " (click)="sell(content9)"
      [disabled]="disableSupport">
      Stop Supporting
    </button>
  </div>
</ng-template>

<ng-template #content8 let-modal>
  <div class="modal-body  px-5 py-3 rounded-3 relative">
    <div class="text-center mb-3">
      <h2 class="fw-bold pt-3" style="font-size: 28px !important;">You are one step closer to supporting {{social_tag}}
      </h2>
      <p class="text-primary pt-2" style="color:white !important;">
        We are processing your transaction as quickly as possible. Click "Check Status" to see the status of all of your
        past transactions.
      </p>
    </div>

    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-custom14 btn-lg btn-block support-btn my-4 " (click)="closeModal()"
          [disabled]="disableSupport">
          Close
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-custom16 btn-lg btn-block support-btn my-4 " (click)="checkStatus()"
          [disabled]="disableSupport">
          Check Status
        </button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #content9 let-modal>
  <div class="modal-body  px-5 py-3 rounded-3 relative">
    <div class="text-center mb-3">
      <h2 class="fw-bold pt-3" style="font-size: 28px !important;">You are one step closer to stop supporting
        {{social_tag}}
      </h2>
      <p class="text-primary pt-2" style="color:white !important;">
        We are processing your transaction as quickly as possible. Click "Check Status" to see the status of all of your
        past transactions.
      </p>
    </div>

    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-custom14 btn-lg btn-block support-btn my-4 " (click)="closeModal()"
          [disabled]="disableSupport">
          Close
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-custom16 btn-lg btn-block support-btn my-4 " (click)="checkStatus()"
          [disabled]="disableSupport">
          Check Status
        </button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #hovercontent2>
  <div class="row">
    <div class="col-6 text-start">
      <p>Coin Price</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        {{ estimate.amount_gary }} {{ selected_coin }}
      </h6>

    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p c>Creator Royalty</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        <span class="text-primary">({{ supportersInfo.fee_percentage }}%)</span>
        - {{ feeSellGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>

  <div class="row">
    <div class="col-6 text-start">
      <p>Platform Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        <span class="text-primary">(1.5%)</span>
        - {{ appsellFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p>Referral Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        <span class="text-primary">(1.5%)</span>
        - {{ referralsellFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p class="">Total</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        {{ sell_total_gary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>
</ng-template>

<!-- view creator detiails -->

<ng-template #content5 let-modal>

  <div class="modal-body rounded-3">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
    </div>
    <div class="text-center mb-3">
      <h3 class="fw-bold">{{ creatordets?.social_tag }}</h3>

    </div>
    <div class="row">
      <div class="col-6">
        <img [src]="creatordets?.profile_img" class="img-fluid rounded-3 creatorImg"
          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
        <!-- <h3 class="basic-title">{{ creatordets?.social_tag }}</h3> -->
      </div>
      <div class="col-6">
        <div class="classprofile">
          <p>Rank</p>
          <h5># {{ creatordets?.current_rank }}</h5>
        </div>
        <div class="classprofile">
          <p>Supporter Level</p>
          <h5>{{ creatordets?.total_amount }} Coins</h5>
        </div>
        <div class="classprofile">
          <p>Supporter Since</p>
          <h5>{{ creatordets?.first_purchase | date : " MMM dd, YYYY" }}</h5>
        </div>
        <div class="classprofile">
          <p>Last Purchase</p>
          <h5>{{ creatordets?.last_purchase | date : "MMM dd, YYYY" }}</h5>
        </div>
        <div>
          <a href="" class="text-primary" [href]="'/' + creatordets?.social_tag">View Creator</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Collectible Modal -->

<ng-template #content6 let-modal>

  <div class="modal-body rounded-3">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
    </div>
    <div class="text-center mb-3">
      <h3 class="fw-bold">{{ collectibleDet?.name }}</h3>

    </div>
    <div class="row">
      <div class="col-md-4">
        <img [src]="getSrc(collectibleDet.address)" class="img-fluid rounded-3"
          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
        <!-- <h3 class="basic-title">{{ collectibleDet?.name }}</h3> -->

        <div class="d-flex align-items-center mt-3">
          <a [href]="collectibleDet?.tensor" target="_blank" class="logos me-3" placement="top"
            ngbTooltip="Buy on Tensor">
            <img src="assets/imgs/tensor.png" alt="" />
          </a>
          <a [href]="collectibleDet?.magic_eden" target="_blank" class="logos me-3" placement="top"
            ngbTooltip="Buy on Magic Eden">
            <img src="assets/imgs/magiceden.png" alt="" />
          </a>
          <a [href]="collectibleDet?.solscan" target="_blank" class="logos" placement="top"
            ngbTooltip="View on Solscan">
            <img src="assets/imgs/solscan.png" alt="" />
          </a>
        </div>
      </div>
      <div class="col-md-8">
        <div class="classprofile2">
          <p>Mint Address</p>
          <div class="d-flex align-items-center">
            <h5 class="mb-0">{{ collectibleDet?.address }}</h5>
            <button (click)="copyText(collectibleDet?.address)" class="btn btn-clear p-0 ms-3">
              <i class="fa-regular fa-copy text-primary" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="classprofile2" *ngIf="collectibleDet?.collection">
          <p>Collection</p>
          <div class="d-flex align-items-center">
            <h5 class="mb-0">{{ collectibleDet?.collection }}</h5>
            <button (click)="copyText(collectibleDet?.collection)" class="btn btn-clear p-0 ms-3">
              <i class="fa-regular fa-copy text-primary" aria-hidden="true"></i>
            </button>
          </div>
          <!-- <h5> {{ (collectibleDet.collection) }} </h5> -->
        </div>
        <!-- <div class="classprofile2">
          <p>Token Standard</p>
          <h5>pNFT</h5>
        </div> -->
        <!-- <div class="classprofile2">
          <p>Royalties</p>
          <h5>10%</h5>
        </div> -->

        <!-- <div class="classprofile2">
          <p>Traits</p>
          <div class="row">
            <div class="col-3">
              <div class="traits">
                <p>Background</p>
                <p>Brown</p>
              </div>
            </div>
            <div class="col-3">
              <div class="traits">
                <p>Eyes</p>
                <p>Stoned</p>
              </div>
            </div>
            <div class="col-3">
              <div class="traits">
                <p>Hat</p>
                <p>Head warmer</p>
              </div>
            </div>
            <div class="col-3">
              <div class="traits">
                <p>Fur</p>
                <p>Panther</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>