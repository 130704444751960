import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'angular-toastify';
import { AppServiceService } from 'src/app/_services/app-service.service';
import { PopupDataService } from 'src/app/_services/popup-data.service';
import { PusherService } from 'src/app/_services/pusher.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  // feeds: any
  loading = true;
  errorMessage: string = null;
  user: any
  qqq: boolean;
  test: any;
  post_id: string;

  constructor(
    public app: AppServiceService,
    private toastService: ToastService,
    private modal: NgbModal,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private service: AppServiceService,
    private pusherService: PusherService,
    private popupDataService: PopupDataService,

  ) { }

  ngOnInit(): void {
    this.getUser();
    this.app.comments = [];
    this.app.post = {}
    this.app.imgFile = "";
    this.app.selectedFile = null;
    this.post_id = this.route.snapshot.paramMap.get('post_id');
    this.pusherService.channel.bind('media-updates', data => {
      console.log(data)
      if (data.type == 'post') {
        // console.log(data.data.post_info[0].id);
        if (data.data.post_info[0]?.id == this.post_id) {
          let newData = data.data.post_info[0]
          let myObject = this.app.post;
          let updatedObject = this.app.updateObject(myObject, newData, this.user.id, data.creatorId)
          this.app.post = updatedObject;
        }
      }
      if (data.type == 'comment') {
        if (data.data.post_info[0]?.id == this.post_id) {

          let newData = data.data.comment_info
          this.app.updateObjectByIdComment(this.app.comments, newData.id, newData, this.user.id, data.creatorId);
        }
      }
      if (data.type == 'new-comment' && data.data.comment_info?.parent_id == null) {
        if (data.data.post_info[0]?.id == this.post_id) {

          let newData = data.data.comment_info
          this.app.comments.unshift(newData);
        }
      }
    });

    this.getPostComments(this.post_id);
  }

  async getUser() {
    const a = await this.service.getUser()
    this.user = a


  }

  getPostComments(id) {
    this.app.getPostComments(id).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.app.post = res.data;
        this.app.comments = res?.data?.comments?.data
        console.log(this.app.post);
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err)
        this.errorMessage = 'There seems to be an error, please wait while we try again'
      },
      complete: () => {
        this.loading = false;
        // this.showpage = true
      }
    })
  }



  // addEmoji28(event) {

  //   const text = `${this.comment.tea2}${event.emoji.native}`;
  //   this.comment.tea2 = text;
  //   // this.showEmojiPicker = false;
  // }
  // addEmoji2(event) {
  //   const text = `${this.comment.tea}${event.emoji.native}`;
  //   this.comment.tea = text;
  //   // this.showEmojiPicker = false;
  // }



  // dislikeComment(id) {
  //   this.app.updateSingleObjectByIdDislikeComment(this.comments, id)

  //   this.app.dislikeComment(this.route.snapshot.paramMap.get('post_id'), id).subscribe({
  //     next: (res: any) => {
  //     },
  //     error: (err: any) => {
  //       console.log(err)
  //     },
  //     complete: () => {
  //       // this.showpage = true
  //     }
  //   })
  // }
  // likeComment(id) {
  //   this.app.updateSingleObjectByIdComment(this.comments, id)

  //   this.app.likeComment(this.route.snapshot.paramMap.get('post_id'), id).subscribe({
  //     next: (res: any) => {
  //     },
  //     error: (err: any) => {
  //       console.log(err)
  //     },
  //     complete: () => {
  //       // this.showpage = true
  //     }
  //   })
  // }
  // convertTime(input) {
  //   var now = new Date();
  //   var timestamp = new Date(input);

  //   var timeDifference = now.getTime() - timestamp.getTime(); // Get the time in milliseconds
  //   var seconds = Math.floor(timeDifference / 1000);
  //   var minutes = Math.floor(seconds / 60);
  //   var hours = Math.floor(minutes / 60);
  //   var days = Math.floor(hours / 24);

  //   if (days > 1) {
  //     return days + ' days ago';
  //   } else if (hours > 1) {
  //     return hours + ' hours ago';
  //   } else if (minutes > 1) {
  //     return minutes + ' mins ago';
  //   } else {
  //     return 'just now';
  //   }
  // };

  // likePost(id) {
  //   let updatedValues;

  //   if (this.feeds.user_likes_count < 1) {

  //     let myObject = this.feeds;
  //     updatedValues = {
  //       likes_count: this.feeds.likes_count + 1,
  //       user_likes_count: this.feeds.user_likes_count + 1,
  //       dis_likes_count: this.feeds.user_dis_likes_count >= 1 ? this.feeds.dis_likes_count - 1 : this.feeds.dis_likes_count,
  //       user_dis_likes_count: this.feeds.user_dis_likes_count >= 1 ? this.feeds.user_dis_likes_count - 1 : 0,
  //     }
  //     const updatedObject = { ...myObject, ...updatedValues };
  //     this.feeds = updatedObject;

  //   } else if (this.feeds.user_likes_count > 0) {
  //     let myObject = this.feeds;
  //     updatedValues = {
  //       likes_count: this.feeds.likes_count - 1,
  //       user_likes_count: this.feeds.user_likes_count - 1,
  //     }
  //     const updatedObject = { ...myObject, ...updatedValues };
  //     this.feeds = updatedObject;
  //   }
  //   this.app.likePost(id).subscribe({
  //     next: (res: any) => {
  //     },
  //     error: (err: any) => {
  //       console.log(err)
  //     },
  //     complete: () => {
  //       // this.showpage = true
  //     }
  //   })
  // }
  // deletePost(post) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You want to delete this post?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     cancelButtonText: 'No',
  //     confirmButtonText: 'Yes',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.app.deletePost(post).subscribe({
  //         next: (res: any) => {
  //           if (res.success === true) {
  //             // this.allActivtyLogs(null);
  //           }
  //         },
  //         error: (err: any) => {
  //           //console.log(err);
  //           // this.alert.danger(err.error.message);
  //           // this.modalService.dismissAll();
  //         },
  //       });
  //     } else {
  //       // User clicked "No" or closed the dialog
  //     }
  //   });
  // }

  // sanitizeHTML(text: string): any {
  //   if (text != null) {
  //     return this.sanitizer.bypassSecurityTrustHtml(this.app.mentionify(this.app.linkify(text.replace(/(?:\r\n|\r|\n)/g, '<br>'))));
  //   }
  // }

  sanitizeHTML(text: string): any {
    if (text != null) {
      // Process links and mentions
      const processedText = this.app.mentionify(this.app.linkify(text));

      // Truncate the text and add "Show more" link if it exceeds 200 characters
      let truncatedText = processedText

      // Check if the truncated text contains an invalid <a> tag
      if (this.app.containsInvalidATag(truncatedText)) {
        // Remove the improperly closed <a> tags from the truncated text
        truncatedText = truncatedText.replace(/<a\b(?![^>]*\bhref=['"]https?:\/\/)([^>]*?)<\/a>(?<!<a)/gi, (match, capturedContent) => {
          // Check if captured content is a valid URL

          return capturedContent
        });
      }
      // if (this.countInnerHTML(processedText) > 200) {
      //   truncatedText += '... <br/><br/> <p class="custom">Show more</p>';
      // }



      // Split the text into lines
      const lines = truncatedText.split(/<br>|[\r\n]+/g);

      // If there are more than 5 lines, add ellipsis and "Show more" link
      // if (lines.length > 5) {
      //   // Update truncatedText to contain the first 5 lines
      //   truncatedText = lines.slice(0, 5).join('<br><br>');
      //   truncatedText += '... <br/><br/> <p class="custom">Show more</p>';
      // }

      // const lines = a.split(/<br>|[\r\n]+/g);
      // b = lines.slice(0, 5).join('<br>');

      // if (lines.length > 5) {
      //   b += '... <br/><br/> <p class="custom">Show more</p>';
      // }

      return this.sanitizer.bypassSecurityTrustHtml((truncatedText.replace(/(?:\r\n|\r|\n)/g, '<br>')));
    }
  }


  goToProfile(social_tag) {
    this.router.navigateByUrl(`/${social_tag}`);

  }
  // commentOnPost(post_id) {
  //   if (this.comment.comment !== "") {
  //     this.comment.post_id = post_id
  //     this.commenting = true;
  //     this.app.postComment(this.comment).subscribe({
  //       next: (res: any) => {
  //         if (res.success === true) {
  //           this.comment.comment = "";
  //           this.comment.post_id = "";
  //           this.getPostComments(this.route.snapshot.paramMap.get('post_id'));
  //           this.modal.dismissAll();
  //           // this.alert.success(res.message);
  //         }
  //       },
  //       error: (err: any) => {
  //         //console.log(err);
  //         Swal.fire({
  //           title: 'Error',
  //           text: err.error.message,
  //           icon: 'error',

  //         })
  //         // this.modalService.dismissAll();
  //         this.commenting = false;

  //       },
  //       complete: () => {
  //         this.commenting = false;
  //       }
  //     });
  //   }
  // }
  // openFileExplorer() {
  //   document.getElementById("file").click();
  // }
  // commentOnPost48(post_id) {
  //   if (this.comment.tea2 !== "") {
  //     this.comment.post_id = post_id
  //     this.comment.comment = this.comment.tea2
  //     this.commenting = true;
  //     if (this.selectedFile === undefined || this.selectedFile === null) {
  //       this.test = this.imgFile;
  //     } else {
  //       this.test = this.selectedFile.file;
  //     }

  //     const pic = this.test;
  //     this.comment.file = pic;
  //     this.app.postComment(this.comment).subscribe({
  //       next: (res: any) => {
  //         if (res.success === true) {
  //           this.comment.comment = "";
  //           this.comment.tea2 = "";
  //           this.comment.post_id = "";
  //           this.imgFile = "";
  //           this.getPostComments(this.route.snapshot.paramMap.get('post_id'));
  //           this.modal.dismissAll();
  //           // this.alert.success(res.message);
  //         }
  //       },
  //       error: (err: any) => {
  //         //console.log(err);
  //         Swal.fire({
  //           title: 'Error',
  //           text: err.error.message,
  //           icon: 'error',

  //         })
  //         // this.modalService.dismissAll();
  //         this.commenting = false;

  //       },
  //       complete: () => {
  //         this.commenting = false;
  //       }
  //     });
  //   }
  // }
  // commentOnPost2(post_id) {
  //   if (this.comment.tea2 !== "") {
  //     this.comment.post_id = post_id
  //     this.comment.comment = this.comment.tea2
  //     this.commenting = true;
  //     this.app.postComment(this.comment).subscribe({
  //       next: (res: any) => {
  //         if (res.success === true) {
  //           this.comment.comment = "";
  //           this.comment.tea2 = "";
  //           this.comment.post_id = "";
  //           this.getPostComments(this.route.snapshot.paramMap.get('post_id'));
  //           this.modal.dismissAll();
  //           // this.alert.success(res.message);
  //         }
  //       },
  //       error: (err: any) => {
  //         //console.log(err);
  //         // this.alert.danger(err.error.message);
  //         // this.modalService.dismissAll();
  //       },
  //       complete: () => {
  //         this.commenting = false;
  //       }
  //     });
  //   }
  // }
  // deleteComment(post) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You want to delete this comment?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     cancelButtonText: 'No',
  //     confirmButtonText: 'Yes',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.app.deleteComment(post).subscribe({
  //         next: (res: any) => {
  //           if (res.success === true) {
  //             this.getPostComments(this.route.snapshot.paramMap.get('post_id'))
  //           }
  //         },
  //         error: (err: any) => {
  //           //console.log(err);
  //           // this.alert.danger(err.error.message);
  //           // this.modalService.dismissAll();
  //         },
  //       });
  //     } else {
  //       // User clicked "No" or closed the dialog
  //     }
  //   });
  // }
}
