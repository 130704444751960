import { Component, OnInit } from '@angular/core';
import { AppServiceService } from './../_services/app-service.service';
@Component({
  selector: 'app-tower',
  templateUrl: './tower.component.html',
  styleUrls: ['./tower.component.scss']
})
export class TowerComponent implements OnInit {
  towers: any[]
  searchValue: string = '';
  public loading: boolean = false
  timeOut: any;
  value: "value";
  timeOutDuration = 1000;

  constructor(private app: AppServiceService,) { }

  ngOnInit(): void {
    this.getActiveTowers()
  }

  handleSearch() {
    clearTimeout(this.timeOut);
    if (this.searchValue != '') {
      if (this.searchValue.length >= 2) {
        this.loading = true
        this.timeOut = setTimeout(() => {
          this.app.getActiveTowers(this.searchValue.toLowerCase()).subscribe({
            next: (res: any) => {
              this.towers = res.data
            },
            complete: () => {
              this.loading = false
            }
          })
        }, this.timeOutDuration);
      } else { }

    } else {
      this.getActiveTowers();
    }
  }

  buildJoinTowerString(trait_type, trait_value) {
    return `https://magiceden.io/marketplace/giraffetower?attributes={"${trait_type}":["{\\"value\\":\\"${trait_value}\\"}"]}`
  }

  getActiveTowers() {
    this.loading = true;
    this.app.getActiveTowers(this.searchValue).subscribe({
      next: (res: any) => {
        const allData = res;
        this.towers = allData.data;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err)
        this.loading = false;
      }
    })
  }

  shouldTruncate(description: string): boolean {
    return description.length > 130;
  }

  truncateDescription(description: string): string {
    if (this.shouldTruncate(description)) {
      return description.slice(0, 130) + '...';
    } else {
      return description;
    }
  }

}
