import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrls: ['./marquee.component.scss']
})
export class MarqueeComponent implements OnInit, AfterViewInit {
  @Input() items: any[];
  animationDuration: string = '0s';

  constructor(private elementRef: ElementRef) {

  }

  ngOnInit(): void {
    // console.log(this.items)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const marquee = this.elementRef.nativeElement.querySelector('.marquee');
      const marqueeWidth = marquee.offsetWidth;
      const itemsWidth = marquee.querySelector('span').offsetWidth * Math.min(this.items.length, 50);
      const duration = itemsWidth / marqueeWidth * 20; // 20s is the original animation duration
      this.animationDuration = `${duration}s`;

      if (marqueeWidth < itemsWidth) {
        marquee.style.width = `${itemsWidth}px`;
      }
    }, 5000);
  }
}
