<div *ngIf="event">

  <div class="container" *ngIf="isAttendee">
    <div class="row mt-3">
      <div class="col-12">
        <button class="btn btn-clear" (click)="back()">
          <i class="fa-solid fa-arrow-left"></i> &nbsp; Back to event
        </button>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <h1 class=" mb-3 text-success">
          <span *ngIf="event.rsvp_text"> {{ event.rsvp_text }} </span>
          <span *ngIf="!event.rsvp_text">Awesome!!! You're all set and ready to attend the event!</span>
        </h1>
        <img [src]="event?.image" class="eventImg" alt="">
        <h3 class="mt-3">
          {{ event.name }}
        </h3>
        <p>
          <i class="fa-regular fa-calendar-days"></i> &nbsp; {{fromdate | date: 'EEEE, MMMM d, y' }} at {{fromdate |
          date: 'h:mm:ss a' }} to {{ todate | date: 'EEEE, MMMM d, y' }} at {{todate|
          date: 'h:mm:ss a' }} {{ zone?.abbreviation }}
        </p>
        <div class="d-flex align-items-center">
          <button class="btn btn-primary" (click)="addToCalendar(event)">Add to
            Calendar</button>
          <div class="ms-3">
            <h5 class="mb-0">Share: <span placement="top" ngbTooltip="Share on Twitter" (click)="openTwitter(event)"
                style="cursor: pointer;">
                <!-- <i class="fa-brands fa-twitter text-primary"></i> -->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"
                  class="r-1nao33i" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                  <!-- <path fill-rule="evenodd"
                                  d="M48,24c0,13.3-10.8,24-24,24C10.7,48,0,37.2,0,24s10.7-24,24-24C37.2,0,48,10.8,48,24Z"
                                  clip-rule="evenodd" opacity="0.3" fill="currentColor" /> -->
                  <path fill="currentColor"
                    d="M18.244,2.25h3.308l-7.227,8.26l8.502,11.24h-6.657l-5.214-6.817L4.99,21.75h-3.31l7.73-8.835L1.254,2.25h6.826l4.713,6.231ZM17.083,19.77h1.833L7.084,4.126h-1.967Z"
                    transform="translate(11.9595 12)" />
                </svg>
              </span>&nbsp;
              <span placement="top" ngbTooltip="Copy Link" (click)="copyToClipboard(event)" style="cursor: pointer;"><i
                  class="fa-solid fa-copy text-primary"></i></span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="!isAttendee">
    <div class="row">
      <div class="col-12 text-center">
        you do not have permission to view this page
        <br>
        {{ navigateBack() }}
      </div>
    </div>
  </div>
</div>