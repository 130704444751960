import { PublicKey } from '@solana/web3.js';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, NgZone, AfterViewChecked, AfterViewInit, OnDestroy } from '@angular/core'; import { ActivatedRoute, Router } from '@angular/router';
import { AppServiceService } from '../../_services/app-service.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WalletService } from '../../_services/wallet.service';
import { getAssociatedTokenAddress } from '../../../utils';
import { getAccountInfo } from 'src/utils/getAccountInfo';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { LoaderModalComponent } from '../../event-calendar/event-calendar.component';
const api = `${environment.apiUrl}`;
import { Clipboard } from '@angular/cdk/clipboard';
import { OrderPipe } from 'ngx-order-pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { PusherService } from 'src/app/_services/pusher.service';
import { BehaviorSubject, Observable, scan, switchMap, tap } from 'rxjs';
import { ToastService } from 'angular-toastify';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  towerMembers: any
  ref: any
  p: any;
  page = 1;
  social_tag: any;
  public loading: boolean = false
  activetab = 1;
  user: any;
  showpost: boolean;
  nextCursor: null;
  userdetails: any;
  public posts$: Observable<any>;
  public loading$ = new BehaviorSubject(true);
  private page$ = new BehaviorSubject(1);
  characterCount: number = 0;
  feeds: any;
  supporting: any;
  showPrice: boolean = false;
  currentPage: any = 1;
  segment_id = ''
  allEvents: any;
  mintPages: any;
  socialPayPages: any;
  allOffers: any[];
  social_pay_url = environment.social_pay_url;
  mint_page_url = environment.mint_page_url;
  filt: any = 'First Support Date';
  solscan_url = 'https://solscan.io/token/'


  constructor(
    private sanitizer: DomSanitizer,
    public app: AppServiceService,
    private route: ActivatedRoute,
    private orderPipe: OrderPipe,
  ) {
    this.route.params.subscribe(params => {
      this.ref = params['ref'];
    });
    this.getTowerMembers()
  }

  async ngOnInit(): Promise<void> {

    this.app.activityLogs = [];
    this.userdetails = await this.app.getUser()
    this.posts$ = this.getPostFeedNew$();

    this.getActivitLog()

  }
  private getPostFeedNew$(): Observable<any> {
    return this.page$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap((page) => this.app.getPostFeedsNew$(page)),
      scan(this.updatePosts, { items: [], page: 0, hasMorePages: true }),
      tap(() => this.loading$.next(false)),
    );
  }

  seeProfile(name: any) {
    return `/${name}`
  }
  private updatePosts = (accumulator, value) => {

    if (value.page === 1) {
      accumulator.items = value.items
      this.feeds = value.items;
      return value;
    }

    accumulator.items.push(...value.items);
    accumulator.page = value.page;
    accumulator.hasMorePages = value.hasMorePages;

    return accumulator;
  }

  getActivitLog() {
    this.app.getSegmentActivityLog(this.segment_id, this.currentPage).subscribe({
      next: (res: any) => {
        console.log('activityLog', res)
        this.app.activityLogs = res.data.data
        this.nextCursor = res.data.meta.next_cursor
      },
      error: (err: any) => {
        console.log('activityLogerror', err)
      }
    })
  }

  // buildJoinTowerString(trait_type, trait_value) {
  //   return `https://magiceden.io/marketplace/giraffetower?attributes={"${trait_type}":["{\\"value\\":\\"${trait_value}\\"}"]}`
  // }
  setActive(num) {
    this.activetab = num
  }

  getTowerMembers() {
    this.loading = true;
    this.app.getTowerMembers(this.ref, this.page).subscribe({
      next: (res: any) => {
        const allData = res;
        console.log('All data', allData)
        this.towerMembers = allData.data;
        this.segment_id = this.towerMembers?.segment_ids[0]
        console.log(this.towerMembers)

      },
      error: (err: any) => {
        console.log('All data', err)
        this.loading = false;
      },
      complete: () => {
        console.log('segment', this.segment_id)
        this.getActivitLog()
        this.getAllEvents()
        this.getMintPages()

        this.loading = false;
      }
    })
  }

  getImagePath(url) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async getUser() {
    const a = await this.app.getUser()
    this.user = a
    if (this.user.social_tag === this.social_tag) {
      this.showpost = true
    } else this.showpost = false
    // console.log('User re ooo', this.user)

  }

  getMoreActivities() {
    if (this.nextCursor != null) {
      this.app.getSegmentActivityLog(this.segment_id, this.nextCursor).subscribe({
        next: (res: any) => {
          // console.log(res)
          this.nextCursor = res.data.meta.next_cursor
          this.app.activityLogs.push(...res.data.data);
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  getAllEvents() {
    this.app.getAllSegmentEvent(this.segment_id).subscribe({
      next: (res: any) => {
        let a = res.data
        console.log('new events', this.allEvents)
        this.allEvents = this.orderPipe.transform(a, 'date')

      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        // this.getRSVPEvents()
      }
    })
  }
  getMintPages() {
    this.app.getSegmentFeaturedMintPages(this.segment_id).subscribe({
      next: (res: any) => {
        this.mintPages = res.data
        // console.log('Mint page - ', res)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.getSocialPayPages()
      }
    })
  }


  getSocialPayPages() {
    this.app.getSegmentFeaturedSocialPayPages(this.segment_id).subscribe({
      next: (res: any) => {
        // console.log('Social pay pages - ', res)
        this.socialPayPages = res.data.data
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        let a = []
        a.push(...this.socialPayPages)
        a.push(...this.mintPages)

        a = this.orderPipe.transform(a, 'created_at')
        this.allOffers = a

        console.log('aaaa', a)
      }
    })
  }
  shouldTruncate(description: string): boolean {
    return description.length > 12;
  }

  truncateDescription(description: string): string {
    if (this.shouldTruncate(description)) {
      return description.slice(0, 12) + '...';
    } else {
      return description;
    }
  }

}
