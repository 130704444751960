import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private hasNotificationSource = new Subject<boolean>();
  hasNotification$ = this.hasNotificationSource.asObservable();

  updateNotificationIndicator(hasNotification: boolean) {
    this.hasNotificationSource.next(hasNotification);
  }
}