<div class="container mt-5">
  <div class="row ">
    <div class="col-md-6 offset-md-3 border p-3">
      <div class="border p-2">

        <nav class="nav nav-pills nav-fill">
          <a class="nav-link" [routerLink]="['/swap/external_swap']" routerLinkActive="active">CONNECT WALLET</a>
          <a class="nav-link" [routerLink]="['/swap/sc_swap']" routerLinkActive="active">SWAP</a>

        </nav>

      </div>

      <div class="">
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>
</div>
