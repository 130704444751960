<div>
  <div class="container">
    <div class="logo mb-5 pt-5 d-flex justify-content-center">
      <img
        src="https://app.socialconnector.io/assets/media/logos/default-dark2.png"
        alt=""
        class="img-fluid"
      />
    </div>
    <div class="align-items-center text-center pt-3" *ngIf="notfound == false">
      <div class="coin-logo">
        <img [src]="coinDetails[0].coin_img" alt="" class="img-fluid" />
      </div>
      <h1 class="creator-details pt-3 mb-3">
        Welome to Gary Club. <br />
        we can't wait to meet you
      </h1>
      <div class="row pt-3">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div class="forms">
            <form
              name="form"
              (ngSubmit)="f.form.valid && submit(f.value)"
              #f="ngForm"
              novalidate
            >
              <input
                type="text"
                name="name"
                [(ngModel)]="addForm.name"
                required
                class="form-control mb-3"
                placeholder="Name"
                #name="ngModel"
              />
              <input
                type="email"
                name="email"
                id=""
                [(ngModel)]="addForm.email"
                required
                class="form-control mb-3"
                placeholder="Email Address"
                #email="ngModel"
              />
              <button class="btn instant-access" [disabled]="!f.form.valid">
                Get Instant Access
                <img src="assets/imgs/Vector.png" alt="" />
              </button>
            </form>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
      <footer class="mt-md-5 mt-0">
        <p class="pt-md-5 pt-4">
          <span class="d-block mt-4"
            >&copy; {{ getCurrentYear() }} Social Connector</span
          >
        </p>
      </footer>
    </div>
    <div
      class="align-items-center text-center pt-3 mx-auto"
      *ngIf="notfound == true"
    >
      <div class="not-found">
        <h1 class="creator-details">Landing page not active</h1>
      </div>

      <footer class="mt-md-5 mt-0">
        <p class="pt-md-5 pt-4">
          <span class="d-block mt-4"
            >&copy; {{ getCurrentYear() }} Social Connector</span
          >
        </p>
      </footer>
    </div>
  </div>
</div>
