<div class="container-fluid">
  <!-- <div class="row">
    <div class="col-12">
      <button class="btn btn-primary" (click)="test()">Test</button>
    </div>
  </div> -->
  <div class="row mt-5">
    <div class="col-12">
      <div *ngIf="!showpage">
        <div class="overlay" style="height: 100px; margin-top: 80px; margin-bottom: 80px"></div>
      </div>
    </div>
    <div class="col-md-8 mb-3" *ngIf="showpage">
      <div class="d-flex align-items-center">
        <div class="logo me-3" *ngIf="coinDetails?.coin_img">
          <img [src]="coinDetails?.coin_img" class="img-fluid" alt="" srcset="" />
        </div>
        <div class="d-block">
          <h3 class="mb-0">
            {{ coinDetails?.coin_name }}
            <span class="text-muted">({{ coinDetails?.coin_symbol }})</span>
          </h3>
          <div>
            <a [href]="'https://solscan.io/token/' + coinDetails?.token_address" target="_blank" class="btn btn-clear"
              *ngIf="coinDetails?.token_address" placement="bottom" ngbTooltip="Token Address">
              <img _ngcontent-ppq-c49="" alt="" srcset="" style="border-radius: 50%" src="assets/imgs/sol-icon.png"
                width="18" />
            </a>
            <a [href]="links?.facebook" target="_blank" class="btn btn-clear" *ngIf="links?.facebook" placement="bottom"
              ngbTooltip="Facebook">
              <i class="fa-brands fa-facebook"></i>
            </a>
            <a class="btn btn-clear" target="_blank" *ngIf="links?.twitter" [href]="links?.twitter" placement="bottom"
              ngbTooltip="Twitter">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a class="btn btn-clear" [href]="links?.instagram" target="_blank" *ngIf="links?.instagram"
              placement="bottom" ngbTooltip="Instagram">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a class="btn btn-clear" *ngIf="links?.discord" [href]="links?.discord" target="_blank" placement="bottom"
              ngbTooltip="Discord">
              <i class="fa-brands fa-discord"></i>
            </a>
            <a class="btn btn-clear" *ngIf="links?.youtube" [href]="links?.youtube" target="_blank" target="_blank"
              placement="bottom" ngbTooltip="Youtube">
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a class="btn btn-clear" *ngIf="links?.twitch" [href]="links?.twitch" target="_blank" target="_blank"
              placement="bottom" ngbTooltip="Youtube">
              <i class="fa-brands fa-twitch"></i>
            </a>
            <a class="btn btn-clear" *ngIf="links?.external" [href]="links?.external" target="_blank" target="_blank"
              placement="bottom" ngbTooltip="Website">
              <i class="fa-solid fa-globe"></i>
            </a>
            <a class="btn btn-clear" *ngIf="links?.website" [href]="links?.website" target="_blank" target="_blank"
              placement="bottom" ngbTooltip="Website">
              <i class="fa-solid fa-globe"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-md-end" *ngIf="showpage">
      <h3>${{ coinDetails?.price_usd }}</h3>
      <a class="btn btn-outline-primary rounded-pill me-3 py-2 px-4" [href]="'/swap/sc_swap/'+token">
        SWAP
      </a>
      <button class="btn btn-outline-primary rounded-pill py-2 px-4" *ngIf="coinDetails?.has_offers"
        (click)="open(content)">
        BUY
      </button>
    </div>
  </div>
  <!-- {{ POWStats?.total_reward | number : "0.0-2" }} -->
  <div class="card my-5 py-5 px-md-5">
    <div class="card-body">
      <h3>Activities</h3>
      <div class="card bg mt-4">
        <div class="card-body">
          <div class="row g-0 py-3">
            <div class="col b-right ps-2">
              <div class="d-flex">
                <div class="me-2">
                  <p>Holders</p>
                  <h4>{{ analytics?.supporters }}</h4>
                </div>
                <div class="d-block ms-auto me-1">


                  <p *ngIf="analytics?.percentage_change_supporters > 0">
                    <span class="text-success">{{
                      analytics?.percentage_change_supporters
                      | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-up text-success"></i>
                  </p>
                  <p *ngIf="analytics?.percentage_change_supporters < 0">
                    <span class="text-danger">{{
                      analytics?.percentage_change_supporters
                      | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-down text-danger"></i>
                  </p>
                </div>
              </div>
            </div>
            <div class="col b-right ps-2">
              <div class="d-flex">
                <div class="me-2">
                  <p>24h Volume</p>
                  <h4>${{ analytics?.twenty_four_hrs_vol }}</h4>
                </div>
                <div class="d-block ms-auto me-1">

                  <p *ngIf="analytics?.vol_percentage_change > 0">
                    <span class="text-success">{{
                      analytics?.vol_percentage_change | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-up text-success"></i>
                  </p>
                  <p *ngIf="analytics?.vol_percentage_change < 0">
                    <span class="text-danger">{{
                      analytics?.vol_percentage_change | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-down text-danger"></i>
                  </p>
                </div>
              </div>
            </div>
            <div class="col b-right ps-2">
              <div class="d-flex">
                <div class="me-2">
                  <p>24h Trades</p>
                  <h4>{{ analytics?.twenty_four_hrs_trades }}</h4>
                </div>
                <div class="d-block ms-auto me-1">


                  <p *ngIf="analytics?.percentage_change_trades > 0">
                    <span class="text-success">{{
                      analytics?.percentage_change_trades | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-up text-success"></i>
                  </p>
                  <p *ngIf="analytics?.percentage_change_trades < 0">
                    <span class="text-danger">{{
                      analytics?.percentage_change_trades | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-down text-danger"></i>
                  </p>
                </div>
              </div>
            </div>
            <div class="col b-right ps-2">
              <div class="row g-1">
                <div class="col-lg-12">
                  <p>Circulating Supply</p>
                  <h4>{{ circulating_supply | number : '1.0-2' }}</h4>
                </div>
                <div class=""></div>
              </div>
            </div>
            <div class="col  b-right ps-2">
              <div class="d-flex">
                <div class="me-2">
                  <p>$GARY Locked</p>
                  <h4>{{ analytics?.gary_locked | number : '1.0-2' }}</h4>
                </div>
                <div class="d-block ms-auto me-1">

                  <!-- <p>
                    <i class="fa-solid fa-circle-info text-danger"></i>
                  </p> -->
                  <p *ngIf="analytics?.percentage_change_gary_locked > 0">
                    <span class="text-success">{{
                      analytics?.percentage_change_gary_locked
                      | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-up text-success"></i>
                  </p>
                  <p *ngIf="analytics?.percentage_change_gary_locked < 0">
                    <span class="text-danger">{{
                      analytics?.percentage_change_gary_locked
                      | number : "1.0-2"
                      }}% &nbsp;</span>
                    <i class="fa-solid fa-arrow-down text-danger"></i>
                  </p>
                </div>
              </div>
            </div>
            <div class="col ps-2">
              <div class="d-flex">
                <div class="me-2">
                  <p>$GARY Earned</p>
                  <h4>{{ POWStats?.total_reward | number : "0.0-2" }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav ngbNav #nav="ngbNav" class="nav-tabs mynav mt-5" [destroyOnHide]="false" (navChange)="onNavChange($event)">
        <ng-container [ngbNavItem]="1" [destroyOnHide]="false">
          <a ngbNavLink>Charts</a>
          <ng-template ngbNavContent>
            <div class="float-md-start mb-3">
              <div class="btn-group border" role="group" aria-label="Basic example">
                <button class="btn btn-dark" (click)="changeDate('1')" [class.active]="activeclass">
                  1D
                </button>
                <button class="btn btn-dark" (click)="changeDate('7')" [class.active]="activeclass2">
                  7D
                </button>
                <button class="btn btn-dark" (click)="changeDate('30')" [class.active]="activeclass3">
                  1M
                </button>
                <button class="btn btn-dark" (click)="changeDate('90')" [class.active]="activeclass6">
                  3M
                </button>
                <button class="btn btn-dark" (click)="changeDate('365')" [class.active]="activeclass7">
                  1Y
                </button>
                <button class="btn btn-dark" (click)="changeDate('all')" [class.active]="activeclass8">
                  ALL
                </button>
              </div>
            </div>

            <div class="float-md-end mb-3">
              <div class="btn-group border" role="group" aria-label="Basic example">
                <button class="btn btn-dark" (click)="changeChart('price')" [class.active]="activeclass4"
                  [disabled]="activeclass4">
                  Price
                </button>
                <button class="btn btn-dark" (click)="changeChart('volume')" [class.active]="activeclass5"
                  [disabled]="activeclass5">
                  Volume Traded
                </button>
              </div>
            </div>

            <div style="clear: both"></div>

            <div style="text-align: center" *ngIf="!loading3">
              <div *ngIf="activeclass4" id="container" style="width: 100%; height: 400px"></div>
              <div id="container2" style="width: 100%; height: 400px" *ngIf="activeclass5"></div>
            </div>

            <div class="" style="height: 400px" *ngIf="loading3">
              <div class="overlay"></div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container [ngbNavItem]="2">
          <a ngbNavLink>Transactions</a>
          <ng-template ngbNavContent>
            <div class="table-responsive">
              <div class="overlay" *ngIf="loading" style="height: 300px"></div>
              <table class="table table-hover align-middle table-lg">
                <thead>
                  <tr>
                    <th class="ps-3">Time</th>
                    <th>Value</th>
                    <th>Amount</th>
                    <th>By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                      let item of data
                        | paginate
                          : {
                              id: 'trades',
                              itemsPerPage: perpage2,
                              currentPage: page,
                              totalItems: size
                            }
                    ">
                    <td class="ps-3 py-3">
                      <span class="iconspan buy me-3" *ngIf="item.action === 'buy'">
                        <i class="fa-solid fa-arrow-down"></i>
                      </span>
                      <span class="iconspan sell me-3" *ngIf="item.action === 'sell'">
                        <i class="fa-solid fa-arrow-up"></i>
                      </span>
                      {{ formatDate(item.date_entered) | dateAsAgo }}
                    </td>
                    <td class="py-3">
                      <span *ngIf="item.action === 'buy'">
                        {{
                        item.ui_amount | number : "1.0-2"
                        }} {{ app.coin_symbol }} <i class="fa-regular fa-arrow-right"></i> {{ item.amount | number :
                        "1.0-2" }} {{ token }}</span>
                      <span *ngIf="item.action === 'sell'">
                        {{ item.amount | number : "1.0-2" }} {{ token }}
                        <i class="fa-regular fa-arrow-right"></i>
                        {{ item.ui_amount | number : "1.0-2"}}
                        {{ app.coin_symbol }}
                      </span>

                    </td>

                    <td class="py-3">
                      <span *ngIf="item.action === 'buy'">
                        <img src="assets/imgs/gary.png" width="45" class="rounded-circle" alt="" />
                        &nbsp;<i class="fa-solid fa-arrow-right"></i>&nbsp;
                        <img [src]="item.coin.coin_img" width="30" class="rounded-circle" alt="" />
                      </span>
                      <span *ngIf="item.action === 'sell'">
                        <img [src]="item.coin.coin_img" width="30" class="rounded-circle" alt="" />
                        &nbsp;<i class="fa-solid fa-arrow-right"></i>&nbsp;
                        <img src="assets/imgs/gary.png" width="45" class="rounded-circle" alt="" />
                      </span>
                    </td>
                    <td class="py-3">
                      <span *ngIf="item?.social_tag">
                        <a [href]="'/'+ item?.social_tag">
                          {{ item?.social_tag }}
                        </a>
                      </span>
                      <a *ngIf="!item?.social_tag" [href]="
                          'https://solscan.io/account/' + item.custom_wallet_address
                        " class="text-primary" target="_blank">{{ truncateString(item.wallet_address) }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center">
                <pagination-controls id="trades" [directionLinks]="true" [responsive]="true"
                  (pageChange)="getPage($event)"></pagination-controls>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <!-- Leader board section start -->
        <ng-container [ngbNavItem]="3">
          <a ngbNavLink>Top 100</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-md-4 offset-md-8">
                <form [formGroup]="myForm">
                  <ng-multiselect-dropdown name="badge" [placeholder]="'Select a badge to see who has it'"
                    [data]="badges" formControlName="badge" [settings]="dropdownSettings"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)">
                  </ng-multiselect-dropdown>
                </form>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover align-middle table-lg">
                <thead>
                  <tr>
                    <th class="ps-5 text-end py-3" style="width: 10px">#</th>
                    <th class="py-3">Name</th>
                    <th class="py-3">Badges</th>
                    <th class="text-end pe-7 py-3">Stacked {{ token }}</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- | paginate: { id:'leader', itemsPerPage: perpage2, currentPage: page, totalItems: size}; -->
                  <tr class="px-5" *ngFor="
                      let item of filterdLeaderboard
                        | paginate
                          : {
                              id: 'leader',
                              itemsPerPage: perpageL,
                              currentPage: 1,
                              totalItems: leaderboardSize
                            };
                      let i = index
                    ">
                    <td class="ps-5 text-end" style="width: 10px">
                      <div class="d-flex align-items-center">
                        <div class="icon">
                          <p>&nbsp;</p>
                          <p>
                            <span *ngIf="
                                item?.movement !== 0 &&
                                item?.direction == 'down'
                              ">
                              <i class="fa-solid text-danger fa-arrow-down"></i>
                            </span>
                            <span *ngIf="
                                item?.movement !== 0 && item?.direction == 'up'
                              ">
                              <i class="fa-solid text-success fa-arrow-up"></i>
                            </span>
                            <span *ngIf="
                                item?.movement !== 0 &&
                                item?.direction == 'same'
                              ">
                              <i class="fa-solid text-dark fa-minus"></i>
                            </span>
                            <!-- <span *ngIf="item?.movement === 0 ">
                                          <i class="fa-solid text-dark fa-minus"></i>
                                        </span> -->
                          </p>
                        </div>
                        <div class="d-block">
                          <p class="mb-0">
                            {{ item?.current_position }}
                          </p>
                          <p class="mb-0">
                            <span *ngIf="
                                item?.movement !== 0 &&
                                item?.direction == 'down'
                              " class="text-danger">
                              {{ item?.movement }}
                            </span>
                            <span *ngIf="
                                item?.movement !== 0 && item?.direction == 'up'
                              " class="text-success">
                              {{ item?.movement }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style="width: 35%">
                      <a *ngIf="item?.user?.social_tag  !== undefined" [href]="'/' + item?.user?.social_tag">
                        <div class="d-flex align-items-center">
                          <div class="img">
                            <img *ngIf="!item?.user?.profile_picture" width="40" height="40" class="rounded-circle"
                              [src]="coinDetails?.coin_img" alt="" />
                            <img *ngIf="item?.user?.profile_picture" id="my-image" [src]="item?.user?.profile_picture"
                              alt="" (error)="showPlaceholder(item)" />
                          </div>
                          <div class="d-block">
                            <h4 class="name">{{ item?.user?.name }}</h4>

                          </div>
                        </div>
                      </a>
                      <div *ngIf="item?.user?.social_tag  === undefined" class="d-flex align-items-center">
                        <div class="img">
                          <img *ngIf="!item?.user?.profile_picture" width="40" height="40" class="rounded-circle"
                            [src]="coinDetails?.coin_img" alt="" />
                          <img *ngIf="item?.user?.profile_picture" id="my-image" [src]="item?.user?.profile_picture"
                            alt="" (error)="showPlaceholder(item)" />
                        </div>
                        <div class="d-block">
                          <h4 class="name">{{ item?.user?.name }}</h4>

                        </div>
                      </div>
                    </td>
                    <td class="py-3" style="width: 35%">
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-col me-2" *ngFor="let item of item.badges.slice(0, 5)">
                          <span class="">
                            <ng-template class="text-center" #tipContent>
                              <h4 class="mb-3">{{ item?.badge_name }}</h4>
                              <p [innerHTML]="item?.description"></p>
                            </ng-template>
                            <img [src]="item.badge_image" width="60" height="60" class="rounded-circle" alt=""
                              [ngbTooltip]="tipContent" />
                          </span>
                          <!-- triggers="click:blur" -->
                        </div>
                        <span>
                          <ng-template class="text-start" #hoverContent>
                            <ul class="text-start list-unstyled">
                              <li *ngFor="let itemss of item.badges.slice(5)" class="mb-2">
                                <img [src]="itemss?.badge_image" width="30" height="30" class="" alt="" />
                                {{ itemss?.badge_name }}
                              </li>
                            </ul>
                          </ng-template>
                        </span>
                        <div *ngIf="item.badges?.length > 5" class="mp-4 rounded-circle bg-secondary"
                          style="cursor: pointer" [ngbTooltip]="hoverContent">
                          +{{ item.badges?.length - 5 }}
                        </div>
                      </div>
                    </td>
                    <td class="text-end pe-7">
                      <span class="text-color">
                        {{ item?.balance }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-end mt-3" *ngIf="!loading">
              <button *ngIf="!hideLoad" class="btn btn-clear btn-sm text-primary me-3" (click)="loadLess()">
                Show Less <i class="fa-solid fa-chevron-up"></i>
              </button>
              <button *ngIf="!hideMore" class="btn btn-clear btn-sm text-primary" (click)="loadMore()">
                Load More <i class="fa-solid fa-chevron-down text-primary"></i>
              </button>
            </div>
          </ng-template>
        </ng-container>
        <!-- <ng-container [ngbNavItem]="4">
          <a ngbNavLink>Events</a>
          <ng-template ngbNavContent>
            <div class="container">
              <div class="row mb-3">
                <div class="col-12">
                  <div class="d-flex mb-5 align-items-center">
                    <button class="btn btn-secondary rounded-pill" (click)="open(content3)">
                      Date Filter
                    </button>
                    <div style="width: 200px" class="ms-3">
                      <input type="text" class="form-control" placeholder="Search For Event" [(ngModel)]="searchEvent"
                        (ngModelChange)="search($event)" />
                    </div>
                    <button class="btn btn-clear text-primary" (click)="clearFilter()">
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>

              <div class="row cursor-pointer mb-3" *ngFor="
                  let item of myEvents
                    | paginate
                      : {
                          itemsPerPage: perpages,
                          currentPage: pages,
                          totalItems: sizes
                        }
                ">
                <div class="col-md-5 mb-3" (click)="View(item)">
                  <img [src]="item.image" class="eventImg" alt="" srcset=""
                    onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
                </div>
                <div class="col-md-7">
                  <div class="main">
                    <div class="contentt">
                      <div (click)="View(item)">
                        <h3 class="event-name" style="cursor: pointer">
                          {{ item.name }}
                        </h3>
                      </div>
                      <p class="lowfont">
                        {{
                        covertTZ(item.date, item.timezone) | date : "medium"
                        }}
                        {{ myZone?.abbreviation }}
                      </p>

                      <p class="lowfont">
                        Created by {{ item.creator.name | titlecase }}
                      </p>

                      <p class="mb-0 lowfont">
                        <img src="../../../assets/imgs/location.svg" alt="SVG Image" />&nbsp;&nbsp;
                        <span class="pl-1">{{ item.event_type | titlecase }} event</span>
                      </p>

                      <div class="mt-4">
                        <span class="badge topic-badge-prim" *ngIf="item?.event_status === 'Started'">{{
                          item.event_status }}</span>
                        <span class="badge topic-badge-danger" *ngIf="item?.event_status === 'Ended'">{{
                          item.event_status }}</span>
                        <span class="badge topic-badge" *ngIf="item?.event_status === 'Scheduled'">{{ item.event_status
                          }}</span>

                        <span class="badge topic-badge" *ngIf="
                            item.event_status == '' ||
                            item?.event_status == 'null' ||
                            item?.event_status == null
                          ">Scheduled</span>
                      </div>
                      <div class="row pt-4 align-items-center">
                        <div class="col-md-6">
                          <p class="mb-0">
                            <img src="../../../assets/imgs/users.svg" alt="SVG Image" />&nbsp;&nbsp;
                            {{ item.interested_candidates }}
                            Interested
                          </p>
                        </div>
                        <div class="col-md-6">
                          <div class="float-right">
                            <button [disabled]="item?.event_status !== 'Scheduled'"
                              class="btn btn-sm btn-prim-border btn-icon me-3" *ngIf="!isAttendee"
                              (click)="rsvpEvent(content4, item, content2)" placement="top" ngbTooltip="RSVP">
                              <i class="fa-solid fa-user-plus" aria-hidden="true"></i>
                            </button>
                            <button class="btn btn-sm sec-color btn-icon me-3" (click)="addToCalendar(item)"
                              placement="top" ngbTooltip="Add to calendar">
                              <i class="fa-solid fa-calendar-plus" aria-hidden="true"></i>
                            </button>
                            <button class="btn btn-sm btn-prim-border btn-icon" (click)="copyToClipboard(item)"
                              placement="top" ngbTooltip="Copy Link">
                              <i class="fa-solid fa-copy" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div></div>
                    </div>
                  </div>
                </div>
                <hr class="my-3" />
              </div>

              <div>
                <pagination-controls class="d-flex justify-content-center my-pagination" [directionLinks]="true"
                  [responsive]="true" (pageChange)="getPages($event)"></pagination-controls>
              </div>
            </div>
          </ng-template>
        </ng-container> -->
      </nav>

      <div [ngbNavOutlet]="nav" class="card bg p-3 mt-3"></div>
    </div>
  </div>

  <div class="row g-5 mt-5">
    <div *ngIf="pamentPages?.length <= 0" class="col-12">
      <h3 *ngIf="coinDetails?.bio">About {{ token }}</h3>
      <div [innerHTML]="coinDetails?.bio"></div>
    </div>
    <div *ngIf="pamentPages?.length > 0 && coinDetails?.bio" class="col-md-6">
      <h3 *ngIf="coinDetails?.bio">About {{ token }}</h3>
      <div [innerHTML]="coinDetails?.bio"></div>
    </div>

    <div class="col-md-3" *ngIf="pamentPages?.length == 1">
      <h3>Featured Offers</h3>
      <div>
        <div *ngFor="let item of pamentPages">
          <div class="slide">
            <a [href]="social_pay_url + item.page_link" target="_blank">
              <div class="card offer-card h-100">
                <img [src]="item.payment_image" onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
                  class="card-img-top img-fluid" alt="..." />
                <div class="card-body">
                  <h5 class="card-title truncate">{{ item.page_name }}</h5>
                  <div class="row align-items-end">
                    <div class="col-10">
                      <div class="card-text truncate" [innerHTML]="item.description"></div>
                    </div>
                    <div class="col-2">
                      <a [href]="social_pay_url + item.page_link" class="card-link" target="_blank">
                        <i class="fa-solid fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 offset-md-1" *ngIf="pamentPages?.length > 1">
      <h3>Featured Offers</h3>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let item of pamentPages">
          <div class="slide">
            <a [href]="social_pay_url + item.page_link" target="_blank">
              <div class="card offer-card h-100">
                <img [src]="item.payment_image" onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
                  class="card-img-top img-fluid" alt="..." />
                <div class="card-body">
                  <h5 class="card-title truncate">{{ item.page_name }}</h5>
                  <div class="row align-items-end">
                    <div class="col-10">
                      <div class="card-text truncate" [innerHTML]="item.description"></div>
                    </div>
                    <div class="col-2">
                      <a [href]="social_pay_url + item.page_link" class="card-link" target="_blank">
                        <i class="fa-solid fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

  <div class="card holder-card mt-5" *ngIf="coinRewards?.length > 0">
    <div class="card-body px-md-5 py-md-5">
      <h3>Holder Rewards</h3>
      <div class="row mt-3">
        <div class="col-lg-4 col-md-6 mb-3" *ngFor="let item of coinRewards">
          <div class="card bg h-100">
            <div class="card-body px-5">
              <div class="row align-items-start">
                <div class="col-xxl-3 col-xl-12 mb-3 holderno py-3">
                  {{ item?.amount }}
                </div>
                <div class="col-xxl-9 col-xl-12 d-block">
                  <h5>{{ item?.amount }} {{ token }}</h5>
                  <p class="card-text mb-0" [innerHTML]="item?.description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-7 g-5" *ngIf="coinDetails?.creator_bio">
    <div class="col-lg-5 col-md-6">
      <div class="creator-img rounded">
        <img [src]="coinDetails?.creator_img" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="col-lg-7 col-md-6">
      <p>WHO IS BEHIND {{ token }} ?</p>
      <h3>{{ coinDetails?.creator_name }}</h3>
      <div>
        <a [href]="'/'+coinDetails.social_tag" class="btn btn-clear me-1 ps-0" placement="bottom"
          ngbTooltip="Gary Club">
          <img src="assets/imgs/gary.png" width="40" height="32" alt="">
        </a>
        <a [href]="socials_url?.facebook_url" target="_blank" class="btn btn-clear me-1 ps-0"
          *ngIf="socials_url?.facebook_url" placement="bottom" ngbTooltip="Facebook">
          <i class="fa-brands fa-facebook fa-2x"></i>
        </a>
        <a class="btn btn-clear me-1" target="_blank" *ngIf="socials_url?.twitter_url" [href]="socials_url?.twitter_url"
          placement="bottom" ngbTooltip="Twitter">
          <i class="fa-brands fa-twitter fa-2x"></i>
        </a>
        <a class="btn btn-clear me-1" [href]="socials_url?.instagram_url" target="_blank"
          *ngIf="socials_url?.instagram_url" placement="bottom" ngbTooltip="Instagram">
          <i class="fa-brands fa-instagram fa-2x"></i>
        </a>
        <a class="btn btn-clear me-1" *ngIf="socials_url?.discord_url" [href]="socials_url?.discord_url" target="_blank"
          placement="bottom" ngbTooltip="Discord">
          <i class="fa-brands fa-discord fa-2x"></i>
        </a>
        <a class="btn btn-clear me-1" *ngIf="socials_url?.youtube_url" [href]="socials_url?.youtube_url" target="_blank"
          target="_blank" placement="bottom" ngbTooltip="Youtube">
          <i class="fa-brands fa-youtube fa-2x"></i>
        </a>
        <a class="btn btn-clear me-1" *ngIf="socials_url?.twitch_url" [href]="socials_url?.twitch_url" target="_blank"
          target="_blank" placement="bottom" ngbTooltip="Twitch">
          <i class="fa-brands fa-twitch fa-2x"></i>
        </a>
        <a class="btn btn-clear me-1" *ngIf="socials_url?.external_url" [href]="socials_url?.external_url"
          target="_blank" target="_blank" placement="bottom" ngbTooltip="Website">
          <i class="fa-solid fa-globe fa-2x"></i>
        </a>
        <a class="btn btn-clear me-1" *ngIf="socials_url?.website_url" [href]="socials_url?.website_url" target="_blank"
          target="_blank" placement="bottom" ngbTooltip="Website">
          <i class="fa-solid fa-globe fa-2x"></i>
        </a>
      </div>
      <div [innerHTML]="coinDetails?.creator_bio"></div>
    </div>
  </div>

  <!-- <div class="row mt-7" *ngIf="token !== '$BAGS'">
    <div class="col-md-8">
      <h3>Proof of Work</h3>
      <h4>Every Dot is a day of work on {{ token }}</h4>
    </div>
    <div class="col-md-4">
      <div class="update">
        <div class="row align-items-center">
          <div class="col">
            <div class="circle ms-auto me-auto" [ngClass]="POWStats?.qualifies_for_reward ? 'green' : 'red'"></div>
          </div>
          <div class="col">
            <p>Live Score</p>
            <h4>{{ POWStats?.active_score }}</h4>
          </div>
          <div class="col">
            <p>$GARY earned</p>
            <h4>{{ POWStats?.total_reward | number : "0.0-2" }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-5">
      <app-pow [tokens]="token"></app-pow>
    </div>
  </div> -->
</div>

<ng-template #content let-modal>
  <div class="modal-header text-center">
    <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
    <div class="d-flex align-items-center justify-content-center text-center">
      <div class="me-3">
        <img [src]="coinDetails?.coin_img" class="img-fluid" alt="" srcset="" width="50" height="50"
          class="rounded-circle" />
      </div>
      <h5 class="mb-0">{{ token }}</h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-center" *ngIf="offers.length > 0">
    <h4 class="mdl">Quantity of token</h4>

    <ul class="list-unstyled mt-4" *ngFor="let item of filteredoffers | orderBy : order3">
      <li class="mb-3">
        <a [href]="item.link" target="_blank" class="btn btn-outline-primary btn-block py-3 monument">{{ item.amount }}
          {{ token }}</a>
      </li>
    </ul>
  </div>
  <div class="modal-body text-center" *ngIf="offers.length <= 0">
    <h4>There are no offers at this time</h4>
    <h4>Please try later</h4>
  </div>
  <div class="modal-footer text-center justify-content-center" *ngIf="offers.length > 0">
    <p>You are heading to an external site!</p>
  </div>
</ng-template>

<!-- ?comment -->
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      Select your preferred payment method:
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <!-- <h6>Enter your Social Connector details to login</h6> -->

    <form [formGroup]="paidForm">
      <div class="form-check">
        <label class="form-check-label" for="flexRadioDefault1">
          <input class="form-check-input" type="radio" name="payment_method" formControlName="payment_method"
            id="flexRadioDefault1" value="social_pay" />
          <div class="d-flex align-items-center me-5 mt-2">
            <span class="d-block">
              <div class="d-flex align-items-center">
                <img src="assets/imgs/logo-socialpay.png" height="50px" alt="" class="me-2" />
                <div class="d-block">
                  <h6 class="mb-0">SOCIAL</h6>
                  <h6 class="mb-0">PAY</h6>
                </div>
              </div>
            </span>
            <span class="ms-auto">
              {{ event.coin_symbol }} {{ event.price | number : "1.2-4" }}
            </span>
          </div>
        </label>

        <label class="form-check-label" for="flexRadioDefault2" *ngIf="event.stripe_amount">
          <input class="form-check-input" type="radio" name="payment_method" formControlName="payment_method"
            id="flexRadioDefault2" value="stripe" />
          <div class="d-flex align-items-center me-5">
            <span class="">
              <img
                src="https://pub-3a9bbf364b7e4fafb2ccf4e85d9eb22a.r2.dev/socialpay-merchant-images/6472b189dbbb81685238153.png"
                width="100px" alt="" />
            </span>
            <span class="ms-auto">
              ${{ event.stripe_amount | number : "1.2-4" }}
            </span>
          </div>
        </label>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <!-- <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button> -->
    <button class="btn btn-primary btn-block py-3" [disabled]="paidForm.invalid" (click)="payNow()">
      Make Payment
    </button>
  </div>
</ng-template>

<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Date</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-center">
    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
      outsideDays="hidden">
    </ngb-datepicker>

    <ng-template #t let-date let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="clear()">Cancel</button>
    <button class="btn btn-primary" (click)="filter()">Submit</button>
  </div>
</ng-template>

<ng-template #content4 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <h6>Enter your Social Connector details to login</h6>
    <form [formGroup]="joinForm">
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" name="email" class="form-control" formControlName="email"
          placeholder="Enter Social Connector Email" />
      </div>
      <div class="form-group">
        <label for="" class="form-label">Password &nbsp;
          <span><a href="https://app.socialconnector.io/auth/forgot-password">Forgot Password?</a></span>
        </label>
        <input type="password" name="password" class="form-control" formControlName="password"
          placeholder="Enter Social Connector Password" />
      </div>
    </form>

    <p class="pt-3" style="float:right;">
      New here?
      <a href="https://app.socialconnector.io/auth/registration" target="_blank">Create a FREE Account</a>
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="submit()" [disabled]="joinForm.invalid">
      Submit
    </button>
  </div>
</ng-template>