import { AppServiceService } from './../_services/app-service.service';
import { Component, OnInit } from '@angular/core';
import { NgbAccordionConfig, NgbAccordionModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { OrderPipe } from 'ngx-order-pipe';
import { InjectionToken } from '@angular/core';

// import { InjectionToken } from '@angular/core';

class InfiniteList {
  private data: number[] = [];
  private index = 0;

  constructor(private initialData: number[]) {
    this.data = initialData;
  }

  public next() {
    if (this.index >= this.data.length) {
      this.index = 0;
    }
    return this.data[this.index++];
  }
}

export const INFINITE_LIST = new InjectionToken<InfiniteList>('INFINITE_LIST');

export function provideInfiniteList(initialData: number[]) {
  return new InfiniteList(initialData);
}

export const InfiniteListProvider = {
  provide: INFINITE_LIST,
  useFactory: provideInfiniteList,
  deps: []
};

@Component({
  selector: 'app-token-lists',
  templateUrl: './token-lists.component.html',
  styleUrls: ['./token-lists.component.scss']
})

export class TokenListsComponent implements OnInit {
  disabled = false;
  isChecked: boolean
  showStats: boolean = true;
  analytics: any;
  tokenLists: any[] = [];
  totalLength: any;
  perPage = 10
  order = 'gary_locked'
  order2 = 'volume_24'
  hideLoad: boolean;
  reverse: boolean = true;
  direction: boolean;
  userFilter: any = []
  loading: boolean = true;
  totalTokenList: any[] = [];
  offers: any;
  token: any;
  tokenMarquee: any[] = [];
  trendingLists: any;
  private data: number[] = [];
  private index = 0;
  marquee2: any[] = [];
  filteredoffers: any;
  order3 = 'amount'
  nextCursor: any;
  constructor(config: NgbAccordionConfig, private app: AppServiceService, private router: Router, private orderPipe: OrderPipe, private modalService: NgbModal) {
    // this.data = initialData;
    config.closeOthers = true;
    // config.type = 'info';

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    // margin: 16,
    autoplay: true,
    slideTransition: 'linear',
    autoplayTimeout: 0,
    autoplaySpeed: 3000,
    // autoplayHoverPause: true,
    // autoplay: true,
    // autoplayTimeout: 1000,
    autoplayHoverPause: false,
    nav: false,
    // navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      760: {
        items: 5
      },
      1000: {
        items: 7
      }
    },
    responsiveRefreshRate: 0
    // nav: true
  }

  ngOnInit(): void {
    this.getAnalytics()
    this.getAllTOkens()
    this.getTrending()
    // this.getOffers()

  }

  initMarquee() {
    const list = document.querySelector("ul") as HTMLUListElement;

    // Get the list width
    const listWidth = list.getBoundingClientRect().width;

    // Get the container element
    const container = document.querySelector(".marquee") as HTMLDivElement;

    // Set the container width to the list width
    container.style.width = listWidth + "px";

    // Set the animation duration based on the number of list items
    const animationDuration = list.children.length * 5;

    // Set the animation property
    container.style.animation = `marquee ${animationDuration}s linear infinite`;

    // Add event listener to pause animation on mouse hover
    container.addEventListener("mouseover", function () {
      container.style.animationPlayState = "paused";
    });

    // Add event listener to continue animation on mouse leave
    container.addEventListener("mouseout", function () {
      container.style.animationPlayState = "running";
    });

  }
  checkStats() {
    // console.log(this.isChecked)
    if (this.isChecked) {
      this.showStats = false
    }
    else { this.showStats = true }
  }

  getAnalytics() {
    this.app.getAnalytics().subscribe({
      next: (res: any) => {
        // console.log('res re naw', res)
        this.analytics = res;
      }
    })
  }

  getAllTOkens() {
    this.loading = true
    this.app.getallTokens(this.nextCursor).subscribe({
      next: (res: any) => {
        console.log('tokens re naw reee', res)
        this.tokenLists = res.data.data
        this.nextCursor = res.data.meta.next_cursor
        this.totalTokenList = this.tokenLists
        this.totalLength = this.tokenLists.length
        this.tokenLists = this.orderPipe.transform(this.totalTokenList, this.order)

      },
      complete: () => {
        this.loading = false
        // this.initMarquee()
      }
    })
  }

  getMoreTokens() {

    if (this.nextCursor != null) {
      this.loading = true
      this.app.getallTokens(this.nextCursor).subscribe({
        next: (res: any) => {
          console.log('tokens re naw', res)
          this.nextCursor = res.data.meta.next_cursor
          // this.tokenLists = this.orderPipe.transform(this.totalTokenList, this.order)
          this.tokenLists.push(...res.data.data);
          this.totalLength = this.tokenLists.length
          this.perPage += 10

          // this.filteredTokenLists = this.orderPipe.transform(this.filteredTokenLists, this.order)

        },
        complete: () => {
          this.loading = false
          // this.initMarquee()
        }
      })
    }


  }

  getTrending() {
    this.loading = true
    this.app.getTrending().subscribe({
      next: (res: any) => {
        console.log('trend re naw', res)
        this.trendingLists = res
        // console.log('trend re naw', this.trendingLists)
        // this.totalTokenList = this.tokenLists
        // this.totalLength = this.tokenLists.length
        // this.tokenLists = this.orderPipe.transform(this.totalTokenList, this.order)
        this.tokenMarquee = this.orderPipe.transform(this.trendingLists, this.order2, true).slice(0, 20)
        // console.log(this.tokenMarquee)
        if (this.tokenMarquee.length > 0) {
          const list = new InfiniteList(this.tokenMarquee);
          for (let i = 0; i < 500; i++) {
            this.marquee2.push(list.next())
          }
          console.log('marquee2', this.marquee2)
        }

      },
      complete: () => {
        this.loading = false
        // this.initMarquee()
      }
    })
  }
  open(content, token) {
    this.token = token
    // console.log(this.token.coin_symbol)
    this.getOffers(content)

  }

  getOffers(content) {

    this.app.getCoinOffers(this.token.coin_symbol).subscribe({
      next: (res: any) => {
        console.log('offer re naw', res)
        this.offers = res.data
        this.filteredoffers = this.orderPipe.transform(this.offers, this.order3)
        console.log('filteredoffers re naw', this.filteredoffers)
      },
      complete: () => {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      }
    })
  }

  loadMore() {
    // console.log(this.perPage, this.totalLength)
    if (this.totalLength > this.perPage) {
      this.perPage += 5
    } else {
      this.hideLoad = true
    }
  }

  loadLess() {
    this.perPage = 5
    this.hideLoad = false
    // console.log(this.perPage, this.totalLength)
    // if (this.totalLength != this.perPage) {
    //   this.perPage -= 5
    // } else {
    //   // this.hideLoad = true
    // }
  }

  onClick(url: any) {
    console.log(url)
    this.router.navigateByUrl("/swap/sc_swap/" + url)
  }

  // sort(name: any) {
  //   this.order = name
  //   let test = name
  //   this.tokenLists.sort((a, b) => a.test.localeCompare(b.test));
  // }
  sort(key: any) {
    this.loading = true
    this.order = key
    this.reverse = !this.reverse;
    // if (this.reverse) {
    //   this.direction = false;
    // } else {
    //   this.direction = true;
    // }
    console.log(this.order)
    // console.log(this.orderPipe.transform(this.tokenLists, this.order, this.reverse, false))
    this.orderPipe.transform(this.tokenLists, this.order, this.reverse, false)
    this.loading = false
    // this.getUserPortfolio();
  }

  change(ev: any) {
    console.log(ev)
    // console.log(this.tokenLists)
    const result = this.tokenLists.filter((item: any) => item.coin_symbol)
    console.log(result)
  }

}
