import { PublicKey } from '@solana/web3.js';
import { data } from './../token-lists/token-details/series-data';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, NgZone, AfterViewChecked, AfterViewInit, OnDestroy } from '@angular/core'; import { ActivatedRoute } from '@angular/router';
import { AppServiceService } from '../_services/app-service.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WalletService } from '../_services/wallet.service';
import { getAssociatedTokenAddress } from '../../utils';
import { getAccountInfo } from 'src/utils/getAccountInfo';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { LoaderModalComponent } from '../event-calendar/event-calendar.component';
const api = `${environment.apiUrl}`;
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-profile-v2',
  templateUrl: './profile-v2.component.html',
  styleUrls: ['./profile-v2.component.scss']
})



export class ProfileV2Component implements OnInit, OnDestroy {
  @ViewChild('card') card: ElementRef;
  @ViewChild('activitycard') activitycard: ElementRef;
  @ViewChild('creatorIcard') creatorIcard: ElementRef;
  @ViewChild('supCard') supCard: ElementRef;
  social_tag: string;
  profile: any;

  noteForm: FormGroup
  joinForm: FormGroup;
  buyForm: FormGroup
  content: any;
  activityLog: any;
  createdEvents: any;
  rsvpEvents: any;
  supportedCommunities: any;
  POWStats: any;
  navti: any = 'attending';
  logedIn: boolean = false;
  supportersInfo: any;
  getIfSupporters: any;
  supporterPerks: any;
  showSlider = false;
  mintPages: any = [];
  collectibles: any = []
  socialPayPages: any = [];
  social_pay_url = environment.social_pay_url;
  mint_page_url = environment.mint_page_url;
  filt: any = 'First Support Date';
  solscan_url = 'https://solscan.io/token/'
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    margin: 16,
    autoHeight: true,

    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      700: {
        items: 3
      },
      900: {
        items: 4
      },
    },
    nav: true
  }
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    margin: 16,
    autoHeight: true,

    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 4
      },
      1000: {
        items: 4
      },
      1200: {
        items: 3
      }
    },
    nav: true
  }
  tokens: any;
  userdetails: any;
  usertoken: any = '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk';
  selected_coin = '$GARY'
  estimate: any;
  total_gary: any;
  usd_value: any;
  tokenBalance: any;
  disableSupport: boolean = true;
  resp: any;
  modalRef: any;
  actStatus: any;
  refresh: NodeJS.Timer;
  stakeBalance: Promise<any>;
  sellForm: FormGroup;
  sell_total_gary: any = '';
  sell_usd_value: any;
  transactions: any;
  size: any;
  perpage: any;
  page = 1
  newTnx: any;
  name: string;
  status: any;
  sol_value: any;
  creatordets: any;
  newnavto = 'creators';
  stakeStats: any;
  collectibleDet: any;
  checkIfSupport: any;
  feeGary: any;
  appFeeGary: any;
  coin_balance: any;
  feeUSD: number;
  feeSOL: number;
  appFeeUsd: number;
  appFeeSOl: number;
  loadingsell: boolean = false;
  loadingbuy: boolean = false;
  showSel: boolean;
  creatorprice: any;
  isCreator: boolean;
  colnavto: any = 'buy';
  loadbtn: boolean;
  cansupport: boolean = true;
  estimate2: any;
  feeSellGary: number;
  appsellFeeGary: number;
  currentPage: any = 1;
  pageSize = 3;
  nextCursor: any;
  supporters: any;
  referralFeeGary: number;
  referralFeeUsd: number;
  referralFeeSOl: number;
  referralsellFeeGary: number;
  interval: NodeJS.Timer;
  constructor(
    private route: ActivatedRoute,
    private service: AppServiceService,
    private modal: NgbModal,
    config: NgbModalConfig,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    public walletservice: WalletService,
    private clipboard: Clipboard,
  ) {
    this.social_tag = route.snapshot.paramMap.get('social_tag')
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    // config.backdrop = 'static';
    // config.keyboard = false;
    config.centered = true;

  }


  async ngOnInit() {
    this.userdetails = await this.service.getUser()
    console.log('Userdetails', this.userdetails)
    this.getstakePrice()
    this.getUserProfile()
    this.getSupporters()
    this.getIfSupporter()
    this.getBasicProfile()
    this.getSupporterPerks()
    this.getstakeCurrencies()
    this.getCreatedEvents()
    this.getActivitLog()
    this.getRSVPEvents()
    this.getStakeStats()
    this.getCommunities()
    this.initForm()
    this.getPOWStat()
    this.getMintPages()
    this.getSocialPayPages()
    this.getFeatuedCollectibles()
    this.getTransactions()
    if (this.service.isLogedIn()) {
      this.logedIn = true;
      this.checkforSupport()
      // this.tokenBalance = await this.getBalance(this.usertoken)
    }
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval)
    }

  }

  getstakePrice() {
    this.service.getPrice(this.social_tag).subscribe({
      next: (res: any) => {
        this.creatorprice = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  checkforSupport() {
    this.service.checkIfSupporter(this.social_tag).subscribe({
      next: (res: any) => {
        console.log('check supporter', res.data)
        this.checkIfSupport = res.data
      }
    })
  }

  // async ngAfterViewInit() {
  //   if (this.service.isLogedIn()) {
  //     this.logedIn = true;
  //     this.tokenBalance = await this.getBalance(this.usertoken)
  //   }
  // }

  getStakeStats() {
    this.service.getStakeStats(this.social_tag).subscribe({
      next: (res: any) => {
        console.log('stakeStats', res.data)

        this.stakeStats = res.data


      },
      error: (err: any) => {
        console.log('stakeStats', err)
      }
    })
  }

  joinTelegram() {
    this.service.joinTelegram(this.social_tag).subscribe({
      next: (res: any) => {
        if (res.success === true) {
          Swal.fire('', res.data, 'success')
        }
      },
      error: (err: any) => {
        Swal.fire('', err.error.message, 'error')
      }
    })
  }
  getstakeCurrencies() {
    this.service.getstakeCurrencies().subscribe({
      next: (res: any) => {
        console.log('currencies', res)
        this.tokens = res.data
      },
      error: (err: any) => {
        console.log('error', err)
      }
    })
  }
  getTransactions() {
    this.service.getTransactions(this.social_tag, this.page).subscribe({
      next: (res: any) => {
        console.log(res)
        this.transactions = res.data.data
        this.newTnx = this.transactions;
        const data = res.data;
        const meta = data.meta;
        console.log(meta)
        this.size = meta.total;
        this.perpage = meta.per_page;
      },
      error: (err: any) => {
        // Swal.fire()
        console.log(err)
      }
    })
  }
  getPage(ev) {
    this.page = ev
    this.getTransactions()
  }
  search() {
    this.newTnx = this.transactions;
    if (this.name === '') {
      this.newTnx = this.transactions;
    } else {
      this.newTnx = this.newTnx.filter(
        (res: { name: any }) =>
          String(res.name)
            .toLocaleLowerCase()
            .match(this.name.toLocaleLowerCase())
      );
    }
  }
  initForm() {
    this.noteForm = this.fb.group({
      amount: ['', Validators.required],
      message: ['', Validators.required],
      to: this.social_tag,
    })

    this.joinForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],

    });
    this.buyForm = this.fb.group({
      // currency: [],
      quantity: []
    })

    this.sellForm = this.fb.group({
      quantity: []
    })
  }
  navto(text) {
    this.navti = text;
  }
  sendNote(content, content2) {
    this.content = content
    if (this.service.isLogedIn()) {
      this.modal.open(content, {}).result
    }

    else {
      this.modal.open(content2, { backdrop: 'static' }).result
    }


  }

  newNavTo(text) {
    this.newnavto = text;
  }

  colNavTo(text) {
    this.colnavto = text;
  }


  async buyStake(content3, content2, content8) {
    this.loadingbuy = true
    this.content = content3

    if (this.service.isLogedIn()) {
      this.tokenBalance = await this.getBalance(this.usertoken)
      console.log(this.tokenBalance)
      this.coin_balance = await this.getBalance(this.supportersInfo.token_address)
      this.coin_balance = parseInt(this.coin_balance)
      this.getBuyEstimate()

      this.interval = setInterval(() => {
        this.getBuyEstimate()
      }, 10000);
      this.modal.open(content3, { backdrop: 'static' }).result
    }

    else {
      this.modal.open(content2, { backdrop: 'static' }).result
    }


  }

  async sellStake(content4, content2) {
    this.loadingsell = true
    this.content = content4

    if (this.service.isLogedIn()) {
      this.getsellEstimate2(1)

      this.sellForm.controls.quantity.setValue(1)
      this.coin_balance = await this.getBalance(this.supportersInfo.token_address)
      this.coin_balance = parseInt(this.coin_balance)
      this.getsellEstimate3(this.coin_balance)
      this.modal.open(content4, { backdrop: 'static' }).result
    }

    else {
      this.modal.open(content2, { backdrop: 'static' }).result
    }

  }

  getBasicProfile() {
    this.service.getBasicProfile(this.social_tag).subscribe({
      next: async (res: any) => {
        console.log('profile', res)
        this.supportersInfo = res.data

        if (this.supportersInfo?.buy_token_active) {
          this.cansupport = true
        } else {
          this.cansupport = false
        }
        this.stakeBalance = await this.getBalance(this.supportersInfo.token_address)
        if (await this.stakeBalance >= 1) {
          this.showSel = true
        } else {
          this.showSel = false
        }
        console.log('stakebalance', this.stakeBalance)

      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.getBuyEstimate()
      }
    })
  }

  getUserProfile() {
    this.service.getCreatorProfile(this.social_tag).subscribe({
      next: async (res: any) => {
        console.log('profile', res)
        this.profile = res.data
        if (this.profile.type === 'creator') {
          this.isCreator = true
        }
        else {
          this.isCreator = false
        }

        console.log('stakebalance', this.stakeBalance)

      },
      error: (err: any) => {
        console.log(err)
      }

    })
  }
  getActivitLog() {
    this.service.getActivityLog(this.social_tag, this.currentPage).subscribe({
      next: (res: any) => {
        console.log('activityLog', res)
        this.activityLog = res.data.data
        this.nextCursor = res.data.meta.next_cursor
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
  getMoreActivities() {
    if (this.nextCursor != null) {
      this.service.getActivityLog(this.social_tag, this.nextCursor).subscribe({
        next: (res: any) => {
          // console.log(res)
          this.nextCursor = res.data.meta.next_cursor
          this.activityLog.push(...res.data.data);
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }
  getSupporterPerks() {
    this.service.getSupporterPerks(this.social_tag).subscribe({
      next: (res: any) => {
        this.supporterPerks = res.data
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => { }
    })
  }
  getSupporters() {
    this.service.getSupporters(this.social_tag).subscribe({
      next: async (res: any) => {
        console.log('supporters info', res)
        this.supporters = res.data.data
        if (this.supportersInfo?.creator_i_support.length < 1) {
          this.newnavto == 'communities'
        }
      },
      error: (err: any) => {
        console.log(err)
      }

    })
  }

  getIfSupporter() {
    this.service.getIfSupporter(this.social_tag).subscribe({
      next: (res: any) => {
        console.log('getIfSupporters', res)
        this.getIfSupporters = res.data
        // console.log('Supporters', this.getIfSupporters);

      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getCreatedEvents() {
    this.service.getCreatedEvents(this.social_tag).subscribe({
      next: (res: any) => {
        // console.log(res)
        this.createdEvents = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getCommunities() {
    this.service.getCommunities(this.social_tag).subscribe({
      next: (res: any) => {
        console.log('supportedCommunities', res)
        this.supportedCommunities = res.data
      },
      error: (err: any) => {
        console.log(err)
      },
    })
  }

  getRSVPEvents() {
    this.service.getRSVPEvent(this.social_tag).subscribe({
      next: (res: any) => {
        // console.log(res)
        this.rsvpEvents = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  cancel() {
    this.loadingsell = false
    this.loadingbuy = false
    this.modal.dismissAll()
    this.noteForm.reset();
    this.joinForm.reset()
    // this.content = null
  }

  login() {
    const payload = {
      email: this.joinForm.value.email,
      password: this.joinForm.value.password,
      service: 'event'
    }

    this.service.login(payload).subscribe({
      next: async (res: any) => {
        // console.log(res)
        const token = res.data.token
        this.getUserByToken(token);
        const result = this.service.setAuthFromLocalStorage(res);
      },
      error: (err: any) => {
        console.log(err)
        // this.alertService.danger('error')
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        this.cancel()
      }
    })

  }
  getUserByToken(token) {
    this.service.getUserByToken(token).subscribe({
      next: (res: any) => {
        // this.userdetails = res
        console.log(this.userdetails)
        // console.log('User reeeeeeee ', res)
        this.service.saveUserToStorage(res)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        //  this.submit()
        // this.modal.open(this.content, {}).result
        window.location.reload()
      }
    })
  }


  submit() {
    const payload = {
      amount: this.noteForm.value.amount,
      message: this.noteForm.value.message,
      to: this.social_tag,
    }
    const header = `Send a note to ${this.social_tag}`
    console.log(payload)
    if (this.noteForm.value.amount >= this.profile?.minimum_note_amount) {
      this.service.sendNote(payload).subscribe({
        next: (res: any) => {
          // console.log(res)

          const message = `Thank you! Your note has been sent to ${this.social_tag}!`
          Swal.fire(header, message, 'success')
          // this.profile = res.data
        },
        error: (err: any) => {
          console.log(err)
          Swal.fire(header, err.error.message, 'error')
        },
        complete: () => {
          this.cancel()
          this.getUserProfile()
        }
      })
    }
    else {
      Swal.fire('', 'Amount is too low', 'warning')
    }


  }

  scrollDown() {
    this.card.nativeElement.scrollBy({
      top: 200,
      left: 0,
      behavior: 'smooth'
    });
  }
  get canScrollUp() {
    if (this.card) {
      return this.card.nativeElement.scrollTop > 0;
    }
    else return false
  }

  get canScrollDown() {
    if (this.card) {
      const scrollTop = this.card.nativeElement.scrollTop;
      const scrollHeight = this.card.nativeElement.scrollHeight;
      const clientHeight = this.card.nativeElement.clientHeight;
      return scrollTop + clientHeight < scrollHeight;
    }
    else return false
  }


  scrollUp() {
    // this.card.nativeElement.scrollTop -= 100;
    this.card.nativeElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  activityscrollDown() {
    // window.scrollBy(0, window.innerHeight);
    this.activitycard?.nativeElement.scrollBy({
      top: 200,
      left: 0,
      behavior: 'smooth'
    });
  }
  get activitycanScrollUp() {
    if (this.card) {
      return this.activitycard?.nativeElement.scrollTop > 0;
    }
    else return false
  }

  activityscrollUp() {
    // this.card.nativeElement.scrollTop -= 100;
    this.activitycard?.nativeElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  supscrollDown() {
    this.supCard.nativeElement.scrollBy({
      top: 300,
      left: 0,
      behavior: 'smooth'
    });
  }
  get csupanScrollUp() {
    if (this.supCard) {
      return this.supCard.nativeElement.scrollTop > 0;
    }
    else return false
  }

  get csupanScrollDown() {
    if (this.supCard) {
      const scrollTop = this.supCard.nativeElement.scrollTop;
      const scrollHeight = this.supCard.nativeElement.scrollHeight;
      const clientHeight = this.supCard.nativeElement.clientHeight;
      return scrollTop + clientHeight < scrollHeight;
    }
    else return false
  }

  supscrollUp() {
    // this.card.nativeElement.scrollTop -= 100;
    this.supCard.nativeElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  creatorIscrollDown() {
    // window.scrollBy(0, window.innerHeight);
    this.creatorIcard.nativeElement.scrollBy({
      top: 200,
      left: 0,
      behavior: 'smooth'
    });
  }
  get creatorIcanScrollUp() {
    if (this.creatorIcard) {
      return this.creatorIcard?.nativeElement?.scrollTop > 0;
    }
    else return false
  }

  get creatorIScrollDown() {
    if (this.creatorIcard) {
      const scrollTop = this.creatorIcard.nativeElement.scrollTop;
      const scrollHeight = this.creatorIcard.nativeElement.scrollHeight;
      const clientHeight = this.creatorIcard.nativeElement.clientHeight;
      return scrollTop + clientHeight < scrollHeight;
    }
    else return false
  }


  creatorIscrollUp() {
    // this.card.nativeElement.scrollTop -= 100;
    this.creatorIcard.nativeElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  filterSupporter(data) {
    if (data == 'first_purchase') {
      this.filt = 'First Support Date';
    } else if (data == 'owned') {
      this.filt = 'Supporter level';
    } else if (data == 'last_purchase') {
      this.filt = 'Most Recent Purchase';
    }
    this.service.getSupportersFilter(this.social_tag, data).subscribe({
      next: async (res: any) => {
        console.log('supportersInfo', res)
        this.supporters = res.data.data
        // console.log('Supporters', this.supportersInfo);
        this.stakeBalance = await this.getBalance(this.supportersInfo.token_address)
        console.log(this.stakeBalance)

      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
  getPOWStat() {
    this.service.getCreatorPOWStats(this.social_tag).subscribe({
      next: (res: any) => {
        this.POWStats = res.data
      }
    })
  }

  getMintPages() {
    this.service.getCreatorFeaturedMintPages(this.social_tag).subscribe({
      next: (res: any) => {
        this.mintPages = res.data
        // console.log('Mint page - ', res)
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
  getSocialPayPages() {
    this.service.getCreatorFeaturedSocialPayPages(this.social_tag).subscribe({
      next: (res: any) => {
        // console.log('Social pay pages - ', res)
        this.socialPayPages = res.data.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getFeatuedCollectibles() {
    this.service.getCreatorFeaturedCollectibles(this.social_tag).subscribe({
      next: (res: any) => {
        console.log('collectibles ', res)
        this.collectibles = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getSrc(image: any): string {
    return api + "/solana/nft/" + image || 'assets/imgs/nft.jpg';
  }

  async getCurrency(item) {

    // console.log('usertoken', ev.target.value)
    this.usertoken = item.token_address
    this.selected_coin = item.coin_symbol
    this.tokenBalance = await this.getBalance(this.usertoken)

    this.getBuyEstimate()
  }

  async getBalance(token) {
    console.log('token', token)

    let tokenABalance;
    try {

      if (token == 'So11111111111111111111111111111111111111112') {
        tokenABalance =
          (await this.walletservice.connection.getBalance(

            new PublicKey(this.userdetails.custodial_wallet)
          ));
        tokenABalance = tokenABalance / 1000000000
      } else {
        const associatedTokenA = await getAssociatedTokenAddress(
          new PublicKey(token),
          new PublicKey(this.userdetails.custodial_wallet)
        );

        const callerTokenAAccount = await getAccountInfo(
          this.walletservice.connection,
          associatedTokenA,
          'confirmed',
          TOKEN_PROGRAM_ID
        );
        // console.log(callerTokenAAccount)

        tokenABalance = (
          await this.walletservice.connection.getTokenAccountBalance(
            callerTokenAAccount.address
          )
        ).value.uiAmount;
      }
      // var
    } catch (error) {
      // console.log(error)
      tokenABalance = '0';
    }
    // console.log(tokenABalance)
    this.loadingsell = false
    this.loadingbuy = false

    return tokenABalance
  }

  getBuyEstimate() {
    // this.disableSupport = true
    // this.loadbtn = true
    const amount = 1
    const payload = {
      amount: amount,
      buyer_currency: this.usertoken,
      token_address: this.supportersInfo.token_address
    }

    this.service.buyEstimate(payload).subscribe({
      next: (res: any) => {
        console.log('buy Estimate', res)
        this.estimate = res.data
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {
        // this.disableSupport = true
        let fee_gary = (this.supportersInfo.fee_percentage / 100) * this.estimate.amount_gary
        this.feeGary = fee_gary
        this.feeUSD = fee_gary * parseFloat(this.estimate.gary_usd)
        this.feeSOL = this.feeUSD / parseFloat(this.estimate.sol_usd)
        // console.log(fee_gary)

        let app_fee_gary = (this.supportersInfo.app_fee_percentage / 100) * this.estimate.amount_gary
        this.appFeeGary = app_fee_gary
        this.appFeeUsd = app_fee_gary * parseFloat(this.estimate.gary_usd)
        this.appFeeSOl = this.appFeeUsd / parseFloat(this.estimate.sol_usd)


        let referral_fee_gary = (this.supportersInfo.referrer_fee_percentage / 100) * this.estimate.amount_gary
        this.referralFeeGary = referral_fee_gary
        this.referralFeeUsd = referral_fee_gary * parseFloat(this.estimate.gary_usd)
        this.referralFeeSOl = this.referralFeeUsd / parseFloat(this.estimate.sol_usd)

        this.total_gary = fee_gary + app_fee_gary + referral_fee_gary + this.estimate.amount_gary
        this.total_gary = parseFloat(this.total_gary)
        this.usd_value = (this.total_gary * this.estimate.gary_usd)
        this.usd_value = this.usd_value
        this.sol_value = this.usd_value / parseFloat(this.estimate.sol_usd)

        if (this.tokenBalance === undefined) {
          this.disableSupport = true
        } else {
          if (this.usertoken == "8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk" || this.usertoken == 'J3wWVGF52xoc6N4c4dmKVm9d4m4oR6AoKrA8ARCfQJSs') {
            if (this.total_gary > this.tokenBalance) {
              this.disableSupport = true
              console.log('true', this.tokenBalance, this.total_gary)
            } else {
              this.disableSupport = false

              console.log('false', this.tokenBalance, this.total_gary)
            }
          } else if (this.usertoken == 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v' || this.usertoken == 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB') {
            if (this.usd_value > this.tokenBalance) {
              this.disableSupport = true
            } else {
              this.disableSupport = false
            }
          } else if (this.usertoken == 'So11111111111111111111111111111111111111112') {
            if (this.sol_value > this.tokenBalance) {
              this.disableSupport = true
            } else {
              this.disableSupport = false
            }
          }
        }

      }
    })

    // console.log('estimate', payload)
  }

  async max() {
    this.stakeBalance = await this.getBalance(this.supportersInfo.token_address)
    let bal = Math.floor(await this.stakeBalance);
    this.sellForm.controls.quantity.setValue(bal);
    console.log(bal)
    this.getsellEstimate2(bal);
  }


  async getsellEstimate(ev) {
    let amount = Math.floor(ev.target.value)
    amount = parseInt(amount.toString());
    this.stakeBalance = await this.getBalance(this.supportersInfo.token_address)
    const payload = {
      amount: amount,
      token_address: this.supportersInfo.token_address
    }
    if (amount <= 0) {
      this.total_gary = 0
    }
    else {
      this.service.sellEstimate(payload).subscribe({
        next: (res: any) => {
          console.log(res)
          this.estimate = res.data
        },
        error: (err) => {
          console.log(err)
          Swal.fire('Error', err.error.message, 'error')
          this.estimate = 0
          this.total_gary = 0
        },
        complete: () => {
          let fee_gary = this.supportersInfo.fee_percentage / 100 * this.estimate.amount_gary
          this.feeSellGary = fee_gary
          let app_fee_gary = this.supportersInfo.app_fee_percentage / 100 * this.estimate.amount_gary
          this.appsellFeeGary = app_fee_gary
          let referral_fee_gary = this.supportersInfo.referrer_fee_percentage / 100 * this.estimate.amount_gary
          this.referralsellFeeGary = referral_fee_gary
          this.total_gary = this.estimate.amount_gary - (fee_gary + app_fee_gary + referral_fee_gary)
          this.sell_total_gary = Math.round(this.total_gary)
          if (amount > parseFloat(this.tokenBalance)) {
            this.disableSupport = true
          } else {
            this.disableSupport = false
          }
        }
      })
    }

    // console.log('estimate', payload)
  }

  async getsellEstimate2(ev) {
    let amount = ev
    amount = parseInt(amount.toString());
    this.stakeBalance = await this.getBalance(this.supportersInfo.token_address)
    const payload = {
      amount: amount,
      token_address: this.supportersInfo.token_address
    }
    if (amount <= 0) {
      this.total_gary = 0
    }
    else {
      this.service.sellEstimate(payload).subscribe({
        next: (res: any) => {
          console.log('sell estimate', res)
          this.estimate = res.data
        },
        error: (err) => {
          console.log(err)
          Swal.fire('Error', err.error.message, 'error')
          this.estimate = 0
          this.total_gary = 0
        },
        complete: () => {
          let fee_gary = this.supportersInfo.fee_percentage / 100 * this.estimate.amount_gary
          this.feeSellGary = fee_gary
          let app_fee_gary = this.supportersInfo.app_fee_percentage / 100 * this.estimate.amount_gary
          this.appsellFeeGary = app_fee_gary
          let referral_fee_gary = this.supportersInfo.referrer_fee_percentage / 100 * this.estimate.amount_gary
          this.referralsellFeeGary = referral_fee_gary
          this.total_gary = this.estimate.amount_gary - (fee_gary + app_fee_gary + referral_fee_gary)
          this.sell_total_gary = Math.round(this.total_gary)
          if (amount > parseFloat(this.tokenBalance)) {
            this.disableSupport = true
          } else {
            this.disableSupport = false
          }
        }
      })
    }

    // console.log('estimate', payload)
  }

  async getsellEstimate3(ev) {
    let amount = ev
    amount = parseInt(amount.toString());
    this.stakeBalance = await this.getBalance(this.supportersInfo.token_address)
    const payload = {
      amount: amount,
      token_address: this.supportersInfo.token_address
    }
    this.service.sellEstimate(payload).subscribe({
      next: (res: any) => {
        console.log('sell estimate 3', res)
        this.estimate2 = res.data
      },
      error: (err) => {
        console.log('sell estimate 3', err)
        Swal.fire('Error', err.error.message, 'error')

      },
      complete: () => {

      }
    })

    // console.log('estimate', payload)
  }


  Support(content8) {

    this.modalRef = this.modal.open(LoaderModalComponent, { backdrop: 'static' });
    const payload = {
      amount: 1,
      buyer_currency: this.usertoken,
      token_address: this.supportersInfo.token_address,
      amount_gary: this.estimate.amount_gary
    }
    console.log(payload)

    this.service.buyStake(payload).subscribe({
      next: (res: any) => {
        console.log(res)
        this.resp = res.data
      },
      error: (err) => {
        this.modalRef.dismiss();
        console.log(err)
        Swal.fire('Failed', err.error.message, 'error')
      },
      complete: () => {
        this.modal.dismissAll();
        this.modal.open(content8, { size: 'md' })
        // this.clear();
        // modalRef.dismiss();
      }
    })
  }
  clear() {
    // this.buyForm.reset()
    this.sellForm.reset()
    this.tokenBalance = '';
    // this.estimate = false;
    this.disableSupport = true;
    this.sell_total_gary = '';
    this.modal.dismissAll()
    this.usertoken = '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk';
    this.selected_coin = '$GARY'
    this.content = null;
    clearInterval(this.interval)
  }
  sell(content9) {
    this.modalRef = this.modal.open(LoaderModalComponent, { backdrop: 'static' });
    const payload = {
      amount: this.sellForm.value.quantity,
      token_address: this.supportersInfo.token_address
    }
    console.log(payload)

    this.service.sellStake(payload).subscribe({
      next: (res: any) => {
        console.log(res)
        this.resp = res.data
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {
        this.modal.dismissAll();
        this.modal.open(content9, { size: 'md' })
        // this.clear();
        // modalRef.dismiss();
      }
    })
  }


  checkPurchaseStatus(content8) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-custom rounded-pill',
        cancelButton: 'btn  btn-outline-primary rounded-pill mx-3',
      },
      buttonsStyling: false,
    });
    this.service.checkPurchaseStatus(this.resp.hash).subscribe({
      next: (res: any) => {
        console.log(res)
        this.actStatus = res.data.status
        let user;
        let url;
        if (this.actStatus === 'approved') {
          if (this.profile?.twitter !== null) {
            user = '@' + this.profile.twitter
            url = `https://twitter.com/intent/tweet/?text=I just bought a coin and supported ${user} at &hashtags=GaryClub`
          }

          else url = `https://twitter.com/intent/tweet/?text=I just bought a coin at &hashtags=GaryClub`
          console.log('success', this.actStatus)
          this.modalRef.dismiss()
          this.modal.dismissAll()
          this.modal.open(content8, { size: 'md' })
          // swalWithBootstrapButtons.fire({
          //   title: '',
          //   icon: 'success',
          //   html:
          //     ``,
          //   text: `Congrats! 🥳 Thank you for supporting ${this.social_tag}!`,
          //   showCloseButton: true,
          //   showCancelButton: true,
          //   focusConfirm: true,
          //   cancelButtonText: 'Done',
          //   confirmButtonText: 'Share to X',

          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     window.open(url, 'blank')
          //     setTimeout(() => {
          //       window.location.reload()
          //     }, 2000);
          //   } else {
          //     window.location.reload()
          //   }
          // })
        }
        else if (this.actStatus === "failed") {
          console.log('failed', this.actStatus)
          this.modalRef.dismiss()
          // clearInterval(this.refresh)
          this.modal.dismissAll()
          Swal.fire('Failed to Support', res.data.log, 'error')
        }
        else {
          setTimeout(() => {
            console.log('pending', this.actStatus)
            this.checkPurchaseStatus(content8)
          }, 3000);
        }

      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {

      }
    })
  }
  closeModal() {
    this.modal.dismissAll()
  }
  checkStatus() {
    location.href = "/user/transactions";
  }
  checkPurchaseStatussell() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-custom rounded-pill',
        cancelButton: 'btn  btn-outline-primary rounded-pill mx-3',
      },
      buttonsStyling: false,
    });
    this.service.checkPurchaseStatus(this.resp.hash).subscribe({
      next: async (res: any) => {
        console.log(res)
        this.actStatus = res.data.status

        if (this.actStatus === 'approved') {
          this.stakeBalance = await this.getBalance(this.supportersInfo.token_address)
          let user;
          let message;
          let displaymessage;
          if (this.actStatus === 'approved') {
            if (this.profile?.twitter !== null) {
              user = '@' + this.profile.twitter
            }

            else { user = this.social_tag }
            if (await this.stakeBalance >= 1) {
              message = `I just sold ${res.data.amount} coin(s) from ${user} at `;
              displaymessage = `you just sold ${res.data.amount} of ${this.social_tag} coin.`
            } else {
              message = `I just sold  ${res.data.amount} coin(s) and stopped supporting ${user} at `;
              displaymessage = `You no longer support ${this.social_tag}.`;
            }
          }
          const url = `https://twitter.com/intent/tweet/?text=${message}&hashtags=GaryClub`;
          console.log('success', this.actStatus)
          // clearInterval(this.refresh)
          this.modalRef.dismiss()
          this.modal.dismissAll()
          swalWithBootstrapButtons.fire({
            title: '',
            icon: 'success',
            html:
              ``,
            text: `You no longer support ${this.social_tag}.`,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: 'Share to X',
            cancelButtonText: 'Done',

          }).then((result) => {
            if (result.isConfirmed) {
              window.open(url, 'blank')
              setTimeout(() => {
                window.location.reload()
              }, 2000);
            } else {
              window.location.reload()
            }
          })
        }
        else if (this.actStatus === "failed") {
          console.log('failed', this.actStatus)
          this.modalRef.dismiss()
          // clearInterval(this.refresh)
          this.modal.dismissAll()
          Swal.fire('Failed to Support', res.data.log, 'error')
        }
        else {
          setTimeout(() => {
            console.log('pending', this.actStatus)
            this.checkPurchaseStatussell()
          }, 3000);
        }

      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {

      }
    })
  }

  viwCreator(item, content5) {
    this.creatordets = item
    console.log(this.creatordets)
    this.modal.open(content5)
  }


  viewCollectible(item, content6) {
    this.collectibleDet = item
    console.log(this.collectibleDet)
    this.modal.open(content6, { size: 'lg' })
  }


  limitText(text: string,): string {
    let maxLength = 20
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  }

  copyText(text) {
    // console.log(this.walletservice.wallet.publicKey);
    this.clipboard.copy(text);
    Swal.fire('Copied', '', 'success')
  }

}
