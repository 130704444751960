<div class="container">
    <div class="all-cent pt-2">

        <div class="all-cent airdrop-div mt-5 mb-3 pt-4 pb-4">
            <div class="container">
                <h2>$JUP Airdrop</h2>
                <p class="pt-1" *ngIf="airdropDetails.balance > 0">You have earned an airdrop for interacting with <br>
                    SocialConnector</p>
                <p class="pt-1" *ngIf="airdropDetails.balance < 1">You are not qualified for this drop</p>
                <h2 class="pt-2 pb-3" *ngIf="airdropDetails.balance > 0">{{(airdropDetails.balance) | numberWithCommas}} $JUP</h2>
                <button class="btn btn-primary claim-airdrop"
                    *ngIf="airdropDetails.balance > 0 && airdropDetails.claimed == false" (click)="claimAirdrop()"><i
                        *ngIf="loadingClaim" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Claim Airdrop</button>
                <button class="btn btn-primary claim-airdrop"
                    *ngIf="airdropDetails.balance > 0 && airdropDetails.claimed == true" disabled>Airdrop
                    Claimed</button>
            </div>
        </div>

    </div>
</div>