import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-item',
  templateUrl: './referral-item.component.html',
  styleUrls: ['./referral-item.component.scss']
})
export class ReferralItemComponent implements OnInit {
  @Input() item: any; // Define an Input property to receive data from the parent component
  @Input() app: any;
  @Input() user: any;
  constructor() { }

  ngOnInit(): void {
  }

}
