<div class="py-2 px-3" *ngIf="item?.activity === 'giraffe_buy'  ">
  <div class="d-flex mb-2 align-items-center">
    <p class="text-w-light text-muted mb-0">
      <span class="icon_padding me-2">
        <i class="fa-regular fa-sm fa-tag"></i>
      </span>
      GIRAFFE SALE
    </p>
    <div class="ms-auto ">
      <span class="text-muted">{{ item?.date }}</span>
    </div>
  </div>
  <div class="d-flex">
    <div class="d-block">
      <div class="imgs mb-2" *ngIf="item?.text?.image">
        <a [href]="item?.image_link">
          <img [src]="item?.text?.image?item?.text?.image:'assets/imgs/placeholder.png'" class="activityimg ">
        </a>
      </div>
      <div class="activity_content">
        <h6>
          <a [href]="'https://solscan.io/token/'+item?.text?.token_address" target="_blank" class="link">
            {{ item?.text?.name }}
          </a>
        </h6>
        <p class="mb-0">

          <a *ngIf="!item?.text?.seller" [href]="'https://solscan.io/account/'+item?.text?.seller_address"
            target="_blank">{{
            truncateString(item?.text?.seller_address)}}
          </a>

          <a *ngIf="item?.text?.seller" [href]="'/'+item?.text?.seller">
            {{ item?.text?.seller }}
          </a>
          &nbsp;
          <i class="fa-regular fa-arrow-right"></i>
          &nbsp;
          <a *ngIf="!item?.text?.buyer" [href]="'https://solscan.io/account/'+item?.text?.buyer_address"
            target="_blank">{{
            truncateString(item?.text?.buyer_address)}}
          </a>

          <a *ngIf="item?.text?.buyer" [href]="'/'+item?.text?.buyer">
            {{ item?.text?.buyer }}
          </a>
        </p>
      </div>
    </div>


    <div class="price">
      <span *ngIf="item?.text?.amount"> {{ item?.text?.amount |number :'1.0-4' }} SOL </span>
    </div>
  </div>
</div>