import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@full-fledged/alerts';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'angular-toastify';
import { AppServiceService } from 'src/app/_services/app-service.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { parseISO } from 'date-fns';
import { format, zonedTimeToUtc } from "date-fns-tz";
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
const share = `${environment.share}`;

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})
export class RsvpComponent implements OnInit {
  id: string;
  event: any;
  fromdate: any;
  zone: any;
  todate: any;
  users: any;
  isAttendee = true;
  attendees: any;

  constructor(
    private service: AppServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private datePipe: DatePipe,
    private modal: NgbModal,
    config: NgbModalConfig,
    private fb: FormBuilder,
    private _toastService: ToastService,
    private alertService: AlertService,
    private clipboard: Clipboard
  ) {
    this.id = route.snapshot.paramMap.get('id');
    console.log(this.id)


    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  ngOnInit(): void {
    this.getEventById()
    // this.getUser()
  }

  getEventById() {
    this.service.getSingleEvent(this.id).subscribe({
      next: (res: any) => {
        console.log(res);
        this.event = res.data
        this.attendees = res.data.attendees
        // console.log(this.attendees)
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getUser()
        this.getZOneIp()
        if (this.event.redirect_url !== null) {
          window.location.href = this.event.redirect_url
        }

      }
    })
  }

  async getUser() {
    const a = await this.service.getUser()
    this.users = a
    console.log(this.users)
    if (this.users) {
      this.isAttendee = this.isRSVPed()
      console.log('Is attendee', this.isAttendee)
    }

  }
  isRSVPed() {
    for (const item of this.attendees) {
      if (item.user_id === this.users.id.toString()) {
        return true;
      }
    }
    return false;
  }
  navigateBack() {
    this.router.navigateByUrl('/events/' + this.id)
  }

  getZOneIp() {
    this.service.getZoneByIp().subscribe({
      next: (res: any) => {
        // console.log(res)
        this.zone = res
      },

      error: (err) => {
        console.log(err)
      },
      complete: () => {
        this.fromdate = this.covertTZ(this.event?.date, this.event.timezone, this.zone?.timezone)
        this.todate = this.covertTZ(this.event?.end_date, this.event.timezone, this.zone?.timezone)

        // console.log(this.fromdate)
      }

    })
  }
  isValidTimezoneFormat(timezone: string): boolean {
    // Regular expression to match the format 'Continent/City' or 'Continent/Country/City'
    const timezoneRegex = /^[A-Za-z_\-/]+(\/[A-Za-z_\-/]+)*$/;

    // console.log(timezoneRegex.test(timezone))
    // Check if the input timezone matches the regular expression
    return timezoneRegex.test(timezone);
  }

  covertTZ(fromDate: string, fromTz: string, toTz: string): string {
    if (!this.isValidTimezoneFormat(fromTz)) {
      // throw new Error("Invalid timezone format. Please use the format 'Continent/City'.");
      return fromDate
    }
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(fromDate, fromTz, toTz)
    if (fromTz === userTimezone) {
      return fromDate
    } else {
      const fromDatetime = parseISO(fromDate);


      // Convert the datetime to UTC
      const utcDatetime = zonedTimeToUtc(fromDatetime, fromTz);

      // Convert the UTC datetime to the target timezone
      const toDatetime = format(utcDatetime, 'yyyy-MM-dd HH:mm:ssXXX', {
        timeZone: userTimezone,
      });

      return toDatetime;
    }
  }

  getFormattedDate(date: any) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = this.datePipe.transform(date, 'yyyyMMdd\'T\'HHmmss', userTimezone);

    return formattedDate
  }


  addToCalendar(item) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
      name: item.name,
      startDate: this.getFormattedDate(this.fromdate),
      endDate: this.getFormattedDate(this.todate),
      ctz: userTimezone,
      details: item.description,
      location: item.location
    }

    // console.log(data)

    const params = new URLSearchParams();
    params.set('action', 'TEMPLATE');
    params.set('text', data.name);
    params.set('dates', data.startDate + '/' + data.endDate);
    params.set('ctz', data.ctz);
    params.set('details', data.details);
    params.set('location', data.location);

    const apiUrl = 'https://calendar.google.com/calendar/render';

    const link = apiUrl + '?' + params.toString();

    const anchor = this.elementRef.nativeElement.appendChild(document.createElement('a'));
    anchor.href = link;
    anchor.target = '_blank';

    anchor.click();

    // Cleanup: remove the anchor element
    anchor.remove();



  }

  // openTwitter(event) {
  //   const url = `https://twitter.com/intent/tweet/?text=${event.name}&url=${window.location.href}&hashtags=SocialConnector&via=GarydotClub`


  //   console.log(url)
  //   // window.location.href = url
  //   window.open(url, 'blank')
  // }

  openTwitter(event) {
    const url2 = `${share}/${event.id}`
    console.log(url2)
    const url = `https://twitter.com/intent/tweet/?text=${event.name}&url=${url2}&hashtags=SocialConnector&via=GarydotClub`


    console.log(url)
    // window.location.href = url
    window.open(url, 'blank')
  }



  copyToClipboard(event) {
    const url = `${share}/${event.id}`
    // const a = window.location.href
    this.clipboard.copy(url)
    // this.alertService.success('Copied to clipboard');
    Swal.fire('Copied to clipboard', '', 'success')
  }

  back() {
    this.router.navigateByUrl('/events/' + this.id)
  }
}
