<div class=" relative">
  <div class="overlay mt-5" style="height: 200px;" *ngIf="loading">
  </div>
  <div *ngIf="!loading">
    <div class="text-center my-3 " *ngIf="errorMessage">
      <p> {{ errorMessage }} </p>
    </div>


    <div class="row " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="loadMore()">
      <div class="col-md-12">
        <ul class="list-unstyled border">

          <li class="border-bottom" *ngFor="let item of app.activityLogs">

            <!-- <app-post-item [item]="item" [user]="user" [app]="app"></app-post-item>
            <app-sale-item [item]="item" [user]="user" [app]="app"></app-sale-item>
            <app-reward-item [item]="item" [user]="user" [app]="app"></app-reward-item>
            <app-general-item [item]="item" [user]="user" [app]="app"></app-general-item>
            <app-offers-item [item]="item" [user]="user" [app]="app"></app-offers-item>
            <app-events-item [item]="item" [user]="user" [app]="app"></app-events-item>
            <app-pow-item [item]="item" [user]="user" [app]="app"></app-pow-item>
            <app-subscription-item [item]="item" [user]="user" [app]="app"></app-subscription-item>
            <app-referral-item [item]="item" [user]="user" [app]="app"></app-referral-item> -->
            <app-custom [item]="item" [user]="user" [app]="app"></app-custom>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>