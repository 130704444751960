<nav class="navbar navbar-expand-sm navbar-light mt-4">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
      <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" />
    </a>


  </div>
</nav>



<div class="container mt-5">
  <div class="row text-center" *ngIf="!displayotpbox">
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <h3>Verify your email</h3>
      <!-- <p>A Crypto Creator Community</p> -->
      <p>Please enter the OTP sent to your email</p>
      <form (ngSubmit)="submit()" class="text-start mt-5">
        <ng-container *ngIf="errorState === errorStates.HasError">
          <div class="mb-lg-15 alert alert-danger">
            <div class="alert-text font-weight-bold">
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="message">
          <div class="mb-lg-15 alert alert-success text-center">
            <div class="alert-text font-weight-bold text-center">
              {{ message }}
            </div>
          </div>
        </ng-container>



        <div class="fv-row mb-10">
          <label class="form-label fw-bolder text-gray-900 fs-6">Enter otp</label>
          <code-input [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>
        <!-- <div class="fv-row mb-10">
          <div class="text-center">
            <a routerLink="/auth/change-email" class="link-primary" id="kt_login_forgot">
              Change Email address
            </a>
          </div>
        </div> -->
        <!--end::Form group-->
        <div class="fv-row mb-10">
          <div class="text-center">
            <a class="link-light" id="kt_login_forgot" (click)="resendOTP()" style="cursor: pointer">
              Didn't receive a code?
              <span class="text-primary">click here to resend code</span>
            </a>
          </div>
        </div>
        <!--begin::Form group-->


        <div class="text-center">
          <a class="btn btn-clear me-3" (click)="cancel()">Cancel</a>
          <button type="submit" class="btn btn-custom my-3" [disabled]="dasabled">

            <ng-container>
              <span class="indicator-label">Continue</span>
            </ng-container>
          </button>
          <!-- <button class="btn btn-custom my-3" [disabled]="resetPasswordForm.invalid">Continue</button> -->
        </div>
      </form>

      <!-- <a [href]="'/forgot_password'">Forgot Password?</a> -->




    </div>
  </div>
</div>


<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
