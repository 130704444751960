import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppServiceService } from '../_services/app-service.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  constructor(private app: AppServiceService) {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  searchTerm = '';
  searchResults: any = [];

  // Mock data for search results (you can replace this with your data source)
  mockData = [
    'Result 1',
    'Result 2',
    'Result 3',
    // Add more results here
  ];
  ngOnInit(): void {
    document.body.classList.add('dark-theme');
  }

  ngOnDestroy() {
    let theme = localStorage.getItem('theme');
    if (theme != 'dark-theme') {
      document.body.classList.remove('dark-theme');
      localStorage.setItem('theme', 'light');
    } else {
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    }
    // window.location.reload();
  }
  1

}
