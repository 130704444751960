import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../_services/app-service.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-wen-airdrop',
  templateUrl: './wen-airdrop.component.html',
  styleUrls: ['./wen-airdrop.component.scss']
})
export class WenAirdropComponent implements OnInit {
  airdropDetails: any = {};
  user: any;
  loadingClaim: boolean = false;
  reference: any;
  actStatus: string;
  constructor(private app: AppServiceService, private router: Router,) { }

  ngOnInit(): void {
    this.getAirdropDetails()
  }
  async getUser() {
    const a = await this.app.getUser()
    this.user = a
    console.log('Userdetails', this.user)

  }
  getAirdropDetails() {
    this.app.getWenAirdropDetails().subscribe({
      next: (res: any) => {
        this.airdropDetails = res.data;
        console.log(this.airdropDetails);
      }
    })
  }
  checkStatus(reference) {
    this.app.checkClaimStatus(reference).subscribe({
      next: (res: any) => {
        this.actStatus = res.data.status

        if (this.actStatus === 'completed') {
          this.loadingClaim = false;
          Swal.fire('Congrats!🥳', res.data.message, 'success');
        }
        else if (this.actStatus == "failed") {
          this.loadingClaim = false;
          Swal.fire('Failed to claim', res.data.message, 'error')
        }
        else {
          setTimeout(() => {
            this.checkStatus(reference)
          }, 3000);
        }

      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {

      }
    })
  }
  claimAirdrop() {
    this.loadingClaim = true;
    this.app.claimWenAirdrop().subscribe({
      next: (res: any) => {
        this.reference = res.data.reference
        setTimeout(() => {
          this.checkStatus(this.reference)
        }, 3000);
      }
    })
  }
}
