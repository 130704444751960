import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppServiceService } from 'src/app/_services/app-service.service';
import { PusherService } from 'src/app/_services/pusher.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fam',
  templateUrl: './fam.component.html',
  styleUrls: ['./fam.component.scss']
})
export class FamComponent implements OnInit, OnDestroy {
  loading = true
  // famsactivityLogs: any = [];
  newloading: boolean = false;
  // page = 1
  nextCursor: any;
  totalLength: any;
  perPage: number;
  hideLoad: boolean = false;
  errorMessage: string = null;
  // page = 1
  timeout: NodeJS.Timeout;
  refresh: NodeJS.Timer;
  user: any;
  imgFile: any;
  param: void;
  constructor(
    public app: AppServiceService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private pusherService: PusherService,
  ) { }

  async getUser() {
    const a = await this.app.getUser()
    this.user = a
  }

  ngOnInit(): void {
    this.app.activityLogs = [];
    this.getUser();
    this.app.imgFile = "";
    this.app.selectedFile = null;

    this.famsActivtyLogs(this.nextCursor)
    this.app.buttonClick$.subscribe((param) => {
      this.param = param
      this.famsActivtyLogs(null, param);
    });
    this.refresh = setInterval(() => {
      this.famsActivtyLogs(null)
    }, 1200000);
  }



  sanitizeHTML(text: string) {
    return this.app.sanitizeHTML(text)
  }

  famsActivtyLogs(nextCursor, filterParam?: any) {
    this.app.famActivtyLogs(nextCursor, filterParam).subscribe({
      next: (res: any) => {
        this.errorMessage = null
        console.log('activities ', res)

        this.nextCursor = res.data.meta.next_cursor
        // this.totalTokenList = this.tokenLists

        this.app.activityLogs = res.data.data

        this.totalLength = this.app.activityLogs.length
        if (this.totalLength <= 0) {
          this.hideLoad = true
        }
        else {
          this.hideLoad = false
        }

      },
      error: (err: any) => {
        console.log(err)
        this.errorMessage = 'There seems to be an error, please wait while we try again '
        this.loading = false
        this.newloading = false
        setTimeout(() => {
          this.famsActivtyLogs(this.nextCursor,)
        }, 5000)
      },
      complete: () => {
        this.errorMessage = null
        console.log('Logs', this.app.activityLogs)
        this.loading = false
        this.newloading = false
      }
    })
  }


  loadMore() {


    if (this.nextCursor != null) {
      // this.loading = true
      this.newloading = true
      this.app.famActivtyLogs(this.nextCursor, this.param).subscribe({
        next: (res: any) => {
          // console.log('tokens re naw', res)
          this.nextCursor = res.data.meta.next_cursor
          // this.tokenLists = this.orderPipe.transform(this.totalTokenList, this.order)
          this.app.activityLogs.push(...res.data.data);
          this.totalLength = this.app.activityLogs.length
          this.perPage += 10

          // this.filteredTokenLists = this.orderPipe.transform(this.filteredTokenLists, this.order)

        },
        error: (err: any) => {
          console.log(err)
          this.loading = false
          this.newloading = false
          Swal.fire('Failed to load', err.error.message, 'error')
          // this.allActivtyLogs(this.nextCursor
        },
        complete: () => {
          this.errorMessage = null
          // this.loading = false
          this.newloading = false
          if (this.nextCursor === null) {
            this.hideLoad = true
          } else {
            this.hideLoad = false
          }
        }
      })
    } else {
      this.hideLoad = true
    }
    // this.allActivtyLogs(this.page + 1)
  }


  ngOnDestroy() {
    clearTimeout(this.timeout)
    clearInterval(this.refresh)
  }


}
