<div class="container">
  <div class="wrapper">
    <div class="card_wrap" *ngIf="user$ | async as _user">
      <div class="logo">
        <img src="assets/imgs/garylogowhite.png" alt="">
      </div>
      <h1>Just a few more steps</h1>
      <p>Get your Social Connector account running without a fuss</p>
      <div class="upload_details_sect_wrap">
        <div class="upload_indicator_wrap">
          <div class="ind-1" [class.active]="profileUploaded"></div>
          <div class="ind-2" [class.active]="walletUploaded"></div>
          <div class="ind-3" [class.active]="xLinked"></div>
          <div class="ind-4" [class.active]="monetizationActivated"></div>
        </div>
        <div class="upload_profile_pic_wrap" [ngClass]=" profileUploaded? 'add_border': '' ">
          <p>Upload a profile picture</p>
          <div class="avatar_wrap d-flex">
            <div class="avatar" onclick="document.getElementById('file-input').click();">
              <input type="file" #imageInput accept="image/*" id="file-input" (change)="processFile(imageInput)">
              <img id="avatar-image" *ngIf="!imgFile" class="w-100"
                [src]="_user.sc_profile_picture || '/assets/com006.svg'" alt="image">
              <img id="avatar-image" *ngIf="imgFile" [src]="imgFile" alt="image">
              <i class="fa-solid fa-plus"></i>
            </div>

            <!-- <span class="fa-solid fa-check bi-check-circle-fill" *ngIf="profileUploaded"></span> -->
          </div>
        </div>
        <div class="upload_profile_pic_wrap" [ngClass]=" walletUploaded? 'add_border': '' ">
          <p>Load 0.05 SOL in your wallet</p>
          <div class="button_wrap d-flex">
            <button *ngIf="!walletUploaded" id="loadwallet" (click)="loadWallet()">Load Wallet</button>
            <button *ngIf="walletUploaded" style="margin-right: 10px;" class="no-cursor actionCompleted"
              id="walletloaded">Wallet Loaded</button>
            <span class="bi bi-check-circle-fill" *ngIf="walletUploaded"></span>
          </div>
        </div>
        <app-modal [showModal]="walletModal"></app-modal>
        <div class="upload_profile_pic_wrap" [ngClass]=" xLinked? 'add_border': '' ">
          <p>Link your X account</p>
          <div class="button_wrap d-flex">
            <button *ngIf="!twitterActive" (click)="linkTwitter()" id="linkX">
              <ng-container *ngIf="isLoading">
                <span class="indicator-progress" [style.display]="'block'">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </ng-container>
              <ng-container *ngIf="!isLoading">
                <span class="indicator-labell">Link X</span>
              </ng-container>
            </button>
            <button *ngIf="twitterActive" style="margin-right: 10px;" class="no-cursor actionCompleted" id="linkX">
              <span class="indicator-labell">Linked</span>
            </button>
            <span id="boot-icon" class="bi bi-check-circle-fill mr-2" *ngIf="xLinked"></span>
          </div>
        </div>
        <div class="upload_profile_pic_wrap" [ngClass]=" monetizationActivated? 'add_border': '' ">
          <p>Activate Monetization</p>
          <div class="button_wrap d-flex">
            <button *ngIf="!monetizationActivated" [disabled]="disableMonetization" id="actiMon" (click)="activate()">
              <ng-container *ngIf="isLoading">
                <span class="indicator-progress" [style.display]="'block'">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </ng-container>
              <ng-container *ngIf="!isLoading">
                <span class="indicator-labell">
                  Activate
                </span>
              </ng-container>
            </button>
            <button *ngIf="monetizationActivated" style="margin-right: 10px;" class="no-cursor actionCompleted"
              id="actiMon">Activated</button>
            <span id="boot-icon" class="bi bi-check-circle-fill mr-2" *ngIf="monetizationActivated"></span>
          </div>
        </div>
        <div class="continue">
          <span *ngIf="!continueToDashoard">
            <a (click)="skip()" class="btn" id="continue_btn">Skip</a>
          </span>
          <span *ngIf="continueToDashoard">
            <a (click)="continue()" class="btn" id="continue_btn">Continue</a>
          </span>
        </div>
      </div>
    </div>

  </div>
</div>