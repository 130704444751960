<div class="">
  <div class="overlay" style="height: 400px;" *ngIf="loading"></div>

  <div class="row">
    <div class="col-md-9">
      <h3>Tower</h3>
    </div>
    <div class="col-md-3">
      <div class="search-container me-3">

        <input type="text" class="form-control " aria-describedby="basic-addon1" [(ngModel)]="searchValue"
          (ngModelChange)="handleSearch()" placeholder="Search Towers">

      </div>

    </div>
  </div>
  <div class="row mt-5" *ngIf="!loading && towers?.length <=0">
    <div class="col-12 text-center">
      <img src="assets/imgs/emptytower.png" alt="" srcset="">
      <p>No data to display</p>
    </div>
  </div>
  <div class="row mt-5">
    <div class=" col-lg-4 col-md-4 col-sm-6 col-12 mb-4" *ngFor="let item of towers">
      <div class="border rounded-4">
        <div class=" p-md-4">
          <h3 class="tower-title"> {{ item.name }} </h3>
          <div class="desc">
            <p> {{ truncateDescription(item.description) }} </p>
          </div>

          <div class="towerimg my-3">
            <span *ngFor="
                let items of item.segment_users.slice(0, 10)">
              <img [src]="items?.image || 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg'"
                class="rounded-circle" alt="" onerror="this.onerror=null;this.src=''" />
            </span>
            <small *ngIf="item.segment_users_count > 10">
              +<span> {{ item.segment_users_count - 10 }} </span>
            </small>
          </div>
          <!-- <p class="text-muted fs-7">Floor price</p>
          <span *ngIf="item.floor_price > 0; else noListings">${{ item.floor_price | number: '0.0-2' }}</span>
          <ng-template #noListings>No Listings</ng-template> -->

          <div class="mt-3">
            <a [routerLink]="['/tower-members', item.ref]" class="btn btn-outline-primary towerbtn rounded-pill me-3">
              Explore</a>
            <a [href]="item.link" target="_blank" rel="noopener noreferrer"
              class="btn btn-custom rounded-pill towerbtn">Join</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>