<div class="row g-0">
  <div class="col-md-2">
    <div class="trending d-flex align-items-center justify-content-center">
      <div class="ms-5 me-3"><i class="fa-solid fa-fire txt-white fa-2x"></i></div>
      <h3>Trending</h3>
    </div>
  </div>
  <div class="col-md-10 scroll">

    <div class="marquee">
      <ul class="list-unstyled" id="list">
        <li *ngFor="let item of marqueeLeaderboard">
          <div class="slide">
            <a [href]="'/' + item?.user?.social_tag">
              <div class="inline">
                <div class="maq">
                  <img *ngIf="!item?.user?.profile_picture" width="40" height="40" class="rounded-circle"
                    src="https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg" alt="">

                  <img *ngIf="item?.user?.profile_picture" src="{{ item?.user?.profile_picture }}" alt=""
                    onerror="this.onerror=null;
                                          this.src='https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg';">
                </div>
                <div class="block">
                  <h4> {{item?.user?.name}}</h4>

                  <p *ngIf="item?.movement  !== 0 && item?.direction == 'up'" class="text-success">
                    <i class="fa-solid text-success fa-arrow-up"></i>&nbsp; {{ item.movement }}
                  </p>
                  <p *ngIf="item?.movement  !== 0 && item?.direction == 'down'" class="text-danger">
                    <i class="fa-solid text-danger fa-arrow-down"></i>&nbsp; {{ item.movement }}
                  </p>
                  <p *ngIf="item?.movement  !== 0 && item?.direction == 'same'">
                    <i class="fa-solid text-dark fa-minus"></i>&nbsp; {{ item.movement }}
                  </p>
                  <p *ngIf="item?.movement === 0">
                    <i class="fa-solid text-dark fa-minus"></i>&nbsp; {{ item.movement }}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </li>

      </ul>
    </div>

  </div>
  <!-- <div class="col-md-10">
    <app-marquee [items]="leaderBoard"></app-marquee>
  </div> -->
</div>

<div class=" border py-3 mt-3 mt-md-5">

  <div class=" mt-3">
    <div class="">
      <div class="row px-4">
        <div class="col-md-9 mb-3 mb-md-0">
          <h3>Top 100</h3>
        </div>
        <div class="col-md-3 ">

          <form [formGroup]="myForm">
            <ng-multiselect-dropdown name="badge" [placeholder]="'Select a badge'" [data]="badges"
              formControlName="badge" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
              (onDeSelect)="onItemSelect($event)">
            </ng-multiselect-dropdown>
          </form>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-3">

      <table class="table align-middle table-borderless">
        <thead class="bg-secondary">
          <tr>
            <th class="ps-5 text-end py-3" style="width: 10px;">#</th>
            <th class="py-3" style="width: 10%;">
              Name
            </th>
            <th class="py-3">Badges</th>
            <th class=" pe-5 py-3">
              <div class="d-flex ">
                <span class="ms-auto">
                  Stacked $GARY
                </span>

              </div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr class="px-5"
            *ngFor="let item of filterdLeaderboard  | paginate: { id:'leader', itemsPerPage: perpage, currentPage: page, totalItems: size}; ">
            <td class="ps-5 text-end" style="width: 10px;">
              <div class="d-flex align-items-center">
                <div class="icon">
                  <p>&nbsp; </p>
                  <p>

                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'down'">
                      <i class="fa-solid text-danger fa-arrow-down"></i>
                    </span>
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'up'">
                      <i class="fa-solid text-success fa-arrow-up"></i>
                    </span>
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'same'">
                      <i class="fa-solid text-dark fa-minus"></i>
                    </span>
                    <!-- <span *ngIf="item?.movement === 0 ">
                      <i class="fa-solid text-dark fa-minus"></i>
                    </span> -->
                  </p>

                </div>
                <div class="d-block">
                  <p class="mb-0">
                    {{item?.current_position}}
                  </p>
                  <p class="mb-0">
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'down'" class="text-danger">
                      {{ item?.movement }}
                    </span>
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'up'" class="text-success">
                      {{ item?.movement }}
                    </span>


                  </p>
                </div>
              </div>

            </td>
            <td style="width: 20%;">
              <a *ngIf="item?.user?.social_tag  !== undefined" [href]="'/' + item?.user?.social_tag">
                <div class="d-flex align-items-center">
                  <div class="img">
                    <img *ngIf="!item?.user?.profile_picture" width="40" height="40" class="rounded-circle"
                      src="https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg" alt="">
                    <img *ngIf="item?.user?.profile_picture" [src]=" item?.user?.profile_picture " alt="" srcset=""
                      onerror="this.onerror=null;
                                            this.src='https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg';">
                  </div>
                  <div class="d-block">
                    <h4 class="name"> {{item?.user?.name}} </h4>

                  </div>

                </div>
              </a>
              <div *ngIf="item?.user?.social_tag  === undefined" class="d-flex align-items-center">
                <div class="img">
                  <img *ngIf="!item?.user?.profile_picture" width="40" height="40" class="rounded-circle"
                    src="https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg" alt="">
                  <img *ngIf="item?.user?.profile_picture" [src]=" item?.user?.profile_picture " alt="" srcset=""
                    onerror="this.onerror=null;
                                                          this.src='https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg';">
                </div>
                <div class="d-block">
                  <h4 class="name"> {{item?.user?.name}} </h4>

                </div>

              </div>
            </td>
            <td class="py-3">
              <div class="d-flex flex-row">
                <div class="d-flex flex-col me-2" *ngFor="let item of item.badges.slice(0,5)">
                  <span class="">
                    <ng-template class="text-center " #tipContent>
                      <h4 class="mb-3"> {{ item?.badge_name }} </h4>
                      <p [innerHTML]="item?.description"> </p>
                    </ng-template>
                    <img [src]="item.badge_image" width="60" height="60" class="" alt="" [ngbTooltip]="tipContent">
                  </span>

                </div>
                <span>
                  <ng-template class="text-start" #hoverContent>
                    <ul class="text-start list-unstyled">
                      <li *ngFor="let itemss of item.badges.slice(5)" class="mb-2">
                        <img [src]="itemss?.badge_image" width="30" height="30" class="" alt="">
                        {{ itemss?.badge_name }}
                      </li>
                    </ul>
                  </ng-template>
                </span>
                <div *ngIf="item.badges?.length > 5" class="mp-4 rounded-circle bg-secondary" style="cursor: pointer;"
                  [ngbTooltip]="hoverContent">+{{
                  item.badges?.length - 5 }}</div>
              </div>
            </td>
            <td class=" pe-5">
              <div class="d-flex ">
                <span class="text-color ms-auto">
                  {{ item?.balance | number : '1.0-4' }}
                </span>
              </div>

            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="text-center mt-3 " *ngIf="!loading">
    <button *ngIf="!hideLoad" class="btn btn-clear btn-sm text-primary me-3" (click)="loadLess()">Show Less <i
        class="fa-solid fa-chevron-up"></i></button>
    <button *ngIf="!hideMore" class="btn btn-clear btn-sm text-primary" (click)="loadMore()">Load More <i
        class="fa-solid fa-chevron-down  text-primary"></i></button>
  </div>
  <!-- leader board section table end -->

</div>