<div class="row">
  <div class="col-lg-3 col-md-4 mb-3 mb-md-0">
    <div class="border px-3 py-4">
      <form>
        <h4>Lock $GARY</h4>
        <div class="form-group">
          <label for="" class="form-label">Amount ($GARY)</label>
          <div class="input-group">
            <input type="number" class="form-control" placeholder="0.0" name="amount" [(ngModel)]="amount"
              (ngModelChange)="getMultiplier()">
            <button class="btn btn-outline-secondary btn-sm" type="button" (click)="setMax()">Max</button>
          </div>
          <span class="text-muted">Balance: {{ tokenBalance | number : '1.0-2' }} $GARY</span>
        </div>

        <div class="form-group">
          <label for="" class="form-label">Duration</label>
          <select name="inputMonth" id="" class="form-select " [(ngModel)]="inputMonth"
            (change)="convertMonthToSeconds()">
            <option value="null"></option>
            <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
      </form>
      <div class="d-flex align-items-center">
        <div class="d-block me-3">
          <p>Rewards Multiplier</p>
          <h3>{{ reward_multiplier | number}}X</h3>
        </div>
        <div class="d-block">
          <p>Total Weight</p>
          <h3> {{ total_weight|number }} </h3>
        </div>
      </div>

      <div class="mt-3 text-center">
        <div ngbDropdown class="" *ngIf="(connected$ | async) === false">
          <button type="button" class="btn btn-block btn-gradient " id="dropdownBasic1" ngbDropdownToggle>
            <i class="fa-solid fa-wallet"></i> &nbsp;&nbsp; Connect Wallet
          </button>
          <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu3 dropdown-menu-dark">
            <li *ngFor="let wallet of wallets$ | async">
              <button ngbDropdownItem class="dropdown-item2 d-flex align-items-center"
                (click)="onSelectWallet(wallet.adapter.name)">
                <span>
                  <img [src]="fixUnsafe(wallet.adapter.icon)" width="20" height="20" class="me-3" alt="" /></span>{{
                wallet.adapter.name }}
              </button>
            </li>
          </ul>
        </div>

        <div *ngIf="connected$ | async">
          <p class="mb-2"><span>
              <img [src]="fixUnsafe(walletIcon$ | async)" width="20" height="20" class="me-3" alt="" /></span>
            {{ truncate(publicKey$ | async) }} <button class="btn btn-sm btn-dark" (click)="onDisconnect()"
              *ngIf="connected$ | async">Disconnect</button></p>
          <button class="btn btn-block btn-custom pd" [disabled]="!amount || !resultSeconds || showLoad"
            (click)="lockGary()">LOCK
            $GARY <span *ngIf="showLoad"><i class="fa-duotone fa-spinner fa-spin-pulse"></i></span>
          </button>
        </div>


      </div>

      <!-- <div class="text-center mt-4">
            <button class="btn btn-block btn-custom pd" [disabled]="!amount || !resultSeconds || showLoad"
              (click)="lockGary()">LOCK $GARY <span *ngIf="showLoad"><i
                  class="fa-duotone fa-spinner fa-spin-pulse"></i></span>
            </button>
          </div> -->
      <div class="mt-2" *ngIf="inputMonth">
        <p class="mb-1"><span><i class="fa-light fa-triangle-exclamation"></i></span>&nbsp;IMPORTANT NOTICE</p>
        <p>Please be aware when locking your $GARY for future rewards, you will receive a token <a
            [href]="'https://solscan.io/token/'+ stakePoolJSON?.stakeMint">{{
            shrotstring(stakePoolJSON?.stakeMint) }}</a> it is required to be able to redeem your deposit and
          rewards. You will see <a [href]="'https://solscan.io/token/'+ stakePoolJSON?.stakeMint">{{
            shrotstring(stakePoolJSON?.stakeMint) }}</a> being burned on withdrawal, this is inconsequential.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-8 mb-3 mb-md-0">
    <div class="border py-4">
      <div class="px-3 mb-4">
        <h4>$GARY Locked</h4>
      </div>
      <div class="lockedheight">
        <div class="table-responsive">
          <table class="table align-middle">
            <thead class="thead-default bg-secondary ">
              <tr>
                <th class="ps-3 ps-md-4">AMOUNT</th>
                <th>WEIGHT</th>
                <th>LOCK DATE</th>
                <th>UNLOCK DATE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of activeStake">
                <td class="ps-3 ps-md-4"> {{ getAmount(item?.depositAmount) }} </td>
                <td><span class="badge rounded-pill px-3 py-2" [ngClass]="{
                          'bg-secondary': item?.weight === 1,
                          'bg-warning': item?.weight > 1 && item.weight <2 ,
                          'bg-info': item?.weight >= 2
                        }"> {{ item?.weight | number}}X</span></td>
                <td> {{ timestampToNormalTime(item?.depositTimestamp) | date: 'MMM dd yyyy, h:mm: a' }} </td>
                <td> {{ secondsToMonths(item?.lockupDuration, item?.depositTimestamp) |date: 'MMM dd yyyy, h:mm: a'
                  }} </td>
                <td class=" pe-3 pe-md-5">
                  <div class="d-flex">
                    <button class="ms-auto btn btn-clear rounded-pill border-primary me-2 btn-sm"
                      (click)="openclaim(content2, item)">CLAIM</button>
                    <button class="btn btn-custom rounded-pill btn-sm"
                      [disabled]="isDateGreaterThanToday(secondsToMonths(item?.lockupDuration, item?.depositTimestamp))"
                      (click)="withdraw(item)">
                      <span *ngIf="!showLoadwit">WITHDRAW</span>
                      <span *ngIf="showLoadwit"><i class="fa-duotone fa-spinner fa-spin-pulse"></i></span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-body relative p-md-5 p-sm-3">
    <button class="close btn btn-clear" (click)="modal.dismiss('Close click')"><i
        class="fa-solid fa-xmark fa-xl fa-beat"></i></button>
    <div class="text-center">
      <h3>Claim Rewards</h3>
      <p class="mb-1">Your claimable amount is {{ item?.claimable }} </p>
      <br>
      <div class="text-center">
        <span *ngIf="showLoadclaim"></span>
      </div>
    </div>


    <button class="btn btn-block btn-custom pd mt-3" [disabled]="item?.claimable <= 0 || showLoadclaim"
      (click)="claim()">
      <span *ngIf="!showLoadclaim">CLAIM</span>
      <span *ngIf="showLoadclaim"><i class="fa-duotone fa-spinner fa-spin-pulse"></i> CLAIMING...</span>
    </button>
  </div>
</ng-template>
