<div class="container mt-lg-4 mt-2">
  <div class="row">
    <div class="col-md-4 mb-3 mb-md-0 ">
      <div class="border p-3">
        <p>Total Locked</p>
        <h3> {{ pool_stats?.totalLocked |number : '1.0-2' }} </h3>
      </div>

    </div>

    <div class="col-md-4 mb-3 mb-md-0">
      <div class="border p-3">
        <p>Average Weight</p>
        <h3> {{ pool_stats?.averageStakeWeight |number: '1.0-2'}}X </h3>
      </div>

    </div>

    <div class="col-md-4 mb-3 mb-md-0">
      <div class="border p-3">
        <p>Total weight Locked</p>
        <h3> {{ pool_stats?.totalWeightedLocked |number: '1.0-2'}} </h3>
      </div>

    </div>

  </div>

  <div class="row mt-5">
    <div class="col-12 mb-3">
      <h3>$GARY LOCK &nbsp;&nbsp; <button class="btn btn-lock rounded-pill" (click)="open(content)">How it works <i
            class="fa-duotone fa-circle-info ms-3 "></i></button> </h3>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-md-3">
      <p *ngIf="exchangemessage">{{exchangemessage}} </p>
      <div class="border">
        <nav class="nav nav-pills nav-fill" style="cursor: pointer;">
          <a class="nav-link" [routerLink]="['/lock_gary/external_lock']" routerLinkActive="active">External Wallet</a>
          <a class="nav-link" [routerLink]="['/lock_gary/sc_lock']" routerLinkActive="active">SC wallet</a>
        </nav>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>


<ng-template #content let-modal>
  <div class="modal-body relative p-md-5 p-sm-3">
    <button class="close btn btn-clear" (click)="modal.dismiss('Close click')"><i
        class="fa-solid fa-xmark fa-xl fa-beat"></i></button>
    <div class="text-center">
      <h3>HOW IT WORKS</h3>
    </div>
    <p class="mb-1">Lock your $GARY to receive % from the GARY Rewards Pool</p>
    <ul>
      <!-- <li>250k $GARY is loaded into the $GARY Rewards Pool for this reward period</li> -->
      <li>More $GARY locked = Larger % from $GARY Rewards Pool</li>
    </ul>
    <p class="mb-1">(T)multiplier: Multiply to the % of $GARY Rewards Pool with amount of time locked</p>
    <ul>
      <li>1 Month - 1X</li>
      <li>3 Months - 1.8X</li>
      <li>6 Months - 3X</li>
    </ul>
    <p>% from $GARY Rewards Pool X (T)multiplier = $GARY claim during locking period</p>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-body relative p-md-5 p-sm-3">
    <button class="close btn btn-clear" (click)="modal.dismiss('Close click')"><i
        class="fa-solid fa-xmark fa-xl fa-beat"></i></button>
    <div class="text-center">
      <h3>Claim Rewards</h3>
      <p class="mb-1">Your claimable amount is {{ item?.claimable }} </p>
      <br>
      <div class="text-center">
        <span *ngIf="showLoadclaim"></span>
      </div>
    </div>


    <button class="btn btn-block btn-custom pd mt-3" [disabled]="item?.claimable <= 0 || showLoadclaim"
      (click)="claim()">
      <span *ngIf="!showLoadclaim">CLAIM</span>
      <span *ngIf="showLoadclaim"><i class="fa-duotone fa-spinner fa-spin-pulse"></i> CLAIMING...</span>
    </button>
  </div>
</ng-template>
