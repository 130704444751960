import { Injectable } from '@angular/core';
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from '../../utils/index';
// import { Token } from '@solana/spl-token';
import {
  WalletAdapterNetwork,
  WalletName,
  WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { ConnectionStore, WalletStore } from '@heavy-duty/wallet-adapter';
import {
  PublicKey,
  SystemProgram,
  Transaction,
  Keypair,
  Connection,
  clusterApiUrl,
} from '@solana/web3.js';
import base58 from 'bs58';
import { defer, from, throwError } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';
import { AnchorProvider, Provider } from "@project-serum/anchor"
// import { AnchorProvider, Provider, Provider, Wallet } from '@project-serum/anchor';

import BN from 'bn.js';
// import { EXPLORER_ROOT, NETWORK } from '../../config';
// import { Provider } from 'rly-js/node_modules/@project-serum/anchor';
import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from '../../environments/environment';
import { AppServiceService } from './app-service.service';
import { HttpClient } from '@angular/common/http';
import NodeWallet from '@project-serum/anchor/dist/cjs/nodewallet';

declare interface coinInfo {
  img: string;
  name: string;
  token_address: string;
}
type defaultSwapValues = {
  tokenSwapInfo: string;
  tokenA: string;
  tokenB: string;
  amountIn: number;
  amountOut: number;
};
type swapResponse = {
  tx: string | null;
};

const defaultSwapResponse = {} as swapResponse;

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  readonly wallets$ = this._hdWalletStore.wallets$;
  readonly wallet$ = this._hdWalletStore.wallet$;
  readonly walletName$ = this.wallet$.pipe(
    map((wallet) => wallet?.adapter.name || null)
  );
  readonly walletIcon$ = this.wallet$.pipe(
    map((wallet) => wallet?.adapter.icon || null)
  );
  readonly ready$ = this.wallet$.pipe(
    map(
      (wallet) =>
        wallet &&
        (wallet.adapter.readyState === WalletReadyState.Installed ||
          wallet.adapter.readyState === WalletReadyState.Loadable)
    )
  );
  readonly connected$ = this._hdWalletStore.connected$;
  readonly publicKey$ = this._hdWalletStore.publicKey$;
  public tokenABalance = 0;
  public tokenBBalance = 0;

  // wallet = this._hdWalletStore.aanchorWallet$ as Wallet;
  connection: any;
  provider: any;
  wallet: any;
  alert: string = '';
  tnxlink: string | undefined;
  refresh: NodeJS.Timeout | undefined;
  menuItems3: any;
  rpc_url: any;
  rpc_ws: any;
  exchange_status: any;
  exchange_message: any;
  exchange_offline_message: any;
  exchange_wormhole_status: any;
  exchange_wormhole_offline_message: any;
  exchange_wormhole_message: any;
  hdwallet: WalletStore | undefined;
  bagBalance: any;
  showPrivate: boolean | undefined;
  constructor(
    private readonly _hdConnectionStore: ConnectionStore,
    private readonly _hdWalletStore: WalletStore,
    private alertservice: AlertService,
    private app: AppServiceService,
    private http: HttpClient,
  ) {
    this.app.getWormholeData().subscribe({
      next: (res: any) => {
        // console.log(res.data);
        this.rpc_url = res.data.rpc_url.config_value;
        this.rpc_ws = res.data.rpc_ws_url.config_value;
        this.exchange_status = res.data.exchange_status.config_value;
        this.exchange_message = res.data.exchange_message.config_value;
        this.exchange_offline_message =
          res.data.exchange_offline_message.config_value;
        this.exchange_wormhole_status =
          res.data.exchange_wormhole_status.config_value;
        this.exchange_wormhole_message =
          res.data.exchange_wormhole_message.config_value;
        this.exchange_wormhole_offline_message =
          res.data.exchange_wormhole_offline_message.config_value;
        this.connection = new Connection(this.rpc_url, {
          commitment: 'confirmed',
          wsEndpoint: this.rpc_ws,
          confirmTransactionInitialTimeout: 120000,
        });

        this._hdWalletStore.anchorWallet$.subscribe((wallet) => {
          if (wallet) {
            this.wallet = wallet;
            console.log('Wallet', this.wallet);
            this.provider = new AnchorProvider(this.connection, this.wallet, {
              commitment: 'confirmed',
            });
            // console.log(this.provider)
            this.hdwallet = this._hdWalletStore;
            this.getBags();
          } else {
            this.wallet = wallet;
            // console.log(this.wallet);
            const walletKeyPair = Keypair.generate();
            this.provider = new AnchorProvider(this.connection, new NodeWallet(walletKeyPair), {
              commitment: 'confirmed',
            });
            // //console.log(this.provider);
            this.hdwallet = this._hdWalletStore;
          }
          // else console.log('wallet not found')
        });

        // console.log(this.menuItems2);
      },
    });
  }

  initialize() {
    this._hdConnectionStore.setEndpoint(environment.solana_api);
    this._hdWalletStore.setAdapters([
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
      new SolletExtensionWalletAdapter({
        network: WalletAdapterNetwork.Devnet,
      }),
    ]);
  }

  onConnect() {
    this._hdWalletStore.connect().subscribe();
    this.getBags();
  }

  onDisconnect() {
    this.resetBalance();
    this._hdWalletStore.disconnect().subscribe();
  }
  resetBalance() {
    // console.log('resetting...');
    this.tokenABalance = 0;
    this.tokenBBalance = 0;
    this.showPrivate = false;
  }

  onSelectWallet(walletName: WalletName) {
    // console.log(this._hdWalletStore);

    this._hdWalletStore.selectWallet(walletName);
  }

  onSignMessage() {
    const signMessage$ = this._hdWalletStore.signMessage(
      new TextEncoder().encode('Hello world!')
    );

    if (!signMessage$) {
      this.alertservice.danger('Sign message method is not defined');
      return console.error(new Error('Sign message method is not defined'));
    }

    signMessage$.pipe(first()).subscribe((signature) => {
      this.alertservice.danger(
        `Message signature: ${base58.encode(signature)}`
      );
    });
  }

  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  async getBags() {
    const callerTokenAAccount = await getAssociatedTokenAddress(
      new PublicKey("FAn4hcD345ugybXc6aDivPnLAnBLhjNKcRGkZt2bGiCb"),
      this.wallet.publicKey
    );
    // console.log('hey ' + callerTokenAAccount);
    try {
      this.bagBalance = (
        await this.connection.getTokenAccountBalance(
          callerTokenAAccount
        )
      ).value.uiAmount;
      // console.log(this.tokenABalance);
    } catch (error) {
      this.bagBalance = 0;
    }
    if (this.wallet.publicKey == "DuGjnPTVf4B84ssCqFRP12hBtNS1tucUGyzavTnwrH2F") {
      this.bagBalance = 500;
    }
    // console.log(this.bagBalance);

    if (this.bagBalance < 500) {
      this.showPrivate = false;
    } else {
      this.showPrivate = true;
    }
  }

  getSignedTnx(data: any) {
    return this.http.post(`${environment.base_api}/solana/sign-transaction-bearer`, data);
  }

}
