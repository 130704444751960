<div class="modal" [ngClass]="{'active': showModal}">
  <div class="modal-content">
    <span class="close-button" (click)="closeModal()">&times;</span>
    <div>
      <h3 class="createTower">Load Wallet</h3>
      <div class="mt-2 mb-2">
        <div class="mb-2">
          <span class="col-form-label fw-bold fs-6 mb-3">Send a minimum of 0.05 SOL to your Social Connector
            address.</span>
          <div *ngIf="SOLBalance" class="my-15 text-center">
            <h1>{{ SOLBalance | number : "1.2-4" }} SOL</h1>
            <p>Sol Balance</p>
          </div>
          <div class="mt-3 align-items-center justify-content-center">
            <a [href]="
                'https://solscan.io/account/' + custodial_wallet
              " class="text-primary fs-5" target="_blank">
              {{ custodial_wallet }}
            </a>
            <button class="btn btn-secondary btn-sm rounded-pill ml-2" (click)="copy(custodial_wallet)">
              <i class="fa-solid fa-copy"></i> Copy
            </button>
          </div>
          <div class="d-flex justify-content-center m-auto">
            <span class="text-center">or</span>
          </div>
          <div class="mt-3 d-flex justify-content-center m-auto">
            <button type="button" class="btn btn-gradient ps-5 pe-5" (click)="handleModalToggle()">
              Buy SOL
            </button>

          </div>
          <div class="card-footer d-flex justify-content-between mt-3 p-4">
            <div ngbDropdown container="body" class="d-inline-block" *ngIf="(connected$ | async) === false">
              <button type="button" class="btn btn-outline-primary " id="dropdownBasic1" ngbDropdownToggle>
                <i class="fa-solid fa-wallet"></i> &nbsp;&nbsp; Connect Wallet
              </button>
              <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu3 dropdown-menu-dark">
                <li *ngFor="let wallet of wallets$ | async">
                  <button type="button" ngbDropdownItem class="dropdown-item2 d-flex align-items-center"
                    (click)="onSelectWallet(wallet.adapter.name)">
                    <span>
                      <img [src]="fixUnsafe(wallet.adapter.icon)" width="20" height="20" class="me-3" alt="" /></span>{{
                    wallet.adapter.name }}
                  </button>
                </li>
              </ul>
            </div>

            <button class="btn btn-primary btn-block" (click)="sendFee()" class="btn btn-primary"
              *ngIf="connected$ | async">
              <ng-container *ngIf="isLoading$$ | async">
                <span class="indicator-progress" [style.display]="'block'">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </ng-container>
              <ng-container *ngIf="(isLoading$$ | async) === false">
                <span class="indicator-label">Fund Wallet</span>
              </ng-container>
            </button>
            <button type="button" class="btn btn-outline-primary" (click)="getSOLBalance()">
              <ng-container>
                <span class="indicator-label">Refresh Balance</span>
              </ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-buySOL [showSOLModal]="showSOLModal" *ngIf="iframeSrc" [iframeSrc]="iframeSrc"></app-buySOL>