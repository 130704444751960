<nav class="navbar navbar-expand-sm navbar-light mt-4">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
      <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" />
    </a>


  </div>
</nav>


<div class="container mt-5">
  <div class="row text-center">
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <h3>Welcome to Gary Club</h3>
      <p>A Crypto Creator Community</p>
      <p>Create an account to get started</p>

      <div class="text-start">
        <form [formGroup]="registrationForm ">
          <!-- <div class="form-group mt-3">
                <input type="email" name="email" formControlName="email"
                  class="form-control form-control-lg form-control-solid" placeholder="Email">
              </div>
              <div class="form-group">
                <input type="password" name="password" formControlName="password"
                  class="form-control form-control-lg form-control-solid" placeholder="Password">
              </div> -->

          <div class="row fv-row mb-7">
            <label class="form-label fw-bolder ">Name</label>
            <div class="input-group">
              <span class="input-group-text">
                <button type="button" class="btn btn-clear">
                  <i class="fa-solid fa-user"></i>
                </button>
              </span>
              <input class="form-control form-control-lg form-control-solid" type="text" name="name"
                formControlName="name" placeholder="name" autocomplete="off" [ngClass]="{
                        'is-invalid': registrationForm.controls['name'].invalid,
                        'is-valid': registrationForm.controls['name'].valid
                      }" />
            </div>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'name is required',
                      control: registrationForm.controls['name']
                    }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'minlength',
                      message: 'name should have at least 3 letters',
                      control: registrationForm.controls['name']
                    }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'maxLength',
                      message: 'name should have maximum 100 letters',
                      control: registrationForm.controls['name']
                    }"></ng-container>
          </div>

          <div class="row fv-row mb-7">
            <label class="form-label fw-bolder ">Username</label>
            <div class="input-group">
              <span class="input-group-text">
                <button type="button" class="btn btn-clear">
                  <i class="fa-solid fa-user "></i>
                </button>
              </span>
              <input class="form-control form-control-lg form-control-solid" type="text" name="social_tag"
                formControlName="social_tag" placeholder="Enter username" autocomplete="off" [ngClass]="{
                          'is-invalid': registrationForm.controls['social_tag'].invalid,
                          'is-valid': registrationForm.controls['social_tag'].valid
                        }" />
            </div>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'SC username is required',
                        control: registrationForm.controls['social_tag']
                      }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'minlength',
                        message: 'SC username should have at least 3 letters',
                        control: registrationForm.controls['social_tag']
                      }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'maxLength',
                        message: 'SC username should have maximum 100 letters',
                        control: registrationForm.controls['social_tag']
                      }"></ng-container>
          </div>

          <div class="fv-row mb-7">
            <label class="form-label fw-bolder ">Email</label>
            <div class="input-group">
              <span class="input-group-text">
                <button type="button" class="btn btn-clear">
                  <i class="fa-sharp fa-solid fa-envelope"></i>
                </button>
              </span>
              <input class="form-control form-control-lg form-control-solid" type="email" placeholder="Email"
                name="email" formControlName="email" autocomplete="off" [ngClass]="{
                    'is-invalid': registrationForm.controls['email'].invalid,
                    'is-valid': registrationForm.controls['email'].valid
                  }" />
            </div>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Email is required',
                  control: registrationForm.controls['email']
                }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'email',
                  message: 'Email is invalid',
                  control: registrationForm.controls['email']
                }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'minlength',
                  message: 'Email should have at least 3 symbols',
                  control: registrationForm.controls['email']
                }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'maxLength',
                  message: 'Email should have maximum 360 letters',
                  control: registrationForm.controls['email']
                }"></ng-container>
          </div>

          <div class="mb-7 fv-row">
            <label class="form-label fw-bolder">Password </label>
            <div class="input-group">
              <span class="input-group-text">
                <button type="button" class="btn btn-clear" (click)="showPass = !showPass">
                  <i class="fa-solid " [class]="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </span>
              <input class="form-control form-control-lg form-control-solid" type="{{ showPass ? 'text' : 'password' }}"
                name="password" formControlName="password" autocomplete="off" [ngClass]="{
                    'is-invalid': registrationForm.controls['password'].invalid,
                    'is-valid': registrationForm.controls['password'].valid
                  }" />
            </div>
            <!-- <input class="form-control form-control-lg form-control-solid" type="password" placeholder="Password"
                name="password" formControlName="password" autocomplete="off" [ngClass]="{
                  'is-invalid': registrationForm.controls['password'].invalid,
                  'is-valid': registrationForm.controls['password'].valid
                }" /> -->
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Password is required',
                  control: registrationForm.controls['password']
                }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'minlength',
                  message: 'Password should have at least 8 letters',
                  control: registrationForm.controls['password']
                }"></ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'maxLength',
                  message: 'Password should have maximum 100 symbols',
                  control: registrationForm.controls['password']
                }"></ng-container>
          </div>

          <div class="row fv-row mb-7">
            <label class="form-label fw-bolder">Referral Code <span class="fs-7 text-muted">(Optional)</span>
            </label>
            <div class="input-group">
              <span class="input-group-text">
                <button type="button" class="btn btn-clear">
                  <i class="fa-solid fa-users"></i>
                </button>
              </span>
              <input class="form-control form-control-lg form-control-solid" type="text" name="referral_code"
                formControlName="referral_code" placeholder="Referral code" autocomplete="off" />
            </div>

          </div>

          <button class="btn btn-block btn-custom my-3" (click)="submit()"
            [disabled]="registrationForm.invalid">Register</button>
        </form>
      </div>

      <!-- <a [href]="socCon+'/auth/forgot-password'">Forgot Password?</a> -->

      <div class="text-center">
        <a class=" mt-4 " [href]="'/login'">
          Already have an account?
        </a>
      </div>

      <!-- <div class="mt-2 mt-md-4">
        <h4 *ngIf="weekly_rewards > 0"> {{ weekly_rewards | number : '1.0-2' }} $GARY paid to creators</h4>
      </div> -->
    </div>
  </div>
</div>


<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>
