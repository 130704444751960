<div>
  <div class="container-fluid">
    <div class="pow pt-5">
      <div class="pow-clock-area mb-5">
        <p>
          POW resets in:
        </p>
        <div class="pow-clock">
          <div class="d-flex align-items-center justify-content-center">
            <div class="border border-danger p-3 rounded clock">
              <h3 class="mb-0">
                {{ hour }}
              </h3>
            </div>
            <span class="mx-3">:</span>
            <div class="border border-danger p-3 rounded">
              <h3 class="mb-0">
                {{ mins }}
              </h3>
            </div>
            <span class="mx-3">:</span>
            <div class="border border-danger p-3 rounded">
              <h3 class="mb-0">
                {{ seconds }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <form action="" [formGroup]="powForm">
            <div class="task-div pt-3 pl-3 pr-3 mb-3 pb-3">
              <div class="d-flex align-items-center">
                <div class="cont-div">
                  <i class="fa-light fa-users"></i>
                </div>
                <p class="mb-0 ms-3">
                  Did you grow your audience today? <span class="asterik">*</span>
                </p>
                <div class="pl-3">
                  <div class="d-flex align-items-center">
                    <div class="form-check form-check-inline align-items-center justify-content-center d-flex">
                      <input class="form-check-input" type="radio" name="grow_audience" value="yes"
                        formControlName="grow_audience">
                      <label class="form-check-label pl-0" for="yes" checked>
                        Yes
                      </label>
                    </div>

                    <div class="form-check form-check-inline align-items-center justify-content-center d-flex">
                      <input class="form-check-input" type="radio" name="grow_audience" formControlName="grow_audience"
                        value="no">
                      <label class="form-check-label pl-0" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="task-div pt-3 pl-3 pr-3 mb-3 pb-3">
              <div class="d-flex align-items-center">
                <div class="cont-div">
                  <i class="fa-light fa-coins"></i>
                </div>
                <p class="mb-0 ms-3">
                  Did you make money today? <span class="asterik">*</span>
                </p>
                <div class="pl-3">
                  <div class="d-flex align-items-center">
                    <div class="form-check form-check-inline align-items-center justify-content-center d-flex">
                      <input class="form-check-input" type="radio" name="make_money" formControlName="make_money"
                        value="yes">
                      <label class="form-check-label pl-0" for="yes" checked>
                        Yes
                      </label>
                    </div>

                    <div class="form-check form-check-inline align-items-center justify-content-center d-flex">
                      <input class="form-check-input" type="radio" name="make_money" formControlName="make_money"
                        value="no">
                      <label class="form-check-label pl-0" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="task-divs pt-3 pl-3 pr-3 mb-3">
              <div class="row">
                <div class="col-md-1 col-2">
                  <div class="cont-div">
                    <i class="fa-regular fa-ballot-check"></i>
                  </div>
                </div>
                <div class="col-md-11 col-10">
                  <div class="d-flex align-items-center">
                    <div class="task-performed">
                      <p class="pt-1">
                        Task(s) Performed <span class="asterik">*</span>
                      </p>
                    </div>
                    <div class="pl-3">
                      <button class="btn btn-outline-light add-task rounded-pill btn-sm" (click)="addFields()">
                        Add Task
                      </button>
                    </div>
                  </div>
                  <form [formGroup]="work_summaryForm">
                    <div class="mb-3 my-3 mb-5 tasko" formArrayName="names">
                      <div *ngFor="let group of names?.controls; let i = index" [formGroupName]="i">
                        <input type="text" class="form-control mb-2" placeholder="Tasks carried out"
                          formControlName="name">
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
            <div class="task-divs pt-3 pl-3 pr-3 mb-3 pb-3">
              <div class="d-flex align-items-center">
                <div class="cont-div">
                  <i class="fa-regular fa-gear"></i>
                </div>
                <p class="mb-0 ms-3">
                  Intensity Level <span class="asterik">*</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="" class="form-label me-3">Low</label>
                <label for="" class="me-3">
                  <input class="form-check-input" type="radio" name="intensity" formControlName="intensity" value="1">
                </label>
                <label for="" class="me-3">
                  <input class="form-check-input" type="radio" name="intensity" formControlName="intensity" value="2">
                </label>
                <label for="" class="me-3">
                  <input class="form-check-input" type="radio" name="intensity" formControlName="intensity" value="3">
                </label>
                <label for="" class="me-3">
                  <input class="form-check-input" type="radio" name="intensity" formControlName="intensity" value="4">
                </label>
                <label for="" class="me-3">
                  <input class="form-check-input" type="radio" name="intensity" formControlName="intensity" value="5">
                </label>
                <label for="" class="form-label">High</label>

                <div>

                </div>
              </div>
            </div>
          </form>
          <button [disabled]="powForm.invalid" class="btn btn-primary submit-pow" (click)="submit()">
            Submit POW
          </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>
</div>