import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-get-involved',
  templateUrl: './get-involved.component.html',
  styleUrls: ['./get-involved.component.scss']
})
export class GetInvolvedComponent implements OnInit, OnDestroy {

  constructor() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  ngOnInit(): void {
    document.body.classList.add('dark-theme');
  }

  ngOnDestroy() {
    let theme = localStorage.getItem('theme');
    if (theme != 'dark-theme') {
      document.body.classList.remove('dark-theme');
      localStorage.setItem('theme', 'light');
    } else {
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    }
    // window.location.reload();
  }

}
