<div class="container mt-5">
  <div class="row" *ngIf="!loading && notifications?.length>0" infiniteScroll [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50" (scrolled)="loadMore()">
    <div class="col-md-10 offset-md-1">
      <div class="row">
        <div class="col-md-6 col-6 ">
          <h3>Notifications</h3>
        </div>
        <div class="col-md-6 col-6">
          <button class="btn btn-clear float-right" placement="top" ngbTooltip="Clear notification"
            (click)="clearNotification()"><i class="fa-solid fa-xmark text-danger"></i></button>
        </div>
      </div>
      <br>
      <ul class="list-unstyled border">
        <li class="border-bottom"
          *ngFor="let item of notifications | paginate: { itemsPerPage: itemsPerPage, currentPage: p}; let i = index">
          <div class="p-3" style="cursor: pointer;" (click)="markAsRead(item.id, item.link)"
            [class.is_not_read]="!item.read_at">
            <div class="d-flex mb-3  align-items-start">
              <h4 class="text-w-light text-muted">
                <span class="icon_padding me-2">
                  <i class="fa-regular fa-newspaper"></i>
                </span>
                {{ item.category ? item.category.toUpperCase() : item.category }}
              </h4>
              <div class="ms-auto ">
                <span class="text-muted">{{ item.created_at | timeAgo }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="imgs me-3">

                <span>
                  <img [src]="item.pic || 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg'"
                    class="activityimg ">
                </span>

              </div>
              <div class="activity_content" [innerHTML]="item?.data"></div>
              <div class="price">
                <span *ngIf="item?.price"> {{ item?.price }} </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</div>