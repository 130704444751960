<div class="container">
  <h3 class="my-5 event-text">Events</h3>

  <div class="d-flex mb-5 align-items-center">
    <button class="btn btn-secondary rounded-pill " (click)="open(content3)">
      Date Filter
    </button>
    <div style="width: 200px;" class="ms-3">
      <input type="text" class="form-control" placeholder="Search For Event" [(ngModel)]="searchEvent"
        (ngModelChange)="search($event)">
    </div>

    <!-- <div ngbDropdown>
      <button type="button" class="btn btn-secondary rounded-pill py-3 px-4" id="dropdownBasic1" ngbDropdownToggle>
        Eligibility Segment
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
        <button ngbDropdownItem class="my-2 py-2" *ngFor="let item of allSegments" (click)="filterBySegment(item.id)">
          {{
          item.name }} </button>

      </div>
    </div> -->
    <button class="btn btn-clear text-primary" (click)="clearFilter()">
      Clear Filter
    </button>
  </div>

  <div class="row cursor-pointer" *ngFor="
      let item of myEvents
        | paginate
          : {
              itemsPerPage: perpages,
              currentPage: pages,
              totalItems: sizes
            }
    ">
    <div class="col-md-5 mb-3" (click)="view(item)">
      <img [src]="item.image" class="eventImg" alt="" srcset=""
        onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
    </div>
    <div class="col-md-7">
      <div class="main">
        <div class="contentt">
          <div (click)="view(item)">
            <h3 class="event-name" style="cursor: pointer">{{ item.name }}</h3>
          </div>
          <p class="lowfont">
            {{ covertTZ(item.date, item.timezone) | date : "medium" }}
            {{ myZone?.abbreviation }}
          </p>
          <!-- <p> {{ item.date | date: 'medium' }} - {{ item.end_date | date: 'medium' }} {{ item.timezone }} </p> -->
          <p class="lowfont">Created by {{ item.creator.name |titlecase }}</p>

          <p class="mb-0 lowfont">
            <img src="../../../assets/imgs/location.svg" alt="SVG Image" />&nbsp;&nbsp;
            <span class="pl-1">{{ item.event_type | titlecase }} event</span>
          </p>
          <!-- <p><i class="fa-solid fa-location-dot text-primary"></i> &nbsp;&nbsp; {{ item.location }} </p> -->
          <div class="mt-4">
            <span class="badge topic-badge-prim" *ngIf="item?.event_status === 'Started'">{{ item.event_status }}</span>
            <span class="badge topic-badge-danger" *ngIf="item?.event_status === 'Ended'">{{ item.event_status }}</span>
            <span class="badge topic-badge" *ngIf="item?.event_status === 'Scheduled'">{{
              item.event_status
              }}</span>

            <span class="badge topic-badge" *ngIf="
                item.event_status == '' ||
                item?.event_status == 'null' ||
                item?.event_status == null
              ">Scheduled</span>
          </div>
          <div class="row pt-4">
            <div class="col-6">
              <p class="mb-0">
                <img src="../../../assets/imgs/users.svg" alt="SVG Image" />&nbsp;&nbsp;
                {{ item.interested_candidates }}
                Interested
              </p>
            </div>
            <div class="col-6">
              <div class="float-right">
                <button [disabled]="item?.event_status !== 'Scheduled'" class="btn btn-sm btn-prim-border btn-icon me-3"
                  *ngIf="!isAttendee" (click)="rsvpEvent(content,item, content2)" placement="top" ngbTooltip="RSVP">
                  <i class="fa-solid fa-user-plus" aria-hidden="true"></i>
                </button>
                <button class="btn btn-sm sec-color btn-icon me-3" (click)="addToCalendar(item)" placement="top"
                  ngbTooltip="Add to calendar">
                  <i class="fa-solid fa-calendar-plus" aria-hidden="true"></i>
                </button>
                <button class="btn btn-sm btn-prim-border btn-icon" (click)="copyToClipboard(item)" placement="top"
                  ngbTooltip="Copy Link">
                  <i class="fa-solid fa-copy" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div></div>

          <!-- <div class="ms-auto">
            <div ngbDropdown placement="top-end">
              <button type="button" class="btn btn-clear" id="dropdownBasic1" ngbDropdownToggle>
                <i class="fa-solid fa-ellipsis"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-2">
                <button ngbDropdownItem class="my-2 py-2" (click)="join(item,content)"><i
                    class="fa-solid fa-user-plus text-primary"></i> &nbsp; Interested</button>
                <button ngbDropdownItem class="my-2 py-2" (click)="addToCalendar(item)"><i
                    class="fa-solid fa-calendar-plus text-primary"></i> &nbsp; Add to Calendar</button>
                <button ngbDropdownItem class="my-2 py-2" (click)="copyLink(item)"><i
                    class="fa-solid fa-link text-primary"></i> &nbsp; Copy Event Link</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <hr class="mb-5 mt-2" />
  </div>
  <div>
    <pagination-controls class="d-flex justify-content-center my-pagination" [directionLinks]="true" [responsive]="true"
      (pageChange)="getPages($event)"></pagination-controls>
  </div>
</div>

<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Date</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-center">
    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
      outsideDays="hidden">
    </ngb-datepicker>

    <ng-template #t let-date let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="clear()">Cancel</button>
    <button class="btn btn-primary" (click)="filter()">Submit</button>
  </div>
</ng-template>




<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <h6>Enter your Social Connector details to login</h6>
    <form [formGroup]="joinForm">
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" name="email" class="form-control" formControlName="email"
          placeholder="Enter Social Connector Email" />
      </div>
      <div class="form-group">
        <label for="" class="form-label">Password
          &nbsp; <span><a href="https://app.socialconnector.io/auth/forgot-password">Forgot Password?</a></span>
        </label>
        <input type="password" name="password" class="form-control" formControlName="password"
          placeholder="Enter Social Connector Password" />
      </div>
    </form>

    <p>
      New here?
      <a href="https://app.socialconnector.io/auth/registration" target="_blank">Create a FREE Account</a>
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="submit()" [disabled]="joinForm.invalid">
      Submit
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      Select your preferred payment method:
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <!-- <h6>Enter your Social Connector details to login</h6> -->

    <form [formGroup]="paidForm">
      <div class="form-check">
        <label class="form-check-label" for="flexRadioDefault1">
          <input class="form-check-input" type="radio" name="payment_method" formControlName="payment_method"
            id="flexRadioDefault1" value="social_pay" />
          <div class="d-flex align-items-center me-5 mt-2">
            <span class="d-block">
              <div class="d-flex align-items-center">
                <img src="assets/imgs/logo-socialpay.png" height="50px" alt="" class="me-2" />
                <div class="d-block">
                  <h6 class="mb-0">SOCIAL</h6>
                  <h6 class="mb-0">PAY</h6>
                </div>
              </div>
            </span>
            <span class="ms-auto">
              {{ event.price | number:'1.2-4' }} {{ event.coin_symbol }}
            </span>
          </div>
        </label>

        <label class="form-check-label" for="flexRadioDefault2" *ngIf="event.stripe_amount">
          <input class="form-check-input" type="radio" name="payment_method" formControlName="payment_method"
            id="flexRadioDefault2" value="stripe" />
          <div class="d-flex align-items-center me-5">
            <span class="">
              <img
                src="https://pub-3a9bbf364b7e4fafb2ccf4e85d9eb22a.r2.dev/socialpay-merchant-images/6472b189dbbb81685238153.png"
                width="100px" alt="" />
            </span>
            <span class="ms-auto"> ${{ event.stripe_amount | number:'1.2-4' }} </span>
          </div>
        </label>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <!-- <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button> -->
    <button class="btn btn-primary btn-block py-3" [disabled]="paidForm.invalid" (click)="payNow()">
      Make Payment
    </button>
  </div>
</ng-template>