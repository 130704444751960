<div class="my-4" *ngIf=" (isLoading$ | async) ===false">
  <div class="d-flex align-items-center justify-content-center">
    <div class="card excard">
      <div class="card-body">

        <ngb-alert [dismissible]="false" type="info" *ngIf="exchange_message" class="text-center">
          {{ exchange_message }}
        </ngb-alert>
        <br>

        <div class="">
          <div class="">
            <div class="d-flex align-items-end mb-3 ">
              <p>Trade</p>
              <div class="mx-auto me-3">
                <button class="btn btn-sm btn-dark rounded me-1" (click)="setHalf()">HALF</button>
                <button class="btn btn-sm btn-dark rounded" (click)="setMax()">MAX</button>
              </div>
              <div>
                <p class="balance">Balance: {{ walletservice.tokenABalance | number : '1.2-4'}}</p>
              </div>

            </div>

            <div class="dark">
              <div class="d-flex align-items-start border-bottom">
                <div ngbDropdown class="d-inline-block">
                  <button type="button" class="btn btn-white" id="dropdownBasic1" ngbDropdownToggle>
                    <span *ngIf="img">
                      <img [src]="img" width="30" alt="" srcset="" style="border-radius: 50%"></span> {{ title}}
                    <i class="fa-solid fa-chevron-down ms-3"></i>
                  </button>
                  <div ngbDropdownMenu class=" dropdown-menu2" aria-labelledby="dropdownBasic1">
                    <div style="position: relative;">
                      <div class="row pabs">
                        <div class="col-12">
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input type="search" class="form-control form-control3" placeholder="Filter by token name"
                              name="name2" [(ngModel)]="name2" (ngModelChange)="search2()">
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="dropcontent mt-2">
                      <div class="row ">
                        <ul class="multi-column-dropdown">
                          <li *ngFor="let item of newMenuItems | orderBy: order : reverse:reverse">
                            <a style="cursor: pointer;" ngbDropdownItem
                              (click)="selectCoin(item.coin_symbol, item?.coin_img, item.token_address)">
                              <img [src]="item?.coin_img" alt="" width="30" style="border-radius: 50%">
                              {{item?.coin_name}}
                            </a>
                          </li>
                        </ul>

                      </div>
                    </div>

                  </div>
                </div>
                <!-- <button type="button" class="btn btn-white">
                        <img src="https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg" width="30" alt=""
                          srcset="">
                        $GARY
                      </button> -->
                <div class="form-group mx-auto text-end me-0">
                  <input inputmode="decimal" type="number" class="form-control text-end" placeholder="0.00"
                    [(ngModel)]="defaultSwapValues.amountIn" (ngModelChange)="estimate1($event)">

                  <!-- <div class="smalltext" *ngIf="coinprice">
                          ${{walletservice.tokenABalance * coinprices | number :
                          '1.2-4'}}
                        </div> -->
                  <div class=" mt-1 me-2 smalltext">
                    <p *ngIf="coinprice">
                      <span class="float-start"> ${{ coinprice }} </span>
                      <span class="float-end"> ${{ defaultSwapValues.amountIn * coinprices | number : '1.2-4' }} </span>
                    </p>
                    <div style="clear: both;" class="mb-2"></div>
                  </div>
                </div>

              </div>
            </div>

            <div class="d-flex align-items-center  justify-content-center ">
              <div class="round" (click)="swap()" style="cursor: pointer;">
                <i class="fa-solid fa-arrow-right-arrow-left trans"></i>
              </div>
              <div class="ms-3">
                <span class="load" *ngIf="spinOn"><i class="fa-solid fa-spinner fa-spin"></i></span>
              </div>
            </div>

            <div class="d-flex align-items-end mb-3 ">
              <p>For (estimated)</p>
              <div class="mx-auto me-0">
                <p class="balance">Balance: {{ walletservice.tokenBBalance | number : '1.2-4' }}</p>
              </div>

            </div>
            <div class="dark">
              <div class="d-flex align-items-start border-bottom">
                <div ngbDropdown class="d-inline-block">
                  <button type="button" class="btn btn-white" id="dropdownBasic1" ngbDropdownToggle>
                    <span *ngIf="img2"><img width="30" [src]="img2" class="mb-1" style="border-radius: 50%"></span> {{
                    coin2 }}
                    <i class="fa-solid fa-chevron-down ms-3"></i>
                  </button>
                  <div ngbDropdownMenu class=" dropdown-menu2" aria-labelledby="dropdownBasic3">
                    <div style="position: relative;">
                      <div class="row pabs">
                        <div class="col-12">
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input type="search" class="form-control form-control3" placeholder="Filter by token name"
                              name="name2" [(ngModel)]="name3" (ngModelChange)="search3()">
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="dropcontent mt-2">
                      <div class="row ">
                        <br>
                        <div class="col-sm-12 ps-3">
                          <ul class="multi-column-dropdown">
                            <li *ngFor="let item of newMenuItems2 | orderBy: order : reverse:reverse">
                              <a ngbDropdownItem style=" cursor: pointer;"
                                (click)="anothercoin(item.coin_symbol, item.coin_img,item.token_address)">
                                <img [src]="item.coin_img" alt="" width="30" style="border-radius: 50%">
                                {{item.coin_name}}
                              </a>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mx-auto text-end me-0">
                  <input type="number" class="form-control text-end" placeholder="0.00" readonly
                    [(ngModel)]="defaultSwapValues.amountOut">
                  <div class=" mt-1 me-2">
                    <p *ngIf="coinprice2">
                      <span class="float-start"> ${{ coinprice2 }} </span>
                      <span class="float-end"> ${{ defaultSwapValues.amountOut * coinprice2s | number : '1.2-4'}}
                      </span>
                    </p>
                    <div style="clear: both;" class="mb-2"></div>
                  </div>
                </div>

              </div>
            </div>
            <p class="light mb-3"><i class="fa-solid fa-circle-info text-warning"></i> &nbsp; We recommend having at
              least <b>0.05 SOL</b>
              for any transaction</p>
          </div>
        </div>
        <div class="mt-3 text-center">

          <div *ngIf="isAuthenticated">
            <button class="btn btn-block btn-gradient"
              [disabled]="defaultSwapValues.amountOut <=0 || defaultSwapValues.amountOut ==null  || noswap"
              (click)="handleSubmit($event)">Swap</button>
          </div>

          <div *ngIf="!isAuthenticated">
            <button class="btn btn-block btn-gradient" (click)="login()">Login</button>
          </div>


        </div>


      </div>
    </div>
  </div>

</div>