<nav class="navbar navbar-expand-sm navbar-light mt-4">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
      <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" />
    </a>


  </div>
</nav>




<div class="container mt-5">
  <div class="row text-center">
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <h3>Welcome to Gary Club</h3>
      <p>A Crypto Creator Community</p>
      <p>Enter your email to reset your password.</p>


      <ng-container *ngIf="errorState === errorStates.HasError">
        <div class="mb-lg-15 alert alert-danger">
          <div class="alert-text font-weight-bold">
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="errorState === errorStates.NoError">
        <div class="mb-10 bg-light-info p-8 rounded">
          <!--begin::Body-->
          <div class="text-info">Sent password reset. Please check your email</div>
          <!--end::Body-->
        </div>
      </ng-container>

      <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" class="text-start">
        <!--begin::Form group-->
        <div class="fv-row mb-10">
          <label class="form-label fw-bolder ">Reset code</label>
          <input class="form-control form-control-lg form-control-solid" type="text" name="code" formControlName="code"
            placeholder="Reset code" autocomplete="off" [ngClass]="{
                  'is-invalid': resetPasswordForm.controls['code'].invalid,
                  'is-valid': resetPasswordForm.controls['code'].valid
                }" />

          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Reset code is required',
                  control: resetPasswordForm.controls['code']
                }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'minLength',
                  message: 'Reset code should have at least 6 symbols',
                  control: resetPasswordForm.controls['code']
                }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'maxLength',
                  message: 'Reset code should have maximum 10 symbols',
                  control: resetPasswordForm.controls['code']
                }"></ng-container>
        </div>
        <!--end::Form group-->


        <!--begin::Form group-->
        <div class="mb-10 fv-row">
          <label class="form-label fw-bolder">Password</label>
          <input class="form-control form-control-lg form-control-solid" type="password" placeholder="Password"
            name="password" formControlName="password" autocomplete="off" [ngClass]="{
                  'is-invalid': resetPasswordForm.controls['password'].invalid,
                  'is-valid': resetPasswordForm.controls['password'].valid
                }" />
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Password is required',
                  control: resetPasswordForm.controls['password']
                }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'minlength',
                  message: 'Password should have at least 8 symbols',
                  control: resetPasswordForm.controls['password']
                }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'maxLength',
                  message: 'Password should have maximum 100 symbols',
                  control: resetPasswordForm.controls['password']
                }"></ng-container>
        </div>
        <!--end::Form group-->

        <!--begin::Form group-->
        <div class="fv-row mb-5">
          <label class="form-label fw-bolder ">Confirm Password</label>
          <input class="form-control form-control-lg form-control-solid" type="password" placeholder="Confirm password"
            name="password_confirmation" autocomplete="off" formControlName="password_confirmation" [ngClass]="{
                  'is-invalid': resetPasswordForm.controls['password_confirmation'].invalid,
                  'is-valid': resetPasswordForm.controls['password_confirmation'].valid
                }" />
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Confirm Password is required',
                  control: resetPasswordForm.controls['password_confirmation']
                }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'minlength',
                  message: 'Confirm Password should have at least 3 symbols',
                  control: resetPasswordForm.controls['password_confirmation']
                }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'maxLength',
                  message: 'Confirm Password should have maximum 100 symbols',
                  control: resetPasswordForm.controls['password_confirmation']
                }"></ng-container>
          <ng-container *ngIf="
                  resetPasswordForm.controls['password_confirmation'].errors &&
                  resetPasswordForm.controls['password_confirmation'].errors.ConfirmPassword
                ">
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                'Passsword' and 'Confirm Password' didn't match.
              </div>
            </div>
          </ng-container>
        </div>
        <!--end::Form group-->

        <div class="text-center">
          <a class="btn btn-clear" routerLink="/login">Cancel</a>
          <button class="btn btn-custom my-3" [disabled]="resetPasswordForm.invalid">Continue</button>
        </div>
      </form>

      <!-- <a [href]="'/forgot_password'">Forgot Password?</a> -->




    </div>
  </div>
</div>


<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
