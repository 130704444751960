import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupDataService {
  private imageUrlSubject = new Subject<string>();
  imageUrl$ = this.imageUrlSubject.asObservable();

  openImagePopup(imageUrl: string) {
    // alert(1);
    this.imageUrlSubject.next(imageUrl);
  }
}
