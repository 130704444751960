<div class="row  g-0">
  <div class="col-md-2">
    <div class="trending d-flex align-items-center justify-content-center">
      <div class="ms-5 me-3"><i class="fa-solid fa-fire txt-white fa-2x"></i></div>
      <h3>Trending</h3>
    </div>
  </div>
  <div class="col-md-10 scroll">

    <div class="marquee">
      <ul class="list-unstyled" id="list">
        <li *ngFor="let item of marquee2">
          <div class="slide">
            <a [href]="['/'+item?.social_tag]">
              <div class="inline">
                <div class="img">
                  <img
                    [src]=" item?.creator_img ? item?.creator_img : 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg' "
                    alt="" srcset="">
                </div>
                <div class="block">

                  <h4> {{ item?.social_tag }} </h4>

                  <p *ngIf="item?.percentageChange > 0" class="text-success"> {{ item?.percentageChange | number:
                    '1.0-2'
                    }}% </p>
                  <p *ngIf="item?.percentageChange < 0" class="text-danger"> {{ item?.percentageChange | number: '1.0-2'
                    }}%
                  </p>
                  <p *ngIf="item?.percentageChange == 0"> {{ item?.percentageChange | number: '1.0-2' }}% </p>
                </div>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="border py-3 mt-3 mt-md-5">



  <div class="row mt-2 align-items-center">
    <div class="col-md-9 mb-3 mb-md-0">
      <h3 class="ms-3">Creator Leaderboard</h3>
    </div>
    <div class="col-md-3 mb-3 mb-md-0 ">
      <div class="search-container me-3">
        <input type="text" [(ngModel)]="searchTerm" (ngModelChange)="search($event)" placeholder="Search Users"
          class="form-control">

      </div>

    </div>
  </div>

  <div class=" mt-3">
    <div class="table-responsive">
      <div class="overlay" style="height: 400px;" *ngIf="loading">
      </div>
      <div class="table-responsive overflow">
        <table class="table align-middle table-borderless">
          <thead class="bg-secondary">
            <tr>
              <th class="py-3 ps-5" (click)="sort('rank')" style="cursor: pointer;">#&nbsp;<span
                  *ngIf="sortData == 'rank'" class="text-primary">
                  <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                  <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
                </span></th>
              <th class="py-3" style="cursor: pointer;">Name
                &nbsp;
                <!-- <span *ngIf="sortData == 'coin_symbol'" class="text-primary">
                  <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                  <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
                </span> -->
              </th>
              <th class="py-3" (click)="sort('supporters')" style="cursor: pointer;">Supporters&nbsp;
                <span *ngIf="sortData == 'supporters'" class="text-primary">
                  <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                  <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
                </span>
              </th>
              <th class="py-3" (click)="sort('price')" style="cursor: pointer;"> Price
                &nbsp;
                <span *ngIf="sortData == 'price'" class="text-primary">
                  <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                  <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
                </span>
              </th>
              <th class="py-3" (click)="sort('score')" style="cursor: pointer;"> Points
                &nbsp;
                <span *ngIf="sortData == 'score'" class="text-primary">
                  <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                  <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
                </span>
              </th>
              <th class="py-3" (click)="sort('gary_earned')" style="cursor: pointer;"> $GARY Earned
                &nbsp;
                <span *ngIf="sortData == 'gary_earned'" class="text-primary">
                  <i class="fa-solid fa-sort-down" *ngIf="reverse"></i>
                  <i class="fa-solid fa-sort-up" *ngIf="!reverse"></i>
                </span>
              </th>
              <th class="py-3" style="cursor: pointer;">7d%&nbsp;

              </th>

            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of filteredTokenLists| filterBy: userFilter  | paginate: { itemsPerPage: perPage, currentPage: 1}">
              <td class="ps-5 text-end" style="width: 90px;">
                <div class="d-flex align-items-center">
                  <div class="icon">
                    <p>&nbsp; </p>
                    <p>

                      <span *ngIf="item?.movement  !== 0 && item?.direction == 'down'">
                        <i class="fa-solid text-danger fa-arrow-down"></i>
                      </span>
                      <span *ngIf="item?.movement  !== 0 && item?.direction == 'up'">
                        <i class="fa-solid text-success fa-arrow-up"></i>
                      </span>
                      <span *ngIf="item?.movement  !== 0 && item?.direction == 'same'">
                        <i class="fa-solid text-dark fa-minus"></i>
                      </span>

                    </p>

                  </div>
                  <div class="d-block">
                    <p class="mb-0">
                      {{item?.current_position}}
                    </p>
                    <p class="mb-0">
                      <span *ngIf="item?.movement  !== 0 && item?.direction == 'down'" class="text-danger">
                        {{ item?.movement }}
                      </span>
                      <span *ngIf="item?.movement  !== 0 && item?.direction == 'up'" class="text-success">
                        {{ item?.movement }}
                      </span>


                    </p>
                  </div>
                </div>

              </td>
              <td>
                <a [routerLink]="['/'+item?.social_tag]" routerLinkActive="router-link-active">
                  <span class="me-2">
                    <img
                      [src]=" item?.creator_img ? item?.creator_img : 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg' "
                      class="rounded-circle creatorImg" alt="">
                  </span>
                  <span>{{ item?.social_tag }} </span>
                </a>
              </td>
              <td>
                {{ item?.total_holders }}
              </td>
              <td> {{ app.getPriceInGary(item?.token_price_usd) | number:'1.0-2' }} {{ app.coin_symbol }} </td>
              <td>
                <span [ngClass]="item?.has_multiplier === true ? 'text-success' : '' ">
                  {{ item?.score }}
                  <span *ngIf="item?.has_multiplier === true">&nbsp; <i
                      class="fa-solid fa-rocket fa-beat-fade"></i></span>
                </span>

              </td>
              <td>
                {{item?.gary_earned| number : '1.0-2'}} $GARY
              </td>
              <!-- <td>
                <span *ngIf="item.percentage_change_3_days > 0"
                  class="text-success">{{item.percentage_change_3_days}}%</span>
                <span *ngIf="item.percentage_change_3_days < 0"
                  class="text-danger">{{item.percentage_change_3_days}}%</span>
                <span *ngIf="item.percentage_change_3_days == 0">{{item.percentage_change_3_days}}%</span>
              </td> -->
              <td>
                <span *ngIf="item?.percentage_change_7_days > 0"
                  class="text-success">{{item?.percentage_change_7_days}}%</span>
                <span *ngIf="item?.percentage_change_7_days < 0"
                  class="text-danger">{{item?.percentage_change_7_days}}%</span>
                <span *ngIf="item?.percentage_change_7_days == 0">{{item?.percentage_change_7_days}}%</span>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="text-end mt-3 me-3" *ngIf="!loading">
    <button *ngIf="!hideLoad" class="btn btn-dark rounded-pill py-2 px-3" (click)="getMoreTokens()">Load More <i
        class="fa-solid fa-chevron-down"></i></button>
    <!-- <button *ngIf="hideLoad" class="btn btn-dark rounded-pill py-2 px-3" (click)="loadLess()">Show Less <i
        class="fa-solid fa-chevron-up"></i></button> -->
  </div>


  <!-- <br><br><br><br><br><br><br><br><br><br> -->
</div>


<ng-template #content let-modal>
  <div class="modal-header text-center">
    <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
    <div class="d-flex  align-items-center justify-content-center text-center">
      <div class="me-3">
        <img [src]="token.coin_image" width="50" height="50" class="rounded-circle" alt="" srcset="">
      </div>
      <h5 class="mb-0">{{ token.coin_symbol }} </h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-center" *ngIf="offers.length > 0">
    <h4 class="mdl">
      Quantity of token
    </h4>

    <ul class="list-unstyled mt-4" *ngFor="let item of filteredoffers | orderBy: order3">
      <li class="mb-3">
        <a [href]="item?.link" target="_blank" class="btn btn-outline-primary btn-block py-3 monument">{{ item?.amount
          }}
          {{ token.coin_symbol }}</a>
      </li>
      <!-- <li class="mb-3">
          <button class="btn btn-outline-primary btn-block py-3 monument">100 $ELEVATE</button>
        </li>
        <li>
          <button class="btn btn-outline-primary btn-block py-3 monument">200 $ELEVATE</button>
        </li> -->
    </ul>
  </div>
  <div class="modal-body text-center" *ngIf="offers.length <= 0">
    <h4>There are no offers at this time</h4>
    <h4>Please try later</h4>
  </div>
  <div class="modal-footer text-center justify-content-center" *ngIf="offers.length > 0">
    <p>You are heading to an external site!</p>
  </div>
</ng-template>