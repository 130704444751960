import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { PusherService } from 'src/app/_services/pusher.service';
@Component({
  selector: 'app-post-item',
  templateUrl: './post-item.component.html',
  styleUrls: ['./post-item.component.scss']
})
export class PostItemComponent implements OnInit {
  @Input() item: any; // Define an Input property to receive data from the parent component
  @Input() app: any;
  @Input() user: any;
  imgFile: any;
  paid_post: any;
  postItem: any;

  constructor(private modal: NgbModal, private pusherService: PusherService, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  
  ngOnInit(): void {
    this.pusherService.channel.bind('paid_post_subscription', data => {
      if (data.success == true && data.user_id == this.user?.id && this.paid_post != null) {
        this.modal.dismissAll()
        this.app.router.navigateByUrl(`/post/${this.paid_post}`)
      } else if (data.success == false && data.user_id == this.user?.id && this.paid_post != null) {
        this.modal.dismissAll()
        Swal.fire('Payment Failed', data.message, 'error')
      }
    });
  }

  toLink(link) {
    this.app.router.navigateByUrl(`/${link}`)
  }
  confirmPay(item, content2) {
    this.postItem = item
    console.log(this.item)
    this.modal.open(content2, { backdrop: true, centered: true })
  }
  pay() {
    this.modal.dismissAll()
    const modalRef = this.modal.open(loadModalComponent, { centered: true });
    console.log(this.postItem)
    this.app.payPerView(this.postItem).subscribe({
      next: (res: any) => {
        console.log(res)
      },
      error: (err: any) => {
        Swal.fire('Failed', err.error.message, 'error')
        modalRef.dismiss();
      },
      complete: () => {
        this.paid_post = this.postItem;
      }
    })
  }


}


@Component({
  selector: 'app-loader-modal',
  template: `
  <div class="modal-body modal-center p-5 rounded-5">
    <div class="d-flex align-items-center">
  <h3>Please Wait...</h3>
  <i class="fa-duotone fa-spinner fa-spin-pulse text-primary fa-xl ms-auto" ></i>
    <!-- <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl ms-auto"></i> -->
    </div>


  <!-- <br> -->
  <div class="d-block">
    <p>Payment is processing...</p>
  </div>
</div>
  `,
  styles: [
    `
     .text.active {
   background-color: var(--bs-primary) !important;
   color: var(--bs-text-secondary) !important;

 }

 .text {
   border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
   border-radius: 50%;
 }
    `
  ]
})
export class loadModalComponent implements OnInit {
  constructor(private modalService: NgbModal, config: NgbModalConfig,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

  // openModal(): NgbModalRef {
  //   return this.modalService.open(content);
  // }

  closeModal(modalRef: NgbModalRef): void {
    modalRef.dismiss();
  }
}
