// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pusher: {
    key: 'tes-exchange',
    port: 443,
    host: 'talk.socialconnector.io',
    cluster: 'mt1',
  },
  base_api: 'https://gatewaytesting.socialconnector.io/api/v2',
  apiUrl: 'https://gatewaytesting.socialconnector.io/api/v2',
  socConnUrl: 'https://testing.socialconnector.io',
  solana_api:
    'https://rpc.ankr.com/solana/9902ace95e4598aa39365c165e93aaf487986a32b6351633df27ab5388836413',
  solana_api_ws:
    'wss://rpc.ankr.com/solana/ws/9902ace95e4598aa39365c165e93aaf487986a32b6351633df27ab5388836413',
  network: 'mainnet',
  year: '2024',
  social_pay_url: 'https://testing.socialconnector.io/checkout/',
  mint_page_url: 'https://testing.socialconnector.io/mint/',
  client_id: "132979932052-hijnj46cefb6trd00au64pee63mi562q.apps.googleusercontent.com",
  // "client_id": "132979932052-hijnj46cefb6trd00au64pee63mi562q.apps.googleusercontent.com",
  "project_id": "sc-test-3319334",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_secret": "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",
  "javascript_origins": [
    "http://127.0.0.1:2200",
    "http://localhost:2200",
    "http://localhost",
    "https://exdev.socialconnector.io",
    "https://exchangestaging.socialconnector.io"
  ],
  apiKey: 'AIzaSyB0Jz6GuLQkExDVVHHOawe_VOVr3z7A6i8',
  share: "https://ms-sc-events-testing.socialconnector.io/event",
  stake_key: 'ErSzQoPvcF2C2MhbQEonZuvEXdzUqYoNLq8ajyUWEYoi',
  program: 'STAKEGztX7S1MUHxcQHieZhELCntb9Ys9BgUbeEtMu1',
  stake_api: 'https://stakeengine.socialconnector.io',
  nonce: '1'
  // stake_key: 'CkPepWVnVomvDncspSkegk8M39SyL92kePTHMmgomuCF',
  // program: 'STAKEGztX7S1MUHxcQHieZhELCntb9Ys9BgUbeEtMu1',
  // stake_api: 'https://stakeengine.socialconnector.io',
  // nonce: '0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
