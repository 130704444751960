import { environment } from 'src/environments/environment';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServiceService } from 'src/app/_services/app-service.service';
import { parseISO } from 'date-fns';
import { format, zonedTimeToUtc } from "date-fns-tz";
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'angular-toastify';
import { AlertService } from '@full-fledged/alerts';
import { Clipboard } from '@angular/cdk/clipboard';
import { Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';

const share = `${environment.share}`;
@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  private userLocalStorageToken = `userdetails`;
  id: string;
  event: any;
  attendees: any = [];
  zone: any;
  fromdate: string;
  todate: string;
  joinForm: any;
  users: any;
  isAttendee: boolean;
  payment_method: any;
  paidForm: any;
  content2: any;

  constructor(
    private service: AppServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private datePipe: DatePipe,
    private modal: NgbModal,
    config: NgbModalConfig,
    private fb: FormBuilder,
    private _toastService: ToastService,
    private alertService: AlertService,
    private clipboard: Clipboard,
    private metaService: Meta,
    private ngZone: NgZone,
  ) {
    this.id = route.snapshot.paramMap.get('id');
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;


    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  ngOnInit(): void {
    this.getEventById()
    this.initForm()


  }
  initForm() {
    this.joinForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.paidForm = this.fb.group({
      payment_method: ['', Validators.required]
    })
  }
  getZOneIp() {
    this.service.getZoneByIp().subscribe({
      next: (res: any) => {
        // console.log(res)
        this.zone = res
      },

      error: (err) => {
        console.log(err)
      },
      complete: () => {


        // console.log(this.fromdate)
      }

    })
  }

  getEventById() {
    this.service.getSingleEvent(this.id).subscribe({
      next: (res: any) => {
        console.log(res);
        this.event = res.data
        this.attendees = res.data.attendees
        // console.log(this.attendees)
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.fromdate = this.covertTZ(this.event?.date, this.event.timezone,)
        this.todate = this.covertTZ(this.event?.end_date, this.event.timezone)
        this.getUser()
        this.getZOneIp()
        const shareableUrl = `https://gary.club/events/${this.event.id}`;
        const plainTextBioDet = this.stripHtmlTags(this.event.description);
        console.log(plainTextBioDet)
        this.metaService.updateTag({ name: 'description', content: plainTextBioDet });
        this.metaService.updateTag({ name: 'twitter:image', content: this.event.image });
        this.metaService.updateTag({ property: 'og:url', content: shareableUrl });
        this.ngZone.run(() => {
          this.metaService.updateTag({ property: 'og:description', content: plainTextBioDet });
          this.metaService.updateTag({ property: 'og:url', content: shareableUrl });
          this.metaService.updateTag({ property: 'og:title', content: this.event.name });
          this.metaService.updateTag({ property: 'og:site_name', content: this.event.name });
          this.metaService.updateTag({ property: 'og:image', content: this.event.image });
          this.metaService.updateTag({ name: 'twitter:image', content: this.event.image });
        })
      }
    })
  }

  private stripHtmlTags(htmlString: string): string {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  }

  isValidTimezoneFormat(timezone: string): boolean {
    // Regular expression to match the format 'Continent/City' or 'Continent/Country/City'
    const timezoneRegex = /^[A-Za-z_\-/]+(\/[A-Za-z_\-/]+)*$/;

    // console.log(timezoneRegex.test(timezone))
    // Check if the input timezone matches the regular expression
    return timezoneRegex.test(timezone);
  }



  covertTZ(fromDate: string, fromTz: string): string {
    if (!this.isValidTimezoneFormat(fromTz)) {
      // throw new Error("Invalid timezone format. Please use the format 'Continent/City'.");
      return fromDate
    }
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // console.log(fromDate, fromTz, toTz)
    if (fromTz === userTimezone) {
      return fromDate
    } else {
      const fromDatetime = parseISO(fromDate);


      // Convert the datetime to UTC
      const utcDatetime = zonedTimeToUtc(fromDatetime, fromTz);

      // Convert the UTC datetime to the target timezone
      const toDatetime = format(utcDatetime, 'yyyy-MM-dd HH:mm:ssXXX', {
        timeZone: userTimezone,
      });

      return toDatetime;
    }
  }
  getFormattedDate(date: any) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = this.datePipe.transform(date, 'yyyyMMdd\'T\'HHmmss');

    return formattedDate
  }

  addToCalendar(item) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
      name: item.name,
      startDate: this.getFormattedDate(this.fromdate),
      endDate: this.getFormattedDate(this.todate),
      ctz: userTimezone,
      details: item.description,
      location: item.location
    }

    // console.log(data)

    const params = new URLSearchParams();
    params.set('action', 'TEMPLATE');
    params.set('text', data.name);
    params.set('dates', data.startDate + '/' + data.endDate);
    params.set('ctz', data.ctz);
    params.set('details', data.details);
    params.set('location', data.location);

    const apiUrl = 'https://calendar.google.com/calendar/render';

    const link = apiUrl + '?' + params.toString();

    const anchor = this.elementRef.nativeElement.appendChild(document.createElement('a'));
    anchor.href = link;
    anchor.target = '_blank';

    anchor.click();

    // Cleanup: remove the anchor element
    anchor.remove();



  }

  attendEvent() {
    this.service.attendEvent(this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        // this.getUser()
        if (res.data.make_payment) {
          this.modal.open(this.content2, {}).result
        }
        else {
          this.router.navigateByUrl('/events/' + this.id + '/rsvp')
        }
      },
      error: (err: any) => {
        console.log(err)
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        // this.router.navigateByUrl('/events/' + this.id + '/rsvp')
        // this.getEventById()
      }
    })
  }

  rsvpEvent(content, event, content2) {
    this.content2 = content2
    if (this.service.isLogedIn()) {
      this.attendEvent()
      // if (event.ticket === 'paid') {
      //   this.modal.open(content2, {}).result
      // }
      // else {
      //   this.attendEvent()
      // }


    } else {
      this.modal.open(content, {}).result
    }
  }
  async getUser() {
    const a = await this.service.getUser()
    this.users = a
    console.log(this.users)
    if (this.users) {
      this.isAttendee = this.isRSVPed()
      console.log('Is attendee', this.isAttendee)
    }

  }

  isRSVPed() {
    for (const item of this.attendees) {
      if (item.user_id === this.users.id.toString()) {
        return true;
      }
    }
    return false;
  }
  cancel() {
    this.modal.dismissAll()
    this.joinForm.reset();
  }

  submit() {
    const payload = {
      email: this.joinForm.value.email,
      password: this.joinForm.value.password,
      service: 'event'
    }

    this.service.login(payload).subscribe({
      next: async (res: any) => {
        console.log(res)
        if (res.data.token) {
          const token = res.data.token
          this.getUserByToken(token);
          const result = this.service.setAuthFromLocalStorage(res);
        }
        else {
          // this.alertService.danger('Login Not Successfull')
          Swal.fire('Login not successful', '', 'error')
        }

      },
      error: (err: any) => {
        console.log(err)
        // this.alertService.danger('error')
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        this.cancel()

      }
    })

  }

  payNow() {
    const modalRef = this.modal.open(loadModalComponent);
    const pay = {
      payment_method: this.paidForm.value.payment_method,
    }

    console.log(pay)

    this.service.makePayment(pay, this.id).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.page_link) {
          modalRef.dismiss();
          window.location.href = res.page_link
        } else {
          modalRef.dismiss();
          this.router.navigateByUrl('/events/' + this.id + '/rsvp')
        }
      },
      error: (err: any) => {
        modalRef.dismiss();
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        modalRef.dismiss();
        this.modal.dismissAll()
        this.router.navigateByUrl('/events/' + this.id + '/rsvp')
      }
    })
  }
  openTwitter(event) {
    const url2 = `${share}/${event.id}`
    console.log(url2)
    const url = `https://twitter.com/intent/tweet/?text=${event.name}&url=${url2}&hashtags=SocialConnector&via=GarydotClub`


    console.log(url)
    // window.location.href = url
    window.open(url, 'blank')
  }

  getUserByToken(token) {
    this.service.getUserByToken(token).subscribe({
      next: (res: any) => {
        // console.log('User reeeeeeee ', res)
        this.service.saveUserToStorage(res)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        this.getUser()
        this.attendEvent()
      }
    })
  }

  copyToClipboard(event) {
    const url = `${share}/${event.id}`
    // const a = window.location.href
    this.clipboard.copy(url)
    // this.alertService.success('Copied to clipboard');
    Swal.fire('Copied to clipboard', '', 'success')
  }
}




@Component({
  selector: 'app-loader-modal',
  template: `
  <div class="modal-body modal-center p-5 rounded-5">
    <div class="d-flex align-items-center">
  <h3>Please Wait...</h3>
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl ms-auto"></i>
    </div>

  <!-- <br> -->
  <!-- <div class="text-center mt-3">
    <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl"></i>
  </div> -->
</div>
  `,
  styles: [
    `
     .text.active {
   background-color: var(--bs-primary) !important;
   color: var(--bs-text-secondary) !important;

 }

 .text {
   border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
   border-radius: 50%;
 }
    `
  ]
})
export class loadModalComponent implements OnInit {
  constructor(private modalService: NgbModal, config: NgbModalConfig,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

  // openModal(): NgbModalRef {
  //   return this.modalService.open(content);
  // }

  closeModal(modalRef: NgbModalRef): void {
    modalRef.dismiss();
  }
}
