<div class="container-fluid" *ngIf="!stoploading">
  <div class="overlay" style="height: 100vh;"></div>
</div>

<div class="container-fluid mt-3" [class]="profile? 'fadein' : '' " *ngIf="!userNotExist && !accountSuspended">
  <!-- New Profile design -->
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="row">
        <div class="col-6">
          <img class="profilepic img-fluid" [src]="
                      profile?.sc_profile_picture
                        ? profile?.sc_profile_picture
                        : 'assets/imgs/placeholder.png'
                    " alt="" srcset="" />
        </div>
        <div class="col-6">
          <div class="table-responsive">
            <table class="table tabili">
              <tr>
                <td>
                  <p>Price</p>
                  <h3>{{ service.getPriceInGary(creatorprice?.total_gary_usd)|| 0 | number :'1.0-2' }} {{
                    service.coin_symbol }}</h3>
                </td>
                <td>
                  <p>Rank</p>
                  <h3>#{{ supportersInfo?.rank || '-' }}</h3>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="profile-name">
        <div class="d-flex">
          <h3>{{ profile?.social_tag }}</h3>
          <div class="ogbadge" *ngIf="profile?.is_og">
            <img src="assets/imgs/og_badge.png" alt="">
          </div>
          <div class="ogbadge" *ngIf="!profile?.is_og && profile?.is_giraffe_owner">
            <img src="assets/imgs/is_giraffe_owner.png" alt="">
          </div>
        </div>


      </div>
      <div class="d-flex">
        <div class="joinTelegram px-3 py-2 bg-primary border-0 text-center"
          *ngIf="getIfSupporters?.viewer_already_a_member == true && supportersInfo?.telegram_chat == true"
          (click)=" joinTelegram()">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/242px-Telegram_2019_Logo.svg.png"
            alt="" class="img-fluid" width="20%">
          <p class="">Join Telegram</p>
        </div>

        <div class="joinTelegram  px-3 py-2 bg-primary border-0 text-center"
          *ngIf="getIfSupporters?.viewer_already_a_member == false && supportersInfo?.telegram_chat == true"
          (click)=" buyStake(content3, content2, content8)">
          <span><i class="fa-solid fa-lock"></i></span>
          <p class="">Private Chat</p>
        </div>
      </div>
      <div class="profile-description">
        <h3 *ngIf="profile?.bio != 'null'">{{ profile?.bio }}</h3>
      </div>
      <div>
        <div class="d-md-flex align-items-center mt-1">
          <!-- <a class="me-3 text-md" *ngIf="profile?.city != null && profile?.country != null">
            <i class="fa-solid fa-location-dot"></i>
            {{ profile?.city }}, {{ profile?.country }}
            </a> -->
          <a *ngIf="profile?.twitter_user_id" class="btn btn-clear x-ico" target="_blank"
            [href]="'https://twitter.com/' + profile?.twitter_user_id">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a *ngIf="profile?.instagram_user_id" class="btn btn-clear x-ico" target="_blank"
            [href]="'https://instagram.com/' + profile?.instagram_user_id">
            <i class="fa-brands fa-instagram"></i>
          </a>
        </div>
      </div>
      <div class="col-12 col-lg-8 col-md-12">
        <div class="info-table table-responsive">
          <table class="table table-bordered mt-3 text-center">
            <tr>
              <th class="py-3">$GARY Earned</th>
              <th class="py-3">Followers</th>
              <th class="py-3">Supporters</th>
              <th class="py-3">Points</th>
            </tr>
            <tr>
              <td>
                {{ stakeStats?.total_earned || 0 | number : "1.0-2" }}
              </td>
              <td>
                {{ user_profile?.followers_count || 0}}
              </td>
              <td>
                {{ stakeStats?.supporters || 0}}
              </td>
              <td>
                <span [ngClass]="supportersInfo?.has_multiplier === true ? 'text-success' : '' ">
                  {{ supportersInfo?.points || 0 }}
                  <span *ngIf="supportersInfo?.has_multiplier === true">&nbsp; <i
                      class="fa-solid fa-rocket fa-beat-fade"></i></span>
                </span>
                <!-- <h3 class="classsup" *ngIf="supportersInfo?.points > 0">{{ supportersInfo?.points }} </h3> -->
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="action-btns mt-3 d-md-flex d-none">

        <button class="btn btn-custom rounded-pill me-3 custombtn" *ngIf="cansupport"
          (click)="buyStake(content3, content2, content8)">
          <i *ngIf="loadingbuy" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Support
        </button>
        <button *ngIf="showSel" class="btn btn-outline-primary rounded-pill me-3 custombtn stop-support"
          (click)="sellStake(content4, content2)">
          <i *ngIf="loadingsell" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Stop Supporting
        </button>
        <span *ngIf="!showpost">
          <button class="follow-btn btn btn-outline-primary rounded-pill me-3 custombtn"
            *ngIf="user_profile?.is_following == false" (click)="followUser(profile?.user_id)">
            Follow
          </button>
          <button class="btn btn-outline-primary rounded-pill me-3 custombtn" *ngIf="user_profile?.is_following == true"
            (click)="followUser(profile?.user_id)">
            Unfollow
          </button></span>
        <span *ngIf="showpost && completeReg">
          <button class="btn btn-outline-primary rounded-pill me-3 custombtn" (click)="complete()">Activate
            Monetization</button>
        </span>

        <span *ngIf="showpost">
          <button class="btn btn-outline-primary rounded-pill me-3 custombtn"
            (click)="createModal(content7)">Post</button>
        </span>
        <span *ngIf="!showpost">
          <button *ngIf="isCreator  &&  profile?.minimum_note_amount !== null"
            class="btn btn-outline-primary rounded-pill me-3 custombtn" (click)="sendNote(content, content2)">
            Send a Note
          </button>
        </span>




      </div>

      <div class="action-btns mt-3 d-md-none d-block">
        <div class="row">
          <div class="col-6">
            <button class="btn btn-custom rounded-pill me-3 custombtn" *ngIf="cansupport"
              (click)="buyStake(content3, content2, content8)">
              <i *ngIf="loadingbuy" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Support
            </button>
          </div>
          <div class="col-6">
            <button *ngIf="showSel" class="btn btn-outline-primary rounded-pill me-3 custombtn stop-support"
              (click)="sellStake(content4, content2)">
              <i *ngIf="loadingsell" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Stop Supporting
            </button>
          </div>
          <div class="col-6" *ngIf="!showpost">
            <button class="follow-btn btn btn-outline-primary rounded-pill me-3 custombtn"
              *ngIf="user_profile?.is_following == false" (click)="followUser(profile?.user_id)">
              Follow
            </button>
            <button class="btn btn-outline-primary rounded-pill me-3 custombtn"
              *ngIf="user_profile?.is_following == true" (click)="followUser(profile?.user_id)">
              Unfollow
            </button>
          </div>
          <div class="col-6">
            <span *ngIf="showpost">
              <button class="btn btn-outline-primary rounded-pill me-3 custombtn"
                (click)="createModal(content7)">Post</button>
            </span>
            <span *ngIf="!showpost">
              <button *ngIf="isCreator  &&  profile?.minimum_note_amount !== null"
                class="btn btn-outline-primary rounded-pill me-3 custombtn" (click)="sendNote(content, content2)">
                Send a Note
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Navs -->

  <div class="nav-bars">
    <div class="row">
      <div class="col-md-8 offset-md-2 table-responsive">
        <ul class="nav  nav-justified  flex-nowrap">
          <li class="nav-item ">
            <a class="nav-link  text-active-primary " [ngClass]="activetab === 1 ? 'active' : ''"
              (click)="setActive(1)">
              Feed
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-active-primary " [ngClass]="activetab === 2 ? 'active' : ''" (click)="setActive(2)">
              Supporters
            </a>
          </li>
          <!-- <li class="nav-item ">
            <a class="nav-link text-active-primary " [ngClass]="activetab === 3 ? 'active' : ''" (click)="setActive(3)">
              Events
            </a>
          </li> -->


          <li class="nav-item ">
            <a class="nav-link text-active-primary " [ngClass]="activetab === 4 ? 'active' : ''" (click)="setActive(4)">
              Offers
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-active-primary " [ngClass]="activetab === 5 ? 'active' : ''" (click)="setActive(5)">
              Collectibles
            </a>
          </li>

          <li class="nav-item ">
            <a class="nav-link text-active-primary " [ngClass]="activetab === 6 ? 'active' : ''" (click)="setActive(6)">
              Supporting
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-active-primary " [ngClass]="activetab === 7 ? 'active' : ''" (click)="setActive(7)">
              POW
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-active-primary " [ngClass]="activetab === 8 ? 'active' : ''" (click)="setActive(8)">
              Towers
            </a>
          </li>

        </ul>
      </div>
    </div>
  </div>




  <div class="container">
    <div *ngIf="activetab===1">
      <div class="row  mt-3" *ngIf="service.activityLogs?.length <=0">
        <div class="col-12 text-center">
          <p>No data to display</p>

        </div>
      </div>
      <div class="row  mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="getMoreActivities()">
        <div class="col-md-8 offset-md-2">
          <ul class="list-unstyled border">
            <li class="border-bottom" *ngFor="let item of service.activityLogs">
              <app-custom [item]="item" [user]="user" [app]="service"></app-custom>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <div *ngIf="activetab === 2">
      <div class="row  mt-3">
        <div class="col-12">
          <div class="">
            <div class="c">
              <div class="row ">
                <div class="col-md-6">
                </div>

                <div class="col-md-6 text-md-end">
                  <div class="row">
                    <div ngbDropdown display="dynamic" placement="bottom-end" class="d-inline-block">
                      <button type="button" class="btn dropdownBtn" id="dropdownBasic1" ngbDropdownToggle>
                        {{ filt }}
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="filterSupporter('first_purchase')">
                          First Support Date
                        </button>
                        <button ngbDropdownItem (click)="filterSupporter('owned')">
                          Supporter Level
                        </button>
                        <button ngbDropdownItem (click)="filterSupporter('last_purchase')" s>
                          Most Recent Purchase
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="supporters?.length <= 0">
                <p class="text-center">
                  This creator has no supporters
                </p>
              </div>
              <div *ngIf="supporters?.length > 0">
                <div class="row  mt-3">

                  <div class="col-lg-1 col-md-2 col-sm-3 col-4 mb-4 text-center" *ngFor="let item of supporters"
                    style="cursor: pointer" (click)="viwCreator(item, content5)">
                    <div class="mb-2">
                      <img [src]="item?.profile_img" class="roundedd" width="80" height="80" alt=""
                        onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
                    </div>
                    <h6 class="mb-1">{{ item?.social_tag }}</h6>
                  </div>

                </div>
              </div>



              <div class="text-end mt-3">
                <button class="btn btn-clear text-primary" *ngIf="csupanScrollUp" (click)="supscrollUp()">
                  Back to top
                </button>
                <button class="btn btn-clear text-primary" *ngIf="csupanScrollDown" (click)="supscrollDown()">
                  Load more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- <div *ngIf="activetab === 3">
      <div class="row  mt-3" *ngIf="allEvents?.length <=0">
        <div class="col-12 text-center">
          <p>No data to display</p>

        </div>
      </div>
      <div class="row  mt-3">
        <div class="col-lg-4 col-md-6 col-12 mb-4 relative" *ngFor="let item of allEvents">
          <a class="" [href]="['/events/'+ item.id]">
            <div class="card relative">
              <div class="timeover">
                <div class="d-block color">
                  <h4 class="mb-0"> {{ item?.date_tz | date: 'dd' }} </h4>
                  <p class="mb-0 fs-7"> {{ item?.date_tz | date: 'MMM' }} </p>
                </div>
              </div>
              <div class="attend">
                <div class="inner" [ngClass]="item?.event_action === 'attending'? 'bg-primary': 'bg-success'">
                  {{item?.event_action | uppercase }}
                </div>
              </div>
              <img [src]="item?.image ? item?.image : 'assets/imgs/background.jpg'" class="card-img-top" alt="" />
              <div class="eventTitle">
                <div class="text">
                  {{ item?.name }}
                </div>
              </div>

            </div>
          </a>

        </div>
      </div>
    </div> -->

    <div *ngIf="activetab === 4">
      <div class="row  mt-3" *ngIf="allOffers?.length <=0">
        <div class="col-12 text-center">
          <p>No data to display</p>

        </div>
      </div>
      <div class="row  mt-3">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 relative mb-4" *ngFor="let item of allOffers">
          <a *ngIf="!item.nft_hash" class="" [href]="social_pay_url + item.page_link" target="_blank">
            <div class="card relative">

              <div class="attend">
                <div class="inner" [ngClass]="item?.nft_hash  ? 'bg-primary': 'bg-success'">
                  Buy
                </div>
              </div>
              <img [src]="item?.payment_image ? item?.payment_image : 'assets/imgs/background.jpg'"
                class="card-img-top offerimg" alt="" />
              <div class="eventTitle">
                <div class="text">
                  {{ item?.page_name }}
                </div>
              </div>

            </div>
          </a>

          <a *ngIf="item.nft_hash" class="" [href]="mint_page_url + item.hash" target="_blank">
            <div class="card relative">

              <div class="attend">
                <div class="inner" [ngClass]="item?.nft_hash  ? 'bg-primary': 'bg-success'">
                  Mint
                </div>
              </div>
              <img [src]="item?.nft?.image ? item?.nft?.image : 'assets/imgs/background.jpg'"
                class="card-img-top offerimg" alt="" />
              <div class="eventTitle">
                <div class="text">
                  {{ item?.nft?.name }}
                </div>
              </div>

            </div>
          </a>
        </div>
      </div>
    </div>


    <div *ngIf="activetab ===5 ">
      <div class="row  mt-3" *ngIf="collectibles?.length <=0">
        <div class="col-12 text-center">
          <p>No data to display</p>

        </div>
      </div>
      <div class="row  mt-3">
        <div class="col-lg-3 col-md-4 mb-4 relative" *ngFor="let item of collectibles">
          <div class="card relative" (click)="viewCollectible(item, content6)" style="cursor: pointer">
            <img *ngIf="item.image" [src]="item?.image ? item?.image : 'assets/imgs/nft.jpg'" class="card-img-top"
              alt="" />
            <img *ngIf="!item.image" [src]="getSrc(item.address)" class="card-img-top img-fluid"
              onerror="this.onerror=null;this.src='assets/imgs/nft.jpg';" />
            <div class="eventTitle">
              <div class="text">
                {{ item?.name }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div *ngIf="activetab===6">
      <div class="row  mt-3">
        <div class="col-12">
          <div class="card ">
            <div *ngIf="supporting?.length  <=0 ">
              <p class="text-center">
                No data to display
              </p>
            </div>
            <div *ngIf="supporting?.length  >0 " class="table-responsive  align-items-center">
              <table class="table table-striped">
                <thead class="head border">
                  <tr>
                    <th colspan="2" class="py-3 ps-1 ps-md-5">Creator</th>
                    <th class="py-3">Rank</th>
                    <th class="py-3">Price</th>
                    <th class="py-3">Quantity</th>
                    <th class="text-end py-3 pe-5">Supporter Since</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="align-middle" *ngFor="let item of supporting">
                    <td class="ps-1 ps-md-5" style="width: 50px !important;">
                      <a [href]="['/'+ item?.social_tag]">
                        <img
                          [src]="item?.creator_img ? item?.creator_img : 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg' "
                          alt="" class="rounded-circle me-3" width="50" height="50" />

                      </a>
                    </td>
                    <td>
                      <a [href]="['/'+ item?.social_tag]">

                        {{ item?.social_tag }}
                      </a>
                    </td>
                    <td> {{ item?.current_rank }} </td>
                    <td>
                      {{ service.getPriceInGary(item?.price) || 0 | number : '0.0-2'}} {{ service.coin_symbol }}
                    </td>
                    <td>
                      {{ item?.total }}
                    </td>

                    <td class="text-end pe-5"> {{ item?.first_purchase | date : 'MMM dd, YYYY' }} </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div *ngIf="activetab===8">
      <div class="row  mt-3" *ngIf="myTowers?.length <=0">
        <div class="col-12 text-center">
          <p>No data to display</p>

        </div>
      </div>
      <div class="row  mt-3">
        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-4" *ngFor="let item of myTowers">
          <div class="card">
            <div class="card-body p-md-4">
              <h3 class="tower-title"> {{ item?.name }} </h3>
              <div class="desc">
                <p> {{ truncateDescription(item?.description) }} </p>
              </div>

              <div class="towerimg my-3">
                <span *ngFor="
                        let items of item.segment_users.slice(0, 10)">
                  <img [src]="items?.image || 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg'"
                    class="rounded-circle" alt="" onerror="this.onerror=null;this.src=''" />
                </span>
                <small *ngIf="item?.segment_users_count > 10">
                  +<span> {{ item?.segment_users_count - 10 }} </span>
                </small>
              </div>
              <!-- <p class="text-muted fs-7">Floor price</p>
              <p>${{ item?.floor_price| number:'1.0-2' }} </p> -->

              <div class="mt-3">
                <a [routerLink]="['/tower-members', item.ref]"
                  class="btn btn-outline-primary towerbtn rounded-pill me-3">
                  Explore</a>
                <a [href]="item.link" target="_blank" rel="noopener noreferrer"
                  class="btn btn-custom rounded-pill towerbtn">Join</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>




  <div *ngIf="activetab===7">
    <div class="row  mt-3">
      <div class="col-12">

        <app-team-pow [social_tag]="social_tag"></app-team-pow>
      </div>
    </div>
  </div>


  <!-- Navs end -->



</div>
<div class="container-fluid mt-3" *ngIf="accountSuspended">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="row">
        <div class="col-6">
          <img class="profilepic img-fluid" [src]="
                        profile?.sc_profile_picture
                          ? profile?.sc_profile_picture
                          : 'assets/imgs/placeholder.png'
                      " alt="" srcset="" />
        </div>
        <div class="col-6">
          <div class="table-responsive">
            <table class="table tabili">
              <tr>
                <td>
                  <p>Price</p>
                  <h3>--</h3>
                </td>
                <td>
                  <p>Rank</p>
                  <h3>--</h3>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="profile-name">
        <div class="d-flex">
          <h3>{{ profile?.social_tag }}</h3>
          <!-- <div class="ogbadge" *ngIf="profile?.is_og">
            <img src="assets/imgs/og_badge.png" alt="">
          </div>
          <div class="ogbadge" *ngIf="!profile?.is_og && profile?.is_giraffe_owner">
            <img src="assets/imgs/is_giraffe_owner.png" alt="">
          </div> -->
        </div>


      </div>
      <div class="d-flex">
        <!-- <div class="joinTelegram px-3 py-2 bg-primary border-0 text-center"
          *ngIf="getIfSupporters?.viewer_already_a_member == true && supportersInfo?.telegram_chat == true"
          (click)=" joinTelegram()">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/242px-Telegram_2019_Logo.svg.png"
            alt="" class="img-fluid" width="20%">
          <p class="">Join Telegram</p>
        </div> -->

        <!-- <div class="joinTelegram  px-3 py-2 bg-primary border-0 text-center"
          *ngIf="getIfSupporters?.viewer_already_a_member == false && supportersInfo?.telegram_chat == true"
          (click)=" buyStake(content3, content2, content8)">
          <span><i class="fa-solid fa-lock"></i></span>
          <p class="">Private Chat</p>
        </div> -->
      </div>
      <div class="profile-description">
        <!-- <h3 *ngIf="profile?.bio != 'null'">{{ profile?.bio }}</h3> -->
      </div>
      <!-- <div>
        <div class="d-md-flex align-items-center mt-1">

          <a *ngIf="profile?.twitter_user_id" class="btn btn-clear x-ico" target="_blank"
            [href]="'https://twitter.com/' + profile?.twitter_user_id">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a *ngIf="profile?.instagram_user_id" class="btn btn-clear x-ico" target="_blank"
            [href]="'https://instagram.com/' + profile?.instagram_user_id">
            <i class="fa-brands fa-instagram"></i>
          </a>
        </div>
      </div> -->
      <div class="col-12 col-lg-8 col-md-12">
        <div class="info-table table-responsive">
          <table class="table table-bordered mt-3 text-center">
            <tr>
              <th class="py-3">$GARY Earned</th>
              <th class="py-3">Followers</th>
              <th class="py-3">Supporters</th>
              <th class="py-3">Points</th>
            </tr>
            <tr>
              <td>
                --
              </td>
              <td>
                --
              </td>
              <td>
                --
              </td>
              <td>
                --
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="action-btns mt-3 d-md-flex d-none">
        <button *ngIf="showSel" class="btn btn-outline-primary rounded-pill me-3 custombtn stop-support"
          (click)="sellStake(content4, content2)">
          <i *ngIf="loadingsell" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Stop Supporting
        </button>
      </div>

      <div class="action-btns mt-3 d-md-none d-block">
        <div class="row">
          <div class="col-6">
            <button *ngIf="showSel" class="btn btn-outline-primary rounded-pill me-3 custombtn stop-support"
              (click)="sellStake(content4, content2)">
              <i *ngIf="loadingsell" class="fa-solid fa-spinner fa-spin-pulse me-3"></i> Stop Supporting
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 text-center">
      <h3 class="fadeIn">Account Suspended</h3>
      <p>Gary Club suspends accounts that violate our TOS</p>
      <button type="button" name="button" class="btn btn-custom mt-5" (click)="goHome()">Return To Home</button>
    </div>
  </div>
</div>

<div class="container" *ngIf="userNotExist">
  <app-error404></app-error404>
</div>

<!-- Send Note -->
<ng-template #content let-modal>
  <div class="modal-body px-5 py-3 rounded-3">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
    </div>
    <div class="text-center">
      <h3 class="mfw-bold">Send a note to {{ social_tag }}</h3>
    </div>
    <form [formGroup]="noteForm">
      <div class="form-group">
        <label for="" class="form-label">Amount (Amount should not be less than
          {{ profile?.minimum_note_amount | number : "1.0-2" }} $GARY)</label>
        <input type="text" class="form-control" formControlName="amount" name="amount" />
      </div>

      <div class="form-group">
        <label for="" class="form-label">Note</label>
        <textarea class="form-control" rows="5" name="message" formControlName="message"
          placeholder="Type your thoughtful note here..." style="border: var(--border) !important"></textarea>
      </div>
    </form>
    <div class="text-center mt-3">
      <button class="btn btn-custom btn-block" (click)="submit()" [disabled]="noteForm.invalid">
        Send Note
      </button>
    </div>
  </div>
</ng-template>

<!-- Login -->
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
  </div>
  <div class="modal-body">
    <h6>Enter your Social Connector details to login</h6>
    <form [formGroup]="joinForm">
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" name="email" class="form-control" formControlName="email"
          placeholder="Enter Social Connector Email" />
      </div>
      <div class="form-group">
        <label for="" class="form-label">Password &nbsp;
          <span><a href="https://app.socialconnector.io/auth/forgot-password">Forgot Password?</a></span>
        </label>
        <input type="password" name="password" class="form-control" formControlName="password"
          placeholder="Enter Social Connector Password" />
      </div>
    </form>

    <p class="pt-3" style="float:right;">
      New here?
      <a href="https://app.socialconnector.io/auth/registration" target="_blank">Create a FREE Account</a>
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="login()" [disabled]="joinForm.invalid">
      Submit
    </button>
  </div>
</ng-template>

<ng-template #hoverContent>
  <div class="row">
    <div class="col-6 text-start">
      <p>Coin Price</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        {{ estimate.amount_gary }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        {{ estimate.amount_gary }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'SOL'">
        {{ estimate.amount_sol }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDT'">
        {{ estimate.amount_usd }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        {{ estimate.amount_usd }} {{ selected_coin }}
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p c>Creator Royalty</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        <span class="text-primary">({{ supportersInfo?.fee_percentage }}%)</span>
        {{ feeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        <span class="text-primary">({{ supportersInfo?.fee_percentage }}%)</span>
        {{ feeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <!-- Calculate for sol -->
      <h6 *ngIf="selected_coin === 'SOL'">
        <span class="text-primary">({{ supportersInfo?.fee_percentage }}%)</span>
        {{ feeSOL | number : "1.0-2" }}
        {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDT'">
        <span class="text-primary">({{ supportersInfo?.fee_percentage }}%)</span>
        {{ feeUSD | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        <span class="text-primary">({{ supportersInfo?.fee_percentage }}%)</span>
        {{ feeUSD | number : "1.0-2" }} {{ selected_coin }}
      </h6>
    </div>
  </div>

  <div class="row">
    <div class="col-6 text-start">
      <p>Platform Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        <span class="text-primary">( {{ supportersInfo.app_fee_percentage }}%)</span>
        {{ appFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        <span class="text-primary">( {{ supportersInfo.app_fee_percentage }}%)</span>
        {{ appFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'SOL'">
        <span class="text-primary">( {{ supportersInfo.app_fee_percentage }}%)</span>
        {{ appFeeSOl | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'USDT'">
        <span class="text-primary">( {{ supportersInfo.app_fee_percentage }}%)</span>
        {{ appFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        <span class="text-primary">( {{ supportersInfo.app_fee_percentage }}%)</span>
        {{ appFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p>Referral Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        <span class="text-primary">({{supportersInfo.referrer_fee_percentage}}%)</span>
        {{ referralFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        <span class="text-primary">({{supportersInfo.referrer_fee_percentage}}%)</span>
        {{ referralFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'SOL'">
        <span class="text-primary">({{supportersInfo.referrer_fee_percentage}}%)</span>
        {{ referralFeeSOl | number : "1.0-2" }} {{ selected_coin }}
      </h6>

      <h6 *ngIf="selected_coin === 'USDT'">
        <span class="text-primary">({{supportersInfo.referrer_fee_percentage}}%)</span>
        {{ referralFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        <span class="text-primary">({{supportersInfo.referrer_fee_percentage}}%)</span>
        {{ referralFeeUsd | number : "1.0-2" }} {{ selected_coin }}
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p class="">Total</p>
    </div>
    <div class="col-6 text-end">
      <h6 *ngIf="selected_coin === '$GARY'">
        {{ total_gary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === '$DEV'">
        {{ total_gary | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'SOL'">
        {{ sol_value | number : "1.0-2" }} {{ selected_coin }}
      </h6>
      <h6 *ngIf="selected_coin === 'USDT'">
        {{ usd_value | number : "1.0-2" }} {{ selected_coin }}}
      </h6>
      <h6 *ngIf="selected_coin === 'USDC'">
        {{ usd_value | number : "1.0-2" }} {{ selected_coin }}}
      </h6>
    </div>
  </div>
</ng-template>
<!-- Buy Stake -->
<ng-template #content3 let-modal>
  <div class="modal-body px-5 py-3 rounded-3 relative">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="clear()"></button>
    </div>
    <div class="text-center">
      <h3 class="fw-bold">Support {{ social_tag }}</h3>
      <p class="text-primary">
        You own {{ coin_balance }} Coins worth
        {{ estimate.amount_gary * coin_balance }} $GARY
      </p>
    </div>
    <div class="alert alert-warning mt-2" *ngIf="!profile?.twitter">This account is not linked to X so please DYOR prior
      to
      supporting to ensure this is the creator you want to support
    </div>
    <div class="row mt-3">
      <div class="col-sm-5">
        <div class="d-flex align-items-center">
          <h3 class="mb-0 font-lato">Price</h3>
          <button class="btn btn-clear btn-icon ms-1 btn-sm" [ngbTooltip]="hoverContent" triggers="manual"
            #t="ngbTooltip" (click)="t.open()">
            <i class="fa-solid fa-circle-info"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-7 text-end">
        <h3 class="fw-bold mb-0">
          <span *ngIf="selected_coin === '$GARY' || selected_coin === '$DEV'">
            {{ total_gary | number : "1.0-2" }} {{ selected_coin }}
          </span>
          <span *ngIf="selected_coin === 'SOL'">
            {{ sol_value | number : "1.0-2" }} {{ selected_coin }}
          </span>
          <span *ngIf="selected_coin === 'USDT'">
            {{ usd_value | number : "1.0-2" }} {{ selected_coin }}
          </span>
          <span *ngIf="selected_coin === 'USDC'">
            {{ usd_value | number : "1.0-2" }} {{ selected_coin }}
          </span>
        </h3>
        <p class="text-sm">
          You have {{ tokenBalance | number : "1.0-2" }} {{ selected_coin }} in
          your wallet
        </p>
      </div>
    </div>

    <div ngbDropdown class=" ">
      <button type="button" class="btn paydropdown mt-3" id="dropdownBasic1" ngbDropdownToggle>
        Pay in {{ selected_coin }}

        <i _ngcontent-apx-c69="" class="fa-solid fa-chevron-down ms-auto"></i>
      </button>
      <div ngbDropdownMenu class="full" aria-labelledby="dropdownBasic1">
        <div *ngFor="let item of tokens">
          <button ngbDropdownItem (click)="getCurrency(item)">
            {{ item?.coin_symbol }}
          </button>
        </div>
      </div>
    </div>



    <button class="btn btn-custom2 mt-4 btn-lg btn-block support-btn mb-4 mt-4" (click)="Support(content8)"
      [disabled]="disableSupport">
      Support
    </button>
  </div>
</ng-template>

<!-- Sell Stake -->
<ng-template #content4 let-modal>
  <div class="modal-body  px-5 py-3 rounded-3 relative">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="clear()"></button>
    </div>
    <div class="text-center mb-3">
      <h3 class="fw-bold">Support {{ social_tag }}</h3>
      <p class="text-primary">
        You own {{ coin_balance }} Coins worth
        {{ estimate2?.amount_gary | number : '1.0-2' }} $GARY
      </p>
    </div>

    <form [formGroup]="sellForm">
      <div class="form-group">
        <label for="" class="form-label">Quantity</label>
        <div class="row mb-3" style="padding-left: 0px !important">
          <div class="col-md-9">
            <input type="text" class="form-control" name="quantity" formControlName="quantity"
              (keyup)="getsellEstimate($event)" style="padding-top: 10px; padding-bottom: 10px" />
          </div>
          <div class="col-md-3">
            <button class="btn max-btn float-left" type="button" id="button-addon2" (click)="max()">
              Max
            </button>
          </div>
        </div>
      </div>

      <div class="pricedisplay my-4">
        <div class="row">
          <div class="col-md-4">
            <div class="d-flex">
              <h3 class="mb-0">Price</h3>
              <button class="btn btn-clear btn-icon ms-1 btn-sm" [ngbTooltip]="hovercontent2" triggers="manual"
                #t="ngbTooltip" (click)="t.open()">
                <i class="fa-solid fa-circle-info"></i>
              </button>
            </div>

          </div>
          <div class="col-md-8 text-end">
            <h3 class="mb-0">{{ sell_total_gary }} $GARY</h3>
          </div>
        </div>
      </div>

    </form>
    <button class="btn btn-custom2 btn-lg btn-block support-btn my-4 " (click)="sell(content9)"
      [disabled]="disableSupport">
      Stop Supporting
    </button>
  </div>
</ng-template>

<ng-template #content8 let-modal>
  <div class="modal-body  px-5 py-3 rounded-3 relative">
    <div class="text-center mb-3">
      <h2 class="fw-bold pt-3" style="font-size: 28px !important;">You are one step closer to supporting {{social_tag}}
      </h2>
      <p class=" pt-2">
        We are processing your transaction as quickly as possible. Click "Check Status" to see the status of all of your
        past transactions.
      </p>
    </div>

    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-custom14 btn-lg btn-block support-btn my-4 " (click)="closeModal()"
          [disabled]="disableSupport">
          Close
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-custom16 btn-lg btn-block support-btn my-4 " (click)="checkStatus()"
          [disabled]="disableSupport">
          Check Status
        </button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #content9 let-modal>
  <div class="modal-body  px-5 py-3 rounded-3 relative">
    <div class="text-center mb-3">
      <h2 class="fw-bold pt-3" style="font-size: 28px !important;">You are one step closer to stop supporting
        {{social_tag}}
      </h2>
      <p class="pt-2">
        We are processing your transaction as quickly as possible. Click "Check Status" to see the status of all of your
        past transactions.
      </p>
    </div>

    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-custom14 btn-lg btn-block support-btn my-4 " (click)="closeModal()"
          [disabled]="disableSupport">
          Close
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-custom16 btn-lg btn-block support-btn my-4 " (click)="checkStatus()"
          [disabled]="disableSupport">
          Check Status
        </button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #hovercontent2>
  <div class="row">
    <div class="col-6 text-start">
      <p>Coin Price</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        {{ estimate.amount_gary }} {{ selected_coin }}
      </h6>

    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p c>Creator Royalty</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        <span class="text-primary">({{ supportersInfo?.fee_percentage }}%)</span>
        - {{ feeSellGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>

  <div class="row">
    <div class="col-6 text-start">
      <p>Platform Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        <span class="text-primary">({{ supportersInfo?.app_fee_percentage }}%)</span>
        - {{ appsellFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p>Referral Fee</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        <span class="text-primary">({{supportersInfo.referrer_fee_percentage}}%)</span>
        - {{ referralsellFeeGary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <p class="">Total</p>
    </div>
    <div class="col-6 text-end">
      <h6>
        {{ sell_total_gary | number : "1.0-2" }} {{ selected_coin }}
      </h6>

    </div>
  </div>
</ng-template>

<!-- view creator detiails -->

<ng-template #content5 let-modal>

  <div class="modal-body rounded-3">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
    </div>
    <div class="text-center mb-3">
      <h3 class="fw-bold">{{ creatordets?.social_tag }}</h3>

    </div>
    <div class="row">
      <div class="col-6">
        <img [src]="creatordets?.profile_img" class="img-fluid rounded-3 creatorImg"
          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
        <!-- <h3 class="basic-title">{{ creatordets?.social_tag }}</h3> -->
      </div>
      <div class="col-6">
        <div class="classprofile">
          <p>Rank</p>
          <h5># {{ creatordets?.current_rank }}</h5>
        </div>
        <div class="classprofile">
          <p>Supporter Level</p>
          <h5>{{ creatordets?.total_amount }} Coins</h5>
        </div>
        <div class="classprofile">
          <p>Supporter Since</p>
          <h5>{{ creatordets?.first_purchase | date : " MMM dd, YYYY" }}</h5>
        </div>
        <div class="classprofile">
          <p>Last Purchase</p>
          <h5>{{ creatordets?.last_purchase | date : "MMM dd, YYYY" }}</h5>
        </div>
        <div>
          <a href="" class="text-primary" [href]="'/' + creatordets?.social_tag">View Creator</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Collectible Modal -->

<ng-template #content6 let-modal>

  <div class="modal-body rounded-3">
    <div class="closebtn">
      <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
    </div>
    <div class="text-center mb-3">
      <h3 class="fw-bold">{{ collectibleDet?.name }}</h3>

    </div>
    <div class="row">
      <div class="col-md-4">
        <img [src]="getSrc(collectibleDet.address)" class="img-fluid rounded-3"
          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
        <!-- <h3 class="basic-title">{{ collectibleDet?.name }}</h3> -->

        <div class="d-flex align-items-center mt-3">
          <a [href]="collectibleDet?.tensor" target="_blank" class="logos me-3" placement="top"
            ngbTooltip="Buy on Tensor">
            <img src="assets/imgs/tensor.png" alt="" />
          </a>
          <a [href]="collectibleDet?.magic_eden" target="_blank" class="logos me-3" placement="top"
            ngbTooltip="Buy on Magic Eden">
            <img src="assets/imgs/magiceden.png" alt="" />
          </a>
          <a [href]="collectibleDet?.solscan" target="_blank" class="logos" placement="top"
            ngbTooltip="View on Solscan">
            <img src="assets/imgs/solscan.png" alt="" />
          </a>
        </div>
      </div>
      <div class="col-md-8">
        <div class="classprofile2">
          <p>Mint Address</p>
          <div class="d-flex align-items-center">
            <h5 class="mb-0">{{ collectibleDet?.address }}</h5>
            <button (click)="copyText(collectibleDet?.address)" class="btn btn-clear p-0 ms-3">
              <i class="fa-regular fa-copy text-primary" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="classprofile2" *ngIf="collectibleDet?.collection">
          <p>Collection</p>
          <div class="d-flex align-items-center">
            <h5 class="mb-0">{{ collectibleDet?.collection }}</h5>
            <button (click)="copyText(collectibleDet?.collection)" class="btn btn-clear p-0 ms-3">
              <i class="fa-regular fa-copy text-primary" aria-hidden="true"></i>
            </button>
          </div>
          <!-- <h5> {{ (collectibleDet.collection) }} </h5> -->
        </div>
        <!-- <div class="classprofile2">
          <p>Token Standard</p>
          <h5>pNFT</h5>
        </div> -->
        <!-- <div class="classprofile2">
          <p>Royalties</p>
          <h5>10%</h5>
        </div> -->

        <!-- <div class="classprofile2">
          <p>Traits</p>
          <div class="row">
            <div class="col-3">
              <div class="traits">
                <p>Background</p>
                <p>Brown</p>
              </div>
            </div>
            <div class="col-3">
              <div class="traits">
                <p>Eyes</p>
                <p>Stoned</p>
              </div>
            </div>
            <div class="col-3">
              <div class="traits">
                <p>Hat</p>
                <p>Head warmer</p>
              </div>
            </div>
            <div class="col-3">
              <div class="traits">
                <p>Fur</p>
                <p>Panther</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>

<!-- Create post -->
<ng-template #content7 let-modal>
  <div class="modal-body">
    <h3 class="text-center mb-3" style=" padding-top: 10px;">
      Create Post
    </h3>
    <div class="row mb-4">
      <div class="col-md-2 col-3">
        <img [src]="user?.sc_profile_picture || 'assets/imgs/placeholder.png'" alt="" class="img-fluid prof-img">
      </div>
      <div class="col-md-10 col-9">
        <h4 class="mb-2">
          {{ user?.name }}
        </h4>
        <!-- <div class="mb-3">
          <div class="row">
            <div class="col-6 col-md-5">
              <select name="" class="form-select" id="" [(ngModel)]="post.type" (ngModelChange)="setPrice($event)">
                <option value="public">Public</option>
                <option value="supporters">Supporters</option>
                <option value="paid">Paid</option>
              </select>
            </div>
            <div class="col-6 col-md-6" *ngIf="showPrice">
              <div class="input-group mb-3">
                <input type="text" class="form-control" [(ngModel)]="post.amount">
                <span class="input-group-text">$GARY</span>
              </div>
            </div>
          </div>

        </div> -->
        <div class="post-details">
          <textarea name="" id="" class="form-control" rows="1" placeholder="What's going on?"
            (click)="showEmojiPicker = false" (blur)="showEmojiPicker = false" [(ngModel)]="post.body"></textarea>
        </div>
        <div class="post-img">

          <input class="test" #imageInput style="display: none" type="file" accept="image/*" id="file"
            (change)="processFiles($event)" multiple />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="postImages?.length > 0">
          <div class="row">
            <div class="col-md-6 col-6 mb-3" *ngFor="let item of postImages">
              <img [src]="item.src" class="img-upload" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="padding-bottom: 10px;">
      <div class="col-md-5 col-5">
        <div class="row align-items-center ">
          <div class="col-md-3 col-3">
            <span class="like-icon">
              <i class="fa-regular fa-image fa-xl image-icon" (click)="showEmojiPicker = false; openFileExplorer()"></i>
            </span>
          </div>
          <div class="col-md-3 col-3">
            <span class="like-icon"><i class="fa-regular fa-face-smile fa-xl face-icon"
                (click)="toggleEmojiPicker()"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-7 col-7">
        <div class="d-flex justify-content-end">
          <div class="me-2">
            <div ngbDropdown>
              <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                <span *ngIf="post.type != 'Paid' || post.amount == null">
                  {{ post.type }}
                </span>
                <span *ngIf="post.type == 'Paid' && post.amount != null">
                  {{ post.amount }} $GARY
                </span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div class="d-item" (click)="setPostType('Public')">Public</div>
                <div class="d-item" (click)="setPostType('Paid')">Paid</div>
                <div class="d-item" *ngIf="post.type == 'Paid'">
                  <div class="input-group">
                    <input type="number" class="form-control" [(ngModel)]="post.amount">
                  </div>
                </div>
                <div class="d-item" (click)="setPostType('Supporters')">Supporters</div>
              </div>
            </div>
          </div>
          <div class="">
            <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
              [disabled]="post.body == ''  ||  diasable">
              Post</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="emojis">
          <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
            title="Pick your emoji…"></emoji-mart>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="container ">
    <div class="modal-body pt-4 pb-4 d-block d-md-none">
      <div class="row mb-4">
        <div class="col-md-2 col-3">
          <img [src]="user?.sc_profile_picture" alt="" class="img-fluid prof-img"
            style="height: 65px !important; width: 65px !important;">
        </div>
        <div class="col-md-10 col-9">

          <div class="mb-3">
            <select name="" id="" [(ngModel)]="post.type">
              <option value="public">Public</option>
              <option value="supporters">Supporters</option>
            </select>
          </div>
          <div class="post-details">
            <textarea name="" id="post-field" cols="35" rows="5" placeholder="What's going on?" [(ngModel)]="post.body"
              (input)="countCharacters()" (click)="showEmojiPicker = false" (blur)="showEmojiPicker = false"></textarea>
          </div>
          <div class="post-img" style="text-align:center;">
            <img *ngIf="imgFile" [src]="imgFile" style="
                        width: 50%;
                        height: 50%;
                        background-position: center;
                        background-size: cover" alt="" />
            <input class="test" #imageInput style="display: none;" type="file" id="file"
              (change)="processFile(imageInput)" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <div class="row">
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/image.svg" alt="" (click)="showEmojiPicker = false; openFileExplorer()">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/videoo.svg" alt="" (click)="showEmojiPicker = false; openFileExplorer()">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/gif.svg" alt="" (click)="showEmojiPicker = false;">
              </span>
            </div>
            <div class="col-md-3 col-3">
              <span class="like-icon">
                <img src="../../assets/imgs/smileee.svg" alt="" (click)="toggleEmojiPicker()">
              </span>
            </div>
          </div>

        </div>
        <div class="col-md-6 col-6">
          <button class="btn btn-primary create-post2 float-right" (click)="showEmojiPicker = false; createPost()"
            [disabled]="post.body == ''">
            Post</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="emojis">
            <emoji-mart class="emoji-mart" set="twitter" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
              title="Pick your emoji…"></emoji-mart>
          </div>
        </div>
      </div>
    </div>

  </div> -->
</ng-template>

<ng-template #contentp let-modal>
  <div class="container">
    <!-- <div class="modal-header">

      <h4 class="text-center mb-3" style="padding-top: 10px; font-size: 16px;">
        Post a reply
      </h4>

      <i class="fa-light fa-xmark" (click)="closeModal()"></i>
    </div> -->
    <div class="modal-body">
      <div class="postDetails">
        <div class="user-details palo" style="margin-bottom: 15px;">
          <img (click)="service.goToProfile(service.postDetails?.owner_name)" [src]="service.postDetails?.owner"
            onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
            style="width: 50px; height:50px;" />
          <h6 class="nameea" (click)="service.goToProfile(service.postDetails?.owner_name)">
            {{ service.postDetails?.owner_name }}
          </h6>
        </div>
        <div class="line">

          <div class="user-details palo">
            <img (click)="service.goToProfile(service.comment?.user?.social_tag)"
              [src]="service.comment?.user?.sc_profile_picture"
              onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
              style="visibility: hidden;" />
            <div class=" mb-1 act-cont">
              <p [innerHTML]="service.sanitizeHTML(service.postDetails?.text?.body)"></p>
            </div>
            <!-- <img [src]="postDetails?.text?.post_images[0].path" *ngIf="postDetails?.text?.post_images.length > 0" alt=""
              class="img-fluid mb-1 d-none d-md-block" style="border-radius: 10px; max-width:400px;">
            <img [src]="postDetails?.text?.post_images[0].path" *ngIf="postDetails?.text?.post_images.length > 0" alt=""
              class="img-fluid mb-1 d-block d-md-none" style="border-radius: 10px;"> -->
          </div>
        </div>
      </div>
      <div class="user-details palo" style="margin-bottom: 15px;">
        <img (click)="service.goToProfile(user?.social_tag)" [src]="user?.sc_profile_picture"
          onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" alt="" class="img-fluid prof-img"
          style="width: 50px; height:50px;" />
        <textarea name="" class="autoresize" id="" (click)="service.showEmojiPicker = false"
          [(ngModel)]="service.comment.comment" [placeholder]="'Reply to ' + service.postDetails?.owner_name"
          style="overflow: hidden; overflow-wrap: break-word; height: 49px;"></textarea>

      </div>
      <div class="post-img">
        <img *ngIf="imgFile" [src]="imgFile" style="
                          width: 50%;
                          height: 50% !important;
                         " alt="" />
        <input class="test" #imageInput style="display: none;" type="file" accept="image/*" id="file"
          (change)="service.processFile(imageInput)" />
      </div>
      <div class="emoji">
        <emoji-mart class="emoji-mart" [perLine]="12" [emojiTooltip]="false" [showPreview]="false" set="twitter"
          [hideRecent]="true" *ngIf="service.showEmojiPicker" (emojiSelect)="service.addEmoji($event)"
          title="Pick your emoji…"></emoji-mart>
      </div>
      <div class="timeline-box">
        <button class="ctrl-btn" type="button">
          <i class="fa-regular fa-face-smile fa-xl face-icon" (click)="service.toggleEmojiPicker()"></i>
        </button>
        <button class="ctrl-btn" type="button" (click)="service.showEmojiPicker = false; service.openFileExplorer()">
          <i class="fa-regular fa-image fa-xl face-icon"></i>
        </button>
        <!-- <button class="ctrl-btn" type="button">
          <i class="fa-regular fa-gif fa-xl face-icon"></i>
        </button> -->
      </div>
      <!-- <hr> -->
      <div class="post-publication">
        <button class="btn btn-publish" [disabled]="service.commenting"
          (click)="service.showEmojiPicker = false;service.commentOnPost(service.postDetails?.text?.id)">Post</button>
      </div>
    </div>

  </div>


</ng-template>