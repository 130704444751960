<div class="container-fluid">
  <div class="row mt-7">
    <div class="col-12">
      <div class="text-center">
        <h3>Giraffe Tower</h3>
        <div class="img">
          <img src="assets/imgs/giraffeTower.png" alt="Giraffe Tower" srcset="">
        </div>
      </div>
    </div>
  </div>

  <div class="card ">
    <div class="card-body p-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="text-primary">Yo. creators!</h4>
          <h4 class="lato">Giraffe Tower’s here to make the
            money printer go brrrr!</h4>
          <p class="">
            We're on a mission to put a giraffe in every creator's wallet, skyrocketing your brand and making money like
            never
            before. We know you have got that fire, that unbreakable spirit, and you are ready to crush the game
          </p>
          <p class="">
            So why not join a community where we hustle harder, dream bigger, and push the limits like there is no
            freaking tomorrow
          </p>
        </div>
        <div class="col-md-6 text-center">
          <div class="something">
            <img _ngcontent-lqk-c109="" src="assets/imgs/giraffe.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5 align-items-center">
    <div class="col-md-6">
      <div>
        <img _ngcontent-lqk-c110="" src="assets/imgs/ball.png" alt="" srcset="" class="img-fluid">
      </div>
    </div>
    <div class="col-md-6">
      <h4 class="text-primary">
        Its time to EAT!
      </h4>
      <h4 class="lato">Together, we'll dominate the digital world,
        breaking barriers and rewriting the rules.</h4>
      <p>You wanna be a high-roller, A straight - up baller, Giraffe Tower's got your back, and we'll stop at nothing to
        make
        sure you're makin' BANK!
        So, get ready, stand tall, and join the Giraffe Tower Now! We’re about
        to blow up the internet and ride the wave of success straight to the top.</p>
      <a class="btn btn-custom rounded" href="https://magiceden.io/marketplace/giraffetower" target="_blank">Join Now</a>
    </div>
  </div>

  <div class="card mt-5 ">
    <div class="withbg">
      <div class="card-body p-5">
        <div class="row  align-items-center">
          <div class="col-md-6">
            <h4 class="lato">Lets show them what we’re made of
              and make some serious coin while we are at it!</h4>
            <p>No more playing it safe, it’s time to GO BIG or GO HOME!
            </p>
            <p>Lets GOOOOO!</p>
          </div>
          <div class="col-md-6 text-center">
            <img _ngcontent-lqk-c109="" src="assets/imgs/bags.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
