import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profille-suspended',
  templateUrl: './profille-suspended.component.html',
  styleUrls: ['./profille-suspended.component.scss']
})
export class ProfilleSuspendedComponent implements OnInit {
  @Input() profile: any; //
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goHome() {
    this.router.navigateByUrl('/home')
  }

}
