import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-token-tnx-item',
  templateUrl: './token-tnx-item.component.html',
  styleUrls: ['./token-tnx-item.component.scss']
})
export class TokenTnxItemComponent implements OnInit {
  @Input() item: any; // Define an Input property to receive data from the parent component
  @Input() app: any;
  @Input() user: any;
  constructor() { }

  ngOnInit(): void {
  }
  truncateString(str: string): string {
    if (str.length <= 10) {
      return str;
    } else {
      const truncatedString = `${str.substr(0, 5)}...${str.substr(-5)}`;
      return truncatedString;
    }
  }

}
