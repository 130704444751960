import { Component, OnInit } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppServiceService } from './../_services/app-service.service';

@Component({
  selector: 'app-tower-members',
  templateUrl: './tower-members.component.html',
  styleUrls: ['./tower-members.component.scss']
})
export class TowerMembersComponent implements OnInit {
  towerMembers: any
  ref: any
  p: any;
  page = 1;
  social_tag: any;
  public loading: boolean = false

  constructor(private sanitizer: DomSanitizer, private app: AppServiceService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.ref = params['ref'];
    });
  }

  ngOnInit(): void {
    this.getTowerMembers()

  }

  seeProfile(name: any) {
    return `/${name}`
  }

  buildJoinTowerString(trait_type, trait_value) {
    return `https://magiceden.io/marketplace/giraffetower?attributes={"${trait_type}":["{\\"value\\":\\"${trait_value}\\"}"]}`
  }

  getTowerMembers() {
    this.loading = true;
    this.app.getTowerMembers(this.ref, this.page).subscribe({
      next: (res: any) => {
        const allData = res;
        console.log('All data', allData)
        this.towerMembers = allData.data;
        console.log(this.towerMembers)
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err)
        this.loading = false;
      }
    })
  }

  getImagePath(url) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
