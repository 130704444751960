<div class="container mt-4" *ngIf="event">
  <div class="row">
    <div class="col-12">
      <h1>{{ event?.name }}</h1>
      <a [href]="'/' + event?.creator.social_tag" class="noHover">
        <div class="d-flex align-items-center mt-4">

          <div class="me-3">
            <img [src]="event?.creator?.sc_profile_picture" class="rounded" width="60" height="60" alt=""
              onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
          </div>
          <div class="d-block">
            <p class="mb-1 text-sm hostedBy">Hosted By:</p>
            <h6 class="creatorName">{{ event?.creator?.social_tag |titlecase }}</h6>
          </div>
        </div>
      </a>

    </div>
  </div>

  <div class="row mt-5">
    <div class="col-md-8 mb-3">
      <img [src]="event?.image" class="eventimg rounded-1" alt=""
        onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div *ngIf="isAttendee">
            <div class="row mb-2" *ngIf="event?.event_type === 'physical'">
              <div class="col-1">
                <i class="fa-solid fa-location-dot text-primary"></i>
              </div>
              <div class="col-11">
                <p class="text-sm mb-1">
                  Physical Event
                  <span class="badge bg-primary">{{
                    event?.ticket | titlecase
                    }}</span>
                </p>
                <p class="mb-0">{{ event.location }}</p>
              </div>
            </div>

            <div class="row mb-2" *ngIf="event?.event_type !== 'physical'">
              <div class="col-1">
                <img src="../../../assets/imgs/video.svg" alt="SVG Image" />
              </div>
              <div class="col-11">
                <p class="mb-1 eventType pl-2">
                  Online Event
                  <span class="badge ticketBadge  ml-1">{{
                    event?.ticket | titlecase
                    }}</span>
                </p>
                <p class="mb-0 linkVisibility pl-2">
                  <a [href]="event.location" target="_blank">{{
                    event.location
                    }}</a>
                </p>
              </div>
            </div>
          </div>

          <div *ngIf="!isAttendee">
            <div class="row mb-2" *ngIf="event?.event_type === 'physical'">
              <div class="col-1">
                <i class="fa-solid fa-location-dot text-primary"></i>
              </div>
              <div class="col-11">
                <p class="eventType mb-1">
                  Physical Event
                  <span class="badge ticketBadge">
                    {{ event?.ticket | titlecase }}
                  </span>
                </p>
                <p class="mb-0 linkVisibility">
                  <span>Location visible for attendees</span>
                </p>
                <!-- <span>Location visible for attendees</span> -->
              </div>
            </div>
            <div class="row mb-2" *ngIf="event?.event_type !== 'physical'">
              <div class="col-1">
                <img src="../../../assets/imgs/video.svg" alt="SVG Image" />
              </div>
              <div class="col-11">
                <p class="mb-1 eventType pl-2">
                  Online Event
                  <span class="badge ticketBadge ml-1">{{
                    event?.ticket | titlecase
                    }}</span>
                </p>
                <p class="mb-0 linkVisibility pl-2">
                  Link visible when you RSVP
                </p>
              </div>
            </div>
          </div>

          <div class="mb-2 pt-2">
            <div class="row">
              <div class="col-1">
                <img src="../../../assets/imgs/clock.svg" alt="SVG Image" />
              </div>
              <div class="col-11">
                <p class="mb-1 pl-2 abba">
                  {{ fromdate | date : "EEE, MMM d, y" }} at
                  {{ fromdate | date : "h:mm:ss a" }} to
                </p>
                <p class="mb-1 pl-2 abba">
                  {{ todate | date : "EEE, MMMM d, y" }} at
                  {{ todate | date : "h:mm:ss a" }} {{ zone?.abbreviation }}
                </p>
              </div>
            </div>
          </div>

          <!-- <div class="mb-2" *ngIf="event.ticket ===  'paid'">
            <div class="row">
              <div class="col-1">
                <i class="fa-solid fa-money-bill text-primary"></i>
              </div>
              <div class="col-11">
                {{ event.coin_symbol }} {{ event.price }}
              </div>
            </div>
          </div> -->
          <div class="row pt-2">
            <div class="col-1">
              <img src="../../../assets/imgs/users.svg" alt="SVG Image" />
            </div>
            <div class="col-11">
              <p class="pl-2">{{ event?.interested_candidates }} Interested</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-3" *ngIf="event.limit > 0 && !isAttendee">
        <div class="col-md-4">
          <p class="mb-0">{{ event.limit }} slots left</p>
          <!-- limit -->
        </div>
        <div class="col-md-8">
          <button [disabled]="event?.event_status === 'Ended'" class="btn btn-block btn-primary btnprim py-3 rsvpButton"
            (click)="rsvpEvent(content, event, content2)">
            RSVP
          </button>
        </div>
      </div>

      <div *ngIf="event.limit <= 0">
        <button [disabled]="event?.event_status === 'Ended'"
          class="btn btn-block btn-primary btnprim rsvpButton mt-3 py-3" *ngIf="!isAttendee"
          (click)="rsvpEvent(content, event, content2)">
          RSVP
        </button>
      </div>

      <div class="row align-items-center mt-3">
        <div class="col-sm-6 mb-3" *ngIf="isAttendee">
          <button class="btn btn-prim-border btn-block" (click)="addToCalendar(event)">
            Add to Calendar
          </button>
        </div>
        <div class="col-sm-6 mb-3">
          <h5 class="mb-0">
            <span class="shareText">Share:</span>
            <span placement="top" ngbTooltip="Share on Twitter" (click)="openTwitter(event)" style="cursor: pointer"
              class="pl-2">


              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"
                class="r-1nao33i" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">

                <path fill="currentColor"
                  d="M18.244,2.25h3.308l-7.227,8.26l8.502,11.24h-6.657l-5.214-6.817L4.99,21.75h-3.31l7.73-8.835L1.254,2.25h6.826l4.713,6.231ZM17.083,19.77h1.833L7.084,4.126h-1.967Z"
                  transform="translate(11.9595 12)" />
              </svg>

            </span>&nbsp;
            <span placement="top" ngbTooltip="Copy Link" (click)="copyToClipboard(event)" style="cursor: pointer"
              class="pl-2"><img src="../../../assets/imgs/copy.svg" alt="SVG Image" /></span>
          </h5>
        </div>
      </div>
    </div>
  </div>

  <hr class="my-5" />

  <div class="row">
    <div class="col-12">
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
          <a ngbNavLink class="about-text">About</a>
          <ng-template ngbNavContent>
            <div class="event-description" [innerHTML]="event?.description"></div>
            <!-- <div class="mt-2 pt-2">
              <h5 class="topic mb-4">Topics</h5>
              <span
                class="badge topic-badge me-3"
                *ngFor="let item of event?.tags"
              >
                {{ item | titlecase }}
              </span>
            </div> -->
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink class="rsvp-text">RSVP ({{ attendees.length }})</a>
          <ng-template ngbNavContent>
            <div class="row mt-3">
              <div class="col-12"></div>

              <div class="col-4 col-md-2 col-lg-2 text-center mb-3" *ngFor="let item of attendees">
                <a [href]="'/' + item?.user?.social_tag">
                  <div class="mb-2">
                    <img [src]="item?.user?.sc_profile_picture" class="rounded" width="80" height="80" alt=""
                      onerror="this.onerror=null;this.src='assets/imgs/placeholder.png';" />
                  </div>
                  <h6 class="mb-1">{{ item?.user.name }}</h6>
                </a>

              </div>
            </div>
          </ng-template>
        </ng-container>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-5 ms-2"></div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <h6>Enter your Social Connector details to login</h6>
    <form [formGroup]="joinForm">
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" name="email" class="form-control" formControlName="email"
          placeholder="Enter Social Connector Email" />
      </div>
      <div class="form-group">
        <label for="" class="form-label">Password &nbsp; <span><a
              href="https://app.socialconnector.io/auth/forgot-password">Forgot Password?</a></span></label>
        <input type="password" name="password" class="form-control" formControlName="password"
          placeholder="Enter Social Connector Password" />
      </div>
    </form>
    <!-- <p>
      <a href="https://app.socialconnector.io/auth/forgot-password">Forgot Password?</a>
    </p> -->

    <p class="pt-3" style="float:right;">
      New here?
      <a href="https://app.socialconnector.io/auth/registration" target="_blank">Create a FREE Account</a>
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="submit()" [disabled]="joinForm.invalid">
      Submit
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      Select your preferred payment method:
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <!-- <h6>Enter your Social Connector details to login</h6> -->

    <form [formGroup]="paidForm">
      <div class="form-check">
        <label class="form-check-label" for="flexRadioDefault1">
          <input class="form-check-input" type="radio" name="payment_method" formControlName="payment_method"
            id="flexRadioDefault1" value="social_pay" />
          <div class="d-flex align-items-center me-5 mt-2">
            <span class="d-block">
              <div class="d-flex align-items-center">
                <img src="assets/imgs/logo-socialpay.png" height="50px" alt="" class="me-2" />
                <div class="d-block">
                  <h6 class="mb-0">SOCIAL</h6>
                  <h6 class="mb-0">PAY</h6>
                </div>
              </div>
            </span>
            <span class="ms-auto">
              {{ event.price | number:'1.2-4' }} {{ event.coin_symbol }}
            </span>
          </div>
        </label>

        <label class="form-check-label" for="flexRadioDefault2" *ngIf="event.stripe_amount">
          <input class="form-check-input" type="radio" name="payment_method" formControlName="payment_method"
            id="flexRadioDefault2" value="stripe" />
          <div class="d-flex align-items-center me-5">
            <span class="">
              <img
                src="https://pub-3a9bbf364b7e4fafb2ccf4e85d9eb22a.r2.dev/socialpay-merchant-images/6472b189dbbb81685238153.png"
                width="100px" alt="" />
            </span>
            <span class="ms-auto"> ${{ event.stripe_amount | number:'1.2-4' }} </span>
          </div>
        </label>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <!-- <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button> -->
    <button class="btn btn-primary btn-block py-3" [disabled]="paidForm.invalid" (click)="payNow()">
      Make Payment
    </button>
  </div>
</ng-template>