import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppServiceService } from 'src/app/_services/app-service.service';
import { PusherService } from 'src/app/_services/pusher.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-replies',
  templateUrl: './replies.component.html',
  styleUrls: ['./replies.component.scss']
})
export class RepliesComponent implements OnInit {
  loading = true;
  errorMessage: string = null;
  user: any
  comment_id: string;
  constructor(public app: AppServiceService, private modal: NgbModal, private sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private service: AppServiceService, private pusherService: PusherService) { }

  ngOnInit(): void {
    this.app.post = {};
    this.app.imgFile = "";
    this.app.selectedFile = null;
    this.comment_id = this.route.snapshot.paramMap.get('comment_id');
    this.pusherService.channel.bind('media-updates', data => {
      console.log(data)
      if (data.type == 'comment') {
        // console.log(data.data.post_info[0].id);
        if (data.data.comment_info?.id == this.comment_id) {

          let newData = data.data.comment_info
          let myObject = this.app.post;
          let updatedObject = this.app.updateObject(myObject, newData, this.user.id, data.creatorId)
          this.app.post = updatedObject;
        }
      }

      if (data.type == 'comment') {
        let id = data?.data?.comment_info?.parent_id || data.data.comment_info?.id
        if (id == this.comment_id) {

          let newData = data.data.comment_info
          this.app.updateObjectByIdComment(this.app.comments, newData.id, newData, this.user.id, data.creatorId);
        }
      }
      if (data.type == 'new-comment' && data.data.comment_info?.parent_id == this.comment_id) {

        let newData = data.data.comment_info
        this.app.comments.unshift(newData);

      }
      // if (data.type == 'post') {
      //   if (data.data.post_info.id == this.route.snapshot.paramMap.get('comment_id')) {

      //     let newData = data.data.post_info
      //     let myObject = this.feeds;
      //     const updatedObject = { ...myObject, ...newData };
      //     this.feeds = updatedObject;
      //   }
      // }
      // if (data.type == 'comment') {
      //   if (data.data.post_info.id == this.route.snapshot.paramMap.get('comment_id')) {
      //     let newData = data.data.comment_info
      //     this.app.updateObjectByIdComment(this.comments, newData.id, newData);
      //   }
      // }
    });
    this.getUser();
    this.getPostComments(this.comment_id);
  }
  async getUser() {
    const a = await this.service.getUser()
    this.user = a


  }




  getPostComments(id) {
    this.app.getCommentsComments(id).subscribe({
      next: (res: any) => {
        this.app.post = res.data;
        this.app.comments = res?.data?.comments?.data
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        // this.showpage = true
        this.loading = false;
      }
    })
  }

  // sanitizeHTML(text: string): any {
  //   if (text != null) {
  //     return this.sanitizer.bypassSecurityTrustHtml(this.app.mentionify(this.app.linkify(text.replace(/(?:\r\n|\r|\n)/g, '<br>'))));
  //   }
  // }

  sanitizeHTML(text: string): any {
    if (text != null) {
      // Process links and mentions
      const processedText = this.app.mentionify(this.app.linkify(text));

      // Truncate the text and add "Show more" link if it exceeds 200 characters
      let truncatedText = processedText

      // Check if the truncated text contains an invalid <a> tag
      if (this.app.containsInvalidATag(truncatedText)) {
        // Remove the improperly closed <a> tags from the truncated text
        truncatedText = truncatedText.replace(/<a\b(?![^>]*\bhref=['"]https?:\/\/)([^>]*?)<\/a>(?<!<a)/gi, (match, capturedContent) => {
          // Check if captured content is a valid URL

          return capturedContent
        });
      }
      // if (this.countInnerHTML(processedText) > 200) {
      //   truncatedText += '... <br/><br/> <p class="custom">Show more</p>';
      // }



      // Split the text into lines
      const lines = truncatedText.split(/<br>|[\r\n]+/g);

      // If there are more than 5 lines, add ellipsis and "Show more" link
      // if (lines.length > 5) {
      //   // Update truncatedText to contain the first 5 lines
      //   truncatedText = lines.slice(0, 5).join('<br><br>');
      //   truncatedText += '... <br/><br/> <p class="custom">Show more</p>';
      // }

      // const lines = a.split(/<br>|[\r\n]+/g);
      // b = lines.slice(0, 5).join('<br>');

      // if (lines.length > 5) {
      //   b += '... <br/><br/> <p class="custom">Show more</p>';
      // }

      return this.sanitizer.bypassSecurityTrustHtml((truncatedText.replace(/(?:\r\n|\r|\n)/g, '<br>')));
    }
  }
  goToProfile(social_tag) {
    this.router.navigateByUrl(`/${social_tag}`);

  }

}
