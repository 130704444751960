import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppServiceService } from '../_services/app-service.service';
import { Router } from '@angular/router';
import { NgbAccordionConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { InjectionToken } from '@angular/core';

export const INFINITE_LIST = new InjectionToken<InfiniteList>('INFINITE_LIST');

export function provideInfiniteList(initialData: number[]) {
  return new InfiniteList(initialData);
}

export const InfiniteListProvider = {
  provide: INFINITE_LIST,
  useFactory: provideInfiniteList,
  deps: []
};

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit, OnDestroy {
  rewards: any;
  dater: boolean = false;
  loading: boolean = false;
  private countdownInterval: any;
  end_dater: boolean;

  constructor(
    config: NgbAccordionConfig,
    private app: AppServiceService,
    private router: Router,
    private orderPipe: OrderPipe,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getAllRewards();

  }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  claimReward(data) {
    this.loading = true;
    let payload = {
      'merkle_tree': data.distributor,
      'token_address': data.mint,
      'amount': data.amount,
      'index': data.index,
      'proof': data.proof
    };
    this.app.claimReward(payload).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.getAllRewards();
      },
      error: () => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  getAllRewards() {
    this.loading = true;
    this.app.getallRewards().subscribe({
      next: (res: any) => {
        this.rewards = res.data;
        this.initializeCountdown();
        const now = new Date().getTime();
        if (new Date(this.rewards[0].config.start_date).getTime() > now) {
          this.dater = true;
        } else {
          this.dater = false;
        }
        if (new Date(this.rewards[0].config.end_date).getTime() < now) {
          this.end_dater = true;
        } else {
          this.end_dater = false;
        }
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  initializeCountdown() {
    const reward = this.rewards[0];
    if (!reward.amount && !this.end_dater) {

      this.countdownInterval = setInterval(() => {
        const now = new Date().getTime();


        let countDownDate;

        if (new Date(reward.config.start_date).getTime() > now) {
          countDownDate = new Date(reward.config.start_date).getTime();
        } else {
          countDownDate = new Date(reward.config.end_date).getTime();
        }

        const distance = countDownDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        document.getElementById("days").innerHTML = days.toString();
        document.getElementById("hours").innerHTML = hours.toString();
        document.getElementById("minutes").innerHTML = minutes.toString();
        document.getElementById("seconds").innerHTML = seconds.toString();

        if (distance < 0) {
          clearInterval(this.countdownInterval);
          document.getElementById("countdown").innerHTML = "EXPIRED";
        }
      }, 1000);
    }
  }
}

class InfiniteList {
  private data: number[] = [];
  private index = 0;

  constructor(private initialData: number[]) {
    this.data = initialData;
  }

  public next() {
    if (this.index >= this.data.length) {
      this.index = 0;
    }
    return this.data[this.index++];
  }
}
