<app-post-item [item]="item" [user]="user" [app]="app"></app-post-item>
<app-sale-item [item]="item" [user]="user" [app]="app"></app-sale-item>
<app-reward-item [item]="item" [user]="user" [app]="app"></app-reward-item>
<app-general-item [item]="item" [user]="user" [app]="app"></app-general-item>
<app-offers-item [item]="item" [user]="user" [app]="app"></app-offers-item>
<app-events-item [item]="item" [user]="user" [app]="app"></app-events-item>
<app-airdrop-item [item]="item" [user]="user" [app]="app"></app-airdrop-item>
<app-pow-item [item]="item" [user]="user" [app]="app"></app-pow-item>
<app-subscription-item [item]="item" [user]="user" [app]="app"></app-subscription-item>
<app-referral-item [item]="item" [user]="user" [app]="app"></app-referral-item>
<app-giraffe-buy-item [item]="item" [user]="user" [app]="app"></app-giraffe-buy-item>
<app-token-tnx-item [item]="item" [user]="user" [app]="app"></app-token-tnx-item>