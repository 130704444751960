import { Component, NgModule, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServiceService } from '../_services/app-service.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-creator-dashboard',
  templateUrl: './creator-dashboard.component.html',
  styleUrls: ['./creator-dashboard.component.scss'],

})

export class CreatorDashboardComponent implements OnInit {

  coinDetails: any;
  showpage: boolean;
  notfound: boolean = false;
  addForm: any = {
    name: null,
    email: null,
  };
  @Input() f: any;
  constructor(private route: ActivatedRoute, private router: Router, private app: AppServiceService) {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  getCurrentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
  submit(f: any) {
    this.app.createCreatorReferredAccount(this.route.snapshot.paramMap.get('coin_symbol'), f).subscribe({
      next: (res: any) => {
        if (res.success === true) {
          this.addForm.name = "";
          this.addForm.email = "";
          Swal.fire('Success', res.message, 'success');
          // this.alert.success(res.message);
        }
      },
      error: (err: any) => {
        //console.log(err);
        Swal.fire('Failed!', err.error.message, 'error');
        // this.alert.danger(err.error.message);
        // this.modalService.dismissAll();
      },
    });
  }
  getCoinDetails(coin) {
    this.app.getSingleCreatorLandingDetails(coin).subscribe({
      next: (res: any) => {
        // console.log('token re naw', res)
        this.coinDetails = res.data
        console.log(res.data);
        if (res.data[0].status == 'inactive') {
          this.notfound = true;
        }
      },
      error: (err: any) => {
        this.notfound = true;
      },
      complete: () => {
        this.showpage = true
      }
    })
  }
  ngOnInit(): void {
    this.getCoinDetails(this.route.snapshot.paramMap.get('coin_symbol'));
  }

}
