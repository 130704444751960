import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-events-item',
  templateUrl: './events-item.component.html',
  styleUrls: ['./events-item.component.scss']
})
export class EventsItemComponent implements OnInit {
  @Input() item: any; // Define an Input property to receive data from the parent component
  @Input() app: any;
  @Input() user: any;
  constructor() { }

  ngOnInit(): void {
  }

}
