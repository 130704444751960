<div class="container"></div>
<div class="overlay" style="height: 400px;" *ngIf="loading"></div>
<div class="container" *ngIf="!loading">

  <div class="row mt-5">
    <div class="col-12 ">
      <div class="d-flex align-items-center mb-2">
        <h2 class="mb-0">{{ towerMembers?.name }}</h2>
        <!-- <a [href]="buildJoinTowerString(towerMembers?.trait_type, towerMembers?.trait_value)" target="_blank" -->
        <a [href]="towerMembers.link" target="_blank" rel="noopener noreferrer"
          class="btn btn-custom rounded-pill ms-3">
          Join Tower
        </a>
      </div>
      <p class="description mb-3">
        {{ towerMembers?.description }}

      </p>
      <!-- <p class="mb-0">Floor Price</p>
      <span *ngIf="towerMembers.floor_price > 0; else noListings">${{ towerMembers.floor_price | number: '0.0-2'
        }}</span>
      <ng-template #noListings>No Listings</ng-template> -->
    </div>

  </div>


  <div class=" overflow-y-auto">
    <ul class="nav  nav-justified  flex-nowrap">
      <li class="nav-item ">
        <a class="nav-link  text-active-primary " [ngClass]="activetab === 1 ? 'active' : ''" (click)="setActive(1)">
          Feed
        </a>
      </li>

      <li class="nav-item ">
        <a class="nav-link text-active-primary " [ngClass]="activetab === 2 ? 'active' : ''" (click)="setActive(2)">
          Events
        </a>
      </li>


      <li class="nav-item ">
        <a class="nav-link text-active-primary " [ngClass]="activetab === 3 ? 'active' : ''" (click)="setActive(3)">
          Offers
        </a>
      </li>
      <li class="nav-item ">
        <a class="nav-link text-active-primary " [ngClass]="activetab === 4 ? 'active' : ''" (click)="setActive(4)">
          Members
        </a>
      </li>
    </ul>
  </div>

  <div *ngIf="activetab===1">
    <div class="row  mt-3" *ngIf="app.activityLogs?.length <=0">
      <div class="col-12 text-center">
        <p>No data to display</p>

      </div>
    </div>
    <div class="row  mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="getMoreActivities()">
      <div class="col-md-8 offset-md-2">
        <ul class="list-unstyled border">
          <li class="border-bottom" *ngFor="let item of app.activityLogs">
            <app-custom [item]="item" [user]="user" [app]="app"></app-custom>
          </li>
        </ul>

      </div>
    </div>
  </div>

  <div *ngIf="activetab === 2">
    <div class="row  mt-3" *ngIf="allEvents?.length <=0">
      <div class="col-12 text-center">
        <p>No data to display</p>

      </div>
    </div>
    <div class="row  mt-3">
      <div class="col-lg-4 col-md-6 col-12 mb-4 relative" *ngFor="let item of allEvents">
        <a class="" [href]="['/events/'+ item.id]">
          <div class="card relative">
            <div class="timeover">
              <div class="d-block color">
                <h4 class="mb-0"> {{ item?.date_tz | date: 'dd' }} </h4>
                <p class="mb-0 fs-7"> {{ item?.date_tz | date: 'MMM' }} </p>
              </div>
            </div>
            <img [src]="item?.image ? item?.image : 'assets/imgs/background.jpg'" class="card-img-top" alt="" />
            <div class="eventTitle">
              <div class="text">
                {{ item?.name }}
              </div>
            </div>

          </div>
        </a>

      </div>
    </div>
  </div>

  <div *ngIf="activetab===3">
    <div class="row  mt-3" *ngIf="allOffers?.length <=0">
      <div class="col-12 text-center">
        <p>No data to display</p>

      </div>
    </div>
    <div class="row  mt-3">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 relative mb-4" *ngFor="let item of allOffers">
        <a *ngIf="!item.nft_hash" class="" [href]="social_pay_url + item.page_link" target="_blank">
          <div class="card relative">

            <div class="attend">
              <div class="inner" [ngClass]="item?.nft_hash  ? 'bg-primary': 'bg-success'">
                Buy
              </div>
            </div>
            <img [src]="item?.payment_image ? item?.payment_image : 'assets/imgs/background.jpg'"
              class="card-img-top offerimg" alt="" />
            <div class="eventTitle">
              <div class="text">
                {{ item?.page_name }}
              </div>
            </div>

          </div>
        </a>

        <a *ngIf="item.nft_hash" class="" [href]="mint_page_url + item.hash" target="_blank">
          <div class="card relative">

            <div class="attend">
              <div class="inner" [ngClass]="item?.nft_hash  ? 'bg-primary': 'bg-success'">
                Mint
              </div>
            </div>
            <img [src]="item?.nft?.image ? item?.nft?.image : 'assets/imgs/background.jpg'"
              class="card-img-top offerimg" alt="" />
            <div class="eventTitle">
              <div class="text">
                {{ item?.nft?.name }}
              </div>
            </div>

          </div>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="activetab === 4">
    <div class="row  mt-3">
      <div class="col-12">
        <div class="">
          <div class="c">

            <div *ngIf="towerMembers?.segment_users?.length <= 0">
              <p class="text-center">
                This creator has no supporters
              </p>
            </div>
            <div *ngIf="towerMembers?.segment_users?.length > 0">
              <div class="row  mt-3">

                <div class="col-lg-1 col-md-2 col-sm-3 col-4 mb-4 text-center"
                  *ngFor="let item of towerMembers?.segment_users" style="cursor: pointer">
                  <a [href]="'/'+item?.name">
                    <div class="text-center">
                      <img [src]="item?.image || 'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg'"
                        alt="" class="img-fluid towerImg">
                      <p class="tower" placement="bottom" [ngbTooltip]="item?.name">{{ truncateDescription(item?.name)
                        }}
                      </p>
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>