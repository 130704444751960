import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Wallet } from '@project-serum/anchor';
import { AppServiceService } from '../_services/app-service.service';
import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { WalletService } from '../_services/wallet.service';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { NotificationService } from '../_services/notification-service.service';
import { PusherService } from '../_services/pusher.service';
import { Howl } from 'howler'
import { NgbDropdownConfig, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  @Output() isNotification = new EventEmitter<void>();
  year: any
  theme: string
  public isMenuCollapsed = true;
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;
  garyPrice: number = null;
  currentPage: any = 1;
  coin_symbol: any;
  joinForm: FormGroup;
  noteForm: FormGroup;
  content: any;
  url: any;
  refresh: NodeJS.Timeout;
  private authLocalStorageToken = `user`;
  private userLocalStorageToken = `userdetails`;
  isAuthenticated: boolean;
  showsearch: boolean;
  priceRsc: any;
  custodian_wallet: any = [];
  notifications: any = [];
  totalBalance: any;
  solBalance: any;
  solPrice: any;
  users: any;
  user: any;
  interval: NodeJS.Timer;
  hasNotification: boolean = false;
  private subscription: Subscription;
  exchange_message: any;
  priority: string = 'default';
  priority_fee: number = 20000;
  priority_key = 'priority_fee'


  constructor(
    private app: AppServiceService,
    private alertService: AlertService,
    private loadingBar: LoadingBarService,
    private walletservice: WalletService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private router: Router,
    private modal: NgbModal,
    config: NgbModalConfig,
    private service: AppServiceService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private pusherService: PusherService,
    dropconfig: NgbDropdownConfig
  ) {
    dropconfig.placement = 'bottom-end';
    // dropconfig.autoClose = false;
    this.subscription = this.notificationService.hasNotification$.subscribe(hasNotification => {
      this.hasNotification = hasNotification;
    });
  }
  ngOnInit(): void {
    this.configcheck()
    this.year = new Date().getUTCFullYear();
    this.isNotification.emit();
    this.getUser()
    this.getWalletBlances();
    this.getCoreBalance()
    this.url = window.location.pathname;
    this.getTheme()
    this.walletservice.initialize();
    this.getpricewid();
    this.initForm()
    this.getAuth()
    this.scrollPageToTop()
    this.getNotifications()
    const a = JSON.parse(localStorage.getItem('userdetails'));
    this.user = a

    this.interval = setInterval(() => this.getpricewid(), 60000)
    this.pusherService.channel.bind(`notification-for-${this.user.id}`, data => {
      if (data) {
        // alert(10);
        var sound = new Howl({
          src: ["https://bucket.socialconnector.io/notification.mp3"],
          html5: true,
          format: ['mp3', 'aac']
        });

        sound.play();
        this.hasNotification = !this.hasNotification;
        this.getNotifications()
      }
    });

    this.getPriority()
  }
  getPriority() {
    const a = localStorage.getItem(this.priority_key)
    if (!a) {
      localStorage.setItem(this.priority_key, this.priority_fee.toString())
    } else {
      this.priority_fee = parseFloat(a)
      this.setpriority()
    }
  }



  getWalletBlances() {
    this.app.getUserWalletBalances().subscribe({
      next: (res: any) => {
        console.log(res)
        let data = res.data
        this.custodian_wallet = data
        // console.log(this.custodian_wallet)
      },
      error: (err: any) => { },
      complete: () => {
        let totalBalanceUsd = 0;

        for (let i = 0; i < this.custodian_wallet.length; i++) {
          totalBalanceUsd += this.custodian_wallet[i].balance_usd;
        }
        this.totalBalance = totalBalanceUsd
        this.app.getSolBalance().subscribe({
          next: (res: any) => {
            console.log(res)
            this.solBalance = res.data.balance
            this.app.getSolPrice().subscribe({
              next: (res: any) => {
                this.solPrice = res?.data?.price;
                this.totalBalance += this.solBalance * this.solPrice
              },
              error: (err: any) => { },
              complete: () => { }
            })
          },
          error: (err: any) => { },
          complete: () => { }
        })

      }
    })
  }



  getCoreBalance() {
    this.app.getCoreBalance().subscribe({
      next: (res: any) => {
        console.log('price ', res)
        this.priceRsc = res.data
      }
    })
  }
  scrollPageToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  loginModal(content) {
    this.modal.open(content, { backdrop: 'static' }).result
  }
  cancel() {
    this.modal.dismissAll()
    this.noteForm.reset();
    this.joinForm.reset()
    // this.content = null
  }
  initForm() {
    this.joinForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],

    });
  }
  login() {
    const payload = {
      email: this.joinForm.value.email,
      password: this.joinForm.value.password,
      service: 'event'
    }

    this.service.login(payload).subscribe({
      next: async (res: any) => {
        console.log(res)
        const token = res.data.token
        this.getUserByToken(token);
        const result = this.service.setAuthFromLocalStorage(res);
        // location.reload();
      },
      error: (err: any) => {
        console.log(err)
        // this.alertService.danger('error')
        // this.alertService.danger(err.error.message)
        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
        this.cancel()
      }
    })

  }
  getUserByToken(token) {
    this.service.getUserByToken(token).subscribe({
      next: (res: any) => {
        // console.log('User reeeeeeee ', res)
        this.service.saveUserToStorage(res)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        location.reload();
        //  this.submit()
        // this.modal.open(this.content, {}).result
      }
    })
  }

  async getUser() {
    const a = await this.service.getUser()
    this.users = a
    console.log('Userdetails', this.users)


  }
  getAuth() {
    const auth = this.app.getAuth()
    console.log(auth)
    if (auth) {
      this.isAuthenticated = true
    } else {

      this.isAuthenticated = false
      localStorage.removeItem(this.authLocalStorageToken);
      localStorage.removeItem(this.userLocalStorageToken);
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.refresh) {
      clearInterval(this.refresh);
    }
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  getTheme() {
    if (localStorage.getItem('theme') == null) {
      this.theme = 'dark-theme';
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    } else {
      this.theme = localStorage.getItem('theme') || 'light';
    }

  }


  toggleDarkTheme(): void {
    let theme = localStorage.getItem('theme');

    if (theme === 'dark-theme') {
      document.body.classList.remove('dark-theme');
      localStorage.setItem('theme', 'light');
    } else {
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    }
    window.location.reload();
  }

  // toggleNotification(): void {
  //   this.router.navigate(['/notifications']);
  // }


  onDisconnect() {
    this.walletservice.onDisconnect();
  }

  onSelectWallet(walletName) {
    this.walletservice.onSelectWallet(walletName);
  }

  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  fixUnsafe(url) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  copyText() {
    // console.log(this.walletservice.wallet.publicKey);
    this.clipboard.copy(this.walletservice.wallet.publicKey);
    this.alertService.success('Copied');
  }

  getpricewid() {
    this.app.getGaryPrice().subscribe({
      next: (res: any) => {
        console.log('Gary Details', res);
        this.coin_symbol = res.data.coin.coin_symbol;
        this.garyPrice = res.data.cg_price_usd
      },
    });
  }

  getNotifications() {
    this.app.getNotifications().subscribe({
      next: (res: any) => {
        this.notifications = res.data.data
        const allReadAtNonNull = this.notifications.every(el => el.read_at !== null);

        if (!allReadAtNonNull) {
          this.notificationService.updateNotificationIndicator(true);
        } else {
          this.notificationService.updateNotificationIndicator(false);
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  onClick() {
    // this.alertService.info('This service is coming soon');
    // this.router.navigateByUrl('https://app.socialconnector.io/auth/registration/g104akl3')
    const link = 'https://app.socialconnector.io/auth/registration/g104akl3'
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = link;
    a.click();
    document.body.removeChild(a);
  }

  logOut() {
    this.app.logout()
    // this.currentUserSubject.next(null);
    const returnUrl = location.pathname;
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.userLocalStorageToken);
    this.router.navigate(['/login']);
    // window.location.reload()
  }

  search(content2) {
    this.modal.open(content2, { centered: false })
  }

  testSearch() {
    this.showsearch = true
  }

  navigatetodashboard() {
    const url = `https://app.socialconnector.io/dashboard`
    window.location.href = `${url}`
  }

  configcheck() {
    this.app.checkConfig().subscribe({
      next: (res: any) => {
        console.log('wormhole response', res)
        if (res.data.exchange_message.config_value !== "") {
          this.exchange_message = res.data.exchange_message.config_value
        } else {
          this.exchange_message = null
        }

      }
    })
  }

  toggleRPC(priority) {
    if (priority === 'default') {
      this.priority_fee = 20000
    } else if (priority === 'fast') {
      this.priority_fee = 30000
    } else if (priority === 'turbo') {
      this.priority_fee = 50000
    }


  }
  setpriority() {
    // console.log(this.priority_fee)
    if (this.priority_fee !== 20000 && this.priority_fee !== 30000 && this.priority_fee !== 50000) {
      this.priority = 'custom'
    } else if (this.priority_fee === 20000) {
      this.priority = 'default'
    } else if (this.priority_fee === 30000) {
      this.priority = 'fast'
    } else if (this.priority_fee === 50000) {
      this.priority = 'turbo'
    }
  }

  setRPC(value) {
    this.priority = value
    this.toggleRPC(this.priority)
  }

  save() {
    // console.log(this.priority_fee)
    localStorage.setItem(this.priority_key, this.priority_fee.toString())
    this.alertService.success('Priority fee saved!');
    this.app.getPriority()
    // Swal.fire()
  }
}
